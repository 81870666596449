import React from "react"
import { getLocalizedText } from "../../../shared/locale"

function getCircle(range) {
    const radius = (range.range.high - range.range.low) / 2.0
    const center = range.range.low + radius

    return (
        <circle
            key={range.rank}
            className={`ratio-rank-${range.rank}`}
            cx={center}
            cy={100 - center}
            r={radius}
            clipPath="url(#triangle)"
        />
    )
}

const indexShapes = {
    simple: () => (
        <path d="M236,120H223.66406A96.15352,96.15352,0,0,0,136,32.33618V20a8,8,0,0,0-16,0V32.33618A96.15352,96.15352,0,0,0,32.33594,120H20a8,8,0,0,0,0,16H32.33594A96.15352,96.15352,0,0,0,120,223.66382V236a8,8,0,0,0,16,0V223.66382A96.15352,96.15352,0,0,0,223.66406,136H236a8,8,0,0,0,0-16Zm-40,16h11.59912A80.14164,80.14164,0,0,1,136,207.59912V196a8,8,0,0,0-16,0v11.59912A80.14164,80.14164,0,0,1,48.40088,136H60a8,8,0,0,0,0-16H48.40088A80.14164,80.14164,0,0,1,120,48.40088V60a8,8,0,0,0,16,0V48.40088A80.14164,80.14164,0,0,1,207.59912,120H196a8,8,0,0,0,0,16Zm-28-8a40,40,0,1,1-40-40A40.04552,40.04552,0,0,1,168,128Z" />
    ),
    arthro: () => (
        <g>
            <circle cx={120} cy={120} r={80} className="external-circle" />
            <circle cx={120} cy={120} r={50} className="internal-circle" />
        </g>
    ),
}

export const RatioIndex = ({
    id,
    denominator,
    numerator,
    scale = 0.08,
    ratioStyle,
}) => {
    const IndexShape = indexShapes[ratioStyle]
    return (
        <g
            className={`ratio-index ratio-index-${id} ratio-index-${ratioStyle}`}
            style={{
                overflow: "visible",
                transform: `translate(${denominator.value - 10}px, ${
                    90.0 - numerator.value
                }px) scale(${scale}, ${scale})`,
            }}>
            <IndexShape />
        </g>
    )
}

const Ratio = ({
    id,
    language,
    numerator,
    denominator,
    ranks = [],
    points = {},
    indexLegend = "",
    ratioStyle = "simple",
}) => {
    return (
        <div
            className={`ratio-chart-container ${id}-chart-container`}
            style={{ overflow: "visible" }}>
            <svg
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                style={{ overflow: "visible" }}>
                <defs>
                    <clipPath id="triangle">
                        <path d="M 0,0 L 0,100 L 100,100 Z" />
                    </clipPath>
                </defs>
                <g className={`ratio-chart ${id}-chart`}>
                    {ranks.map((r) => getCircle(r))}
                </g>
                <g className={`ratio-horizontal-ticks`}>
                    <line
                        className={`ratio-horizontal-ticks-line`}
                        x1="0"
                        y1="100"
                        x2="100"
                        y2="100"
                    />
                    {[0, 50, 100].map((value, idx) => (
                        <>
                            <line
                                key={idx}
                                className={`ratio-horizontal-ticks-tick`}
                                x1={value}
                                y1="100"
                                x2={value}
                                y2="104"
                            />
                            <text
                                className={`ratio-horizontal-ticks-label`}
                                transform={`translate(${value} 110)`}>
                                <tspan x="0" y="0">
                                    {value + "%"}
                                </tspan>
                            </text>
                        </>
                    ))}
                </g>
                <g className={`ratio-vertical-ticks`}>
                    <line
                        className={`ratio-vertical-ticks-line`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="100"
                    />
                    {[0, 50, 100].map((value, idx) => (
                        <>
                            <line
                                key={idx}
                                className={`ratio-vertical-ticks-tick`}
                                x1={0}
                                y1={100 - value}
                                x2={-4}
                                y2={100 - value}
                            />
                            <text
                                className={`ratio-vertical-ticks-label`}
                                transform={`translate(0 ${100 - value})`}>
                                <tspan x="-8" y="3">
                                    {value + "%"}
                                </tspan>
                            </text>
                        </>
                    ))}
                </g>
                <RatioIndex
                    id={id}
                    ratioStyle={ratioStyle}
                    numerator={numerator}
                    denominator={denominator}
                />
                <g className={`ratio-points ${id}-points`}>
                    {points.map((p) => (
                        <>
                            <line
                                className={`ratio-points-line`}
                                x1={p.x}
                                x2={p.x + 20}
                                y1={100 - p.y}
                                y2={100 - p.y - 20}
                            />
                            <circle
                                cx={p.x + 20}
                                cy={100 - p.y - 20}
                                r={2}
                                className={`ratio-points-line-end`}
                            />
                            <foreignObject
                                className={`ratio-points-label`}
                                x={p.x + 25}
                                y={100 - p.y - 30}>
                                <span>
                                    {getLocalizedText(p.label, language)}
                                </span>
                            </foreignObject>
                        </>
                    ))}
                </g>
            </svg>
            {indexLegend ? (
                <div className={"ratio-index-sample ratio-index-sample-" + id}>
                    <svg>
                        <RatioIndex
                            id={id + "_sample"}
                            ratioStyle={ratioStyle}
                            numerator={{ value: 90 }}
                            denominator={{ value: 90 }}
                            scale={0.15}
                        />
                    </svg>
                    <span>{getLocalizedText(indexLegend, language)}</span>
                </div>
            ) : null}
        </div>
    )
}

export const SummaryRatio = ({
    denominator,
    numerator,
    rank = "neutral",
    editable = false,
    onSave = () => {},
    onReset = () => {},
}) => {
    return (
        <div className="values">
            {editable ? (
                <div className="ratio-reset" onClick={onReset}>
                    Reset
                </div>
            ) : null}
            <span
                contentEditable={editable}
                onBlur={
                    editable ? (e) => onSave(e.target.innerText) : undefined
                }
                className={`numerator rank-${rank}`}>
                {numerator.value}
            </span>{" "}
            /{" "}
            <span className={`denominator rank-${rank}`}>
                {denominator.value}
            </span>
        </div>
    )
}

export default Ratio
