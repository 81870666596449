import React from "react"
import { withRouter } from "react-router"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { referralSchema as schema } from "./schemas"
import ReferralForm from "./form"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"

export const AddReferralForm = ({ history, templates }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const emptyReferral = {
        code: "",
        name: "",
        email: "",
        pec: "",
        fiscalCode: "",
        phone: "",
        address: "",
        city: "",
        province: "",
        cap: "",
        piva: "",
        sdi: "",
        sendRules: {
            patient: false,
            referral: false,
        },
        attachmentRules: {
            referral: "Complete",
            patient: "Complete",
        },
        customizations: [],
    }

    const update = (referral) => {
        api.update(referral, true).then(() => {
            history.push(routes.referrals.index.url)
        })
    }

    return (
        <ReferralForm
            isEdit={false}
            initialValues={emptyReferral}
            schema={schema}
            onSubmit={update}
            onCancel={() => history.goBack()}
            formProps={{ title: "Add Referral", primaryText: "Save" }}
            templates={templates?.items || []}
        />
    )
}

const loadData = ({ api }) => {
    return api.loadTemplates()
}

export const AddReferral = ({ history, match }) => {
    const api = mkApi()
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        api,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    if (data)
        return (
            <AddReferralForm history={history} match={match} templates={data} />
        )
    return null
}

export default withRouter(AddReferral)
