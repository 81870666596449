import React from "react"
import TextField from "../shared/components/textfield"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionMetadataEdit = ({ formist }) => {
    return (
        <>
            <input type="hidden" {...formist.getFieldProps("id")} />
            <input type="hidden" {...formist.getFieldProps("index")} />
            <div className="form-row">
                <LocalizedTextField
                    label="Name"
                    width="r-col-60"
                    {...formist.getFieldProps("name")}
                />
                <TextField
                    label="Html Element Id"
                    width="col"
                    {...formist.getFieldProps("htmlElementId")}
                />
            </div>
        </>
    )
}

export default SectionMetadataEdit
