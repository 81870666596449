import React from "react"

const COLOR_STYLES = [
    { label: "Black", style: "black" },
    { label: "Red", style: "red" },
    { label: "Orange", style: "orange" },
    { label: "Yellow", style: "yellow" },
    { label: "Green", style: "green" },
    { label: "Blue", style: "blue" },
    { label: "Indigo", style: "indigo" },
    { label: "Violet", style: "violet" },
]

export const colorStyleMap = {
    black: {
        color: "rgba(0, 0, 0, 1.0)",
    },
    red: {
        color: "rgba(255, 0, 0, 1.0)",
    },
    orange: {
        color: "rgba(255, 127, 0, 1.0)",
    },
    yellow: {
        color: "rgba(180, 180, 0, 1.0)",
    },
    green: {
        color: "rgba(0, 180, 0, 1.0)",
    },
    blue: {
        color: "rgba(0, 0, 255, 1.0)",
    },
    indigo: {
        color: "rgba(75, 0, 130, 1.0)",
    },
    violet: {
        color: "rgba(127, 0, 255, 1.0)",
    },
}

const ColorStyleControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle()

    const onSelect = (e) => {
        props.onToggle(e.target.value)
    }

    return (
        <select onChange={onSelect}>
            {COLOR_STYLES.map((type) => (
                <option
                    key={type.label}
                    selected={currentStyle.has(type.style)}
                    value={type.style}>
                    {type.label}
                </option>
            ))}
        </select>
    )
}

export default ColorStyleControls
