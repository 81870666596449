import React from "react"
import Select from "@atlaskit/select"
import { truncate } from "lodash"

const Filters = ({
    templates,
    setTemplates,
    selectedTemplates,
    sections,
    setSections,
    selectedSections,
}) => {
    const templateOptions = [
        ...templates.map((t) => ({
            label: t.name,
            value: t.id,
            sections: t.sections,
        })),
    ]
    const sectionOptions = [
        ...sections.map((s) => ({
            label: `${s.name}${
                s.description
                    ? " (" + truncate(s.description, { length: 20 }) + ")"
                    : ""
            } - ${s.type}`,
            value: s.id,
        })),
    ]
    return (
        <div>
            {templates.length > 1 ? (
                <>
                    <h5>Filter Templates:</h5>
                    <Select
                        onChange={setTemplates}
                        value={selectedTemplates}
                        options={templateOptions}
                        isMulti
                        id="template"
                    />
                </>
            ) : null}
            <h5>Filter Sections:</h5>
            <Select
                onChange={setSections}
                value={selectedSections}
                options={sectionOptions}
                isMulti
                id="section"
            />
        </div>
    )
}

export default Filters
