import React from "react"
import Wizard from "../shared/components/wizard"
import PatientGuardianForm, {
    model as empty,
} from "../patients/patient-guardian"
import { withRouter } from "react-router"
import useFormist from "react-formist"
import { getItem, mergeItem } from "./storage"
import { routes } from "../../shared/routing"
import { apis } from "../../shared/apis"
import { undo } from "./form"
import { guardianSchema as schema } from "../patients/schemas"

const RegisterPatientGuardian = ({ history, match }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)
    const initialData =
        (geneticAnalysis.patientDetails &&
            geneticAnalysis.patientDetails.guardian) ||
        empty
    const formist = useFormist(initialData, {
        schema,
        onSubmit: (data) => {
            if (data.guardian === "yes") {
                apis.patients.guardian
                    .updateOne(geneticAnalysis.patientId, barcode, data)
                    .then(() => {
                        mergeItem(barcode, {
                            patientDetails: {
                                ...geneticAnalysis.patientDetails,
                                guardian: data,
                            },
                        })
                        history.push(
                            routes.geneticAnalyses.registerPatientDiseases.buildUrl(
                                barcode,
                            ),
                        )
                    })
            } else {
                mergeItem(barcode, {
                    patientDetails: {
                        ...geneticAnalysis.patientDetails,
                        guardian: {
                            guardian: "no",
                        },
                    },
                })
                history.push(
                    routes.geneticAnalyses.registerPatientDiseases.buildUrl(
                        barcode,
                    ),
                )
            }
        },
    })

    return (
        <Wizard
            title={`Legal Guardian for: ${barcode}`}
            onUndo={undo(history, barcode)}
            onPrevious={() => history.goBack()}
            {...formist.getFormProps()}>
            <PatientGuardianForm formist={formist} />
        </Wizard>
    )
}

export default withRouter(RegisterPatientGuardian)
