import SubForm from "../shared/components/subform"
import PatientPhysicalDescriptionForm from "./patient-physical"
import PatientGuardianForm from "./patient-guardian"
import PatientDiseasesForm from "./patient-diseases"
import PatientDrugsForm from "./patient-drugs"
import React, { useState } from "react"
import DynamicTableStateless from "@atlaskit/dynamic-table"
import Tabs from "@atlaskit/tabs"
import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"
import StatusLink from "../shared/components/status-link"
import { getItem } from "../../shared/security/storage"
import { analysisTypes } from "../../analysisTypes"
import ArrowRightCircleIcon from "@atlaskit/icon/glyph/arrow-right-circle"

export const EditMultiBarcode = ({
    formist,
    barcode,
    analyses,
    patientData,
    setBarcode,
}) => {
    const user = getItem("user")
    const [selectedTab, selectTab] = useState(0)

    const head = {
        cells: [
            {
                key: "pointer",
                content: "",
                width: 3,
            },
            {
                key: "barcode",
                content: "Barcode",
            },
            {
                key: "referral",
                content: "Referral",
            },
            {
                key: "status",
                content: "Status",
            },
            {
                key: "type",
                content: "Type",
            },
            { key: "report-print" },
            { key: "report-show" },
        ],
    }
    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content:
                            item.barcode === barcode ? (
                                <ArrowRightCircleIcon
                                    size="medium"
                                    primaryColor="#0052CC"
                                />
                            ) : null,
                    },
                    {
                        content: (
                            <Link
                                to={{
                                    pathname:
                                        routes.geneticAnalyses.edit.buildUrl(
                                            item.barcode,
                                        ),
                                }}>
                                {item.barcode}
                            </Link>
                        ),
                    },
                    {
                        content: item.referral,
                    },
                    {
                        content: (
                            <span
                                className={`barcode-status barcode-status-${item.status.toLowerCase()}`}>
                                {getStatusLabel(item.status)}
                            </span>
                        ),
                    },
                    {
                        content: getAnalysisType(item.analysisType),
                    },
                    {
                        content: (
                            <StatusLink
                                item={item}
                                expectedStatus="analysed"
                                linkText="Show Report"
                                to={{
                                    pathname: routes.reports.show.buildUrl(
                                        item.barcode,
                                    ),
                                }}
                            />
                        ),
                    },
                    {
                        content: (item.layoutIds || [undefined]).map(
                            (layout) => (
                                <div key={layout}>
                                    <StatusLink
                                        item={item}
                                        expectedStatus="analysed"
                                        linkText={`Print ${
                                            !item.layoutIds ||
                                            item.layoutIds.length === 1
                                                ? "Report"
                                                : layout
                                        }`}
                                        to={{
                                            pathname: routes.reports.toPrintUrl(
                                                {
                                                    layout,
                                                    barcode: item.barcode,
                                                    reportMode: "complete",
                                                    token: user.token,
                                                },
                                            ),
                                        }}
                                        target="_blank"
                                    />
                                </div>
                            ),
                        ),
                    },
                    {
                        content:
                            item.barcode === barcode ? (
                                <span>Editing</span>
                            ) : (
                                <Link
                                    to=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setBarcode(item.barcode)
                                    }}>
                                    Edit
                                </Link>
                            ),
                    },
                ],
            }
        })
    }
    const tabs = [
        {
            label: "Physical Description",
            content: (
                <div className="form-container-separator">
                    <SubForm
                        formist={formist}
                        namespace="patientPhysicalDescription">
                        {({ getFieldProps, change }) => (
                            <PatientPhysicalDescriptionForm
                                formist={{ getFieldProps, change }}
                                anonymous={
                                    formist.getFieldProps("patient.anonymous")
                                        .value
                                }
                                barCode={
                                    barcode ||
                                    patientData.patientPhysicalDescription
                                        .barcode
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Guardian",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientGuardian">
                        {({ getFieldProps }) => (
                            <PatientGuardianForm
                                formist={{ getFieldProps }}
                                barCode={
                                    barcode ||
                                    patientData.patientGuardian.barcode
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Diseases",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientDiseases">
                        {({ getFieldProps }) => (
                            <PatientDiseasesForm
                                formist={{ getFieldProps }}
                                barCode={
                                    barcode ||
                                    patientData.patientDiseases.barcode
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Drugs",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientDrugs">
                        {({ getFieldProps }) => (
                            <PatientDrugsForm
                                formist={{ getFieldProps }}
                                barCode={
                                    barcode || patientData.patientDrugs.barcode
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
    ]

    function getStatusLabel(status) {
        if (status === "NotYetRegistered") return "To Register"
        return status
    }
    function getAnalysisType(type) {
        const analysisType = analysisTypes.filter((a) => a.value === type)[0]
        if (analysisType) return analysisType.label
        return "Unknown"
    }

    return (
        <>
            <div className="form-container-separator">
                {analyses.items.length > 0 ? (
                    <div className="patient-barcodes">
                        <DynamicTableStateless
                            head={head}
                            rows={mkRows(analyses.items)}
                            isFixedSize
                        />
                    </div>
                ) : null}
                <div className="form-container-separator">
                    <Tabs
                        tabs={tabs}
                        onSelect={(tab, idx) => selectTab(idx)}
                        selected={selectedTab}
                    />
                </div>
            </div>
        </>
    )
}
