import { apis } from "../../shared/apis"

function filterError(resp) {
    if (!resp.ajaxError) return resp
}

export function mkApi() {
    return {
        load(barcode) {
            return apis.geneticAnalyses.load(barcode)
        },
        loadPatientsToApprove() {
            return apis.geneticAnalyses.loadOnlineRegistrations(180)
        },
        loadApprovedPatients() {
            return apis.geneticAnalyses.loadApprovedPatients(3)
        },
        approvePatients(patients) {
            return apis.geneticAnalyses
                .approvePatients({ patients })
                .then((res) => {
                    if (res.ajaxError) throw res.ajaxError
                    return res
                })
        },
        removePatientToApprove(id) {
            return apis.geneticAnalyses
                .removePatientToApprove(id)
                .then((res) => {
                    if (res.ajaxError) throw res.ajaxError
                    return res
                })
        },
        loadFull(barcode) {
            return apis.geneticAnalyses
                .load(barcode)
                .then((geneticAnalysis) => {
                    return apis.patients
                        .loadFull(geneticAnalysis.patientId, barcode)
                        .then((patientDetails) => {
                            return {
                                ...geneticAnalysis,
                                patientDetails,
                            }
                        })
                })
        },
        register(value) {
            return apis.geneticAnalyses.register(value).then(() => {
                if (value.analyzed) {
                    return apis.geneticAnalyses.markAsAnalysed(value.barcode)
                }
            })
        },
        updateReportConfiguration(value) {
            const { barcode, ...body } = value
            return apis.geneticAnalyses.updateReportConfiguration(barcode, body)
        },
        loadReferrals() {
            return apis.referrals.loadAll(1, 10000)
        },
        loadTemplates() {
            return apis.templates.loadAll(1, 1000)
        },
        loadLayouts() {
            return apis.layouts.loadAll(1, 1000)
        },
        loadPatients() {
            return apis.patients.loadAll(1, 10000)
        },
        searchPatients(pattern) {
            return apis.patients.search(pattern, 1, 10000)
        },
        loadNotAnonymousPatients() {
            return apis.patients.loadNotAnonymous(1, 10000)
        },
        loadPatientDetails(id) {
            return Promise.all([
                apis.patients.load(id),
                apis.patients.physicalDescription.loadLast(id),
                apis.patients.guardian.loadLast(id),
                apis.patients.diseases.loadLast(id),
                apis.patients.drugs.loadLast(id),
            ]).then((responses) => {
                return {
                    patientData: filterError(responses[0]),
                    physicalDescription: filterError(responses[1]),
                    guardian: filterError(responses[2]),
                    diseases: filterError(responses[3]),
                    drugs: filterError(responses[4]),
                }
            })
        },
        loadGeneticAnalyses(barcodes) {
            const loadMany = barcodes.map((x) => apis.geneticAnalyses.load(x))
            return Promise.all(loadMany)
        },
        sendApprovedEmail(barcode, options) {
            return apis.geneticAnalyses.sendApprovedEmail(barcode, options)
        },
        addReferral(value) {
            const { code, ...body } = value
            return apis.referrals.updateOne(code, body)
        },
        addOnePatient(patient) {
            return apis.patients.addOne(patient)
        },
    }
}
