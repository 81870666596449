import React from "react"
import { Link } from "react-router-dom"
import { error } from "../../../shared/notifications"

const showError = (msg) =>
    error(msg, {
        dismiss: false,
    })

const Component = (props) => {
    const { item, expectedStatus, linkText, error } = props
    const status = item.status.toLowerCase()

    if (status === expectedStatus)
        return <Link onClick={() => showError(error)}>{linkText}</Link>
    return <span>{linkText}</span>
}

export default Component
