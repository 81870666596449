import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.hormones
                .loadAll(pageNumber, pageSize)
                .then((hormones) => dispatch(actions.loadAll(hormones)))
        },
        load(id) {
            return apis.hormones.load(id)
        },
        update(value, isNew = false, reload = false) {
            const { name, ...body } = value
            return apis.hormones
                .updateOne(name, body)
                .then(() =>
                    dispatch(actions[isNew ? "add" : "edit"](value, reload)),
                )
        },
        search(pattern, pageNumber, pageSize) {
            return apis.hormones
                .search(pattern, pageNumber, pageSize)
                .then((hormones) => {
                    dispatch(actions.search({ pattern, ...hormones }))
                    return hormones
                })
        },
    }
}
