import React from "react"
import Spinner from "@atlaskit/spinner"

export default function Uploading() {
    return (
        <div className="upload-inprogress">
            <Spinner size="xlarge" isCompleting={false} />
            <span className="upload-message">
                Uploading file... Please wait...
            </span>
        </div>
    )
}
