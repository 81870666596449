import React, { useContext, useReducer } from "react"

const StateContext = React.createContext()

const StateContextProvider = ({ initialState, reducer, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const contextValue = { state, dispatch }
    return (
        <StateContext.Provider value={contextValue}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)

export const withStateContext = (AppComponent, initialState, reducer) => {
    const C = (props) => (
        <StateContextProvider initialState={initialState} reducer={reducer}>
            <AppComponent {...props} />
        </StateContextProvider>
    )

    const appName = AppComponent.displayName || AppComponent.name
    C.displayName = `withStateContext(${appName})`

    return C
}
