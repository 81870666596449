import React from "react"
import { withRouter } from "react-router"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"

export default (
    Component,
    api,
    loadData = () => {},
    getState = () => ({
        state: {},
    }),
    fromState = () => {},
    loadAdditional = [],
) => {
    const cachedLoadData = ({ stored, loadAdditional, ...other }) => {
        const loadDataPromise =
            (stored && Promise.resolve(stored)) || loadData(other)
        if (loadAdditional.length > 0) {
            return Promise.all([
                loadDataPromise,
                ...loadAdditional.map((f) => f(other)),
            ])
        }
        return loadDataPromise
    }
    return withRouter(({ match, history }) => {
        const id = match.params.id
        const { state } = getState()
        const stored = fromState(state, id)
        const { data, error, isLoading } = useAsync({
            promiseFn: cachedLoadData,
            api,
            id,
            stored,
            loadAdditional,
        })
        if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
        if (error) return `Something went wrong: ${error.message}`
        if (data)
            return <Component data={[data]} match={match} history={history} />
        return null
    })
}
