import * as yup from "yup"
import { isDateValid } from "../../shared/dates"

export const patientSchema = yup.object().shape({
    firstName: yup.string().when("anonymous", {
        is: false,
        then: (schema) => schema.required(),
    }),
    lastName: yup.string().when("anonymous", {
        is: false,
        then: (schema) => schema.required(),
    }),
    email: yup
        .string()
        .email()
        .when("anonymous", {
            is: false,
            then: (schema) => schema.required(),
        }),
    bornOn: yup.string().when("anonymous", {
        is: false,
        then: (schema) =>
            schema
                .required()
                .test(
                    "date",
                    "bornOn should be date in dd/mm/yyyy format",
                    isDateValid,
                ),
    }),
    birthPlace: yup.string().nullable(),
    socialNumber: yup.string().nullable(),
    vat: yup.string().nullable(),
    streetLine1: yup.string().nullable(),
    city: yup.string().nullable(),
    province: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    anonymous: yup.boolean().required(),
})

export const guardianSchema = yup.object().shape({
    guardian: yup.string().default("no").required(),
    firstName: yup.string().when("guardian", {
        is: "yes",
        then: (schema) => schema.required(),
    }),
    lastName: yup.string().when("guardian", {
        is: "yes",
        then: (schema) => schema.required(),
    }),
    socialNumber: yup.string(),
    email: yup.string(),
    telephone: yup.string(),
    address: yup.string(),
})

export const physicalDescriptionSchema = yup.object().shape({
    age: yup
        .number()
        .positive()
        .nullable()
        .transform((value, originalValue) =>
            originalValue === "" ? null : value,
        ),
    genderType: yup.string().default("unspecified"),
    weight: yup
        .number()
        .positive()
        .nullable()
        .transform((value, originalValue) =>
            originalValue === "" ? null : value,
        ),
    height: yup
        .number()
        .positive()
        .nullable()
        .transform((value, originalValue) =>
            originalValue === "" ? null : value,
        ),
    ethnicityType: yup.string().default("unspecified"),
    exerciseFrequencyType: yup.string().default("unspecified"),
    smokerType: yup.string().default("unspecified"),
    smokerNoMoreFrom: yup
        .string()
        .nullable()
        .when("smokerType", {
            is: "noMore",
            then: (schema) => schema.required(),
        }),
})

export const diseasesSchema = yup.object().shape({})

export const drugsSchema = yup.object().shape({})

export const fullSchema = yup.object().shape({
    patient: patientSchema,
    patientPhysicalDescription: physicalDescriptionSchema,
    patientGuardian: guardianSchema,
    patientDiseases: diseasesSchema,
    patientDrugs: drugsSchema,
})

export const genders = ["male", "female"]
export const ethnicities = ["caucasian", "african", "amerindian", "asian"]
export const exerciseFrequencies = ["low", "average", "high"]
export const smokers = ["no", "yes", "noMore"]
export const enterotypes = ["1", "2", "3"]
