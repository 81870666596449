import React from "react"
import { Link } from "react-router-dom"

const StatusLink = (props) => {
    const { item, expectedStatus, linkText, to, target, style = {} } = props
    const status = item.status.toLowerCase()
    if (status === expectedStatus)
        return (
            <Link style={style} to={to} target={target}>
                {linkText}
            </Link>
        )
    return <span style={style}>{linkText}</span>
}

export default StatusLink
