import { arrayToObj, objToArray } from "../../shared/fun"
import { identity } from "ramda"

export const checksToObj = (checks, defaults) =>
    arrayToObj(
        checks,
        (y) => y.label,
        () => defaults,
    )

export const objToChecks = (obj) => objToArray(obj, "name", "data", identity)

export const detailsToObj = (items) =>
    arrayToObj(
        items,
        (y) => y.name,
        (y) => y.data,
    )
