import React, { Component } from "react"

class SectionErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="section-error-boundary">
                    <h3>Error in Section</h3>
                    <details>
                        <summary>
                            {this.state.error?.toString() || "Unknown Error"}
                        </summary>
                        <p>Stack: {this.state.error?.stack || ""}</p>
                    </details>
                </div>
            )
        }
        return this.props.children
    }
}

export default SectionErrorBoundary
