import React from "react"
import { match } from "../../shared/sections"
import SectionStackedChart from "./section-stackedchart"
import SectionListGenoType from "./section-listgenotype"
import SectionTable from "./section-table"
import SectionCategory from "./section-category"
import SectionBarChart from "./section-bar-chart"
import SectionHistogram from "./section-histogram"
import SectionGauge from "./section-gauge"
import SectionRatio from "./section-ratio"
import SectionFreeText from "./section-free-text"
import SectionImage from "./section-image"
import SectionLines from "./section-lines"
import SectionGroup from "./section-group"
import SectionError from "./section-error"
import SectionErrorBoundary from "../shared/components/section-error-boundary"

export default function Section({
    barcode,
    history,
    style,
    item,
    language,
    onSaveFreetext,
    onDirty = () => {},
    autoSave = false,
    onSaveImage,
    onSaveRatio,
    onResetRatio,
    onCancel,
}) {
    function renderSection(section, barcode, language, style, history) {
        return match(section.type)
            .case({
                stackedchart(s) {
                    return <SectionStackedChart item={s} language={language} />
                },
                listgenotype(s) {
                    return (
                        <SectionListGenoType
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                category(s) {
                    return (
                        <SectionCategory
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                barchart(s) {
                    return (
                        <SectionBarChart
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                histogram(s) {
                    return (
                        <SectionHistogram
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                gauge(s) {
                    return (
                        <SectionGauge
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                freetext(s) {
                    return (
                        <SectionFreeText
                            style={style}
                            language={language}
                            item={s}
                            onSave={onSaveFreetext}
                            onDirty={onDirty}
                            onCancel={onCancel}
                            autoSave={autoSave}
                        />
                    )
                },
                ratio(s) {
                    return (
                        <SectionRatio
                            style={style}
                            editable={true}
                            onSave={onSaveRatio}
                            onReset={onResetRatio}
                            language={language}
                            item={s}
                        />
                    )
                },
                image(s) {
                    return (
                        <SectionImage
                            style={style}
                            language={language}
                            item={s}
                            autoSave={autoSave}
                            onSave={onSaveImage}
                            onCancel={onCancel}
                        />
                    )
                },
                table(s) {
                    return (
                        <SectionTable
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                lines(s) {
                    return (
                        <SectionLines
                            style={style}
                            language={language}
                            item={s}
                        />
                    )
                },
                group(s) {
                    return (
                        <SectionGroup
                            style={style}
                            language={language}
                            getComponent={renderSection}
                            barcode={barcode}
                            history={history}
                            item={s}
                        />
                    )
                },
                error(s) {
                    return (
                        <SectionError
                            style={style}
                            language={language}
                            getComponent={renderSection}
                            item={s}
                            barcode={barcode}
                            history={history}
                        />
                    )
                },
            })
            .apply(section)
    }
    return (
        <SectionErrorBoundary>
            {renderSection(item, barcode, language, style, history)}
        </SectionErrorBoundary>
    )
}
