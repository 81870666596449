import React from "react"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import TableField, { printableErrors } from "../shared/components/tablefield"
import useFormist from "react-formist"
import { getEffectType } from "./mappers"

const variantsSchema = [
    {
        field: "genoType",
        label: "GenoType",
    },
    {
        field: "genoTypeFileAlias",
        label: "File Alias",
    },
    {
        field: "name",
        label: "Name",
    },
    {
        field: "allele1",
        label: "Allele1",
    },
    {
        field: "alleleeffectvalue1",
        label: "ES1",
    },
    {
        type: "label",
        field: (record) => getEffectType(parseFloat(record.alleleeffectvalue1)),
        label: "EffectType1",
    },
    {
        field: "allele2",
        label: "Allele2",
    },
    {
        field: "alleleeffectvalue2",
        label: "ES2",
    },
    {
        type: "label",
        field: (record) => getEffectType(parseFloat(record.alleleeffectvalue2)),
        label: "EffectType2",
    },
    {
        field: "allele3",
        label: "Allele3",
    },
    {
        field: "alleleeffectvalue3",
        label: "ES3",
    },
    {
        type: "label",
        field: (record) => getEffectType(parseFloat(record.alleleeffectvalue3)),
        label: "EffectType3",
    },
    {
        field: "allele4",
        label: "Allele4",
    },
    {
        field: "alleleeffectvalue4",
        label: "ES4",
    },
    {
        type: "label",
        field: (record) => getEffectType(parseFloat(record.alleleeffectvalue4)),
        label: "EffectType4",
    },
]

const FormFields = ({
    initialValues,
    isEdit,
    schema,
    formProps,
    onSubmit,
    onCancel,
}) => {
    const formist = useFormist(initialValues, { schema, onSubmit })
    return (
        <Form {...formProps} {...formist.getFormProps()} onCancel={onCancel}>
            <div className="form-row">
                <TextField
                    label="Code"
                    width="col-sm-4"
                    readOnly={isEdit}
                    {...formist.getFieldProps("code")}
                />
            </div>
            <div className="form-row">
                <TableField
                    label="Variants"
                    editable
                    schema={variantsSchema}
                    errors={printableErrors(formist.errors, "variants")}
                    width="col"
                    {...formist.getFieldProps("variants")}
                />
            </div>
        </Form>
    )
}

export default FormFields
