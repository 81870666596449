import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.referrals
                .loadAll(pageNumber, pageSize)
                .then((referrals) => dispatch(actions.loadAll(referrals)))
        },
        load(id) {
            return apis.referrals.load(id)
        },
        loadTemplates() {
            return apis.templates.loadAllWithAllSections(1, 1000)
        },
        update(value, isNew = false, reload = false) {
            const { code, ...body } = value
            return apis.referrals
                .updateOne(code, body)
                .then(() =>
                    dispatch(actions[isNew ? "add" : "edit"](value, reload)),
                )
        },
        search(pattern, pageNumber, pageSize) {
            return apis.referrals
                .search(pattern, pageNumber, pageSize)
                .then((referrals) => {
                    dispatch(actions.search({ pattern, ...referrals }))
                    return referrals
                })
        },
    }
}
