import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import SectionDataSourceListEdit from "./section-datasource-list-edit"
import { CommentAction } from "@atlaskit/comment"
import CheckField from "../shared/components/checkfield"
import { pipe } from "ramda"
import { SelectField } from "../shared/components/selectfield"
import LocalizedTextField from "../shared/components/localized-textfield"

const appendLevel = (bacterium, level) =>
    bacterium.indexOf(";") === -1 ? level + ";" + bacterium : bacterium

const getThresholds = (section) =>
    section.bacteria.map(
        (b, index) =>
            (section.thresholds && section.thresholds[index]) || 100.0,
    )

const getOtherThresholds = (section) =>
    section.bacteria.map(
        (b, index) =>
            (section.otherThresholds && section.otherThresholds[index]) || 0,
    )

const bacteriaTypes = [
    { label: "Genus", value: "Genus" },
    { label: "Phylum", value: "Phylum" },
    { label: "Species", value: "Species" },
    { label: "Family", value: "Family" },
]

const compareTypes = [
    { label: ">", value: "GreaterThan" },
    { label: "<", value: "LessThan" },
    { label: "inside", value: "Inside" },
    { label: "outside", value: "Outside" },
]

const SectionCompareEdit = ({ section, bacteria = [], onSave, onCancel }) => {
    const model = {
        ...section,
        bacteria: section.bacteria.map((b) => atob(b)),
        thresholds: getThresholds(section),
        otherThresholds: getOtherThresholds(section),
    }

    function getFilteredBacteria() {
        return bacteria
            .filter(
                (b) =>
                    b.level === formist.getFieldProps("bacteriumLevel").value,
            )
            .map((b) => b.group)
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup
            .string()
            .test(
                "valid-description",
                "description is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        showValues: yup.bool().required(),
        bacteriumLevel: yup.string().required(),
        compareType: yup.string().required(),
        bacteria: yup
            .array()
            .of(
                yup
                    .string()
                    .test("valid-bacteria", "Unknown bacterium", (value) => {
                        return (
                            value.trim() === "" ||
                            getFilteredBacteria().filter(
                                (b) =>
                                    value === b ||
                                    value ===
                                        formist.getFieldProps("bacteriumLevel")
                                            .value +
                                            ";" +
                                            b,
                            ).length === 1
                        )
                    }),
            )
            .min(1)
            .required(),
        thresholds: yup.array().of(yup.number().min(0).max(100)),
        otherThresholds: yup.array().of(yup.number().min(0).max(100)),
        bacteriaRestId: yup
            .string()
            .test(
                "valid-bacteriaRestId",
                "bacteriaRestId is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const isOtherThresholdsNeeded = (type) =>
        type === "Inside" || type === "Outside"

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        bacteria: formist.values.bacteria
                            .filter((b) => b.trim() !== "")
                            .map(
                                pipe(
                                    (b) =>
                                        appendLevel(
                                            b,
                                            formist.getFieldProps(
                                                "bacteriumLevel",
                                            ).value,
                                        ),
                                    btoa,
                                ),
                            ),
                        thresholds: formist.values.bacteria
                            .filter((b) => b.trim() !== "")
                            .map(
                                (_, index) =>
                                    (formist.values.thresholds &&
                                        formist.values.thresholds[index]) ||
                                    100.0,
                            ),
                        otherThresholds: isOtherThresholdsNeeded(
                            formist.values.compareType,
                        )
                            ? formist.values.bacteria
                                  .filter((b) => b.trim() !== "")
                                  .map(
                                      (_, index) =>
                                          (formist.values.otherThresholds &&
                                              formist.values.otherThresholds[
                                                  index
                                              ]) ||
                                          100.0,
                                  )
                            : null,
                    })
                }
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Level"
                    width="col"
                    options={bacteriaTypes}
                    {...formist.getFieldProps("bacteriumLevel")}
                />
                <SelectField
                    label="CompareType"
                    width="col"
                    options={compareTypes}
                    {...formist.getFieldProps("compareType")}
                />
            </div>
            <SectionDataSourceListEdit
                dataSourceField={formist.getFieldProps("bacteria")}
                thresholdsField={formist.getFieldProps("thresholds")}
                otherThresholdsField={
                    isOtherThresholdsNeeded(formist.values.compareType)
                        ? formist.getFieldProps("otherThresholds")
                        : undefined
                }
                errors={formist.errors}
                hideLevel
                suggestions={getFilteredBacteria()}
            />
            <div className="form-row">
                <LocalizedTextField
                    label="Other Bacteria Label"
                    width="r-col-20"
                    {...formist.getFieldProps("bacteriaRestId")}
                />
                <div style={{ marginLeft: 50, marginTop: 50 }}>
                    <CheckField
                        label="Show Values in table"
                        width="col"
                        {...formist.getFieldProps("showValues")}
                    />
                </div>
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionCompareEdit
