import React, { useState } from "react"
import Tabs from "@atlaskit/tabs"
import ReporterToDo from "./todo"
import ReporterDone from "./done"

function ReporterDashboard() {
    const [selectedTab, selectTab] = useState(0)

    const tabs = [
        {
            label: <h2>To do</h2>,
            content: <ReporterToDo />,
        },
        {
            label: <h2>Done</h2>,
            content: <ReporterDone />,
        },
    ]

    return (
        <div className="mt-4">
            <Tabs
                tabs={tabs}
                onSelect={(_, idx) => selectTab(idx)}
                selected={selectedTab}
            />
        </div>
    )
}

export default ReporterDashboard
