import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.patients
                .loadAll(pageNumber, pageSize)
                .then((patients) => dispatch(actions.loadAll(patients)))
        },
        loadGeneticAnalyses(patientId) {
            return apis.geneticAnalyses.loadByPatients(patientId)
        },
        load(id) {
            return apis.patients.load(id)
        },
        loadFull(id, barcode = "last") {
            return apis.patients.loadFull(id, barcode)
        },
        loadBarcode(barcode) {
            return apis.patients.loadBarcode(barcode)
        },
        loadReferrals() {
            return apis.referrals.loadAll(1, 10000)
        },
        update(value) {
            const { id, ...body } = value
            return apis.patients
                .updateOne(id, body)
                .then(() => dispatch(actions.update(value)))
        },
        updateFull(value) {
            const id = value.patient.id
            return apis.patients
                .updateFull(id, value)
                .then(() => dispatch(actions.update(value)))
        },
        addFull(value) {
            return apis.patients.addFull(value).then(({ id }) => {
                dispatch(actions.add(id, value))
            })
        },
        search(pattern, pageNumber, pageSize) {
            return apis.patients
                .search(pattern, pageNumber, pageSize)
                .then((patients) =>
                    dispatch(actions.search({ pattern, ...patients })),
                )
        },
        removePatient(id) {
            return apis.patients.remove(id).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
    }
}
