import React, { createRef, useCallback } from "react"
import TextArea from "../shared/components/textarea"
import { useSuggest } from "../../shared/form"

const suggestionFind = (suggestions) => (text, callback) => {
    const keyword = text.toLowerCase()
    const results = suggestions.reduce((previous, word) => {
        if (
            word.toLowerCase().indexOf(keyword) !== -1 &&
            word.toLowerCase() !== keyword
        ) {
            return [...previous, word]
        }
        return previous
    }, [])
    setTimeout(() => {
        callback(results)
    }, 300)
}

const SectionDataSourceListEdit = ({
    fieldName = "bacteria",
    dataSourceField,
    thresholdsField,
    otherThresholdsField,
    errors = {},
    hideLevel = false,
    suggestions = [],
    separator = ";",
    title = "Bacteria",
}) => {
    const { value, onChange, error, ...other } = dataSourceField
    const textarea = createRef(null)
    useSuggest({
        input: textarea,
        suggestions: [useCallback(suggestionFind(suggestions), [suggestions])],
        onSelected: (value) => {
            onChange({
                target: {
                    value: value.split("\n"),
                },
            })
        },
        regex: "(?:^|\\s+|" + separator + ")([a-zA-Z0-9 ]+)$",
    })

    const bacteriaValue = hideLevel
        ? value.map((v) => v.substring(v.lastIndexOf(separator) + 1)).join("\n")
        : value.join("\n")

    const onBacteriaChange = (e) => {
        e.preventDefault()
        onChange({
            target: {
                value: e.target.value.split("\n"),
            },
        })
    }
    const bacteriaError = (errors[fieldName] || [])
        .map((e, idx) => "Row " + (idx + 1) + ": " + e)
        .filter((b) => b)
        .join(", ")

    const thresholdsValue = thresholdsField
        ? thresholdsField.value.join("\n")
        : ""

    const thresholdsError = (errors["thresholds"] || [])
        .map((e, idx) => "Row " + (idx + 1) + ": " + e)
        .filter((b) => b)
        .join(", ")

    const onThresholdsChange = (e) => {
        e.preventDefault()
        thresholdsField.onChange({
            target: {
                value: e.target.value.split("\n"),
            },
        })
    }

    const otherThresholdsValue = otherThresholdsField
        ? otherThresholdsField.value.join("\n")
        : ""

    const otherThresholdsError = (errors["otherThresholds"] || [])
        .map((e, idx) => "Row " + (idx + 1) + ": " + e)
        .filter((b) => b)
        .join(", ")

    const onOtherThresholdsChange = (e) => {
        e.preventDefault()
        otherThresholdsField.onChange({
            target: {
                value: e.target.value.split("\n"),
            },
        })
    }

    return (
        <>
            <div className="form-row">
                <TextArea
                    label={title}
                    width="col"
                    rows={10}
                    ref={textarea}
                    error={bacteriaError}
                    {...other}
                    value={bacteriaValue}
                    onChange={onBacteriaChange}
                />

                {thresholdsField && (
                    <TextArea
                        label="Thresholds"
                        width="col"
                        rows={10}
                        error={thresholdsError}
                        {...other}
                        value={thresholdsValue}
                        onChange={onThresholdsChange}
                    />
                )}

                {otherThresholdsField && (
                    <TextArea
                        label="High Thresholds"
                        width="col"
                        rows={10}
                        error={otherThresholdsError}
                        {...other}
                        value={otherThresholdsValue}
                        onChange={onOtherThresholdsChange}
                    />
                )}
            </div>
        </>
    )
}

export default SectionDataSourceListEdit
