import React from "react"
import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"
import Email from "./approve-emails"
import { showError } from "./approve-helpers"
import { mkApi } from "./api"
import DynamicTableStateless from "@atlaskit/dynamic-table"

const ApprovedPatient = ({ approved, onSentEmail, isLoading }) => {
    const head = {
        cells: [
            {
                key: "fullname",
                content: "Full Name",
                width: 15,
            },
            {
                key: "email",
                content: "Email",
                width: 20,
            },
            {
                key: "referralcode",
                content: "Referral Code",
                width: 15,
            },
            {
                key: "barcodes",
                content: "Barcode(s)",
                width: 10,
            },
            {
                key: "actions",
                content: "",
                width: 20,
            },
        ],
    }
    const makeRows = (approved, onSentEmail) => {
        return approved.items.map((item, index) => {
            const { patient, barcodes, properties } = item

            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <>
                                {patient.firstName} {patient.lastName}
                            </>
                        ),
                    },
                    {
                        content: <>{patient.email}</>,
                    },
                    {
                        content: <>{properties["CodiceReferente"]}</>,
                    },
                    {
                        content: (
                            <div>
                                {barcodes.map((barcode) => (
                                    <div
                                        className="default-high-cell"
                                        key={barcode}>
                                        {barcode}
                                    </div>
                                ))}
                            </div>
                        ),
                    },
                    {
                        content: barcodes.map((barcode) => (
                            <>
                                <div className="row default-high-cell">
                                    <div className="col">
                                        <Link
                                            className="ml-2"
                                            to={{
                                                pathname:
                                                    routes.patients.edit.buildUrl(
                                                        barcode,
                                                    ),
                                            }}
                                            target="_blank">
                                            Patient
                                        </Link>
                                        <Link
                                            className="ml-2"
                                            to={{
                                                pathname:
                                                    routes.geneticAnalyses.edit.buildUrl(
                                                        barcode,
                                                    ),
                                            }}
                                            target="_blank">
                                            Analysis
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <Email
                                            key={`${barcode}-emails`}
                                            api={mkApi()}
                                            onSent={onSentEmail}
                                            onError={showError}
                                            geneticAnalysis={
                                                approved.geneticAnalyses[
                                                    barcode
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )),
                    },
                ],
            }
        })
    }

    return (
        <div className="form-container-separator">
            <div className="dashboard-table mt-2">
                <DynamicTableStateless
                    head={head}
                    rows={makeRows(approved, onSentEmail)}
                    loadingSpinnerSize="large"
                    isLoading={isLoading}
                    isFixedSize
                    rowsPerPage={25}
                    page={1}
                />
            </div>
        </div>
    )
}

export default ApprovedPatient
