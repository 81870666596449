import React, { useCallback, useState } from "react"
import * as yup from "yup"
import useFormist from "react-formist"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import { SelectField } from "../shared/components/selectfield"
import Button from "@atlaskit/button"
import RankedRangeListEdit from "./ranked-range-list-edit"
import { b64DecodeUnicode, b64EncodeUnicode } from "../../shared/base64"
import TextField from "../shared/components/textfield"
import { getLocalizedText } from "../../shared/locale"

function sectionName(section, language) {
    return `${getLocalizedText(section.name, language)} (${section.type})`
}

const SectionBalanceEdit = ({
    section,
    onSave,
    onCancel,
    allSections,
    language,
}) => {
    const model = {
        ...section,
        rankedSections: section.rankedSections.map((s) => ({
            sectionId: s.split("#")[0],
            ranks: s.split("#")[1].split(",").map(b64DecodeUnicode),
        })),
        ranks: section.ranks.map(b64DecodeUnicode),
        boostSections: section.boostSections.map((s) => ({
            sectionId: s.split("#")[0],
            expression: s.split("#")[1],
        })),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        ranks: yup.array().min(1).of(yup.string().required()),
        rankedSections: yup.array().min(1),
        boostSections: yup.array().min(0),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        rankedSections: formist.values.rankedSections.map(
                            (s) =>
                                s.sectionId +
                                "#" +
                                s.ranks.map(b64EncodeUnicode).join(","),
                        ),
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                        boostSections: formist.values.boostSections.map(
                            (s) => s.sectionId + "#" + s.expression,
                        ),
                    })
                }
            })
            .catch(() => {})
    }

    const sections = (allSections || []).map((s) => ({
        label: sectionName(s, language),
        value: s.id,
    }))

    const [selectedSection, setSelectedSection] = useState(null)

    const selectSection = useCallback(function (e) {
        setSelectedSection(e.target.value)
    }, [])

    const addSectionHandler = () => {
        formist.change("rankedSections", [
            ...formist.values.rankedSections,
            { sectionId: selectedSection, ranks: [] },
        ])
    }

    const removeSectionHandler = (id) => {
        formist.change(
            "rankedSections",
            formist.values.rankedSections.filter((s) => s.sectionId !== id),
        )
    }

    const [selectedBoostSection, setSelectedBoostSection] = useState(null)

    const selectBoostSection = useCallback(function (e) {
        setSelectedBoostSection(e.target.value)
    }, [])

    const addSectionBoostHandler = () => {
        formist.change("boostSections", [
            ...formist.values.boostSections,
            { sectionId: selectedBoostSection, expression: "" },
        ])
    }

    const removeSectionBoostHandler = (id) => {
        formist.change(
            "boostSections",
            formist.values.boostSections.filter((s) => s.sectionId !== id),
        )
    }

    function getSection(section) {
        const found = allSections.filter((s) => s.id === section)[0]
        return sectionName(found)
    }

    const onSectionChange = (e, changed) => {
        formist.change(
            "rankedSections",
            formist.values.rankedSections.map((s) =>
                s.sectionId !== changed
                    ? s
                    : { sectionId: changed, ranks: e.target.value },
            ),
        )
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <TextField
                    label="Type"
                    width="col"
                    {...formist.getFieldProps("balanceType")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Section"
                    width="col"
                    options={sections}
                    value={selectedSection}
                    onChange={selectSection}
                />
            </div>
            <div className="form-row">
                <Button appearance="primary" onClick={addSectionHandler}>
                    Add Section
                </Button>
            </div>

            {formist.values.rankedSections.map((s) => (
                <div key={s.sectionId}>
                    <div className="form-row">
                        <span>{getSection(s.sectionId)}</span>
                        <span>
                            {" "}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    removeSectionHandler(s.sectionId)
                                }}>
                                Remove
                            </a>
                        </span>
                    </div>
                    <div className="form-row">
                        <RankedRangeListEdit
                            freeRanks
                            field={{
                                value: s.ranks,
                                onChange: (e) =>
                                    onSectionChange(e, s.sectionId),
                            }}
                        />
                    </div>
                </div>
            ))}
            <div className="form-row">
                <SelectField
                    label="Boost Section"
                    width="col"
                    options={sections}
                    value={selectedBoostSection}
                    onChange={selectBoostSection}
                />
            </div>
            <div className="form-row">
                <Button appearance="primary" onClick={addSectionBoostHandler}>
                    Add Boost Section
                </Button>
            </div>
            {formist.values.boostSections.map((s) => (
                <div key={`boost-${s.sectionId}`}>
                    <div className="form-row">
                        <span>{getSection(s.sectionId)}</span>
                        <span>
                            {" "}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    removeSectionBoostHandler(s.sectionId)
                                }}>
                                Remove
                            </a>
                        </span>
                    </div>
                    <div className="form-row">
                        <TextField
                            label="Expression"
                            width="col"
                            value={s.expression}
                            onChange={(e) =>
                                formist.change(
                                    "boostSections",
                                    formist.values.boostSections.map((bs) =>
                                        bs.sectionId !== s.sectionId
                                            ? bs
                                            : {
                                                  ...bs,
                                                  expression: e.target.value,
                                              },
                                    ),
                                )
                            }
                        />
                    </div>
                </div>
            ))}
            <h3>Final Points</h3>
            <div className="form-row">
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks"),
                    }}
                    errors={formist.getFieldProps("ranks").error}
                />
            </div>

            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionBalanceEdit
