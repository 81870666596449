export default {
    key: "unorderedListType",
    types: {
        DISC: "disc",
        CIRCLE: "circle",
        SQUARE: "square",
    },
    defaultType: "DISC",
    options: [
        { label: "\u25CF", style: "DISC" },
        { label: "\u25CB", style: "CIRCLE" },
        { label: "\u25A0", style: "SQUARE" },
    ],
}
