import { postFile } from "../../shared/ajax"
import { actions } from "./store"
import { apis } from "../../shared/apis"

export function mkApi(dispatch) {
    return {
        load({
            pageNumber,
            pageSize,
            sortBy,
            type,
            template,
            layout,
            pattern,
        }) {
            return apis.geneticAnalyses
                .loadAll(
                    pageNumber,
                    pageSize,
                    sortBy,
                    type + ":" + template + ":" + layout,
                    pattern,
                )
                .then((data) =>
                    dispatch(
                        actions.load({
                            ...data,
                            sortField: sortBy,
                            pattern,
                            type,
                            template,
                            layout,
                        }),
                    ),
                )
        },
        pdf(barcode, layout) {
            return apis.geneticAnalyses.createPDF(barcode, layout)
        },
        allpdf(barcode) {
            return apis.geneticAnalyses.createAllPDF(barcode)
        },
        downloadPDF(barcode, layout, reportMode) {
            return apis.geneticAnalyses.url.downloadPDF(
                barcode,
                layout,
                reportMode,
            )
        },
        updateBarcode(oldBarcode, newBarcode) {
            return apis.geneticAnalyses.updateBarcode(oldBarcode, newBarcode)
        },
        removeBarcode(barcode, force) {
            return apis.geneticAnalyses
                .removeBarcode(barcode, force)
                .then((res) => {
                    if (res.ajaxError) throw res.ajaxError
                    return res
                })
        },
        markAsDone(barcode) {
            return apis.reporters.markAsDone(barcode)
        },
        fixAnalysisBarcode(oldBarcode, newBarcode) {
            return apis.geneticAnalyses.fixBarcode(oldBarcode, newBarcode)
        },
        assignReporter(barcode, reporter) {
            return apis.geneticAnalyses.assignReporter(barcode, reporter)
        },
        unassignReporter(barcode) {
            return apis.geneticAnalyses.unassignReporter(barcode)
        },
        reimport(value) {
            return apis.geneticAnalyses.reimport(value)
        },
        uploadLabResult(file, type) {
            return postFile("/api/files/" + type, file).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
        sendEmail(
            barcode,
            layout,
            to,
            patientAttachmentRule,
            referralAttachmentRule,
        ) {
            return apis.reports.sendEmail(
                barcode,
                layout,
                to,
                patientAttachmentRule,
                referralAttachmentRule,
            )
        },
        loadTemplates() {
            return apis.templates.loadAll(1, 1000)
        },
        loadLayouts() {
            return apis.layouts.loadAll(1, 1000)
        },
        loadReporters() {
            return apis.users.loadByRole("reporter")
        },
    }
}
