import { DatePicker } from "@atlaskit/datetime-picker"
import React, { forwardRef } from "react"
import { toIso } from "../../../shared/dates"

// eslint-disable-next-line react/display-name
const DateField = forwardRef((props, ref) => {
    const {
        visible = true,
        label,
        placeholder,
        width,
        value,
        onChange,
        error,
        disabled = false,
        ...otherProps
    } = props
    return (
        <div
            className={`form-group ${width}`}
            style={{ display: visible ? "" : "none" }}>
            {label ? <label>{label}</label> : null}
            <DatePicker
                isDisabled={disabled}
                dateFormat="DD/MM/YYYY"
                className="form-control"
                placeholder={placeholder}
                value={toIso(value)}
                onChange={onChange}
                ref={ref}
                hideIcon
                {...otherProps}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
})

export default DateField
