import React from "react"
import { SelectField } from "../shared/components/selectfield"
import { analysisTypes } from "../../analysisTypes"

const filterAnalysisTypes = [
    {
        label: "All",
        value: "",
    },
    ...analysisTypes,
]

const TemplatesFilter = ({ filter, onFilter, width, showLabel }) => {
    function setFilter(evt) {
        onFilter({ analysisType: evt.target.value })
    }

    return (
        <SelectField
            label={showLabel ? "Analysis Type" : ""}
            width={width}
            options={filterAnalysisTypes}
            value={filter.analysisType || ""}
            onChange={setFilter}
        />
    )
}

export default TemplatesFilter
