import AutoSuggest from "@avcs/autosuggest"
import "@avcs/autosuggest/dropdown.css"
import { useEffect } from "react"

export function handleEnter(f) {
    return (e) => {
        if (e.keyCode != 13) return
        e.preventDefault()
        f(e)
    }
}

export function useSuggest({ input, suggestions, onSelected, regex, trigger }) {
    useEffect(() => {
        if (input.current) {
            const current = input.current
            const suggest = new AutoSuggest(
                {
                    suggestions,
                    onChange: () => {
                        onSelected(current.value)
                    },
                },
                current,
            )
            if (document.body.getElementsByTagName("main").length > 0) {
                const dropdown = suggest.dropdown.dropdown
                document.body.removeChild(dropdown)

                document
                    .getElementsByTagName("main")
                    .item(0)
                    .appendChild(dropdown)
            }
            if (regex) {
                suggest.suggestionLists[0].regex = new RegExp(regex)
            }
            if (trigger) {
                suggest.suggestionLists[0].trigger = trigger
            }
        }
    }, [input.current])
}
