import React, { useEffect, useState } from "react"
import { mkApi } from "./api"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import Comment, { CommentAction } from "@atlaskit/comment"
import Button from "@atlaskit/button"
import { CreatableSelectField } from "../shared/components/selectfield"
import { toSelectModel } from "./register-patient-select"
import { success } from "../../shared/notifications"
import ModalDialog from "@atlaskit/modal-dialog"
import Form from "../shared/components/form"
import {
    cleanList,
    cleanTemplate,
    drawMatchingIcon,
    existNameSurname,
    existsEmail,
    geneticAnalysesMap,
    getBarcodes,
    getDiseases,
    getDrugs,
    getEthnicity,
    getExerciseType,
    getHeight,
    getInteger,
    getMenopause,
    getOtherDrugs,
    getSex,
    getSmokerType,
    hasGuardian,
    matching,
    mergeBarcodes,
    showError,
    trimProperties,
} from "./approve-helpers"
import Tabs from "@atlaskit/tabs"
import DynamicTableStateless from "@atlaskit/dynamic-table"
import CrossIcon from "@atlaskit/icon/glyph/cross"

import ApprovedPatient from "./approved-patient"

const loadPatientsToApprove = ({ api }) => {
    return api.loadPatientsToApprove()
}

const loadApproved = ({ api }) => {
    return api.loadApprovedPatients().then((approved) => {
        const barcodes = approved.items.flatMap((x) => x.barcodes)
        return api.loadGeneticAnalyses(barcodes).then((gas) => ({
            ...approved,
            geneticAnalyses: geneticAnalysesMap(gas),
        }))
    })
}

function getBarcodeStatus(barcode, allBarcodes) {
    return allBarcodes.indexOf(barcode) === -1 ? "green" : "red"
}

function printBarcodes(
    patientId,
    barcodes,
    allBarcodes,
    onUpdateBarcode,
    onRemoveBarcode,
) {
    return barcodes.map((b, index) =>
        b.deleted ? null : (
            <div key={index}>
                <input
                    style={{
                        margin: "3px",
                        borderRadius: 5,
                        outline: `${getBarcodeStatus(
                            b.barcode,
                            allBarcodes,
                        )} 2px solid`,
                    }}
                    value={b.barcode}
                    onChange={(e) =>
                        onUpdateBarcode(patientId, index, e.target.value)
                    }
                />
                <CommentAction
                    key="removebarcode"
                    onClick={() => onRemoveBarcode(patientId, index)}>
                    <CrossIcon size="small" />
                </CommentAction>
            </div>
        ),
    )
}

const loadPatients = ({ api }) => {
    return api.loadNotAnonymousPatients()
}

const ApprovePatient = () => {
    const api = mkApi()
    const [updates, setUpdates] = useState(0)
    const { data, error, isLoading } = useAsync({
        promiseFn: loadPatientsToApprove,
        api,
        watch: updates,
    })

    const {
        data: existingPatients,
        error: errorPatients,
        isLoading: isLoadingPatients,
    } = useAsync({
        promiseFn: loadPatients,
        api,
        watch: updates,
    })

    const {
        data: approved,
        error: errorApproved,
        isLoading: isLoadingApproved,
    } = useAsync({
        promiseFn: loadApproved,
        api,
        watch: updates,
    })

    const [selected, setSelected] = useState([])
    const [assignedPatients, setAssignedPatients] = useState({})
    const [updatedBarcodes, setUpdatedBarcodes] = useState({})
    const [removedBarcodes, setRemovedBarcodes] = useState({})
    const [patientToRemove, setPatientToRemove] = useState(null)

    useEffect(() => {
        if (data) {
            setAssignedPatients({})
            setUpdatedBarcodes({})
            setRemovedBarcodes({})
            setSelected([])
        }
    }, [data])

    const [selectedTab, selectTab] = useState(0)

    if (isLoading || isLoadingPatients || isLoadingApproved)
        return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    if (errorPatients) return `Something went wrong: ${errorPatients.message}`
    if (errorApproved) return `Something went wrong: ${errorApproved.message}`

    function selectAll() {
        setSelected(data.items.map((i) => i.patientId))
    }

    function deselectAll() {
        setSelected([])
    }

    function toggleSelect(id, value) {
        setSelected(
            value ? [...selected, id] : selected.filter((i) => i !== id),
        )
    }

    function updatePatient(patientId, updatedId) {
        setAssignedPatients({
            ...assignedPatients,
            [patientId]: updatedId,
        })
    }
    function updateBarcode(patientId, index, barcode) {
        setUpdatedBarcodes({
            ...updatedBarcodes,
            [patientId]: { ...updatedBarcodes[patientId], [index]: barcode },
        })
    }
    function removeBarcode(patientId, index) {
        setRemovedBarcodes({
            ...removedBarcodes,
            [patientId]: [...(removedBarcodes[patientId] || []), index],
        })
    }

    function confirmRemovePatient(patient) {
        setPatientToRemove(patient)
    }

    function cancelRemovePatient() {
        setPatientToRemove(null)
    }

    function removePatient() {
        api.removePatientToApprove(patientToRemove.patientId)
            .then((result) => {
                if (result && !result.error) {
                    success("Patient successfully removed!")
                    setUpdates((v) => v + 1)
                    setPatientToRemove(null)
                    setSelected([])
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    function removeParens(template) {
        let result = template
        while (result.indexOf("(") !== -1) {
            const from = result.indexOf("(")
            let to = result.indexOf(")", from)
            if (to === -1) to = result.length
            result = result.substring(0, from) + result.substring(to + 1)
        }
        return result
    }

    function buildApprovedPatient(id) {
        const patientData = data.items.filter((p) => p.patientId === id)[0]
        const { properties } = patientData
        const { language = "it" } = properties
        const barcodes = mergeBarcodes(
            getBarcodes(patientData),
            updatedBarcodes[id] || {},
            removedBarcodes[id] || [],
        ).map((b) => b.trim())
        const analysisTypes = cleanList(patientData, "TipoAnalisi")
        const analysisSubTypesMacrobiota = patientData.properties[
            "TipoMacrobiota"
        ]
            ? patientData.properties["TipoMacrobiota"].split("|")
            : ["", "", ""]

        const analysisSubTypesEpigene = patientData.properties["TipoEpigene"]
            ? patientData.properties["TipoEpigene"].split("|")
            : ["", "", ""]
        const analysisSubTypes = analysisSubTypesMacrobiota.map(
            (m, idx) => m || analysisSubTypesEpigene[idx],
        )
        const templates = cleanList(patientData, "Template")
            .filter((t) => t.toLowerCase().indexOf("symbyo") === -1)
            .map((l) =>
                removeParens(l)
                    .split(",")
                    .map(cleanTemplate)
                    .filter((t) => t),
            )
        const analyses = barcodes
            .map((barcode, index) => ({
                barcode,
                analysisType: analysisTypes[index],
                analysisSubType: analysisSubTypes[index],
                templates: templates[index],
            }))
            .filter((a) => a.barcode)
        const assignedPatientId =
            assignedPatients[id] === "new"
                ? null
                : assignedPatients[id] ||
                  (patientData.patient && patientData.patient.id) ||
                  null
        return {
            patientId: id,
            referralCode: properties["CodiceReferente"],
            assignedPatientId,
            analyses,
            patientData: {
                birthPlace: null,
                bornOn: properties["DataNascita"],
                city: properties["Citta"],
                email: properties["Email"],
                firstName: properties["Nome"],
                lastName: properties["Cognome"],
                postalCode: properties["CAP"],
                province: properties["Provincia"],
                socialNumber: properties["CodiceFiscale"],
                vat: properties["PIVA"],
                streetLine1: properties["Indirizzo"],
                streetLine2: "",
            },
            physicalDescription: {
                genderType: getSex(properties["Sesso"]),
                menopauseType: getMenopause(
                    properties["Sesso"],
                    properties["SintomiFemminili"],
                ),
                ethnicityType: getEthnicity(
                    removeParens(properties["Etnia"]).trim(),
                ),
                exerciseFrequencyType: getExerciseType(properties["Esercizio"]),
                age: getInteger(properties["Eta"]),
                weight: getInteger(properties["Peso"]),
                height: getHeight(properties["Altezza"]),
                smokertype: getSmokerType(properties["Fumatore"]),
                smokerNoMoreFrom: properties["NonFumatoreDa"],
            },
            drugs: {
                drugs: getDrugs(properties, language),
                additionalDrugsInfo: getOtherDrugs(properties),
                additionalAllergiesInfo: properties["Allergie"],
                additionalNotes: properties["Note"],
            },
            diseases: {
                groups: getDiseases(properties, language),
            },
            guardian: {
                guardian: hasGuardian(properties["Tutore"]),
                firstName: properties["NomeTutore"],
                lastName: properties["CognomeTutore"],
                email: properties["EmailTutore"],
                socialNumber: properties["CodiceFiscaleTutore"],
                telephone: properties["TelefonoTutore"],
                address: properties["IndirizzoTutore"],
            },
        }
    }

    function approveSelected() {
        const patientsToApprove = selected.map(buildApprovedPatient)
        api.approvePatients(patientsToApprove)
            .then((result) => {
                if (result && !result.error) {
                    success("Patients successfully approved!")
                    setUpdates((v) => v + 1)
                    setSelected([])
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    function approveEmailSent() {
        success("Successfully sent emails")
        setUpdates((v) => v + 1)
    }

    const newPatient = {
        id: "new",
        firstName: "(Crea nuovo)",
        lastName: "Nessuno",
        email: "",
    }
    const addNewPatient = (patients) => [...patients, newPatient]

    const patients = toSelectModel(
        addNewPatient((existingPatients && existingPatients.items) || []),
    )
    const head = {
        cells: [
            {
                key: "approve",
                content: "",
                width: 3,
            },
            {
                key: "fullname",
                content: "Full Name",
                width: 20,
            },
            {
                key: "email",
                content: "Email",
                width: 28,
            },
            {
                key: "referralcode",
                content: "Referral Code",
                width: 15,
            },
            {
                key: "matchinpatient",
                content: "Matching Patient",
                width: 40,
            },
            {
                key: "barcodes",
                content: "Barcode(s)",
                width: 25,
            },
            {
                key: "action",
                content: "",
                width: 10,
            },
        ],
    }

    function getCurrentBarcodes(barcodes, updated, removed) {
        return barcodes.map((b, index) => ({
            barcode: updated[index] || b,
            deleted: removed.indexOf(index) !== -1,
        }))
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            const { patientId, properties, patient, barcodes } = item
            const currentBarcodes = getCurrentBarcodes(
                getBarcodes(item).filter((b) => b),
                updatedBarcodes[patientId] || {},
                removedBarcodes[patientId] || [],
            )
            const propsTrimmed = trimProperties(properties)
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <input
                                type="checkbox"
                                data-id={patientId}
                                checked={selected.indexOf(patientId) !== -1}
                                onChange={() =>
                                    toggleSelect(
                                        patientId,
                                        selected.indexOf(patientId) === -1,
                                    )
                                }
                            />
                        ),
                    },
                    {
                        content: (
                            <>
                                <div style={matching(!!patient)}>
                                    {existNameSurname(propsTrimmed)
                                        ? drawMatchingIcon(!!patient)
                                        : null}
                                    {propsTrimmed["Nome"]}{" "}
                                    {propsTrimmed["Cognome"]}
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <div
                                    style={
                                        patient &&
                                        matching(
                                            patient.email ===
                                                propsTrimmed["Email"],
                                        )
                                    }>
                                    {patient && existsEmail(propsTrimmed)
                                        ? drawMatchingIcon(
                                              patient.email ===
                                                  propsTrimmed["Email"],
                                          )
                                        : null}
                                    {propsTrimmed["Email"]}
                                </div>
                            </>
                        ),
                    },
                    {
                        content: <>{propsTrimmed["CodiceReferente"]}</>,
                    },
                    {
                        content: (
                            <>
                                <CreatableSelectField
                                    label=""
                                    width=""
                                    value={
                                        assignedPatients[patientId] ||
                                        (patient && patient.id)
                                    }
                                    options={patients}
                                    onCreate={() => {}}
                                    onChange={(e) =>
                                        updatePatient(patientId, e.target.value)
                                    }
                                />
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <div>
                                    {printBarcodes(
                                        patientId,
                                        currentBarcodes,
                                        barcodes,
                                        updateBarcode,
                                        removeBarcode,
                                    )}
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                <Button
                                    appearance="primary"
                                    onClick={() => confirmRemovePatient(item)}>
                                    Remove
                                </Button>
                            </>
                        ),
                    },
                ],
            }
        })
    }

    const tabs = [
        {
            label: "To Approve",
            content: (
                <>
                    <div className="form-container-separator">
                        <div
                            className="form-row"
                            style={{ marginBottom: "20px" }}>
                            <div className="col-sm-auto mr-4">
                                <Comment
                                    actions={[
                                        <CommentAction
                                            key="selectall"
                                            onClick={selectAll}>
                                            Select All
                                        </CommentAction>,
                                        <CommentAction
                                            key="deselectall"
                                            onClick={deselectAll}>
                                            Deselect All
                                        </CommentAction>,
                                    ]}
                                />
                            </div>

                            <div className="col-sm-auto btn-alongside-center">
                                <Button
                                    appearance="primary"
                                    onClick={approveSelected}>
                                    Approve
                                </Button>
                            </div>
                        </div>
                        <div
                            className="approve-table mt-2"
                            style={{ lineHeight: "1px !important" }}>
                            <DynamicTableStateless
                                head={head}
                                rows={mkRows(data.items)}
                                loadingSpinnerSize="large"
                                isLoading={isLoading}
                                isFixedSize
                            />
                        </div>
                        <div
                            className="form-row"
                            style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <div className="col-sm-auto mr-4">
                                <Comment
                                    actions={[
                                        <CommentAction
                                            key="selectall"
                                            onClick={selectAll}>
                                            Select All
                                        </CommentAction>,
                                        <CommentAction
                                            key="deselectall"
                                            onClick={deselectAll}>
                                            Deselect All
                                        </CommentAction>,
                                    ]}
                                />
                            </div>
                            <div className="col-sm-auto btn-alongside-center">
                                <Button
                                    appearance="primary"
                                    onClick={approveSelected}>
                                    Approve
                                </Button>
                            </div>
                            {patientToRemove ? (
                                <ModalDialog
                                    id="confirm-remove-patient-dialog"
                                    heading="Remove Patient">
                                    <Form
                                        title=""
                                        size="full"
                                        buttons={[
                                            <Button
                                                key="confirm"
                                                appearance="primary"
                                                onClick={removePatient}>
                                                Remove
                                            </Button>,
                                            "cancel",
                                        ]}
                                        primaryText="Remove"
                                        onCancel={cancelRemovePatient}>
                                        <div className="form-row">
                                            Are you sure you want to remove{" "}
                                            {patientToRemove.properties["Nome"]}{" "}
                                            {
                                                patientToRemove.properties[
                                                    "Cognome"
                                                ]
                                            }
                                            ?
                                        </div>
                                    </Form>
                                    <div>&nbsp;</div>
                                </ModalDialog>
                            ) : null}
                        </div>
                    </div>
                </>
            ),
        },
        {
            label: "Recently Approved",
            content: (
                <ApprovedPatient
                    approved={approved}
                    onSentEmail={approveEmailSent}
                    isLoading={isLoading}
                />
            ),
        },
    ]

    if (data) {
        return (
            <>
                <div className="mt-4">
                    <Tabs
                        tabs={tabs}
                        onSelect={(tab, idx) => selectTab(idx)}
                        selected={selectedTab}
                    />
                </div>
            </>
        )
    }
    return null
}

export default ApprovePatient
