import React from "react"
import Wizard from "../shared/components/wizard"
import { withRouter } from "react-router"
import { patientSchema as schema } from "../patients/schemas"
import PatientForm from "../patients/patient"
import useFormist from "react-formist"
import { mergeItem } from "./storage"
import { routes } from "../../shared/routing"
import { apis } from "../../shared/apis"
import { undo } from "./form"
import { model as empty } from "../patients/patient"

const RegisterPatientAdd = ({
    history,
    match,
    newPatientName,
    onPrevious,
    patientId,
    model,
}) => {
    const barcode = match.params.id
    const formist = useFormist(
        { ...(model || empty), firstName: newPatientName },
        {
            schema,
            onSubmit: (patient) => {
                if (patientId) {
                    apis.patients.updateOne(patientId, patient).then(() => {
                        mergeItem(barcode, {
                            patientData: patient,
                        })
                        history.push(
                            routes.geneticAnalyses.registerPatientPhysicalDescription.buildUrl(
                                barcode,
                            ),
                        )
                    })
                } else {
                    apis.patients.addOne(patient).then((patientId) => {
                        mergeItem(barcode, {
                            newPatient: true,
                            patientId: patientId.id,
                            patientData: patient,
                        })
                        history.push(
                            routes.geneticAnalyses.registerPatientPhysicalDescription.buildUrl(
                                barcode,
                            ),
                        )
                    })
                }
            },
        },
    )

    return (
        <Wizard
            title={`Patient for: ${barcode}`}
            onPrevious={onPrevious}
            onUndo={undo(history, barcode)}
            {...formist.getFormProps()}>
            <PatientForm formist={formist} />
        </Wizard>
    )
}

export default withRouter(RegisterPatientAdd)
