import React from "react"
import Semaphore from "./semaphore"
import { getLocalizedText } from "../../shared/locale"

export const semaphoreType = (gene) => {
    const { effectType = "noEffect", effectTypeValue = 0.0 } = gene
    if (effectTypeValue === 1) return "danger"
    if (effectTypeValue === 0.5) return "dangeretero"
    if (effectTypeValue === 2) return "good"
    if (effectType === "power") return "power"
    if (effectType === "endurance") return "endurance"
    return "normal"
}

const descriptionCss = {
    fontStyle: "normal",
    marginLeft: "0.2em",
    marginBottom: "0.5em",
    textAlign: "center",
}
const tableCss = {
    borderCollapse: "collapse",
}
const hederRowCss = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
}
const noteCss = {
    fontStyle: "normal",
    marginTop: "1.5em",
}
const headerCss = (width = "10%") => ({
    paddingBottom: "0.5em",
    width,
})

const groupNameCss = {
    fontWeight: "bold",
    marginLeft: "0.2em",
    marginBottom: "0.5em",
}

const translations = {
    gene: {
        it: "Gene",
        eng: "Gene",
    },
    variant: {
        it: "Variante",
        eng: "Variant",
    },
    role: {
        it: "Ruolo e/o funzione",
        eng: "Role and/or function",
    },
    gn: {
        it: "GN",
        eng: "GN",
    },
}

function filterGroups(groups, from, to) {
    if (from === 0 && to === 0) {
        return groups
    }
    return groups.slice(from - 1, to)
}

export default function SectionListGenoType({ item, language }) {
    const {
        name = "",
        description,
        groups = [],
        note,
        header = true,
        from = 0,
        to = 0,
    } = item

    const renderGroup = (group, showName) => {
        return (
            <div className="listgenotype-group">
                {showName && (
                    <p style={groupNameCss}>
                        {getLocalizedText(group.name, language)}
                    </p>
                )}
                <table className="r-table" style={tableCss}>
                    <thead>
                        <tr style={hederRowCss}>
                            <th
                                className="r-table-col r-text-left"
                                style={headerCss("13%")}>
                                <span>{translations["gene"][language]}</span>
                            </th>
                            <th
                                className="r-table-col r-text-right"
                                style={headerCss("10%")}>
                                {translations["variant"][language]}
                            </th>
                            <th
                                className="r-table-col r-text-left"
                                style={headerCss("64%")}>
                                {translations["role"][language]}
                            </th>
                            <th
                                className="r-table-col r-text-left"
                                style={headerCss("10%")}>
                                {translations["gn"][language]}
                            </th>
                            <th
                                className="r-table-col r-text-left"
                                style={headerCss("3%")}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.values.map((v, index) => {
                            return (
                                <tr key={index}>
                                    <td className="r-table-col r-text-left">
                                        {v.geneCode}
                                    </td>
                                    <td className="r-table-col r-text-right">
                                        {v.variantName}
                                    </td>
                                    <td className="r-table-col r-text-left">
                                        {getLocalizedText(v.effect, language)}
                                    </td>
                                    <td className="r-table-col r-text-left">
                                        {getLocalizedText(v.allele, language)}
                                    </td>
                                    <td className="r-table-col r-text-left">
                                        <Semaphore
                                            type="semaphore"
                                            value={semaphoreType(v)}
                                            size={15}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    const icon = name.indexOf("#") === -1 ? name : name.split("#")[0]
    return (
        <>
            {header ? (
                <>
                    <div
                        data-testid="section-listgenotype"
                        style={{ display: "flex" }}>
                        <div>
                            <img
                                src={"/icon/" + icon + ".png"}
                                style={{
                                    margin: "10px 30px 0 0",
                                }}
                            />
                        </div>
                        <div>
                            <h1 className="h2 mt-3">
                                {getLocalizedText(name, language)}
                            </h1>
                            <p style={descriptionCss}>
                                {getLocalizedText(description, language)}
                            </p>
                        </div>
                    </div>
                    <p style={noteCss}>{getLocalizedText(note, language)}</p>
                </>
            ) : null}
            {filterGroups(groups, from, to).map((g) =>
                renderGroup(g, groups.length > 1),
            )}
        </>
    )
}
