import React from "react"
import { ComposedChart, Line, YAxis, XAxis, Area } from "recharts"
import { curveCardinal } from "d3-shape"

import { getLocalizedText } from "../../shared/locale"
import "./lines.scss"

const descriptionCss = {
    fontStyle: "normal",
    marginLeft: "0.2em",
    marginBottom: "0.5em",
    textAlign: "center",
}

const colors = {
    0: "#FF002B",
    1: "#ff7300",
    2: "#ffdf00",
    3: "#47c79c",
    4: "#ffdf00",
    5: "#ff7300",
    6: "#FF002B",
}

const hours = ["08:00", "12:00", "18:00", "24:00"]

function getTicks(maxValue) {
    if (maxValue < 20) {
        return [...Array(maxValue).keys()].map((n) => n + 1)
    }
    return [...Array(maxValue / 10.0).keys()].map((idx) => (idx + 1) * 10)
}

export default function SectionLines({ item, language }) {
    const { name = "", description, values = [], min = 0, max = 100 } = item
    const areasValues =
        values.length > 0 ? [...Array(values[0].ranges.length).keys()] : []

    const data = values.map((v, idx) =>
        areasValues.reduce(
            (acc, cur) => ({
                ...acc,
                [`value${cur}`]:
                    cur === areasValues.length - 1
                        ? max - v.ranges[cur].range.low
                        : v.ranges[cur].range.high - v.ranges[cur].range.low,
            }),
            { value: v.value, index: idx },
        ),
    )

    return (
        <div className={`section-lines`}>
            <div data-testid="section-lines" style={{ display: "flex" }}>
                <div>
                    <h1 className="h2 mt-3">
                        {getLocalizedText(name, language)}
                    </h1>
                    <p style={descriptionCss}>
                        {getLocalizedText(description, language)}
                    </p>
                    <div className="section-lines-chart">
                        <ComposedChart width={360} height={390} data={data}>
                            <defs>
                                {Object.keys(colors).map((idx) => (
                                    <pattern
                                        key={idx}
                                        id={`pattern-${idx}`}
                                        patternUnits="userSpaceOnUse"
                                        width="4"
                                        height="4"
                                        patternTransform="scale(0.5 0.5) rotate(90 0 0)">
                                        <rect
                                            width="4"
                                            height="4"
                                            style={{ fill: colors[idx] }}
                                        />
                                        <path
                                            d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                            style={{
                                                stroke: "#EEE",
                                                strokeWidth: 0.5,
                                            }}
                                        />
                                    </pattern>
                                ))}
                            </defs>
                            <YAxis
                                type="number"
                                width={30}
                                domain={[min - max * 0.03, max * 1.01]}
                                axisLine={false}
                                ticks={getTicks(max)}
                                tick={{ stroke: "#374994", fontSize: 12 }}
                                tickLine={{ stroke: "#374994" }}
                                tickSize={10}
                                interval={0}
                                allowDataOverflow
                            />
                            <XAxis
                                type="number"
                                ticks={[0, 1, 2, 3]}
                                domain={[-0.1, 3.1]}
                                dataKey="index"
                                tick={{ stroke: "#374994", fontSize: 12 }}
                                tickLine={{ stroke: "#374994" }}
                                axisLine={{ stroke: "#374994" }}
                                tickSize={10}
                                tickFormatter={(value) => hours[value]}
                            />
                            {areasValues.map((a) => (
                                <Area
                                    isAnimationActive={false}
                                    key={a}
                                    type={curveCardinal.tension(0.5)}
                                    dataKey={`value${a}`}
                                    stackId="1"
                                    stroke="#ccc"
                                    fill={`url(#pattern-${a}`}
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                />
                            ))}
                            <Line
                                isAnimationActive={false}
                                dataKey="value"
                                stroke="#0000FF"
                                strokeDasharray="5 5"
                                strokeWidth={3}
                                dot={{
                                    r: 5,
                                    fill: "#FFFFFF",
                                    stroke: "#00F",
                                    strokeDasharray: "none",
                                }}
                            />
                        </ComposedChart>
                    </div>
                </div>
            </div>
        </div>
    )
}
