import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TextField from "../shared/components/textfield"
import { SelectField } from "../shared/components/selectfield"
import SectionListBacteriumRankingEdit from "./section-list-bacteriumranking-edit"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import RankedRangeListEdit from "./ranked-range-list-edit"
import LocalizedTextField from "../shared/components/localized-textfield"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    description: yup.string(),
    chartType: yup.string().required(),
    chartSize: yup.string().required(),
    maxPercentage: yup.number().required().min(0).max(100),
    multipliers: yup.array().min(0).of(yup.string().required()),
    ranks: yup.array().of(yup.string().required()),
    bacteria: yup
        .array()
        .min(1)
        .of(
            yup.object().shape({
                bacterium: yup.string().required(),
                ranges: yup
                    .array()
                    .min(1)
                    .of(
                        yup
                            .string()
                            .test(
                                "valid-rankedrange",
                                "Fill all the requested values",
                                (value) => {
                                    return (
                                        value.trim() !== "" &&
                                        value.split(";").length === 3 &&
                                        value.split(";")[0].trim() !== "" &&
                                        value.split(";")[1].trim() !== "" &&
                                        value.split(";")[2].trim() !== ""
                                    )
                                },
                            ),
                    ),
            }),
        ),
})

const SectionMacrobiotaChartEdit = ({
    section,
    bacteria = [],
    onSave,
    onCancel,
}) => {
    const toRanking = (s) => {
        return {
            bacterium: b64DecodeUnicode(s.substring(0, s.indexOf("#"))),
            ranges: s
                .substring(s.indexOf("#") + 1)
                .split("|")
                .map(b64DecodeUnicode),
        }
    }

    const model = {
        ...section,
        bacteria: section.bacteria.map((b) => toRanking(b64DecodeUnicode(b))),
        multipliers: section.multipliers.map(b64DecodeUnicode),
        ranks: section.ranks.map(b64DecodeUnicode),
    }

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        bacteria: formist.values.bacteria
                            .map(
                                (b) =>
                                    b64EncodeUnicode(b.bacterium) +
                                    "#" +
                                    b.ranges
                                        .filter((r) => r.trim() !== "")
                                        .map(b64EncodeUnicode)
                                        .join("|"),
                            )
                            .map(b64EncodeUnicode),
                        multipliers:
                            formist.values.multipliers.map(b64EncodeUnicode),
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const chartTypeOptions = [
        {
            value: "NegativeBar",
            label: "Negative Bar",
        },
        {
            value: "PositiveBar",
            label: "Positive Bar",
        },
        {
            value: "Custom",
            label: "Custom",
        },
    ]

    const chartSizeOptions = [
        {
            value: "Large",
            label: "Large",
        },
        {
            value: "Medium",
            label: "Medium",
        },
        {
            value: "Small",
            label: "Small",
        },
    ]

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="ChartType"
                    width="col"
                    {...formist.getFieldProps("chartType")}
                    options={chartTypeOptions}
                />
                <SelectField
                    label="Size"
                    width="col"
                    {...formist.getFieldProps("chartSize")}
                    options={chartSizeOptions}
                />
            </div>

            <div className="form-row">
                <div>
                    <h5>Boost Multipliers</h5>
                    <RankedRangeListEdit
                        freeRanks
                        field={{
                            ...formist.getFieldProps("multipliers"),
                        }}
                        errors={formist.getFieldProps("multipliers").error}
                    />
                </div>
                <div style={{ marginLeft: 50, marginTop: 50 }}>
                    <TextField
                        label="Max Percentage"
                        width="col"
                        type="number"
                        {...formist.getFieldProps("maxPercentage")}
                    />
                </div>
            </div>
            <SectionListBacteriumRankingEdit
                allBacteria={bacteria}
                bacteriumRankingField={formist.getFieldProps("bacteria")}
            />
            <div style={{ marginLeft: 50 }}>
                <h5>Ranks</h5>
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks"),
                    }}
                    errors={formist.getFieldProps("ranks").error}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionMacrobiotaChartEdit
