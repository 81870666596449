import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TextField from "../shared/components/textfield"
import { SelectField } from "../shared/components/selectfield"
import SuggestTextField from "../shared/components/suggesttextfield"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import SectionBacteriumColorRangeEdit from "./section-bacteriumcolorrange-edit"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionHistogramEdit = ({ section, onSave, bacteria = [], onCancel }) => {
    const model = {
        ...section,
        bacterium: section.bacterium ? b64DecodeUnicode(section.bacterium) : "",
        colors: section.colors.map(b64DecodeUnicode),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        histogramType: yup.string().required(),
        bacterium: yup
            .string()
            .test(
                "valid-bacteria",
                "Unknown or duplicated bacterium",
                (value) =>
                    bacteria.filter((b) => b.level + ";" + b.group === value)
                        .length === 1,
            ),
        bars: yup.number().required(),
        colors: yup.array().min(1).of(yup.string()),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        bacterium: b64EncodeUnicode(formist.values.bacterium),
                        colors: formist.values.colors.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const histogramTypeOptions = [
        {
            value: "NegativeHistogram",
            label: "Negative",
        },
        {
            value: "PositiveHistogram",
            label: "Positive",
        },
    ]

    const suggestions = bacteria.map((b) => `${b.level};${b.group}`)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Histogram Type"
                    width="col"
                    {...formist.getFieldProps("histogramType")}
                    options={histogramTypeOptions}
                />
                <SuggestTextField
                    suggestions={suggestions}
                    label="Bacterium"
                    width="col"
                    {...formist.getFieldProps("bacterium")}
                    onSelected={(b) =>
                        formist.getFieldProps("bacterium").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <TextField
                    label="Bars"
                    width="col"
                    type="number"
                    {...formist.getFieldProps("bars")}
                />
            </div>
            <SectionBacteriumColorRangeEdit
                bacteria={bacteria}
                field={formist.getFieldProps("colors")}
            />

            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionHistogramEdit
