import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    referrals: [],
    pattern: "",
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-REFERRALS", handleLoadReferrals)
        .case("CLEAR-REFERRALS", handleClearReferrals)
        .case("EDIT-REFERRAL", handleEditReferral)
        .case("ADD-REFERRAL", handleAddReferral)
        .case("SEARCH-REFERRAL", handleSearchReferral)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-REFERRALS", data }
    },
    clear() {
        return { type: "CLEAR-REFERRALS" }
    },
    edit(data) {
        return { type: "EDIT-REFERRAL", data }
    },
    add(data, reload) {
        return { type: "ADD-REFERRAL", data, reload }
    },
    search(data) {
        return { type: "SEARCH-REFERRAL", data }
    },
}

const handleLoadReferrals = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        referrals: items,
        pattern: "",
        ...pagination,
    }
}

const handleClearReferrals = () => {
    return {
        isLoading: false,
        referrals: [],
        pageNumber: undefined,
        pageSize: undefined,
        totalPages: undefined,
    }
}

const handleEditReferral = (state, action) => {
    const { referrals } = state
    const referral = action.data

    const newReferrals = referrals.map((x) =>
        x.code === referral.code ? referral : x,
    )
    return {
        referrals: newReferrals,
    }
}

const handleAddReferral = (state, action) => {
    const { referrals } = state

    const newReferrals = [action.data, ...referrals]
    return action.reload
        ? { referrals: [] }
        : {
              referrals: newReferrals,
          }
}

const handleSearchReferral = (state, action) => {
    const { items, pagination, pattern } = action.data
    return {
        isLoading: false,
        referrals: items,
        pattern,
        ...pagination,
    }
}
