import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import SectionListHormonesValuesEdit from "./section-list-hormonesvalues-edit"
import CheckField from "../shared/components/checkfield"
import LocalizedTextField from "../shared/components/localized-textfield"
import TextField from "../shared/components/textfield"
import { hormonesSchema } from "../hormones/schemas"

const SectionListHormonesEdit = ({
    section,
    hormones = [],
    onSave,
    onCancel,
}) => {
    const model = {
        ...section,
    }

    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup
            .string()
            .test(
                "valid-description",
                "description is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        hormones: hormonesSchema(hormonesSuggestions),
        withChart: yup.bool().required(),
        withSymbol: yup.bool().required(),
        symbolFormula: yup.string(),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                    })
                }
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />

                <SectionListHormonesValuesEdit
                    hormonesField={formist.getFieldProps("hormones")}
                    errors={formist.errors}
                    suggestions={hormonesSuggestions}
                />
            </div>
            <div className="form-row">
                <CheckField
                    label="With Bar Chart"
                    width="col"
                    {...formist.getFieldProps("withChart")}
                />
                <CheckField
                    label="With Symbol"
                    width="col"
                    {...formist.getFieldProps("withSymbol")}
                />

                <TextField
                    label="Symbol Formula"
                    width="col"
                    {...formist.getFieldProps("symbolFormula")}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionListHormonesEdit
