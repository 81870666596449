import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TableField from "../shared/components/tablefield"
import LocalizedTextField from "../shared/components/localized-textfield"
import { b64DecodeUnicode, b64EncodeUnicode } from "../../shared/base64"
import RankedRangeListEdit from "./ranked-range-list-edit"
import { SelectField } from "../shared/components/selectfield"

const genoTypesSchema = [
    {
        field: "genoType",
        label: "GenoType",
    },
]

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    description: yup.string(),
    genoTypes: yup.array().min(1).of(yup.string().required()),
    ranks: yup.array().of(yup.string().required()),
})

const widgetTypes = [
    { label: "Bar", value: "BAR" },
    { label: "Gauge", value: "GAUGE" },
]

const SectionDNAChartEdit = ({ section, onSave, onCancel }) => {
    const model = {
        ...section,
        ranks: section.ranks.map(b64DecodeUnicode),
    }
    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <SelectField
                    label="Widget"
                    width="col"
                    options={widgetTypes}
                    {...formist.getFieldProps("widget")}
                />
            </div>
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <TableField
                    label=""
                    width="r-col-20"
                    editable
                    mode="list"
                    schema={genoTypesSchema}
                    errors={formist.getFieldProps("genoTypes").error}
                    {...formist.getFieldProps("genoTypes")}
                />
                <div style={{ marginLeft: 50 }}>
                    <h5>Ranks</h5>
                    <RankedRangeListEdit
                        freeRanks
                        withLabel
                        field={{
                            ...formist.getFieldProps("ranks"),
                        }}
                        errors={formist.getFieldProps("ranks").error}
                    />
                </div>
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionDNAChartEdit
