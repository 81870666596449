import React from "react"
import { getLocalizedText } from "../../shared/locale"
import Gauge from "../shared/components/gauge"
import Legend from "../shared/components/legend"
import { getTicksSpan } from "./ticks"

const formatNumber = (num, decimals) => Number(num).toFixed(decimals)

const renderValue = (value, format, decimals) => {
    switch (format) {
        case "number":
            return formatNumber(value)
        case "numberwithdecimals":
            return formatNumber(value, 2)
        case "fraction":
            return formatNumber(value) + "/100"
        case "percentagewithcolon":
            return ": " + formatNumber(value, decimals) + "%"
        case "percentage":
            return formatNumber(value, decimals) + "%"
        default:
            return ""
    }
}

function getSubTicks(maxValue, style) {
    if (style === "clock") {
        return [
            0.25, 0.5, 0.75, 1.25, 1.5, 1.75, 2.25, 2.5, 2.75, 3.25, 3.5, 3.75,
            4.25, 4.5, 4.75, 5.25, 5.5, 5.75, 6.25, 6.5, 6.75, 7.25, 7.5, 7.75,
            8.25, 8.5, 8.75, 9.25, 9.5, 9.75, 10.25, 10.5, 10.75, 11.25, 11.5,
            11.75,
        ]
    }
    if (maxValue > 7) return []
    const num = getTicksSpan(0, maxValue, 0.05, [0.125, 0.25])
    const numTicks = Math.round(maxValue / num)

    return numTicks === 0
        ? []
        : [...Array(numTicks + 1).keys()].map((v) => (v * maxValue) / numTicks)
}

const spans = [0.25, 0.5, 1, 2, 3, 4, 5, 8, 10, 20, 23, 30, 40, 50]

function getTicks(maxValue) {
    const num = getTicksSpan(0, maxValue, 0.1, spans)
    return Math.round(maxValue / num)
}

function getGapLabels(ranges, style) {
    if (style === "average") {
        return ["A"]
    }
    return []
}

function getLabels(maxValue, style) {
    if (style === "clock") {
        return [
            { value: 0, text: "12" },
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
            { value: 4, text: "4" },
            { value: 5, text: "5" },
            { value: 6, text: "6" },
            { value: 7, text: "7" },
            { value: 8, text: "8" },
            { value: 9, text: "9" },
            { value: 10, text: "10" },
            { value: 11, text: "11" },
        ]
    }
    const num = getTicksSpan(0, maxValue, 0.15, spans)
    const numLabels = Math.round(maxValue / num)

    return [...Array(numLabels + 1).keys()].map((v) => ({
        value: (v * maxValue) / numLabels,
        text: (v * maxValue) / numLabels,
    }))
}

function getGaugeType(type) {
    if (type.indexOf("dynamic:") !== -1) {
        const [, gaugeType, style = "simple"] = type.split(":")
        return [true, gaugeType, style]
    }
    return [false, type, "simple"]
}

const SectionGauge = ({ item, style, language = "it" }) => {
    const {
        name,
        description,
        gaugeType = "",
        rank,
        value,
        max = 100,
        offsetAngle = -135,
        fullAngle = 270,
        htmlElementId = "",
        decimals = 2,
        format,
        ranks = [],
    } = item

    const [dynamic, type, gaugeStyle] = getGaugeType(gaugeType)

    const parts = htmlElementId.split("-")
    const simpleName =
        parts.length > 0 ? parts[parts.length - 1] : htmlElementId

    // TODO: make ticks, labels and withLegend configurable
    const ticks = getTicks(max, gaugeStyle)
    const subTicks = getSubTicks(max, gaugeStyle)
    const labels = getLabels(max, gaugeStyle)
    const gapLabels = getGapLabels(ranks, gaugeStyle)
    const withLegend = ranks.reduce((res, cur) => cur.label || res, false)

    const sectorSizes = { simple: 14, clock: 25, average: 14 }

    const ticksSizes = { simple: 5, clock: 25, average: 5 }
    const subTicksSizes = { simple: 1, clock: 10, average: 1 }

    return (
        <div
            data-testid="section-gauge"
            style={style}
            className={"gauge-container gauge-container-" + type}>
            <div>
                <h1 className="h2 mt-3 r-title">
                    {getLocalizedText(name, language)}
                </h1>
                <p>{getLocalizedText(description, language)}</p>
            </div>
            {dynamic ? (
                <>
                    <Gauge
                        id={`${type}_${simpleName}`}
                        gaugeStyle={gaugeStyle}
                        className={`${type} ${type}-${simpleName}`}
                        sectors={ranks.map((r) => ({
                            min: r.range.low,
                            max: r.range.high,
                            name: r.rank,
                        }))}
                        sectorSize={sectorSizes[gaugeStyle]}
                        start={offsetAngle}
                        stop={offsetAngle + fullAngle}
                        max={max}
                        value={value}
                        ticks={[...Array(ticks + 1).keys()].map(
                            (v) => (v * max) / ticks,
                        )}
                        subTicks={subTicks}
                        gapLabels={gapLabels}
                        labels={labels}
                        valueLabel={renderValue(value, format, decimals)}
                        range={rank}
                        ticksSize={ticksSizes[gaugeStyle]}
                        subTicksSize={subTicksSizes[gaugeStyle]}
                    />
                    {withLegend ? (
                        <Legend items={ranks} language={language} />
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export default SectionGauge
