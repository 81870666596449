import React from "react"
import SectionHeader from "../../shared/components/section-header"
import DefaultFormButtonBar from "../../shared/components/default-form-button-bar"

export const Form = ({
    title,
    primaryText,
    secondaryText,
    size,
    onSubmit,
    onCancel,
    children,
    buttons,
    buttonsClassName,
    buttonsOnTop = false,
}) => {
    const className = size === "full" ? "" : "col-md-6"

    return (
        <div style={{ marginBottom: "20px" }} className={className}>
            {title ? <SectionHeader title={title} /> : null}
            <form onSubmit={onSubmit}>
                {buttonsOnTop ? (
                    <>
                        <DefaultFormButtonBar
                            buttonsClassName={buttonsClassName}
                            primaryText={primaryText}
                            secondaryText={secondaryText}
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            buttons={buttons}
                            separator={false}
                        />
                        <hr />
                    </>
                ) : null}
                {children}
                <DefaultFormButtonBar
                    buttonsClassName={buttonsClassName}
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    buttons={buttons}
                />
            </form>
        </div>
    )
}

export default Form
