import * as yup from "yup"

const noValues = [null, undefined, ""]

// NOTE: https://github.com/jquense/yup/issues/298
const emptyToNull = (converted, original) =>
    noValues.includes(original) ? null : converted

export const addEmpty = {
    level: "",
    group: "",
    attributes: {
        MacroBiota: {},
        Vaginal: {},
        Oral: {},
    },
}

const groupSchema = yup.string().required()
const levelSchema = yup.string().required()
const rangeLowSchema = yup.number().transform(emptyToNull).nullable().min(0)
const rangeHighSchema = yup
    .number()
    .nullable()
    .when("low", {
        is: (low) => noValues.includes(low),
        then: (schema) => schema.transform(emptyToNull).oneOf(noValues),
        otherwise: (schema) =>
            schema.required().moreThan(yup.ref("low")).max(100),
    })
const expectedSchema = yup
    .number()
    .transform(emptyToNull)
    .nullable()
    .min(0)
    .max(100)

const implicationsTextSchema = yup.string().transform(emptyToNull).nullable()
const implicationsTypeSchema = yup
    .mixed()
    .nullable()
    .when("text", {
        is: (text) => noValues.includes(text),
        then: (schema) => schema.transform(emptyToNull).oneOf(noValues),
        otherwise: (schema) => schema.required().oneOf(["good", "bad"]),
    })

const implicationsSchema = yup.object().notRequired().shape({
    text: implicationsTextSchema,
    type: implicationsTypeSchema,
})

export const rangeSchema = yup.object().shape({
    low: rangeLowSchema,
    high: rangeHighSchema,
})

export const addSchema = yup.object().shape({
    group: groupSchema,
    level: levelSchema,
    range: yup
        .object()
        .shape({
            MacroBiota: rangeSchema,
            Vaginal: rangeSchema,
            Oral: rangeSchema,
        })
        .notRequired()
        .nullable(),
    expected: yup
        .object()
        .shape({
            MacroBiota: expectedSchema,
            Vaginal: expectedSchema,
            Oral: expectedSchema,
        })
        .notRequired(),
    implications: yup
        .object()
        .shape({
            MacroBiota: implicationsSchema,
            Vaginal: implicationsSchema,
            Oral: implicationsSchema,
        })
        .notRequired(),
})

export const editSchema = yup.object().shape({
    group: groupSchema,
    level: levelSchema,
    range: yup
        .object()
        .shape({
            MacroBiota: rangeSchema,
            Vaginal: rangeSchema,
            Oral: rangeSchema,
        })
        .notRequired(),
    expected: yup
        .object()
        .shape({
            MacroBiota: expectedSchema,
            Vaginal: expectedSchema,
            Oral: expectedSchema,
        })
        .notRequired(),
    implications: yup
        .object()
        .shape({
            MacroBiota: implicationsSchema,
            Vaginal: implicationsSchema,
            Oral: implicationsSchema,
        })
        .notRequired(),
})
