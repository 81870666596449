import React, { useState } from "react"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import useFormist from "react-formist"
import { SelectField } from "../shared/components/selectfield"
import ImplicationsEdit from "./implications-edit"
import Tabs from "@atlaskit/tabs"

export const bacteriaTypes = [
    { label: "Order", value: "Order" },
    { label: "Genus", value: "Genus" },
    { label: "Phylum", value: "Phylum" },
    { label: "Species", value: "Species" },
    { label: "Family", value: "Family" },
]

const FormFields = ({
    initialValues,
    isEdit,
    schema,
    formProps,
    onSubmit,
    onCancel,
}) => {
    const formist = useFormist(initialValues, { schema, onSubmit })
    const [selectedTab, setSelectedTab] = useState(0)
    const tabs = [
        {
            label: "Gut",
            content: (
                <>
                    <div className="form-container-separator">
                        <div className="form-row">
                            <TextField
                                label="Value Range"
                                width="col-md-3"
                                placeholder="low value"
                                {...formist.getFieldProps(
                                    "range.MacroBiota.low",
                                )}
                            />
                            <TextField
                                label="&nbsp;"
                                width="col-md-3"
                                placeholder="high value"
                                {...formist.getFieldProps(
                                    "range.MacroBiota.high",
                                )}
                            />
                            <TextField
                                label="Expected"
                                width="col-md-3"
                                {...formist.getFieldProps(
                                    "expected.MacroBiota",
                                )}
                            />
                        </div>
                        <ImplicationsEdit
                            formist={formist}
                            field="implications.MacroBiota"
                        />
                    </div>
                </>
            ),
        },
        {
            label: "Vaginal",
            content: (
                <>
                    <div className="form-container-separator">
                        <div className="form-row">
                            <TextField
                                label="Value Range"
                                width="col-md-3"
                                placeholder="low value"
                                {...formist.getFieldProps("range.Vaginal.low")}
                            />
                            <TextField
                                label="&nbsp;"
                                width="col-md-3"
                                placeholder="high value"
                                {...formist.getFieldProps("range.Vaginal.high")}
                            />
                            <TextField
                                label="Expected"
                                width="col-md-3"
                                {...formist.getFieldProps("expected.Vaginal")}
                            />
                        </div>
                        <ImplicationsEdit
                            formist={formist}
                            field="implications.Vaginal"
                        />
                    </div>
                </>
            ),
        },
        {
            label: "Oral",
            content: (
                <>
                    <div className="form-container-separator">
                        <div className="form-row">
                            <TextField
                                label="Value Range"
                                width="col-md-3"
                                placeholder="low value"
                                {...formist.getFieldProps("range.Oral.low")}
                            />
                            <TextField
                                label="&nbsp;"
                                width="col-md-3"
                                placeholder="high value"
                                {...formist.getFieldProps("range.Oral.high")}
                            />
                            <TextField
                                label="Expected"
                                width="col-md-3"
                                {...formist.getFieldProps("expected.Oral")}
                            />
                        </div>
                        <ImplicationsEdit
                            formist={formist}
                            field="implications.Oral"
                        />
                    </div>
                </>
            ),
        },
    ]
    return (
        <Form {...formProps} {...formist.getFormProps()} onCancel={onCancel}>
            <div className="form-row">
                <SelectField
                    label="Level"
                    width="col"
                    isDisabled={isEdit}
                    options={bacteriaTypes}
                    {...formist.getFieldProps("level")}
                />
                <TextField
                    label="Group"
                    width="col"
                    readOnly={isEdit}
                    {...formist.getFieldProps("group")}
                />
            </div>
            <Tabs
                tabs={tabs}
                onSelect={(tab, idx) => setSelectedTab(idx)}
                selected={selectedTab}
            />
        </Form>
    )
}

export default FormFields
