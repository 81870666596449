import React, { useEffect, useState } from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Button from "@atlaskit/button"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"

export default function UpdateBarcode({
    enabled = false,
    onConfirm,
    onCancel,
    barcode,
}) {
    const [newBarcode, setNewBarcode] = useState(barcode)
    useEffect(() => setNewBarcode(barcode), [barcode])
    return enabled ? (
        <ModalDialog id="update-barcode-dialog" heading="Change Barcode">
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={() => onConfirm(newBarcode)}>
                        Change
                    </Button>,
                    "cancel",
                ]}
                primaryText="Change"
                onCancel={onCancel}>
                <div className="form-row">
                    <TextField
                        label="New Barcode"
                        isDisabled
                        width="col"
                        value={newBarcode}
                        onChange={(e) => setNewBarcode(e.target.value)}
                    />
                    <TextField
                        label="Old Barcode"
                        isDisabled
                        width="col"
                        value={barcode}
                    />
                </div>
            </Form>
        </ModalDialog>
    ) : null
}
