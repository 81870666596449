import React, { useCallback, useRef } from "react"
import { is } from "immutable"
import RichTextEditor from "../shared/components/rich-text-editor"
import DefaultFormButtonBar from "../shared/components/default-form-button-bar"
import { convertFromRaw, convertToRaw } from "draft-js"
import { throttle } from "lodash"
import { useDirtyStore } from "./dirty-notifier"
import { getLocalizedText } from "../../shared/locale"

const SectionFreetext = ({
    item,
    readOnly,
    onSave,
    language,
    autoSave = false,
    style = {},
    disabled = false,
}) => {
    const {
        id,
        name,
        value = '{"blocks":[{"key":"523bk","text":"Modifica questo testo o cancellalo dal report","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        description = "",
        revision = 1,
    } = item
    const content = convertFromRaw(JSON.parse(value.replaceAll("\n", "")))
    const updatedContent = useRef(content)
    const dirty = useRef(false)
    const onDirty = useDirtyStore((state) => state.setDirty)

    const batchSave = useCallback(
        throttle(
            (content) => {
                onSave(
                    {
                        ...item,
                        value: JSON.stringify(convertToRaw(content)),
                    },
                    true,
                    () => {
                        dirty.current = false
                        onDirty(id, false)
                    },
                )
            },
            1000,
            {
                leading: false,
                trailing: true,
            },
        ),
        [id],
    )

    const onChange = (content) => {
        const oldContent = updatedContent.current
        updatedContent.current = content
        if (!is(content, oldContent)) {
            if (!dirty.current) {
                dirty.current = true
                onDirty(id, true)
            }
            if (autoSave) {
                batchSave.cancel()
                batchSave(content)
            }
        }
    }

    const onSaveHandler = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault()
        }
        onSave({
            ...item,
            value: JSON.stringify(convertToRaw(updatedContent.current)),
        })
        dirty.current = false
        onDirty(id, false)
    }

    const buttonsBar = () => {
        if (!readOnly && !autoSave) {
            return (
                <DefaultFormButtonBar
                    primaryText="Save"
                    onSubmit={onSaveHandler}
                    onCancel={() => {}}
                />
            )
        }
        // else render nothing
    }

    return (
        <div
            className="section-freetext"
            data-testid="section-freetext"
            style={style}>
            <div>
                <h1 className="h2 mt-3 r-title">
                    {getLocalizedText(name, language)}
                </h1>
            </div>
            {description ? (
                <p>{getLocalizedText(description, language)}</p>
            ) : null}
            <RichTextEditor
                disabled={disabled}
                content={content}
                onChange={onChange}
                onSave={autoSave ? null : onSaveHandler}
                readOnly={readOnly}
                revision={revision}
            />
            {buttonsBar()}
        </div>
    )
}

export default SectionFreetext
