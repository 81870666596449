import React from "react"
import unordered from "./unordered-list"
import ordered from "./ordered-list"
import { EditorState, Modifier, RichUtils } from "draft-js"

import { getCurrentlySelectedBlock } from "./block-selection"

const ListTypeControls = (props) => {
    const { editorState, getListType, listTypes } = props
    const onSelect = (e) => {
        props.onToggle(e.target.value)
    }
    const selection = editorState.getSelection()
    const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())

    const listType = getListType(currentBlock)

    return (
        <select onChange={onSelect}>
            {listTypes.map((type) => (
                <option
                    key={type.label}
                    selected={listType === type.style}
                    value={type.style}>
                    {type.label}
                </option>
            ))}
        </select>
    )
}

function getListStyle(key, types, defaultType) {
    return function (block) {
        const blockData = block.getData()
        const listType = blockData.get(key) || defaultType

        return `list-style-${types[listType]}`
    }
}

function getListType(key, defaultType) {
    return function (block) {
        return block.getData().get(key) || defaultType
    }
}

function setListType(key) {
    return function (editorState, type) {
        const { content, target } = getCurrentlySelectedBlock(editorState)

        return EditorState.push(
            editorState,
            Modifier.mergeBlockData(content, target, {
                [key]: type,
            }),
            "change-block-data",
        )
    }
}

const getUnorderedListType = getListType(unordered.key, unordered.defaultType)
const getOrderedListType = getListType(ordered.key, ordered.defaultType)

const UnorderedListTypeControls = (props) => {
    return (
        <ListTypeControls
            {...props}
            getListType={getUnorderedListType}
            listTypes={unordered.options}
        />
    )
}

const OrderedListTypeControls = (props) => {
    return (
        <ListTypeControls
            {...props}
            getListType={getOrderedListType}
            listTypes={ordered.options}
        />
    )
}

function toggleListType(key) {
    return function (editorState, listType) {
        const { content, currentBlock, hasAtomicBlock, target } =
            getCurrentlySelectedBlock(editorState)

        if (hasAtomicBlock) {
            return editorState
        }

        const blockData = currentBlock.getData()
        const listTypeToSet =
            blockData && blockData.get(key) === listType ? undefined : listType

        return EditorState.push(
            editorState,
            Modifier.mergeBlockData(content, target, {
                [key]: listTypeToSet,
            }),
            "change-block-data",
        )
    }
}

if (!RichUtils.toggleOrderedListType) {
    RichUtils.toggleOrderedListType = toggleListType(ordered.key)
}

if (!RichUtils.toggleUnorderedListType) {
    RichUtils.toggleUnorderedListType = toggleListType(unordered.key)
}

export const LIST_TYPES_OBJ = {
    "unordered-list-item": {
        styleSelector: UnorderedListTypeControls,
        getListType: getUnorderedListType,
        setListType: setListType(unordered.key),
        getListStyle: getListStyle(
            unordered.key,
            unordered.types,
            unordered.defaultType,
        ),
    },
    "ordered-list-item": {
        styleSelector: OrderedListTypeControls,
        getListType: getOrderedListType,
        setListType: setListType(ordered.key),
        getListStyle: getListStyle(
            ordered.key,
            ordered.types,
            ordered.defaultType,
        ),
    },
}
