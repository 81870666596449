import React from "react"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { editSchema as schema } from "./schemas"
import { editMappers as M } from "./mappers"
import FormFields from "./form-fields"
import { head } from "ramda"

import withFetch from "../shared/components/fetch"

export const Edit = ({ data, history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const update = (gene) => {
        api.update(M.toApiModel(gene)).then(() => {
            history.push(routes.genes.index.url)
        })
    }

    return (
        <FormFields
            isEdit
            initialValues={M.toFormModel(data[0])}
            schema={schema}
            onSubmit={update}
            onCancel={() => history.goBack()}
            formProps={{
                title: "Edit Gene",
                primaryText: "Save",
                size: "full",
            }}
        />
    )
}

const loadData = ({ api, id }) => {
    return api.load(id)
}

const fromState = (state, id) =>
    head(state.genes.filter((item) => item.code === id))

export default withFetch(Edit, mkApi(), loadData, useStateContext, fromState)
