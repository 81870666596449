import React from "react"
import { EditorState, Modifier, RichUtils } from "draft-js"
import StyleButton from "./style-button"
import { getCurrentlySelectedBlock } from "./block-selection"
const ALIGNMENT_DATA_KEY = "textAlignment"

if (!RichUtils.toggleAlignment) {
    RichUtils.toggleAlignment = (editorState, alignment) => {
        const { content, currentBlock, hasAtomicBlock, target } =
            getCurrentlySelectedBlock(editorState)

        if (hasAtomicBlock) {
            return editorState
        }

        const blockData = currentBlock.getData()
        const alignmentToSet =
            blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment
                ? undefined
                : alignment

        return EditorState.push(
            editorState,
            Modifier.mergeBlockData(content, target, {
                [ALIGNMENT_DATA_KEY]: alignmentToSet,
            }),
            "change-block-data",
        )
    }
}

const ALIGNMENTS = {
    CENTER: "center",
    JUSTIFY: "justify",
    LEFT: "left",
    RIGHT: "right",
}

const ALIGNMENT_STYLES = [
    { label: "Center", style: "CENTER" },
    { label: "Justified", style: "JUSTIFY" },
    { label: "Left", style: "LEFT" },
    { label: "Right", style: "RIGHT" },
]

const AlignmentStyleControls = (props) => {
    const { editorState } = props
    const selection = editorState.getSelection()
    const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
    const blockData = currentBlock.getData()
    const alignment = blockData.get(ALIGNMENT_DATA_KEY) || "LEFT"

    return (
        <>
            {ALIGNMENT_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={alignment === type.style}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </>
    )
}

export const getAlignmentStyle = (block) => {
    const blockData = block.getData()
    const alignment = blockData.get(ALIGNMENT_DATA_KEY) || "LEFT"

    return `text-alignment-${ALIGNMENTS[alignment]}`
}

export default AlignmentStyleControls
