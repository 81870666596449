import React, { useState } from "react"
import { SelectField } from "../shared/components/selectfield"
import { analysisTypes } from "../../analysisTypes"
import InlineSearch from "../shared/components/inline-search"
import AutocompleteSelectField from "../shared/components/autocomplete-selectedfield"
import DateField from "../shared/components/datefield"
import dayjs from "dayjs"

const filterAnalysisTypes = [
    {
        label: "All Analysis Type",
        value: "",
    },
    ...analysisTypes,
]

const ReporterDoneFilter = ({
    reporter,
    type,
    referral,
    patient,
    from,
    onFilter,
    onChangeSearch,
    totalItems = 0,
    reporters = [],
    loadPatients,
    loadReferrals,
}) => {
    function setReporterFilter(evt) {
        onFilter("reporter", evt.target.value)
    }

    function setFromFilter(value) {
        onFilter("from", dayjs(value, "YYYY-MM-DD").format("DD/MM/YYYY"))
    }

    function setTypeFilter(evt) {
        onFilter("type", evt.target.value)
    }

    function setReferralFilter(evt) {
        onFilter("referral", evt.target)
    }

    function setPatientFilter(evt) {
        onFilter("patient", evt.target)
    }

    const allReferrals = [
        {
            label: "All Referrals",
            value: "",
        },
    ]

    const allPatients = [
        {
            label: "All Patients",
            value: "",
        },
    ]

    const allReporters = [
        {
            label: "All Reporters",
            value: "",
        },
    ]

    const patientsOptions = patient ? [patient, ...allPatients] : allPatients
    const referralsOptions = referral
        ? [referral, ...allReferrals]
        : allReferrals

    const reportersOptions = [
        ...allReporters,
        ...reporters.map((r) => ({
            label: r.name,
            value: r.email,
        })),
    ]

    function autocompletePatients(pattern) {
        if (!pattern) {
            return Promise.resolve(patientsOptions)
        }
        return loadPatients(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
            }))
        })
    }

    function autocompleteReferrals(pattern) {
        if (!pattern) {
            return Promise.resolve(referralsOptions)
        }
        return loadReferrals(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.code,
                label: item.name,
            }))
        })
    }

    function isDateValid(date) {
        return date && dayjs(date, "DD/MM/YYYY", true).isValid()
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <SelectField
                width="col-2"
                options={reportersOptions}
                value={reporter || ""}
                onChange={setReporterFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={referralsOptions}
                value={referral?.value || ""}
                load={autocompleteReferrals}
                onChange={setReferralFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={patientsOptions}
                value={patient?.value || ""}
                load={autocompletePatients}
                onChange={setPatientFilter}
            />
            <SelectField
                width="col-2"
                options={filterAnalysisTypes}
                value={type || ""}
                onChange={setTypeFilter}
            />
            <InlineSearch onChange={onChangeSearch} />
            <DateField
                label="From"
                width="col reporter-done-filter-date"
                value={from}
                onChange={setFromFilter}
                error={isDateValid(from) ? null : "Invalid date"}
            />
            <div
                style={{
                    textAlign: "right",
                    flexGrow: 1,
                    fontWeight: 700,
                    fontSize: "1rem",
                }}>
                {totalItems} items
            </div>
        </div>
    )
}

export default ReporterDoneFilter
