import React, { useState } from "react"
import { withRouter } from "react-router"
import RegisterPatientAdd from "./register-patient-add"
import RegisterPatientSelect from "./register-patient-select"
import { getItem } from "./storage"

const RegisterPatient = (props) => {
    const barcode = props.match.params.id
    const geneticAnalyses = getItem(barcode)
    const [newPatientState, setNewPatientState] = useState({
        newPatientName: geneticAnalyses.newPatient
            ? geneticAnalyses.patientData.firstName
            : "",
    })

    const onCreate = (label) => {
        setNewPatientState({
            newPatientName: label,
        })
    }

    const onAddCancel = () => {
        setNewPatientState({
            newPatientName: "",
        })
    }

    if (newPatientState.newPatientName)
        return (
            <RegisterPatientAdd
                onPrevious={onAddCancel}
                {...props}
                patientId={geneticAnalyses.patientId}
                model={geneticAnalyses.patientData}
                newPatientName={newPatientState.newPatientName}
            />
        )

    return (
        <div className="form-row">
            <RegisterPatientSelect {...props} onCreate={onCreate} />
        </div>
    )
}

export default withRouter(RegisterPatient)
