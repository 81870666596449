import React from "react"
import { CommentAction } from "@atlaskit/comment"
import SuggestTextField from "../shared/components/suggesttextfield"
import RankedRangeListEdit from "./ranked-range-list-edit"

const SectionListBacteriumRankingEdit = ({
    title = "Bacteria",
    bacteriumRankingField,
    allBacteria,
}) => {
    const { value, onChange, error } = bacteriumRankingField
    const suggestions = allBacteria.map((b) => `${b.level};${b.group}`)

    const onBacteriumChange = (newValue, changed) => {
        onChange({
            target: {
                value: value.map((v, i) =>
                    i === changed
                        ? {
                              bacterium: newValue,
                              ranges: [...v.ranges],
                          }
                        : v,
                ),
            },
        })
    }

    const onTableChange = (e, changed) => {
        onChange({
            target: {
                value: value.map((v, i) =>
                    i === changed
                        ? {
                              bacterium: v.bacterium,
                              ranges: e.target.value,
                          }
                        : v,
                ),
            },
        })
    }

    const removeBacterium = (removed) => {
        onChange({
            target: {
                value: value.filter((v, i) => i !== removed),
            },
        })
    }

    const addBacterium = () => {
        onChange({
            target: {
                value: [
                    ...value,
                    {
                        bacterium: "",
                        ranges: [],
                    },
                ],
            },
        })
    }
    const errorsFor = (idx) => {
        return error[idx] || {}
    }
    const renderBacteria = (bacteria, suggestions) =>
        bacteria.map((b, idx) => (
            <div key={b.bacterium.level + b.bacterium.group}>
                <hr />

                <div className="form-row">
                    <SuggestTextField
                        suggestions={suggestions}
                        label="Bacterium"
                        width="r-col-20"
                        value={b.bacterium}
                        onChange={(e) => onBacteriumChange(e.target.value, idx)}
                        onSelected={(e) => onBacteriumChange(e, idx)}
                    />
                    <RankedRangeListEdit
                        field={{
                            value: b.ranges,
                            error,
                            onChange: (e) => onTableChange(e, idx),
                        }}
                        errors={errorsFor(idx)}
                    />
                </div>

                <div className="form-row">
                    <CommentAction key="1" onClick={() => removeBacterium(idx)}>
                        Remove Bacterium
                    </CommentAction>
                </div>
            </div>
        ))
    const globalError = error && typeof error === "string" ? error : ""
    return (
        <>
            <h3>{title}</h3>
            <div className="form-row">
                <CommentAction key="1" onClick={() => addBacterium()}>
                    New Bacterium
                </CommentAction>
            </div>
            <small className="text-danger">{globalError}</small>
            {renderBacteria(value, suggestions)}
            <hr />
        </>
    )
}

export default SectionListBacteriumRankingEdit
