import React, { useState } from "react"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import useFormist from "react-formist"
import Tabs from "@atlaskit/tabs"
import { hormonSpecifiers } from "./schemas"
import TableField from "../shared/components/tablefield"
import LocalizedTextField from "../shared/components/localized-textfield"

const attributeSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
    },
]

const variantSchema = [
    {
        field: "name",
        label: "Name",
    },
    {
        field: "description",
        label: "Description",
    },
]

const FormFields = ({
    initialValues,
    isEdit,
    schema,
    formProps,
    onSubmit,
    onCancel,
}) => {
    const formist = useFormist(initialValues, { schema, onSubmit })
    const [selectedTab, setSelectedTab] = useState(0)

    function onAttributeChange(specifier) {
        return function (e) {
            formist.getFieldProps(`attributes.${specifier}`).onChange({
                target: {
                    value: e.target.value
                        ? e.target.value.map((r) => ({
                              range: {
                                  low: r.low,
                                  high: r.high,
                              },
                              rank: r.rank,
                          }))
                        : [],
                },
            })
        }
    }

    function getRows(attributes) {
        return attributes.map((attribute) => ({
            low: attribute.range.low,
            high: attribute.range.high,
            rank: attribute.rank,
        }))
    }

    const tabs = Object.keys(hormonSpecifiers).map(function (s) {
        return {
            label: hormonSpecifiers[s],
            content: (
                <>
                    <div className="form-row">
                        <TableField
                            key={s}
                            label=""
                            width="col"
                            editable
                            moveRows
                            schema={attributeSchema}
                            // errors={printableErrors(errors, "values")}
                            value={getRows(
                                formist.getFieldProps(`attributes.${s}`).value,
                            )}
                            onChange={onAttributeChange(s)}
                        />
                    </div>
                </>
            ),
        }
    })

    return (
        <Form {...formProps} {...formist.getFormProps()} onCancel={onCancel}>
            <div className="form-row">
                <TextField
                    label="Name"
                    width="col-md-auto"
                    readOnly={isEdit}
                    {...formist.getFieldProps("name")}
                />
                <TextField
                    label="Unit of Measure"
                    width="col-md-auto"
                    {...formist.getFieldProps("uom")}
                />
            </div>
            <div className="form-group">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <TableField
                    label=""
                    width="col"
                    editable
                    moveRows
                    schema={variantSchema}
                    // errors={printableErrors(errors, "values")}
                    {...formist.getFieldProps("variants")}
                />
            </div>
            <Tabs
                tabs={tabs}
                onSelect={(tab, idx) => setSelectedTab(idx)}
                selected={selectedTab}
            />
        </Form>
    )
}

export default FormFields
