import React from "react"
import { getLocalizedText } from "../../shared/locale"
import "./category.scss"

const colIndexCss = {
    textAlign: "center",
}

export default function SectionCategory({ item, style, language = "it" }) {
    const { name, value = {} } = item

    return (
        <div
            data-testid="section-category"
            style={style}
            className={`section-category section-category-${name} section-category-${value.index}`}>
            <div>
                <h1 className="h2 mt-3 r-title">
                    {getLocalizedText(name, language)}
                </h1>
                <hr />
            </div>
            <div className="r-row">
                <div className="r-col-60" style={colIndexCss}>
                    <span>{value.category}</span>
                </div>
                <div className="r-col-40">
                    {getLocalizedText(value.text, language)}
                </div>
            </div>
        </div>
    )
}
