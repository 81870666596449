import React from "react"
import StyleButton from "./style-button"

import { LIST_TYPES_OBJ } from "./lists"

const LIST_TYPES = [
    {
        label: "UL",
        style: "unordered-list-item",
    },
    {
        label: "OL",
        style: "ordered-list-item",
    },
]
const Empty = () => {
    return <></>
}

function getStyleSelectorFor(type) {
    return LIST_TYPES_OBJ[type] ? LIST_TYPES_OBJ[type].styleSelector : Empty
}

const ListTypeControls = (props) => {
    const { editorState } = props
    const selection = editorState.getSelection()
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()
    const StyleSelector = getStyleSelectorFor(blockType)
    return (
        <>
            {LIST_TYPES.map((type) => {
                return (
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )
            })}
            <StyleSelector
                editorState={editorState}
                onToggle={(type) => props.onToggleStyle(blockType, type)}
            />
        </>
    )
}

export function setListType(editorState, type) {
    const block = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
    return LIST_TYPES_OBJ[block.getType()]
        ? LIST_TYPES_OBJ[block.getType()].setListType(editorState, type)
        : editorState
}

export function getListType(block) {
    return LIST_TYPES_OBJ[block.getType()]
        ? LIST_TYPES_OBJ[block.getType()].getListType(block)
        : null
}

export function getListStyle(block) {
    return LIST_TYPES_OBJ[block.getType()]
        ? LIST_TYPES_OBJ[block.getType()].getListStyle(block)
        : ""
}

export default ListTypeControls
