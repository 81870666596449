import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import SectionGenoTypeGroupsEdit from "./section-genotype-groups-edit"
import { CommentAction } from "@atlaskit/comment"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionListGenoTypeEdit = ({ section, onSave, onCancel }) => {
    const toGroup = (s) => {
        const parts = s.split("@")

        return {
            name: parts[0],
            values: s.substring(s.lastIndexOf("@") + 1).split("|"),
        }
    }
    const model = {
        ...section,
        genoTypes: section.genoTypes.map((b) => toGroup(b64DecodeUnicode(b))),
    }

    const groupSchema = yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string(),
                values: yup
                    .array()
                    .of(
                        yup
                            .string()
                            .test(
                                "valid-genotype",
                                "Fill all the requested values",
                                (value) => {
                                    return (
                                        value.trim() !== "" &&
                                        value.indexOf(";") !== -1 &&
                                        value
                                            .substring(0, value.indexOf(";"))
                                            .trim() !== "" &&
                                        value
                                            .substring(value.indexOf(";") + 1)
                                            .trim() !== ""
                                    )
                                },
                            ),
                    )
                    .min(1)
                    .required(),
            }),
        )
        .min(1)
        .required()
        .test(
            "mandatory-names-multiple",
            "You have to specify names of all groups if they are more than one",
            (value) =>
                value.length === 1 ||
                value.filter((v) => v.name.trim() === "").length === 0,
        )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup
            .string()
            .test(
                "valid-description",
                "description is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        missingValueLabel: yup
            .string()
            .test(
                "valid-missing",
                "missingValueLabel is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        note: yup.string(),
        genoTypes: groupSchema,
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        genoTypes: formist.values.genoTypes
                            .map(
                                (g) =>
                                    g.name +
                                    "@" +
                                    g.values
                                        .filter((b) => b.trim() !== "")
                                        .join("|"),
                            )
                            .map(b64EncodeUnicode),
                    })
                }
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <LocalizedTextField
                    label="Missing Value Label"
                    width="r-col-20"
                    {...formist.getFieldProps("missingValueLabel")}
                />
                <LocalizedTextField
                    label="Notes"
                    width="col"
                    {...formist.getFieldProps("note")}
                />
            </div>
            <SectionGenoTypeGroupsEdit
                genoTypesField={formist.getFieldProps("genoTypes")}
            />
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionListGenoTypeEdit
