import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TextField from "../shared/components/textfield"
import SectionListBacteriumSymbolEdit from "./section-list-bacterium-symbol-edit"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    description: yup.string(),
    bacteria: yup.array().min(1).of(yup.string().required()),
})

const SectionSymbolEdit = ({ section, onSave, bacteria = [], onCancel }) => {
    const toSymbol = (s) => {
        const parts = s.split("@")
        return {
            bacterium: b64DecodeUnicode(parts[0]),
            name: parts[1],
            symbol: parts[2],
            condition: parts[3],
        }
    }

    const model = {
        ...section,
        bacteria: section.bacteria.map((b) => toSymbol(b64DecodeUnicode(b))),
    }

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        bacteria: formist.values.bacteria
                            .map(
                                (b) =>
                                    b64EncodeUnicode(b.bacterium) +
                                    "@" +
                                    b.name +
                                    "@" +
                                    b.symbol +
                                    "@" +
                                    b.condition,
                            )
                            .map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <TextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <SectionListBacteriumSymbolEdit
                allBacteria={bacteria}
                field={formist.getFieldProps("bacteria")}
            />
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionSymbolEdit
