import React from "react"
import { getLocalizedText } from "../../shared/locale"
import { routes } from "../../shared/routing"

export function typeOfError(message = "") {
    const searchMissingBacterium = message.match(
        /^No Bacterium with an id of { "level" : "(.*)", "group" : "(.*)" } was found./i,
    )
    const searchMissingPatientData = message.match(
        /No (.*) with an id of .* was found/i,
    )
    const searchNoBacteriumInAnalysis = message.match(
        /Missing bacterium BacteriumCode\((.*), (.*)\) from analysis result.*/i,
    )

    if (searchMissingBacterium) {
        return [
            "UNKNOWN_BACTERIUM",
            searchMissingBacterium[1] + ", " + searchMissingBacterium[2],
            {
                level: searchMissingBacterium[1],
                group: searchMissingBacterium[2],
            },
        ]
    }
    if (searchNoBacteriumInAnalysis) {
        return [
            "NO_BACTERIUM_IN_ANALYSIS",
            searchNoBacteriumInAnalysis[1] +
                ", " +
                searchNoBacteriumInAnalysis[2],
            {
                level: searchNoBacteriumInAnalysis[1],
                group: searchNoBacteriumInAnalysis[2],
            },
        ]
    }
    if (searchMissingPatientData) {
        return ["MISSING_" + searchMissingPatientData[1].toUpperCase(), "", ""]
    }
    return ["GENERIC_ERROR", message, ""]
}

const addPatient = (e, barcode, history) => {
    e.preventDefault()
    history.push(routes.geneticAnalyses.edit.buildUrl(barcode))
}
const addPatientDetails = (e, barcode, history) => {
    e.preventDefault()
    history.push(routes.patients.edit.buildUrl(barcode))
}
const addBacterium = (e, { level, group }, history) => {
    e.preventDefault()
    history.push(
        routes.bacteria.index.routes.addwithdata.buildUrl(level, group),
    )
}

export const getErrorComponents = (e = "", history, barcode) => {
    return (
        <>
            {e
                .split("##")
                .map((error) => getErrorComponent(error, history, barcode))}
        </>
    )
}

export const getErrorComponent = (e, history, barcode) => {
    const [type, message, detail] = typeOfError(e, barcode)
    switch (type) {
        case "UNKNOWN_BACTERIUM":
            return (
                <div>
                    Unknown bacteria: {message}. To add it, click{" "}
                    <a
                        href=""
                        onClick={(e) => addBacterium(e, detail, history)}>
                        here
                    </a>
                </div>
            )
        case "NO_BACTERIUM_IN_ANALYSIS":
            return (
                <div>
                    Missing bacterium from analysis results ({message}), you
                    should reimport a file with all the required bacteria
                </div>
            )

        case "MISSING_PATIENT":
            return (
                <div>
                    Missing patient. To add it, click{" "}
                    <a href="" onClick={(e) => addPatient(e, barcode, history)}>
                        here
                    </a>
                </div>
            )
        case "MISSING_PATIENTPHYSICALDESCRIPTION":
            return (
                <div>
                    Missing patient physical description. To add it, click{" "}
                    <a
                        href=""
                        onClick={(e) => addPatientDetails(e, barcode, history)}>
                        here
                    </a>
                </div>
            )
        default:
            return message
    }
}

function SectionError({
    item,
    language = "it",
    style,
    barcode,
    history = { push: () => {} },
}) {
    const { name, error } = item

    const errorComponent = getErrorComponents(error, history, barcode)

    return (
        <div
            className={`section-error`}
            data-testid="section-error"
            style={style}>
            {name ? (
                <div className="section-error-title">
                    <h1 className="h2 mt-3 r-title">
                        {getLocalizedText(name, language)}
                    </h1>
                    <hr />
                </div>
            ) : null}
            <div className="section-error-message">
                {errorComponent || error}
            </div>
        </div>
    )
}

export default SectionError
