import React from "react"
import TableField, { printableErrors } from "../shared/components/tablefield"

const schema = [
    {
        field: "type",
        label: "Type",
        type: "select",
        options: [
            {
                label: "Text",
                value: "Text",
            },
            {
                label: "NumberAsText",
                value: "NumberAsText",
            },
            {
                label: "Styled Text",
                value: "StyledText",
            },
            {
                label: "Image",
                value: "Image",
            },
            {
                label: "Icon",
                value: "Icon",
            },
        ],
    },
    {
        field: "label",
        label: "Label",
        width: 200,
    },
    {
        field: "value",
        label: "Value",
        type: "expression",
    },
    {
        field: "style",
        label: "Style",
        type: "expression",
    },
]

const TableColumnsEdit = ({ field, errors = {} }) => {
    const { value, onChange, error, ...other } = field

    return (
        <div className="form-row">
            <TableField
                label=""
                width="col"
                editable
                moveRows
                schema={schema}
                errors={printableErrors(errors, "values")}
                {...other}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default TableColumnsEdit
