import React from "react"
import { SelectField } from "../shared/components/selectfield"
import { analysisTypes } from "../../analysisTypes"
import InlineSearch from "../shared/components/inline-search"
import AutocompleteSelectField from "../shared/components/autocomplete-selectedfield"

const filterAnalysisTypes = [
    {
        label: "All Analysis Type",
        value: "",
    },
    ...analysisTypes,
]

const ReporterTodoFilter = ({
    type,
    referral,
    patient,
    onFilter,
    onChangeSearch,
    totalItems = 0,
    loadPatients,
    loadReferrals,
}) => {
    function setTypeFilter(evt) {
        onFilter("type", evt.target.value)
    }

    function setReferralFilter(evt) {
        onFilter("referral", evt.target)
    }

    function setPatientFilter(evt) {
        onFilter("patient", evt.target)
    }

    const allReferrals = [
        {
            label: "All Referrals",
            value: "",
        },
    ]

    const allPatients = [
        {
            label: "All Patients",
            value: "",
        },
    ]

    const patientsOptions = patient ? [patient, ...allPatients] : allPatients
    const referralsOptions = referral
        ? [referral, ...allReferrals]
        : allReferrals

    function autocompletePatients(pattern) {
        if (!pattern) {
            return Promise.resolve(patientsOptions)
        }
        return loadPatients(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
            }))
        })
    }

    function autocompleteReferrals(pattern) {
        if (!pattern) {
            return Promise.resolve(referralsOptions)
        }
        return loadReferrals(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.code,
                label: item.name,
            }))
        })
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <AutocompleteSelectField
                width="col-2"
                options={referralsOptions}
                value={referral?.value || ""}
                load={autocompleteReferrals}
                onChange={setReferralFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={patientsOptions}
                value={patient?.value || ""}
                load={autocompletePatients}
                onChange={setPatientFilter}
            />
            <SelectField
                width="col-2"
                options={filterAnalysisTypes}
                value={type || ""}
                onChange={setTypeFilter}
            />
            <InlineSearch onChange={onChangeSearch} />
            <div
                style={{
                    textAlign: "right",
                    flexGrow: 1,
                    fontWeight: 700,
                    fontSize: "1rem",
                }}>
                {totalItems} items
            </div>
        </div>
    )
}

export default ReporterTodoFilter
