import React, { useState } from "react"
import Button from "@atlaskit/button"
import { useStateContext } from "../../shared/state-context"
import useFormist from "react-formist"
import * as yup from "yup"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import SectionHeader from "../shared/components/section-header"
import SectionItem from "./section-item"
import { Sections as S } from "./section-list"
import { mkApi } from "./api"
import { success } from "../../shared/notifications"
import { head } from "ramda"
import uuidv4 from "uuid/v4"
import { analysisTypes } from "../../analysisTypes"

import withFetch from "../shared/components/fetch"
import { SelectField } from "../shared/components/selectfield"
import ModalDialog from "@atlaskit/modal-dialog"

function byIndex(a, b) {
    return a.index > b.index ? 1 : b.index > a.index ? -1 : 0
}

function sort(state) {
    state.sectionConfigurations.sort(byIndex)
    return state
}

const schema = yup.object().shape({
    id: yup.string().required(),
    baseTemplate: yup.string().nullable(),
    name: yup.string().required(),
    title: yup.string().required(),
    analysisType: yup.string().required(),
    analysiSubType: yup.string(),
    defaultTemplate: yup.boolean().required(),
    sectionConfigurations: yup.array().required(),
})

export const createSectionData = {
    leaderboard: () => ({
        bacteriumLevel: "",
        limit: 0,
    }),
    leaderbarchart: () => ({
        description: "",
        bacteriumLevel: "",
        limit: 0,
    }),
    compare: () => ({
        description: "",
        bacteria: [],
        bacteriaRestId: "",
    }),
    list: () => ({
        description: "",
        missingValueLabel: "",
        note: "",
        semaphore: true,
        bacteria: [],
    }),
    table: () => ({
        description: "",
        note: "",
        type: "table",
        flip: false,
        dataSource: "",
        dataSourceFilter: [],
        columns: [],
    }),
    listgenotype: () => ({
        description: "",
        missingValueLabel: "",
        note: "",
        genoTypes: [],
    }),
    enterotype: () => ({
        bacteria: [],
        descriptionOne: "",
        descriptionTwo: "",
        descriptionThree: "",
    }),
    dnachart: () => ({
        description: "",
        factor: 0,
        genoTypes: [],
        ranks: [],
        widget: "BAR",
    }),
    freetext: () => ({
        content:
            '{"blocks":[{"key":"523bk","text":"Modifica questo testo o cancellalo dal report","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    }),
    macrobiotachart: () => ({
        description: "",
        factor: 0,
        bacteria: [],
        chartType: "PositiveBar",
        multipliers: [],
        ranks: [],
    }),
    histogram: () => ({
        description: "",
        bacterium: "",
        colors: [],
        histogramType: "PositiveHistogram",
        bars: 3,
    }),
    symbol: () => ({
        description: "",
        bacteria: [],
    }),
    summary: () => ({
        summaryType: "MicrobialBiodiversity",
        ranks: [],
    }),
    balance: () => ({
        rankedSections: [],
        ranks: [],
        boostSections: [],
    }),
    ratio: () => ({
        description: "",
        subtitle: "",
        notes: "",
        numerator: "",
        denominator: "",
        ranks: [],
        points: [],
    }),
    image: () => ({
        images: [],
        selected: "",
    }),
    listhormones: () => ({
        description: "",
        hormones: [],
        withChart: false,
    }),
    gaugehormones: () => ({
        description: "",
        hormones: [],
        barRanges: [],
    }),
    summaryhormones: () => ({
        description: "",
        subtitle: "",
        numerator: {
            male: "",
            female: "",
        },
        denominator: {
            male: "",
            female: "",
        },
        ranks: {
            male: [],
            female: [],
        },
    }),
    hormoneschart: () => ({
        description: "",
        factor: 0,
        hormones: [],
        ranks: [],
    }),
    multigaugehormones: () => ({
        description: "",
        hormones: [],
        agePoints: [],
        bmiPoints: [],
        exercisePoints: {},
        smokePoints: {},
        conversionPoints: {
            male: [],
            female: [],
        },
    }),
    lineshormones: () => ({
        description: "",
        hormones: [],
    }),
    ratiogaugehormones: () => ({
        description: "",
        hormones: [],
        barRanges: [],
    }),
    experimental: () => ({
        payload: "",
    }),
}

const allSectionTypes = [
    {
        value: "leaderboard",
        label: "Leaderboard",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "leaderbarchart",
        label: "LeaderBarChart",
        analysisTypes: ["MacroBiota", "Vaginal"],
    },
    {
        value: "compare",
        label: "Compare",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "list",
        label: "List",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "table",
        label: "Table",
        analysisTypes: ["ALL"],
    },
    {
        value: "listgenotype",
        label: "List of Genotypes",
        analysisTypes: ["DNA"],
    },
    {
        value: "enterotype",
        label: "Entero type",
        analysisTypes: ["MacroBiota", "Vaginal"],
    },
    { value: "dnachart", label: "Genehome Chart", analysisTypes: ["DNA"] },
    {
        value: "macrobiotachart",
        label: "Macrobiota Chart",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "histogram",
        label: "Histogram",
        analysisTypes: ["MacroBiota", "Vaginal"],
    },
    {
        value: "symbol",
        label: "Symbol",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    { value: "freetext", label: "Free Text", analysisTypes: ["ALL"] },
    {
        value: "summary",
        label: "Summary",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "balance",
        label: "Balance",
        analysisTypes: ["MacroBiota", "Vaginal", "Oral"],
    },
    {
        value: "ratio",
        label: "Ratio",
        analysisTypes: ["MacroBiota", "Vaginal"],
    },
    { value: "image", label: "Image", analysisTypes: ["ALL"] },
    {
        value: "listhormones",
        label: "List of Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "gaugehormones",
        label: "Gauge on Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "summaryhormones",
        label: "Summary on Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "hormoneschart",
        label: "Hormones Chart",
        analysisTypes: ["Epigene"],
    },
    {
        value: "multigaugehormones",
        label: "MultiGauge on Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "lineshormones",
        label: "Lines on Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "ratiogaugehormones",
        label: "Ratio Gauge on Hormones",
        analysisTypes: ["Epigene"],
    },
    {
        value: "experimental",
        label: "Experimental",
        analysisTypes: ["ALL"],
    },
]

function byAnalysisType(type) {
    return (section) =>
        type === "ALL" ||
        section.analysisTypes.includes("ALL") ||
        section.analysisTypes.includes(type)
}

function RemoveSection({ enabled = false, onConfirm, onCancel, section }) {
    return enabled ? (
        <ModalDialog id="remove-section-dialog" heading="Remove Section">
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={onConfirm}>
                        Remove
                    </Button>,
                    "cancel",
                ]}
                primaryText="Remove"
                onCancel={onCancel}>
                <div className="form-row">
                    Do you want to remove {section.name}?
                </div>
            </Form>
        </ModalDialog>
    ) : null
}

export const Edit = ({ data, history }) => {
    const [templateData, bacteria, pathogens, drugs, hormones, allTemplates] =
        data[0]
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const formist = useFormist(sort(templateData), {
        schema,
        onSubmit: (formData) => {
            const template = {
                ...formData,
                sectionConfigurations: sort(formData).sectionConfigurations.map(
                    (s, idx) => ({
                        ...s,
                        index: idx,
                    }),
                ),
            }
            api.update(template).then((response) => {
                if (response && response.ok) success("Template saved!")
            })
        },
    })

    const [removing, setRemoving] = useState(null)

    function saveTemplate() {
        setTimeout(() => {
            document.querySelector("button[type='submit']").click()
        }, 0)
    }

    const modify = (action) => {
        let sections = formist.values.sectionConfigurations
        const newSections = action(sections).sort(byIndex)
        formist.change("sectionConfigurations", newSections)
    }

    const cloneHandler = (id) => (e) => {
        e.preventDefault()
        modify(S.clone(id))
    }

    const upHandler = (id) => (e) => {
        e.preventDefault()
        modify(S.moveUp(id))
    }

    const downHandler = (id) => (e) => {
        e.preventDefault()
        modify(S.moveDown(id))
    }

    const removeHandler = (e) => {
        e.preventDefault()
        modify(S.remove(removing.id))
        setRemoving(null)
        saveTemplate()
    }

    const confirmRemoveHandler = (section) => (e) => {
        e.preventDefault()
        setRemoving(section)
    }

    const cancelRemoveHandler = (e) => {
        e.preventDefault()
        setRemoving(null)
    }

    const editHandler = (id) => (e) => {
        e.preventDefault()
        modify(S.edit(id))
    }

    const cancelHandler = (id) => (e) => {
        e.preventDefault()
        modify(S.cancel(id))
    }

    const saveHandler = (id) => (edited) => {
        modify(S.save(id, edited))
        saveTemplate()
    }

    const analysisType = formist.getFieldProps("analysisType").value
    const analysisSubType =
        formist.getFieldProps("analysisSubType").value || "Unknown"
    const sectionTypes = allSectionTypes.filter(byAnalysisType(analysisType))
    const baseTemplates = [
        {
            value: null,
            label: " - None - ",
        },
        ...(allTemplates ? allTemplates.items : [])
            .filter(
                (t) =>
                    (!analysisType || t.analysisType === analysisType) &&
                    (analysisSubType === "Unknown" ||
                        t.analysiSubType === analysisSubType) &&
                    t.id !== templateData.id,
            )
            .map((t) => ({
                value: t.id,
                label: t.name,
            })),
    ]

    const [selectedSectionType, setSelectedSectionType] = useState(
        sectionTypes[0].value,
    )
    const createMetadata = (type) => ({
        id: uuidv4(),
        name: "",
        htmlElementId: "",
        type,
        index:
            formist.values.sectionConfigurations.reduce((previous, current) => {
                return Math.max(previous, current.index)
            }, 0) + 1,
    })

    const createSection = (type) => {
        return {
            ...createMetadata(type),
            ...createSectionData[type](),
            isEditMode: true,
            isNew: true,
        }
    }

    const addSectionHandler = () => {
        const newSection = createSection(selectedSectionType)
        const newSections = [
            ...formist.values.sectionConfigurations,
            newSection,
        ]
        formist.change("sectionConfigurations", newSections)
    }
    const allSections = formist.values.sectionConfigurations.map((s) => ({
        id: s.id,
        name: s.name,
        type: s.type,
    }))

    const pathogensItems = pathogens.items || []

    const dataSources = {
        Bacterium: bacteria.items,
        Virus: pathogensItems.filter((p) => p.type === "Virus"),
        Fungus: pathogensItems.filter((p) => p.type === "Fungus"),
        Drug: drugs.items,
        Parasite: pathogensItems.filter((p) => p.type === "Parasite"),
        Hormon: hormones.items,
        Gene: [],
    }

    const currentAnalysisType = analysisTypes.find(
        (a) => a.value === formist.getFieldProps("analysisType").value,
    )

    const analysisSubTypes = [
        {
            label: " - None - ",
            value: "Unknown",
        },
        ...(currentAnalysisType?.subTypes || []),
    ]

    return (
        <>
            <RemoveSection
                enabled={removing !== null}
                section={removing}
                onCancel={cancelRemoveHandler}
                onConfirm={removeHandler}
            />
            <Form
                buttonsClassName="fixed-form-buttons"
                size="full"
                title="Edit Template"
                {...formist.getFormProps()}
                primaryText="Save"
                onCancel={() => history.goBack()}>
                <input type="hidden" {...formist.getFieldProps("id")} />
                <div className="form-row">
                    <TextField
                        label="Name"
                        width="col-4"
                        {...formist.getFieldProps("name")}
                    />
                    <TextField
                        label="Title"
                        width="col-4"
                        {...formist.getFieldProps("title")}
                    />
                    <SelectField
                        label="Analysis Type"
                        width="col"
                        options={analysisTypes}
                        {...formist.getFieldProps("analysisType")}
                    />
                    {analysisSubTypes.length > 1 ? (
                        <SelectField
                            label="Analysis Sub Type"
                            width="col"
                            options={analysisSubTypes}
                            {...formist.getFieldProps("analysisSubType")}
                        />
                    ) : null}
                </div>
                <div className="form-row">
                    <SelectField
                        label="Base Template"
                        width="col"
                        options={baseTemplates}
                        {...formist.getFieldProps("baseTemplate")}
                    />
                </div>
                <div className="form-container-separator">
                    <SectionHeader title="Sections" size="h4" />
                    {formist.values.sectionConfigurations.map((x, i) => {
                        return (
                            <SectionItem
                                key={i}
                                section={x}
                                bacteria={bacteria.items}
                                hormones={hormones.items}
                                dataSources={dataSources}
                                onEdit={editHandler(x.id)}
                                onUp={upHandler(x.id)}
                                onDown={downHandler(x.id)}
                                onRemove={confirmRemoveHandler(x)}
                                onSave={saveHandler(x.id)}
                                onCancel={cancelHandler(x.id)}
                                onClone={cloneHandler(x.id)}
                                allSections={allSections}
                            />
                        )
                    })}
                </div>

                <div className="form-container-separator">
                    <div className="form-row">
                        <select
                            data-testid="section-types-combo"
                            onChange={(e) =>
                                setSelectedSectionType(e.target.value)
                            }>
                            {sectionTypes.map((s) => (
                                <option
                                    key={s.value}
                                    selected={s.value === selectedSectionType}
                                    value={s.value}>
                                    {s.label}
                                </option>
                            ))}
                        </select>
                        <Button
                            appearance="primary"
                            onClick={addSectionHandler}>
                            Add Section
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    )
}

const fromState = (state, id) =>
    head(state.items.filter((item) => item.id === id))

const loadData = ({ api, id }) => api.load(id)

const loadBacteria = ({ api }) => api.loadBacteria()

const loadPathogens = ({ api }) => api.loadPathogens()

const loadDrugs = ({ api }) => api.loadDrugs()

const loadHormones = ({ api }) => api.loadHormones()

const loadTemplates = ({ api }) => api.loadTemplates()

export default withFetch(Edit, mkApi(), loadData, useStateContext, fromState, [
    loadBacteria,
    loadPathogens,
    loadDrugs,
    loadHormones,
    loadTemplates,
])
