import React from "react"
import SectionGenoTypeListEdit from "./section-genotype-list-edit"
import { CommentAction } from "@atlaskit/comment"
import LocalizedTextField from "../shared/components/localized-textfield"
import CheckField from "../shared/components/checkfield"

const SectionGenoTypeGroupsEdit = ({ genoTypesField }) => {
    const { value, onChange, error } = genoTypesField

    const onNameChange = (e, changed) => {
        onChange({
            target: {
                value: value.map((v, i) =>
                    i === changed
                        ? {
                              name: e.target.value,
                              values: [...v.values],
                          }
                        : v,
                ),
            },
        })
    }

    const onTableChange = (e, changed) => {
        onChange({
            target: {
                value: value.map((v, i) =>
                    i === changed
                        ? {
                              name: v.name,
                              values: e.target.value,
                          }
                        : v,
                ),
            },
        })
    }

    const removeGroup = (removed) => {
        onChange({
            target: {
                value: value.filter((v, i) => i !== removed),
            },
        })
    }

    const addGroup = () => {
        onChange({
            target: {
                value: [
                    ...value,
                    {
                        name: "",
                        values: [],
                    },
                ],
            },
        })
    }
    const errorsFor = (idx) => {
        return error[idx] || {}
    }
    const renderGroups = (groups) =>
        groups.map((g, idx) => (
            <>
                <hr />

                <div className="form-row">
                    <LocalizedTextField
                        label="Group Name"
                        width="r-col-20"
                        value={g.name}
                        onChange={(e) => onNameChange(e, idx)}
                    />
                    <SectionGenoTypeListEdit
                        genoTypesField={{
                            value: g.values,
                            error,
                            onChange: (e) => onTableChange(e, idx),
                        }}
                        errors={errorsFor(idx)}
                    />
                </div>
                {idx > 0 && (
                    <div className="form-row">
                        <CommentAction key="1" onClick={() => removeGroup(idx)}>
                            Remove Group
                        </CommentAction>
                    </div>
                )}
            </>
        ))
    const globalError = error && typeof error === "string" ? error : ""
    return (
        <>
            <h3>GenoTypes</h3>
            <div className="form-row">
                <CommentAction key="1" onClick={() => addGroup()}>
                    New Group
                </CommentAction>
            </div>
            <small className="text-danger">{globalError}</small>
            {renderGroups(value)}
            <hr />
        </>
    )
}

export default SectionGenoTypeGroupsEdit
