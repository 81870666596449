import React from "react"
import { withStateContext, useStateContext } from "../state-context"
import { mkApi } from "./api"
import { initialState, reducer } from "./store"
import { getItem } from "./storage"

export const withSecurityContext = (AppComponent, state) => {
    return withStateContext(AppComponent, state || initialState, reducer)
}

export const useSecurity = () => {
    const { dispatch, state } = useStateContext()
    const api = mkApi(dispatch)
    const storedUser = getItem("user")
    return [state.user, api, storedUser]
}

export function testWithSecurity(component, testState) {
    const App = withSecurityContext(component, testState)
    return <App />
}
