import React from "react"
import PDFLink from "./pdf-link"
import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup,
} from "@atlaskit/dropdown-menu"

const PDFMenu = ({
    layouts = [],
    allLayouts = [],
    barcode = "",
    status,
    downloading = false,
    onCreate,
    onDownload,
    pdf = {},
}) => {
    if (status !== "Analysed") return null
    if (layouts.length === 0) return <span>No Layout Set</span>
    if (layouts.length === 1)
        return downloading === barcode ? (
            <span>Downloading...</span>
        ) : (
            <div className="dashboard-menu">
                <DropdownMenu trigger="PDF" triggerType="button">
                    <DropdownItemGroup>
                        <div key={layouts[0]}>
                            <div>
                                <DropdownItem>
                                    {downloading === barcode ? (
                                        <span>Downloading...</span>
                                    ) : (
                                        <>
                                            <div>
                                                <PDFLink
                                                    withLayout
                                                    item={{
                                                        status,
                                                        barcode,
                                                        pdf,
                                                    }}
                                                    allLayouts={allLayouts}
                                                    layout={layouts[0]}
                                                    onCreate={onCreate}
                                                    onDownload={onDownload}
                                                    reportMode={"complete"}
                                                />
                                            </div>
                                            <div>
                                                <PDFLink
                                                    withLayout
                                                    item={{
                                                        status,
                                                        barcode,
                                                        pdf,
                                                    }}
                                                    allLayouts={allLayouts}
                                                    layout={layouts[0]}
                                                    onCreate={onCreate}
                                                    onDownload={onDownload}
                                                    reportMode={"summary"}
                                                />
                                            </div>
                                        </>
                                    )}
                                </DropdownItem>
                            </div>
                        </div>
                    </DropdownItemGroup>
                </DropdownMenu>
            </div>
        )
    return (
        <div className="dashboard-menu">
            <DropdownMenu trigger="PDF" triggerType="button">
                <DropdownItemGroup>
                    {layouts.map((layout) => (
                        <div key={layout}>
                            <DropdownItem>
                                {downloading === barcode ? (
                                    <span>Downloading...</span>
                                ) : (
                                    <>
                                        <div>
                                            <PDFLink
                                                withLayout
                                                item={{
                                                    status,
                                                    barcode,
                                                    pdf,
                                                }}
                                                allLayouts={allLayouts}
                                                layout={layout}
                                                reportMode={"complete"}
                                                onCreate={onCreate}
                                                onDownload={onDownload}
                                            />
                                        </div>
                                        <div>
                                            <PDFLink
                                                withLayout
                                                item={{
                                                    status,
                                                    barcode,
                                                    pdf,
                                                }}
                                                allLayouts={allLayouts}
                                                layout={layout}
                                                reportMode={"summary"}
                                                onCreate={onCreate}
                                                onDownload={onDownload}
                                            />
                                        </div>
                                    </>
                                )}
                            </DropdownItem>
                        </div>
                    ))}
                </DropdownItemGroup>
            </DropdownMenu>
        </div>
    )
}

export default PDFMenu
