import React from "react"

const RadioCheckList = ({
    label,
    items,
    value,
    onChange,
    style,
    width = "col",
    error,
    visible = true,
}) => {
    return (
        <div
            className={`form-group ${width}`}
            style={{ display: visible ? "block" : "none" }}>
            {label && <label>{label}</label>}
            <div style={style}>
                {items.map((item) => {
                    const id = `radio-check-list-${item.value}`
                    return (
                        <div
                            key={item.value}
                            className="form-check form-check-inline">
                            <input
                                id={id}
                                className="form-check-input"
                                type="radio"
                                checked={value == item.value}
                                onChange={(e) => {
                                    e.target.value = item.value
                                    onChange(e)
                                }}
                            />
                            <label className="form-check-label" htmlFor={id}>
                                {item.label}
                            </label>
                        </div>
                    )
                })}
            </div>
            <small className="text-danger">{error}</small>
        </div>
    )
}

export default RadioCheckList
