import "./show.scss"
import "../../report.css"
import React, { useState } from "react"
import SectionHeader from "../shared/components/section-header"
import { withRouter } from "react-router"
import { apis } from "../../shared/apis"
import { routes } from "../../shared/routing"
import { NavLink } from "react-router-dom"
import { getItem } from "../../shared/security/storage"
import Header from "./header"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import Button from "@atlaskit/button"

import { success } from "../../shared/notifications"
import DirtyNotifier from "./dirty-notifier"
import PDFLink from "../dashboard/pdf-link"
import { download } from "../../shared/ajax"
import { error } from "../../shared/notifications"
import Email, { getEmailStatus } from "../dashboard/report-emails"
import { typeOfError } from "./section-error"

import Filters from "./filters"
import Sections from "./sections"

const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error posting file",
                StackTrace: "",
            }
        }
    }
    if (ex && ex.Message) {
        error(ex.Message, {
            dismiss: false,
        })
        console.error(ex.StackTrace)
    }
}

function getLayoutName(layoutId, layouts) {
    const matching = layouts.filter((l) => l.id === layoutId)
    if (matching && matching.length) return matching[0].name
    return layoutId
}

const Buttons = ({
    report,
    layoutIds,
    allLayouts = [],
    barcode,
    token,
    onDownload,
    onCreate,
    onEmail,
    rules,
}) => {
    return (
        <div
            style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
            }}>
            <NavLink
                style={{ marginRight: 10 }}
                to={{
                    pathname: routes.geneticAnalyses.edit.buildUrl(barcode),
                }}>
                {barcode} (Edit)
            </NavLink>
            {(layoutIds || [undefined]).map((layout) => (
                <>
                    <Button
                        appearance="primary"
                        style={{ marginRight: 10 }}
                        key={layout}>
                        <NavLink
                            style={{ textDecoration: "none", color: "white" }}
                            to={{
                                pathname: routes.reports.toPrintUrl({
                                    layout,
                                    barcode,
                                    reportMode: "complete",
                                    token,
                                }),
                            }}
                            target="_blank">
                            {!layoutIds || layoutIds.length === 1
                                ? "Print Report"
                                : "Print " + getLayoutName(layout, allLayouts)}
                        </NavLink>
                    </Button>
                    <PDFLink
                        style={{ marginRight: 10 }}
                        item={report}
                        layout={layout}
                        allLayouts={allLayouts}
                        onCreate={onCreate}
                        onDownload={onDownload}
                    />
                    <Email
                        style={{ marginRight: 10 }}
                        items={[
                            {
                                status: getEmailStatus(
                                    report.reportEmails || {},
                                    layout,
                                ),
                                barcode,
                                layout,
                                rules,
                            },
                        ]}
                        onSend={onEmail}
                    />
                </>
            ))}
        </div>
    )
}

export const Report = ({ history, barcode, report, onUpdate = () => {} }) => {
    const user = getItem("user") || {}
    const { title, sections = [], templates = [], language } = report

    const [freetextSections, setFreeTextSections] = useState(
        sections.filter((s) => s.type === "freetext"),
    )

    const onCancel = () => history.goBack()
    const onSaveFreetext = (
        item,
        silent = false,
        onSuccess = () => {},
        onError = () => {},
    ) => {
        apis.reports
            .saveSectionFreetext({ barcode, ...item })
            .then(() => {
                if (!silent) success("Free text updated sucessfully")
                const newFreeTexts = freetextSections.map((s) => {
                    if (s.id === item.id) {
                        return { ...s, ...item }
                    }
                    return s
                })
                setFreeTextSections(newFreeTexts)
            })
            .then(onSuccess)
            .catch(onError)
    }

    const onSaveImage = (item) => {
        apis.reports.saveSectionImage({ barcode, ...item }).then(() => {
            success("Image updated sucessfully")
        })
    }

    const onSaveRatio = (item) => {
        apis.reports
            .saveSectionRatio({ barcode, ...item })
            .then((res) => res.json())
            .then((newData) => {
                onUpdate(newData)
                success("Value saved sucessfully")
            })
    }

    const onResetRatio = (item) => {
        apis.reports
            .resetSectionRatio({ barcode, ...item })
            .then((res) => res.json())
            .then((newData) => {
                onUpdate(newData)
                success("Value reset sucessfully!")
            })
    }

    const a4Width = { width: 1000 }

    function filterByTemplates(section, tmpls) {
        if (tmpls.length === 0) return true
        return (
            tmpls
                .reduce((all, cur) => [...all, ...cur.sections], [])
                .indexOf(section.id) !== -1
        )
    }

    const [selectedSections, setSelectedSections] = useState([])
    const [selectedTemplates, setSelectedTemplates] = useState([])

    const currentSections = sections.filter((s) =>
        filterByTemplates(s, selectedTemplates),
    )

    function setSections(selected) {
        setSelectedSections(selected || [])
    }

    function setTemplates(selected) {
        setSelectedTemplates(selected || [])
    }

    function isSectionVisible(section) {
        if (selectedTemplates.length === 0 && selectedSections.length === 0)
            return true
        if (selectedTemplates.length > 0 && selectedSections.length === 0) {
            return currentSections.map((s) => s.id).indexOf(section.id) !== -1
        }
        return (
            currentSections.map((s) => s.id).indexOf(section.id) !== -1 &&
            selectedSections.map((s) => s.value).indexOf(section.id) !== -1
        )
    }

    const [pdfItem, setPdfItem] = useState({
        pdf: report.pdf,
        barcode: report.barcode,
        reportEmails: report.reportEmails,
        status: "Analysed",
    })

    function downloadPDF(barcode, layout, reportMode) {
        const url = apis.geneticAnalyses.url.downloadPDF(
            barcode,
            layout,
            reportMode,
        )
        download(url, barcode, "pdf").catch((e) => showError(e))
    }

    function createPDF(barcode, layout) {
        apis.geneticAnalyses
            .createPDF(barcode, layout)
            .then(() => {
                success("PDF is under construction...")
                setPdfItem((old) => ({
                    ...old,
                    pdf: {
                        ...old.pdf,
                        [layout]: "inprogress",
                    },
                }))
            })
            .catch((e) => {
                error(e.Message)
            })
    }

    function sendEmail(barcode, layout, to, callback) {
        apis.reports
            .sendEmail(barcode, layout, to)
            .then(() => {
                success("Report successfully sent by email!")
            })
            .catch((e) => {
                showError(e)
            })
            .finally(() => {
                if (callback) callback()
            })
    }

    const data = {
        barcode: report.barcode,
        patient: report.fullPatient.patient,
        publishDate: report.publishDate,
    }

    return (
        <div>
            <SectionHeader style={a4Width} title={title} />
            <Buttons
                onDownload={downloadPDF}
                onCreate={createPDF}
                onEmail={sendEmail}
                report={pdfItem}
                layoutIds={report.layoutIds}
                allLayouts={report.layouts}
                token={user.token}
                barcode={barcode}
                rules={report.sendRules}
            />
            <Header style={a4Width} {...data} withLink />
            <DirtyNotifier />

            <Filters
                templates={templates}
                setTemplates={setTemplates}
                selectedTemplates={selectedTemplates}
                sections={currentSections}
                setSections={setSections}
                selectedSections={selectedSections}
            />
            <Sections
                sections={sections}
                isSectionVisible={isSectionVisible}
                style={a4Width}
                language={language}
                onSaveFreetext={onSaveFreetext}
                onSaveImage={onSaveImage}
                onSaveRatio={onSaveRatio}
                onResetRatio={onResetRatio}
                onCancel={onCancel}
                autoSave
                barcode={barcode}
                history={history}
            />

            <Buttons
                report={pdfItem}
                layoutIds={report.layoutIds}
                allLayouts={report.layouts}
                token={user.token}
                barcode={barcode}
                onDownload={downloadPDF}
                onCreate={createPDF}
                onEmail={sendEmail}
                rules={report.sendRules}
            />
        </div>
    )
}

const loadData = ({ barcode }) => {
    return apis.reports.load(barcode)
}

function asArray(error) {
    return error instanceof Array ? error : [error]
}

const validError = (e) =>
    e.Message && e.Message.indexOf("Value is in a bottom state") === -1

export const ReportFetch = ({ match, history }) => {
    const barcode = match.params.id
    const [updatedData, setUpdatedData] = useState(null)
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        barcode,
    })

    const addPatient = (e) => {
        e.preventDefault()
        history.push(routes.geneticAnalyses.edit.buildUrl(barcode))
    }
    const addPatientDetails = (e) => {
        e.preventDefault()
        history.push(routes.patients.edit.buildUrl(barcode))
    }
    const addBacterium = (e, { level, group }) => {
        e.preventDefault()
        history.push(
            routes.bacteria.index.routes.addwithdata.buildUrl(level, group),
        )
    }

    const showError = (e) => {
        const [type, message, detail] = typeOfError(e.Message)
        switch (type) {
            case "UNKNOWN_BACTERIUM":
                return (
                    <div>
                        Unknown bacteria: {message}. To add it, click{" "}
                        <a href="" onClick={(e) => addBacterium(e, detail)}>
                            here
                        </a>
                    </div>
                )
            case "NO_BACTERIUM_IN_ANALYSIS":
                return (
                    <div>
                        Missing bacterium from analysis results ({message}), you
                        should reimport a file with all the required bacteria
                    </div>
                )

            case "MISSING_PATIENT":
                return (
                    <div>
                        Missing patient. To add it, click{" "}
                        <a href="" onClick={(e) => addPatient(e)}>
                            here
                        </a>
                    </div>
                )
            case "MISSING_PATIENTPHYSICALDESCRIPTION":
                return (
                    <div>
                        Missing patient physical description. To add it, click{" "}
                        <a href="" onClick={(e) => addPatientDetails(e)}>
                            here
                        </a>
                    </div>
                )
            default:
                return <div>`Something went wrong: ${e.Message}`</div>
        }
    }

    const Error = ({ errors }) => errors.filter(validError).map(showError)

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return <Error errors={asArray(error)} />
    if (data)
        return (
            <Report
                history={history}
                barcode={barcode}
                report={updatedData || data}
                onUpdate={setUpdatedData}
            />
        )
    return null
}
export default withRouter(ReportFetch)
