import { allDrugs } from "../patients/patient-drugs"
import {
    allDiseasesGroups,
    diseasesForGroup,
} from "../patients/patient-diseases"
import CheckIcon from "@atlaskit/icon/glyph/check"
import WarningIcon from "@atlaskit/icon/glyph/warning"
import React from "react"
import { error } from "../../shared/notifications"

export function geneticAnalysesMap(geneticAnalyses) {
    return geneticAnalyses.reduce((acc, ga) => {
        return { ...acc, [ga.barcode]: ga }
    }, {})
}

export function cleanList(patient, property) {
    return (patient.properties[property] || "").split("|")
}

export function getBarcodes(patient) {
    return cleanList(patient, "Barcode")
}

export function skipError(error) {
    return error.toLowerCase().indexOf("value is in a bottom state") === 0
        ? ""
        : error
}

export function matching(condition, typeElement = "text") {
    return typeElement === "input"
        ? {
              border: "1px solid " + (condition ? "green" : "red"),
              margin: "8px",
          }
        : {
              color: condition ? "green" : "red",
          }
}

export function drawMatchingIcon(condition) {
    return condition ? (
        <CheckIcon size="small" primaryColor="green" />
    ) : (
        <WarningIcon size="small" primaryColor="red" />
    )
}

export function existNameSurname(props) {
    return existsProp(props, "Nome") && existsProp(props, "Cognome")
}

export function existsEmail(props) {
    return existsProp(props, "Email")
}
export function existsProp(properties, key) {
    return !!properties[key]
}
export function notExistsProp(properties, key) {
    return !existsProp(properties, key)
}

function trimIfString(prop) {
    if (typeof prop === "string") {
        return prop.trim()
    }
    return prop
}
export function trimProperties(properties) {
    return Object.keys(properties).reduce((result, key) => {
        result[key] = trimIfString(properties[key])
        return result
    }, {})
}

export function mergeBarcodes(barcodes, updated, removed) {
    return Object.keys(updated)
        .reduce((res, index) => {
            return res.map((b, i) => (i !== Number(index) ? b : updated[index]))
        }, barcodes)
        .filter((_, index) => removed.indexOf(index) === -1)
}

export function cleanTemplate(template) {
    return template.trim().toUpperCase()
}

export function getSex(sex) {
    switch (sex.toLowerCase()) {
        case "male":
        case "maschio":
            return "male"
        case "female":
        case "femmina":
            return "female"
        default:
            return "unspecified"
    }
}

export function getMenopause(sex, menopause) {
    if (sex.toLowerCase() === "femmina" || sex.toLowerCase() === "female") {
        if (
            menopause.toLowerCase().indexOf("premenopausa") !== -1 ||
            menopause.toLowerCase().indexOf("pre-menopause") !== -1
        )
            return "pre"
        else if (
            menopause.toLowerCase().indexOf("menopausa") !== -1 ||
            menopause.toLowerCase().indexOf("menopause") !== -1
        )
            return "post"
        return "pre"
    }
    return "notapplicable"
}

export function getEthnicity(ethnicity) {
    switch (ethnicity.toLowerCase()) {
        case "caucasian":
        case "caucasica":
            return "caucasian"
        case "african":
        case "africana":
            return "african"
        case "amerindian":
        case "amerinda":
            return "amerindian"
        case "asian":
        case "asiatica":
            return "asian"
        default:
            return "unspecified"
    }
}

export function getInteger(value) {
    const result = Math.round(Number(value.replace(",", ".")))
    if (isNaN(result) || !isFinite(result)) return null
    return result
}

export function getHeight(height) {
    const result = Number(height.replace(",", "."))
    if (isNaN(result) || !isFinite(result)) return null
    if (result < 3) {
        return Math.round(result * 100.0)
    }
    return Math.round(result)
}

export function getOtherDrugs(properties) {
    return (
        properties["AltriFarmaciInUso"] ? [properties["AltriFarmaciInUso"]] : []
    )
        .concat(
            properties["AltriFarmaciNonTollerati"]
                ? [properties["AltriFarmaciNonTollerati"]]
                : [],
        )
        .join(", ")
}

export const drugsMatches = {
    it: {
        birthControlPill: "pillola anticoncezionale",
        protonPumpInhibitors: "Inibitori di pompa protonica o antigastrici",
        antidepressant: "Antidepressivi o SSRI",
        antihypertensive: "Antipertensivi",
        statins: "Statine",
        fANS: "FANS",
        omega3: "Omega 3",
        probiotics: "Probiotici",
        laxative: "Lassativi",
    },
    eng: {
        birthControlPill: "Birth control",
        protonPumpInhibitors: "Proton pump inhibitors",
        antidepressant: "Antidepressants",
        antihypertensive: "Antihypertensive",
        statins: "Statins",
        fANS: "FANS",
        omega3: "Omega 3",
        probiotics: "Probiotics",
        laxative: "Laxatives",
    },
}

export const diseasesGroupMatches = {
    respiratory: "SintomiRespiratori",
    hematologic: "SintomiEmatologici",
    bonesAndJoints: "SintomiOssa",
    cardiovascular: "SintomiCardioVascolari",
    food: "SintomiAlimentari",
    kidneyUrogenital: "SintomiUroGenitali",
    endocrine: "SintomiEndocrini",
    dermatological: "SintomiDermatologici",
    metabolism: "SintomiMetabolismo",
    neurologic: "SintomiNeurologici",
    female: "SintomiFemminili",
    appetite: "SintomiAppetito",
}

export const diseasesMatches = {
    it: {
        allergy: "Allergia (specificare nella sezione apposita a cosa)",
        asthma: "Asma",
        chronicBronchitis: "Bronchite cronica",

        anemia: "Anemia",
        macrocytosis: "Macrocitosi",

        rheumatoidArthritis: "Artrite reumatoide",
        arthrosis: "Artrosi",
        jointStiffness: "Rigidità articolare",
        scoliosis: "Scoliosi",
        osteoporosis: "Osteoporosi",
        discopatia: "Discopatia",
        fibromyalgia: "Fibromialgia",

        atherosclerosis: "Arteriosclerosi",
        tachycardia: "Tachicardia",
        bradycardia: "Brachicardia",
        thrombosis: "Trombosi",
        arterialHypotension: "Ipotensione arteriosa (pressione bassa)",
        hypertension: "Ipertensione arteriosa (pressione alta)",
        highCholesterol:
            "Colesterolo alto (indicare i valori di colesterolo HDL, LDL, glicemia e trigliceridi nelle note)",
        heartFailure: "Insufficienza cardiaca",
        highHomocysteine: "Omocisteina alta",

        colitis: "Colite",
        slowDigestion: "Digestione lenta o gonfiore addominale",
        liverDysfunction:
            "Disfunzioni epatiche: es. epatite, cirrosi, steatosi epatica (fegato grasso) specificare nelle note",
        ulcer: "Ulcera gastrica",
        constipation: "Stipsi",
        liverCalculations: "Calcoli al fegato",
        lactoseIntolerance: "Intolleranza al lattosio",
        celiacDisease: "Celiachia",
        diverticulosis: "Diverticolosi",
        gastritis: "Gastrite o reflusso gastro esofageo",
        crohnsDisease: "Morbo di Crohn o Colite Ulcerativa",
        diarrhea: "Diarrea",

        kidneyStones: "Calcoli renali",
        gout: "Gotta",
        kidneyFailure: "insufficienza renale",
        chronicCystitis: "Cistite cronica",
        recurrentCystitis: "Cistiti ricorrenti",
        poorDiuresis: "Diuresi scarsa",
        candidiasis: "Candidosi",
        urethritisProstatitis: "Uretriti o prostatiti",
        bacterialVaginosis: "Vaginosi batterica",

        typeIDiabetes: "Diabete tipo 1 (genetico)",
        typeIIDiabetes: "Diabete tipo 2 (alimentare)",
        hypercholesterolemia: "Ipercolesterolemia (colesterolo alto)",
        hypocholesterolemia: "Ipocolesterolemia (colesterolo basso)",
        hyperthyroidism: "Ipertiroidismo",
        hypothyroidism: "Ipotiroidismo",
        decreasedLibido: "Diminuzione libido",
        hyperparathyroidism: "Iperparatiroidismo",
        hypoglycemia: "Ipoglicemia",
        obesity: "Obesità",
        thinness: "Magrezza",
        ipersurreanalismo: "Ipersurrenalismo",
        hypoadrenalism: "Iposurrenalismo",

        eczema: "Eczema",
        acne: "Acne",
        dermatitis: "Dermatiti",
        urticaria: "Orticaria",
        psoriasis: "Psoriasi",
        scleroderma: "Sclerodermia",
        disidrosi: "Disidrosi",

        hyperglycemia: "Iperglicemia",
        insulinResistance: "Insulino resistenza",
        metabolicSyndrome: "Sindrome metabolica",

        asthenia: "Astenia o stanchezza cronica",
        anxiety: "Ansia",
        depression: "Depressione",
        stress: "Stress",
        panicAttacks: "Attacchi di panico",
        sleepingDisorders: "Disturbi legati al sonno",
        migraine: "Emicrania",

        mammaryCysts: "Cisti mammaria",
        menstrualIrregularity: "Irregolarità mestruale",
        oviarianCysts: "Cisti ovariche",
        premenopause: "Premenopausa",
        menopause: "Menopausa",
        polycysticOvarySyndrome: "Sindrome da ovaio policistico (PCOS)",

        lackOfAppetite: "Inappetenza",
        insatiable: "Insaziabile",
        regular: "Regolare",
    },
    eng: {
        allergy: "Allergies (specify in the notes what kind of allergy)",
        asthma: "Asma",
        chronicBronchitis: "Chronic bronchitis",

        anemia: "Anemia",
        macrocytosis: "Macrocytosis",

        rheumatoidArthritis: "Rheumatoid arthritis",
        arthrosis: "Arthrosis",
        jointStiffness: "Joint stiffness",
        scoliosis: "Scoliosis",
        osteoporosis: "Osteoporosis",
        discopatia: "Discopathy",
        fibromyalgia: "Fibromyalgia",

        atherosclerosis: "Atherosclerosis",
        tachycardia: "Tachycardia",
        bradycardia: "Bradycardia",
        thrombosis: "Thrombosis",
        arterialHypotension: "Arterial hypotension (low blood pressure)",
        hypertension: "Arterial hypertension (high blood pressure)",
        highCholesterol:
            "High cholesterol (register the values of HDL cholesterol, LDL, blood sugar and triglycerides in the notes)",
        heartFailure: "Hearth failure",
        highHomocysteine: "High omocystein",

        colitis: "Colitis",
        slowDigestion: "Slow digestion or bloating",
        liverDysfunction:
            "Liver dysfunction: e.g. hepatitis, cirrhosis - specify in the notes",
        ulcer: "Gastric ulcer",
        constipation: "Constipation",
        liverCalculations: "Liver stones",
        lactoseIntolerance: "Lactose intollerance",
        celiacDisease: "Celiac disease",
        diverticulosis: "Diverticulosis",
        gastritis: "Gastritis or gastroesophageal reflux",
        crohnsDisease: "Crohn’s disease or ulcerative colitis",
        diarrhea: "Diarrhea",

        kidneyStones: "Kidney stones",
        gout: "Gout",
        kidneyFailure: "Kidney failure",
        chronicCystitis: "Chronic cystitis",
        recurrentCystitis: "Recurrent cystitis",
        poorDiuresis: "Poor diuresis",
        candidiasis: "Candidiasis",
        urethritisProstatitis: "Urethritis or prostatitis",
        bacterialVaginosis: "Bacterial vaginosis",

        typeIDiabetes: "Type 1 diabetes",
        typeIIDiabetes: "Type 2 diabetes",
        hypercholesterolemia: "Hypercholesterolemia",
        hypocholesterolemia: "Hypocholesterolemia",
        hyperthyroidism: "Hyperthyroidism",
        hypothyroidism: "Hypothyroidism",
        hyperparathyroidism: "Iperparatiroidismo",
        hypoglycemia: "Hypoglycemia",
        obesity: "Obesity",
        thinness: "Thinness",
        ipersurreanalismo: "Hypersurrenalism",
        hypoadrenalism: "Hyposurrenalism",
        decreasedLibido: "Decreased Libido",

        eczema: "Eczema",
        acne: "Acne",
        dermatitis: "Dermatitis",
        urticaria: "Urticaria",
        psoriasis: "Psoriasis",
        scleroderma: "Sclerodermia",
        disidrosi: "Dyshidrosis",

        hyperglycemia: "Hyperglycemia",
        insulinResistance: "Insulin resistance",
        metabolicSyndrome: "Metabolic syndrome",

        asthenia: "Asthenia",
        anxiety: "Anxiety",
        depression: "Depression",
        stress: "Stress",
        panicAttacks: "Panic attaks",
        sleepingDisorders: "Sleeping disorders",
        migraine: "Migraine",

        mammaryCysts: "Mammary cysts",
        menstrualIrregularity: "Menstrual irregularity",
        oviarianCysts: "Ovarian cysts",
        premenopause: "Pre-menopause",
        menopause: "Menopause",
        polycysticOvarySyndrome: "Polycystic ovary syndrome (PCOS)",

        lackOfAppetite: "Lack of appetite",
        insatiable: "Insatiable",
        regular: "Regular",
    },
}

export function getDrug(drug, language, props) {
    const name = drugsMatches[language][drug]
    return (name && (props || "").indexOf(name) !== -1) || false
}

export function getDrugs(properties, language) {
    return allDrugs().map((name) => ({
        name,
        data: {
            inUse: getDrug(name, language, properties["FarmaciInUso"]),
            notTolerated: getDrug(
                name,
                language,
                properties["FarmaciNonTollerati"],
            ),
        },
    }))
}

export function getSmokerType(smoker) {
    switch (smoker.toLowerCase()) {
        case "yes":
        case "si":
            return "yes"
        case "no":
            return "no"
        default:
            return "noMore"
    }
}

export function getExerciseType(exercise) {
    return (
        {
            "No, sedentario": "low",
            "Si, ogni tanto (1 volta a settimana o meno)": "low",
            "Si, con costanza (2 volte a settimana)": "average",
            "Si, con costanza (3-4 volte a settimana)": "average",
            "Si, con costanza (4-6 volte a settimana)": "average",
            "Si, pratico sport a livello amatoriale (5-7 volte a settimana)":
                "high",
            "Si, pratico sport a livello agonistico (5-7 volte a settimana)":
                "high",
            No: "low",
            "Yes, sometimes (once a week)": "low",
            "Yes, regularly (2-3 times a week)": "average",
            "Yes, regularly (3-4 times a week)": "average",
            "Yes, regularly (4-6 times a week)": "average",
            "Yes, I am an athlete (5-7 times a week)": "high",
        }[exercise] || "unspecified"
    )
}

export function getDiseases(properties, language) {
    return allDiseasesGroups().map((group) => ({
        name: group,
        items: diseasesForGroup(group).map((disease) => ({
            name: disease,
            data: {
                symtoms: getDisease(
                    disease,
                    language,
                    properties[diseasesGroupMatches[group]],
                ),
                familiarity: false,
            },
        })),
    }))
}

export function getDisease(disease, language, props) {
    const name = diseasesMatches[language][disease]
    return (name && (props || "").indexOf(name) !== -1) || false
}

export function hasGuardian(prop) {
    return prop === "Si" || prop === "Yes" ? "yes" : "no"
}

export const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error during approval",
                StackTrace: "",
            }
        }
    }
    let msg
    if (ex && ex.Message) {
        msg = skipError(ex.Message)
        console.error(ex.StackTrace)
    } else if (ex && ex.length) {
        msg = ex
            .map((iex) => {
                if (iex && iex.Message) {
                    return skipError(iex.Message)
                }
                return ""
            })
            .filter((m) => m)
            .join(", ")
    }
    if (msg) {
        error(msg, {
            dismiss: false,
        })
    }
}
