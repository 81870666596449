import { store } from "react-notifications-component"

const defaultCfg = {
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
        duration: 3000,
    },
}

export const success = (message, cfg = {}) => {
    store.addNotification({
        title: "Success",
        message,
        type: "success",
        ...defaultCfg,
        ...cfg,
    })
}

export const warning = (message, cfg = {}) => {
    store.addNotification({
        title: "Info",
        message,
        type: "warning",
        ...defaultCfg,
        ...cfg,
    })
}

export const error = (message, cfg = {}) => {
    store.addNotification({
        title: "Error!",
        message,
        type: "danger",
        ...defaultCfg,
        ...cfg,
    })
}
