import React from "react"
import { byIndex } from "../../shared/sections"
import Section from "./section"

const Sections = ({ sections, isSectionVisible, ...sectionProps }) => {
    return (
        <>
            {sections.sort(byIndex).map((section, index) => {
                return (
                    <div
                        key={index}
                        className="show-page-section-container"
                        style={{
                            display: isSectionVisible(section)
                                ? "block"
                                : "none",
                        }}>
                        <Section {...sectionProps} key={index} item={section} />
                    </div>
                )
            })}
        </>
    )
}

export default Sections
