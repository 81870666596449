import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber) {
            return apis.layouts
                .loadAll(pageNumber)
                .then((layouts) => dispatch(actions.load(layouts)))
        },
        loadFiltered(filter, pageNumber, pageSize) {
            apis.layouts
                .search(filter.analysisType || "", pageNumber, pageSize)
                .then((layouts) =>
                    dispatch(actions.loadFiltered(layouts, filter)),
                )
        },
        load(id) {
            return apis.layouts.load(id)
        },
        loadReferrals() {
            return apis.referrals.loadAll(1, 10000)
        },
        update(value) {
            const { id, ...body } = value
            return apis.layouts.updateOne(id, body)
        },
        setDefault(id) {
            return apis.layouts.setDefault(id)
        },
    }
}
