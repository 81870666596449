import React from "react"
import clamp from "lodash/clamp"
import { range } from "ramda"
import { getTicksSpan } from "./ticks"

function getDimensions(ranks) {
    return {
        min: Number(ranks[0].low),
        max: Number(ranks[ranks.length - 1].high),
    }
}

function calcWidth(min, max, rank) {
    return ((rank.high - rank.low) / (max - min)) * 100.0 + "%"
}

function calcPos(min, max, value) {
    return clamp((value / (max - min)) * 100.0, 0, 100) + "%"
}

const spans = [0.5, 1, 2, 3, 4, 5, 8, 10, 20, 23, 30, 40, 50]

function getTicks(min, max) {
    const span = getTicksSpan(min, max, 0.2, spans)
    return range(0, (max - min) / span + 1).map((idx) => min + idx * span)
}

const RankBar = ({ ranks, value }) => {
    const { min, max } = getDimensions(ranks)
    const ticks = getTicks(min, max)
    const positionFactor = 100 / (max - min)
    return (
        <div className="rankbar-container">
            <div className="rankbar-bar-container">
                {ranks.map((r) => (
                    <div
                        key={r.rank}
                        className={`rank rank-${r.rank}`}
                        style={{ width: calcWidth(min, max, r) }}></div>
                ))}
            </div>

            <div
                className="rankbar-index-container"
                style={{ left: calcPos(min, max, value) }}>
                <svg viewBox="0 0 491.52 491.52" preserveAspectRatio="">
                    <g>
                        <path
                            d="M245.761,0C156.033,0,83.293,72.74,83.293,162.468S245.761,491.52,245.761,491.52s162.467-239.323,162.467-329.052
			C408.228,72.74,335.49,0,245.761,0z M245.761,264.518c-56.36,0-102.049-45.689-102.049-102.049
			c0-56.359,45.688-102.048,102.049-102.048c56.359,0,102.048,45.689,102.048,102.048S302.12,264.518,245.761,264.518z"
                        />
                    </g>
                </svg>
            </div>

            <div className="rankbar-ticks-container">
                {ticks.map((tick) => (
                    <span key={tick}>
                        <span
                            className="rankbar-tick"
                            style={{
                                left: `${tick * positionFactor}%`,
                            }}></span>
                        <span
                            className="rankbar-tick-label"
                            style={{ left: `${tick * positionFactor}%` }}>
                            {tick}
                        </span>
                    </span>
                ))}
            </div>
        </div>
    )
}

export default RankBar
