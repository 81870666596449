import React from "react"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { referralSchema as schema } from "./schemas"
import ReferralForm from "./form"
import { head } from "ramda"

import withFetch from "../shared/components/fetch"

export const Edit = ({ data, history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const update = (referral) => {
        api.update(referral).then(() => {
            history.push(routes.referrals.index.url)
        })
    }

    const [referral, templates] = data[0]

    return (
        <ReferralForm
            isEdit={true}
            initialValues={referral}
            schema={schema}
            onSubmit={update}
            onCancel={() => history.goBack()}
            formProps={{ title: "Edit Referral", primaryText: "Save" }}
            templates={templates?.items || []}
        />
    )
}

const loadData = ({ api, id }) => {
    return api.load(id)
}

const loadTemplates = ({ api }) => {
    return api.loadTemplates()
}

const fromState = (state, id) => {
    return head(state.referrals.filter((item) => item.code === id))
}

export default withFetch(Edit, mkApi(), loadData, useStateContext, fromState, [
    loadTemplates,
])
