import React from "react"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"

const translations = {
    male: {
        it: "Sig. ",
        eng: "Mr. ",
    },
    female: {
        it: "Sig.ra ",
        eng: "Mrs. ",
    },
    anonymous: {
        it: "Anonimo",
        eng: "Anonymous",
    },
}

const Header = ({
    barcode,
    patient,
    publishDate,
    style,
    withLink = false,
    language = "it",
}) => {
    const { firstName, lastName, gender, bornOn, anonymous } = patient
    const date = publishDate ? dayjs(publishDate).format("DD/MM/YYYY") : ""

    const title = () => {
        if (gender === "male") return translations["male"][language]
        else if (gender === "female") return translations["female"][language]
        else return ""
    }

    const nameCss = {
        fontWeight: "bold",
        fontSize: "24px",
    }
    const isAnonymous =
        anonymous ||
        (lastName.toLowerCase().trim() === "anonimo" &&
            firstName.toLowerCase().trim() === "anonimo")

    const fullName = isAnonymous
        ? ""
        : `${title()}${lastName} ${firstName} - ${bornOn}`

    const fullNameForLink = isAnonymous
        ? translations["anonymous"][language]
        : `${title()}${lastName} ${firstName} - ${bornOn}`

    return (
        <>
            <div className="row" style={style}>
                <div className="col" style={nameCss}>
                    {withLink ? (
                        <Link
                            to={{
                                pathname:
                                    routes.patients.edit.buildUrl(barcode),
                            }}
                            target="_blank">
                            {fullNameForLink}
                        </Link>
                    ) : (
                        fullName
                    )}
                </div>
            </div>
            <div className="row" style={style}>
                <div className="col">{`${barcode} - ${date}`}</div>
            </div>
        </>
    )
}

export default Header
