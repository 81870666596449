import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"

export default function FileUpload({ onFileChange, className = "dropzone" }) {
    const onDrop = useCallback(onFileChange, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })
    return (
        <div className={className} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                <p>
                    Drag &apos;n&apos; drop some files here, or click to select
                    files
                </p>
            )}
        </div>
    )
}
