import React, { useEffect, useState } from "react"
import { mkApi } from "./api"
import Form from "../shared/components/form"
import useFormist from "react-formist"
import { success } from "../../shared/notifications"
import { useStateContext } from "../../shared/state-context"
import Tabs from "@atlaskit/tabs"
import withFetch from "../shared/components/fetch"
import { fullSchema } from "./schemas"
import PatientForm, {
    detailsToModel,
    modelToDetails,
} from "../patients/patient"
import SubForm from "../shared/components/subform"
import { EditMultiBarcode } from "./edit-multi-barcode"

export const Edit = ({ data, history, match }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const [patientData, analyses] = data[0]

    const initialBarcode =
        analyses && analyses.items && analyses.items.length > 0
            ? analyses.items[0].barcode
            : null

    const [barcode, setBarcode] = useState(initialBarcode)

    const update = (p) => {
        const patient = modelToDetails(p, {
            common: barcode,
            guardian: patientData.patientGuardian.barcode,
            diseases: patientData.patientDiseases.barcode,
            drugs: patientData.patientDrugs.barcode,
        })
        api.updateFull(patient).then(() => {
            success("Patient saved!")
        })
    }

    const formist = useFormist(detailsToModel(patientData), {
        schema: fullSchema,
        onSubmit: update,
    })

    useEffect(() => {
        if (barcode) {
            api.loadFull(match.params.id, barcode).then((response) => {
                const patient = detailsToModel(response)
                formist.change("patientPhysicalDescription", {
                    ...patient.patientPhysicalDescription,
                    barcode,
                })
                formist.change("patientGuardian", {
                    ...patient.patientGuardian,
                    barcode,
                })
                formist.change("patientDiseases", {
                    ...patient.patientDiseases,
                })
                formist.change("patientDrugs", {
                    ...patient.patientDrugs,
                })
            })
        }
    }, [barcode])

    const [selectedTab, selectTab] = useState(0)

    const tabs = [
        {
            label: "Personal Data",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patient">
                        {({ getFieldProps }) => (
                            <PatientForm
                                anonymous={
                                    formist.getFieldProps("patient.anonymous")
                                        .value
                                }
                                formist={{ getFieldProps }}
                                onUnblock={() =>
                                    formist.change("patient.anonymous", false)
                                }
                                onAgeChange={(age) =>
                                    formist.change(
                                        "patientPhysicalDescription.age",
                                        age,
                                    )
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: analyses.items.length ? "Barcodes" : "Other Data",
            content: (
                <EditMultiBarcode
                    barcode={barcode}
                    patientData={patientData}
                    formist={formist}
                    analyses={analyses}
                    setBarcode={setBarcode}
                />
            ),
        },
    ]

    const patientName = patientData.patient.anonymous
        ? "Anonymous"
        : `${patientData.patient.firstName} ${patientData.patient.lastName}`

    return (
        <>
            <Form
                title={`Edit Patient - ${patientName}`}
                size="full"
                primaryText="Save"
                {...formist.getFormProps()}
                onCancel={() => history.goBack()}>
                <Tabs
                    tabs={tabs}
                    onSelect={(tab, idx) => selectTab(idx)}
                    selected={selectedTab}
                />
            </Form>
        </>
    )
}

const loadData = ({ api, id }) => {
    return api.loadFull(id)
}

const loadGeneticAnalyses = ({ api, id }) => {
    return api.loadGeneticAnalyses(id)
}

const loadReferrals = ({ api }) => {
    return api.loadReferrals()
}

const fromState = () => {}

export default withFetch(Edit, mkApi(), loadData, useStateContext, fromState, [
    loadGeneticAnalyses,
    loadReferrals,
])
