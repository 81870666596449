import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TextArea from "../shared/components/textarea"
import TextField from "../shared/components/textfield"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    imageType: yup.string(),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    images: yup.array().min(0).of(yup.string().required()),
    formula: yup.string(),
})

const SectionImageEdit = ({ section, onSave, onCancel }) => {
    const formist = useFormist(section, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) onSave(formist.values)
            })
            .catch(() => {})
    }

    const images = formist.getFieldProps("images")
    const onChangeImages = (e) => {
        images.onChange({
            target: {
                value: e.target.value.split("\n"),
            },
        })
    }
    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <TextField
                    label="Image Type"
                    width="col"
                    {...formist.getFieldProps("imageType")}
                />
            </div>
            <div className="form-row">
                <TextArea
                    label="Images"
                    width="col"
                    rows={10}
                    value={images.value.join("\n")}
                    onChange={onChangeImages}
                />
                <TextArea
                    label="Formula"
                    width="col"
                    {...formist.getFieldProps("formula")}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionImageEdit
