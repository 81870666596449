import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    pattern: "",
    patients: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-PATIENTS", handleLoadPatients)
        .case("SEARCH-PATIENTS", handleSearchPatients)
        .case("EDIT-PATIENT", handleEditPatient)
        .case("ADD-PATIENT", handleAddPatient)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-PATIENTS", data }
    },
    update(data) {
        return { type: "EDIT-PATIENT", data }
    },
    add(id, data) {
        return { type: "ADD-PATIENT", id, data }
    },
    search(data) {
        return { type: "SEARCH-PATIENTS", data }
    },
}

const handleLoadPatients = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        patients: items,
        pattern: "",
        ...pagination,
    }
}

const handleSearchPatients = (state, action) => {
    const { items, pagination, pattern } = action.data
    return {
        isLoading: false,
        patients: items,
        pattern,
        ...pagination,
    }
}

const handleEditPatient = (state, action) => {
    const { patients } = state
    const { patient } = action.data
    const newPatients = patients.map((p) => (p.id === patient.id ? patient : p))
    return {
        patients: newPatients,
    }
}

const handleAddPatient = (state, action) => {
    const { patients } = state
    const { patient } = action.data
    const newPatient = { ...patient, id: action.id }
    return {
        patients: [...patients, newPatient],
    }
}
