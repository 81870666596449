import {
    filter,
    not,
    isEmpty,
    clone,
    pipe,
    propEq,
    findIndex,
    find,
} from "ramda"
import uuidv4 from "uuid/v4"

const setById = (action, id) => (xs) => {
    const cc = clone(xs)
    const update = pipe(findIndex(propEq("id", id)), (ix) => {
        cc[ix] = action(cc[ix])
        return cc
    })
    return update(cc)
}

const removeById = (id) => filter(pipe(propEq("id", id), not))

export const Sections = {
    clone(id) {
        return (xs) => {
            if (isEmpty(xs)) return xs
            const cc = clone(xs)

            const me = find(propEq("id", id))(cc)
            const newSection = {
                ...me,
                id: uuidv4(),
                name: me.name + " Copy",
                isEditMode: true,
                isNew: true,
                index:
                    cc.reduce((previous, current) => {
                        return Math.max(previous, current.index)
                    }, 0) + 1,
            }
            return [...cc, newSection]
        }
    },

    moveUp(id) {
        return (xs) => {
            if (isEmpty(xs)) return xs
            const cc = clone(xs)

            const me = find(propEq("id", id))(cc)
            const index = me.index
            if (index == 0) return xs

            const before = find(propEq("index", me.index - 1))(cc)
            const beforeIndex = before.index

            me.index = beforeIndex
            before.index = index

            return cc
        }
    },

    moveDown(id) {
        return (xs) => {
            if (isEmpty(xs)) return xs
            const cc = clone(xs)

            const me = find(propEq("id", id))(cc)
            const index = me.index
            if (index === xs.length - 1) return xs

            const after = find(propEq("index", me.index + 1))(cc)
            const afterIndex = after.index

            me.index = afterIndex
            after.index = index

            return cc
        }
    },
    remove(id) {
        return (xs) => {
            if (isEmpty(xs)) return xs
            return removeById(id)(xs)
        }
    },
    edit(id) {
        return (xs) => setById((s) => ({ ...s, isEditMode: true }), id)(xs)
    },
    cancel(id) {
        return (xs) => {
            const me = find(propEq("id", id))(xs)
            if (me.isNew) {
                return removeById(id)(xs)
            }
            return setById((s) => ({ ...s, isEditMode: false }), id)(xs)
        }
    },
    save(id, edited) {
        return (xs) =>
            setById(
                (s) => ({ ...s, ...edited, isEditMode: false, isNew: false }),
                id,
            )(xs)
    },
    // append(item) {
    //     return xs => {
    //         const list = [...xs]
    //         list.push(item)
    //         return list
    //     }
    // },
}
