export default {
    key: "orderedListType",
    types: {
        DECIMAL: "decimal",
        LOWERALPHA: "lower-alpha",
        UPPERALPHA: "upper-alpha",
        UPPERROMAN: "upper-roman",
    },
    defaultType: "DECIMAL",
    options: [
        { label: "1", style: "DECIMAL" },
        { label: "a", style: "LOWERALPHA" },
        { label: "A", style: "UPPERALPHA" },
        { label: "I", style: "UPPERROMAN" },
    ],
}
