import React from "react"
import { withRouter } from "react-router"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { addSchema as schema, addEmpty as empty } from "./schemas"
import { addMappers as M } from "./mappers"
import FormFields from "./form-fields"

const AddGene = ({ history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const add = (gene) => {
        api.update(M.toApiModel(gene), true).then(() => {
            history.push(routes.genes.index.url)
        })
    }

    return (
        <FormFields
            initialValues={empty}
            schema={schema}
            onSubmit={add}
            onCancel={() => history.goBack()}
            formProps={{ title: "New Gene", primaryText: "Add" }}
        />
    )
}

export default withRouter(AddGene)
