import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { range } from "ramda"
import { withRouter } from "react-router"
import SectionHeader from "../shared/components/section-header"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import PaginatedTable from "../shared/components/paginated-table"
import ButtonBar from "../shared/components/button-bar"
import Filter from "./filter"
import { error } from "../../shared/notifications"
import DropdownMenu, {
    DropdownItemGroup,
    DropdownItem,
} from "@atlaskit/dropdown-menu"

const head = {
    cells: [
        {
            key: "name",
            content: "Name",
        },
        {
            key: "analysisType",
            content: "Analysis Type",
        },
        {
            key: "actions",
        },
    ],
}

const mkEditUrl = (item) => {
    const { id } = item
    return routes.templates.index.routes.edit.buildUrl(id)
}

const TemplateList = (props) => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { filter, items, isLoading, pageNumber, totalPages } = state
    const { history } = props
    const pages = range(1, totalPages + 1)

    useEffect(() => {
        if (!items || items.length === 0) {
            api.loadFiltered(filter, pageNumber)
        }
    }, [])

    const onChangePage = (e, pageNumber) => {
        api.loadFiltered(filter, pageNumber)
    }

    const addClickHandler = (e) => {
        e.preventDefault()
        history.push(routes.templates.index.routes.add.url, state)
    }

    const cloneHandler = (item) => (e) => {
        e.preventDefault()
        api.clone(item.id, "Copy of " + item.name).then(() =>
            api.loadFiltered(filter, pageNumber),
        )
    }

    const setDefaultHandler = (item) => (e) => {
        e.preventDefault()
        api.setDefault(item.id).then(() => api.loadFiltered(filter, pageNumber))
    }

    const removeHandler = (item) => (e) => {
        e.preventDefault()
        api.remove(item.id)
            .then(() => api.loadFiltered(filter, pageNumber))
            .catch((e) => {
                error(JSON.parse(e).Message)
            })
    }

    const cloneMenuItem = (item, handler) => {
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Clone
                </Link>
            </DropdownItem>
        )
    }

    const setDefaultMenuItem = (item, handler) => {
        return item.defaultTemplate ? null : (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Set as Default
                </Link>
            </DropdownItem>
        )
    }

    const removeMenuItem = (item, handler) => {
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Remove
                </Link>
            </DropdownItem>
        )
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: mkEditUrl(item),
                                }}>
                                {item.name}{" "}
                                {item.name !== item.title
                                    ? "(" + item.title + ")"
                                    : ""}
                            </Link>
                        ),
                    },
                    {
                        content: (
                            <span
                                style={{
                                    fontWeight: item.defaultTemplate
                                        ? "bold"
                                        : "",
                                }}>
                                {item.analysisType}
                            </span>
                        ),
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {setDefaultMenuItem(
                                        item,
                                        setDefaultHandler,
                                    )}
                                    {cloneMenuItem(item, cloneHandler)}
                                    {removeMenuItem(item, removeHandler)}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    function onFilter(newFilter) {
        api.loadFiltered(newFilter, 0)
    }

    return (
        <>
            <SectionHeader title="Template list" />
            <div className="row">
                <div className="col-4">
                    <Filter width="col" filter={filter} onFilter={onFilter} />
                </div>
                {/*
                    NOTE: aggiunto form-group perché da margin bottom
                          Per allineare e centrare il button è una classe necessaria
                          dato che anche InlineSearch la usa.
                    TODO: Estrarre il <div className="form-group"> interno a InlineSearch
                          così da utilizzarlo qui nel div parent di InlineSearch e Button
                */}
                <div className="form-group col-4 btn-alongside-center">
                    <Button appearance="primary" onClick={addClickHandler}>
                        Add Template
                    </Button>
                </div>
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(items)}
                isLoading={isLoading}
                pages={pages}
                onChangePage={onChangePage}
                pageNumber={pageNumber}
            />
            <ButtonBar>
                <Button appearance="primary" onClick={addClickHandler}>
                    Add Template
                </Button>
            </ButtonBar>
        </>
    )
}

export default withRouter(TemplateList)
