import React, { useState } from "react"
import DropdownMenu from "@atlaskit/dropdown-menu"
import Button from "@atlaskit/button"

export function getEmailStatus(emails, layout) {
    if (emails[layout]) {
        const email = emails[layout]
        return {
            IsSentToPatient: !!email.reportEmailForPatient,
            IsSentToReferral: !!email.reportEmailForReferral,
        }
    }
    return {
        IsSentToPatient: false,
        IsSentToReferral: false,
    }
}

function EmailItem({
    status,
    barcode,
    layout,
    onSend,
    rules = { patient: false, referral: false },
    attachmentRules = { patient: "Complete", referral: "Complete" },
}) {
    const [sendToPatient, setSendToPatient] = useState(rules.patient)
    const [sendToReferral, setSendToReferral] = useState(rules.referral)
    const [patientAttachmentRule, setPatientAttachmentRule] = useState(
        attachmentRules.patient,
    )
    const [referralAttachmentRule, setReferralAttachmentRule] = useState(
        attachmentRules.referral,
    )

    const [sending, setSending] = useState(false)

    const canSend = sendToPatient || sendToReferral

    function send() {
        const receiver =
            sendToPatient && sendToReferral
                ? "all"
                : sendToPatient
                ? "patient"
                : "referral"
        setSending(true)
        setSendToPatient(false)
        setSendToReferral(false)
        onSend(
            barcode,
            layout,
            receiver,
            patientAttachmentRule,
            referralAttachmentRule,
            () => setSending(false),
        )
    }

    return sending ? (
        <span>Sending...</span>
    ) : (
        <div className="m-2">
            <div
                className="m-2"
                style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="checkbox"
                            checked={sendToPatient}
                            onClick={() =>
                                setSendToPatient((current) => !current)
                            }
                        />
                        <span style={{ marginLeft: 5 }}>Patient</span>
                        <div
                            className={
                                status.IsSentToPatient ? "icon-sent" : "no-icon"
                            }
                        />
                    </div>
                    <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={patientAttachmentRule === "Complete"}
                                onChange={() =>
                                    setPatientAttachmentRule("Complete")
                                }
                            />
                            <label className="form-check-label">complete</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={patientAttachmentRule === "Summary"}
                                onChange={() =>
                                    setPatientAttachmentRule("Summary")
                                }
                            />
                            <label className="form-check-label">summary</label>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="checkbox"
                            checked={sendToReferral}
                            onClick={() =>
                                setSendToReferral((current) => !current)
                            }
                        />
                        <span style={{ marginLeft: 5 }}>Referral</span>
                        <div
                            className={
                                status.IsSentToReferral
                                    ? "icon-sent"
                                    : "no-icon"
                            }
                        />
                    </div>
                    <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={referralAttachmentRule === "Complete"}
                                onChange={() =>
                                    setReferralAttachmentRule("Complete")
                                }
                            />
                            <label className="form-check-label">complete</label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={referralAttachmentRule === "Summary"}
                                onChange={() =>
                                    setReferralAttachmentRule("Summary")
                                }
                            />
                            <label className="form-check-label">summary</label>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                className="button-100"
                appearance="primary"
                isDisabled={!canSend}
                onClick={send}>
                Send
            </Button>
        </div>
    )
}

function Email({ items = [], onSend, style = {} }) {
    if (items.length === 1) {
        const { barcode, layout, rules, attachmentRules, status } = items[0]
        return (
            <div style={style}>
                <DropdownMenu trigger="Send" triggerType="button">
                    <EmailItem
                        status={status}
                        barcode={barcode}
                        layout={layout}
                        onSend={onSend}
                        rules={rules}
                        attachmentRules={attachmentRules}
                    />
                </DropdownMenu>
            </div>
        )
    }
    if (items.length > 1) {
        return (
            <div style={style}>
                <DropdownMenu trigger="Send" triggerType="button">
                    {items.map((item, index) => (
                        <>
                            <span style={{ margin: 10, fontWeight: "bold" }}>
                                {item.layout}
                            </span>
                            <EmailItem
                                status={item.status}
                                barcode={item.barcode}
                                layout={item.layout}
                                onSend={onSend}
                                rules={item.rules}
                            />
                            {index < items.length - 1 ? <hr /> : null}
                        </>
                    ))}
                </DropdownMenu>
            </div>
        )
    }
    return null
}

export default Email
