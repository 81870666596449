import React from "react"
import TableField from "../shared/components/tablefield"

const schema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
    },
]

const HormonRangesEdit = ({ hormon, field, visible = true }) => {
    const { value, onChange, error, ...other } = field
    const ranges = value.map((r) => ({
        low: r.split(";")[0],
        high: r.split(";")[1],
        rank: r.split(";")[2],
    }))
    const onRangeChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map(
                          (r) => r.low + ";" + r.high + ";" + r.rank,
                      )
                    : [],
            },
        })
    }
    return (
        <div style={{ display: visible ? "block" : "none" }}>
            <h4>{hormon}</h4>
            <div className="form-row">
                <TableField
                    label=""
                    width="col"
                    editable
                    moveRows
                    schema={schema}
                    // errors={printableErrors(errors, "values")}
                    {...other}
                    value={ranges}
                    onChange={onRangeChange}
                />
            </div>
        </div>
    )
}

export default HormonRangesEdit
