import React, { useState } from "react"
import SectionHeader from "../shared/components/section-header"
import FileUploadMultiple from "../shared/components/file-upload-multiple"
import FileUpload from "../shared/components/file-upload"
import { withRouter } from "react-router"
import Tabs from "@atlaskit/tabs"
import { setItem } from "../labresult/storage"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { error, success } from "../../shared/notifications"
import Spinner from "@atlaskit/spinner"

function Import(props) {
    const [selectedTab, setSelectedTab] = useState(0)
    const [uploading, setUploading] = useState(null)
    const api = mkApi()

    const showError = (e) => {
        let ex = e
        if (typeof e === "string") {
            try {
                ex = JSON.parse(e)
            } catch (e) {
                ex = {
                    Message: "Error posting file",
                    StackTrace: "",
                }
            }
        }
        if (ex && ex.Message) {
            error(ex.Message, {
                dismiss: false,
            })
            console.error(ex.StackTrace)
        }
    }

    const onUploadGutExtra = (analysisType, acceptedFiles) => {
        const file = acceptedFiles[0]
        setUploading(analysisType)
        api.uploadLabResult(file, analysisType)
            .then((result) => {
                if (result && !result.error) {
                    setItem("barcodes", { ...result, analysisType })
                    props.history.push(routes.labresult.import.url)
                }
                setUploading(null)
                success("Analysis successfully uploaded!")
            })
            .catch((e) => {
                showError(e)
                setUploading(null)
            })
    }

    const onUploadResult = (analysisType, acceptedFiles) => {
        const file = acceptedFiles[0]
        setUploading(analysisType)
        api.uploadLabResult(file, analysisType)
            .then((result) => {
                if (result && !result.error) {
                    setItem("barcodes", { ...result, analysisType })
                    props.history.push(routes.labresult.import.url)
                }
                setUploading(null)
                success("Analysis successfully uploaded!")
            })
            .catch((e) => {
                showError(e)
                setUploading(null)
            })
    }
    const tabs = [
        {
            label: "Gut",
            content: (
                <>
                    <SectionHeader title="Upload Symbyo GUT Analysis Result" />
                    {uploading === "macrobiota" ? (
                        <div className="upload-inprogress">
                            <Spinner size="xlarge" isCompleting={false} />
                            <span className="upload-message">
                                Uploading file... Please wait...
                            </span>
                        </div>
                    ) : (
                        <FileUpload
                            key="macrobiota"
                            onFileChange={(f) =>
                                onUploadResult("macrobiota", f)
                            }
                        />
                    )}
                    <SectionHeader title="Upload Symbyo GUT Additional file" />
                    {uploading === "gutextra" ? (
                        <div className="upload-inprogress">
                            <Spinner size="xlarge" isCompleting={false} />
                            <span className="upload-message">
                                Uploading file... Please wait...
                            </span>
                        </div>
                    ) : (
                        <FileUpload
                            key="gutextra"
                            onFileChange={(f) =>
                                onUploadGutExtra("gutextra", f)
                            }
                        />
                    )}
                </>
            ),
        },
        {
            label: "Vaginal",
            content: (
                <>
                    <SectionHeader title="Upload Symbyo Vaginal Analysis Result" />
                    {uploading === "vaginal" ? (
                        <div className="upload-inprogress">
                            <Spinner size="xlarge" isCompleting={false} />
                            <span className="upload-message">
                                Uploading file... Please wait...
                            </span>
                        </div>
                    ) : (
                        <FileUpload
                            key="vaginal"
                            onFileChange={(f) => onUploadResult("vaginal", f)}
                        />
                    )}
                </>
            ),
        },
        {
            label: "Genehome",
            content: (
                <>
                    <SectionHeader title="Upload Genehome Analysis Result" />
                    <FileUploadMultiple history={props.history} />
                </>
            ),
        },
        {
            label: "Epigene",
            content: (
                <>
                    <SectionHeader title="Upload Epigene Analysis Result" />
                    {uploading === "epigene" ? (
                        <div className="upload-inprogress">
                            <Spinner size="xlarge" isCompleting={false} />
                            <span className="upload-message">
                                Uploading file... Please wait...
                            </span>
                        </div>
                    ) : (
                        <FileUpload
                            key="vaginal"
                            onFileChange={(f) => onUploadResult("epigene", f)}
                        />
                    )}
                </>
            ),
        },
        {
            label: "Oral",
            content: (
                <>
                    <SectionHeader title="Upload Symbyo Oral Analysis Result" />
                    {uploading === "oral" ? (
                        <div className="upload-inprogress">
                            <Spinner size="xlarge" isCompleting={false} />
                            <span className="upload-message">
                                Uploading file... Please wait...
                            </span>
                        </div>
                    ) : (
                        <FileUpload
                            key="oral"
                            onFileChange={(f) => onUploadResult("oral", f)}
                        />
                    )}
                </>
            ),
        },
    ]
    return (
        <Tabs
            tabs={tabs}
            onSelect={(tab, idx) => setSelectedTab(idx)}
            selected={selectedTab}
        />
    )
}

export default withRouter(Import)
