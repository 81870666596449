import React from "react"
import Select from "@atlaskit/select"
import { CreatableSelect } from "@atlaskit/select"

const SelectField = ({
    label,
    width,
    value,
    onChange,
    error,
    options = [],
    name,
    disabled = false,
    ...props
}) => {
    const onChangeInner = (...args) => {
        const item = args[0]
        const e = {
            target: { value: item.value },
        }
        onChange(e)
    }
    const selectedOption = options.filter((o) => o.value === value)
    return (
        <div className={`form-group ${width}`}>
            {label ? <label>{label}</label> : null}
            <Select
                className="single-select"
                classNamePrefix="react-select"
                isDisabled={disabled}
                onChange={onChangeInner}
                value={selectedOption}
                options={options}
                id={name}
                {...props}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
}

const MultiSelectField = ({
    label,
    width,
    value,
    onChange,
    error,
    options = [],
    name,
    ...props
}) => {
    const onChangeInner = (...args) => {
        const items = args[0] || []
        const e = {
            target: { value: items.map((x) => x.value) },
        }
        onChange(e)
    }
    const selectedOption = options.filter((o) => value.includes(o.value))
    return (
        <div className={`form-group ${width}`}>
            <label>{label}</label>
            <Select
                className="single-select"
                classNamePrefix="react-select"
                onChange={onChangeInner}
                defaultValue={selectedOption}
                options={options}
                isMulti
                id={name}
                {...props}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
}

const CreatableSelectField = ({
    label,
    width,
    value,
    onChange,
    error,
    onCreate,
    options = [],
    ...props
}) => {
    const onChangeInner = (...args) => {
        const item = args[0]
        const e = {
            target: { value: item.value },
        }
        onChange(e)
    }
    const selectedOption = options.filter((o) => o.value === value)
    return (
        <div className={`form-group ${width}`}>
            <label>{label}</label>
            <CreatableSelect
                className="single-select"
                classNamePrefix="react-select"
                onChange={onChangeInner}
                defaultValue={selectedOption}
                onCreateOption={onCreate}
                options={options}
                {...props}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
}

export { SelectField, MultiSelectField, CreatableSelectField }
