import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import LocalizedTextField from "../shared/components/localized-textfield"
import TextField from "../shared/components/textfield"
import CheckField from "../shared/components/checkfield"
import types from "../../shared/types"
import { SelectField } from "../shared/components/selectfield"
import SectionDataSourceListEdit from "./section-datasource-list-edit"
import TableColumnsEdit from "./table-columns-edit"
import { b64DecodeUnicode, b64EncodeUnicode } from "../../shared/base64"

function getSuggestions(dataSources, dataSource) {
    const items = dataSources[dataSource]
    switch (dataSource) {
        case "Virus":
        case "Fungus":
        case "Parasite":
        case "Bacterium":
            return items.map((b) => b.level + ";" + b.group)
        case "Drug":
            return items.map((b) => b.code)
        case "Hormon":
            return items.map((b) => b.name)

        default:
            return items
    }
}

const SectionTableEdit = ({ section, dataSources = {}, onSave, onCancel }) => {
    const model = {
        ...section,
        dataSourceFilter: section.dataSourceFilter.map(b64DecodeUnicode),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        tableType: yup.string().required(),
        description: yup.string(),
        note: yup.string(),
        flip: yup.boolean().required(),
        dataSource: yup.string().required(),
        dataSourceFilter: yup
            .array()
            .of(
                yup
                    .string()
                    .test(
                        "valid-source",
                        "Unknown element",
                        (value) =>
                            value.trim() === "" ||
                            suggestions.filter((b) => b === value).length === 1,
                    ),
            ),
        columns: yup.array().of(
            yup.object().shape({
                type: yup.string().required(),
                label: yup.string(),
                value: yup.string(),
                style: yup.string(),
            }),
        ),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        dataSourceFilter: formist.values.dataSourceFilter
                            .filter((b) => b.trim() !== "")
                            .map(b64EncodeUnicode),
                    })
                }
            })
            .catch(() => {})
    }

    const allDataSources = types.dataSourceTypes.map((ds) => ({
        label: ds,
        value: ds,
    }))

    const suggestions = formist.values.dataSource
        ? getSuggestions(dataSources, formist.values.dataSource)
        : []

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <div className="r-col-2">
                    <TextField
                        label="Type"
                        width="col"
                        {...formist.getFieldProps("tableType")}
                    />
                </div>
                <div className="r-col-2">
                    <CheckField
                        label="Flip"
                        width="col"
                        {...formist.getFieldProps("flip")}
                    />
                </div>
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
                <LocalizedTextField
                    label="Notes"
                    width="col"
                    {...formist.getFieldProps("note")}
                />
            </div>
            <div className="form-row">
                <div className="r-col-30">
                    <SelectField
                        label="Data Source"
                        width="col"
                        options={allDataSources}
                        {...formist.getFieldProps("dataSource")}
                    />
                </div>
                <div className="r-col-30" style={{ marginLeft: 50 }}>
                    <SectionDataSourceListEdit
                        fieldName="dataSourceFilter"
                        title={formist.values.dataSource}
                        key={formist.values.dataSource}
                        dataSourceField={formist.getFieldProps(
                            "dataSourceFilter",
                        )}
                        errors={formist.errors}
                        suggestions={suggestions}
                        hideLevel={false}
                    />
                </div>
                <div className="r-col-40">
                    <TableColumnsEdit
                        field={formist.getFieldProps("columns")}
                        errors={formist.errors}
                    />
                </div>
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionTableEdit
