import React, { useEffect } from "react"
import { range } from "ramda"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import { useStateContext, withStateContext } from "../../shared/state-context"
import SectionHeader from "../shared/components/section-header"
import { mkApi } from "./api"
import { initialState, reducer } from "./store"
import PaginatedTable from "../shared/components/paginated-table"
import { routes } from "../../shared/routing"
import Filter from "./filter"
import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup,
} from "@atlaskit/dropdown-menu"

const head = {
    cells: [
        {
            key: "name",
            content: "name",
            isSortable: true,
            width: 15,
        },
        { key: "analysisType", content: "Analysis Type" },
        { key: "referralCode", content: "REF" },
        { key: "actions" },
    ],
}

const Component = () => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { filter, layouts, isLoading, pageNumber, totalPages } = state
    const pages = range(1, totalPages + 1)

    useEffect(() => {
        api.loadFiltered(filter, pageNumber)
    }, [])

    const onChangePage = (e, pageNumber) => {
        api.loadFiltered(filter, pageNumber)
    }

    const setDefaultMenuItem = (item, handler) => {
        return item.defaultLayout ? null : (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Set as Default
                </Link>
            </DropdownItem>
        )
    }

    const setDefaultHandler = (item) => (e) => {
        e.preventDefault()
        api.setDefault(item.name).then(() =>
            api.loadFiltered(filter, pageNumber),
        )
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: routes.layouts.edit.buildUrl(
                                        item.id,
                                    ),
                                }}>
                                {item.name}
                            </Link>
                        ),
                    },
                    {
                        content: (
                            <span
                                style={{
                                    fontWeight: item.defaultLayout
                                        ? "bold"
                                        : "",
                                }}>
                                {item.analysisType}
                            </span>
                        ),
                    },
                    {
                        content: <span>{item.referralCode}</span>,
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {setDefaultMenuItem(
                                        item,
                                        setDefaultHandler,
                                    )}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    function onFilter(newFilter) {
        api.loadFiltered(newFilter, 0)
    }

    return (
        <>
            <SectionHeader title="Layout" />
            <div className="row">
                <Filter width="col-4" filter={filter} onFilter={onFilter} />
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(layouts)}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
            />
        </>
    )
}

export default withStateContext(withRouter(Component), initialState, reducer)
