import React from "react"
import TableField, { printableErrors } from "../shared/components/tablefield"

const freeRankedRangeSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
    },
]

const labeledSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
    },
    {
        field: "label",
        label: "Label",
        localized: true,
    },
]

const rankedRangeSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
        type: "select",
        options: [
            {
                label: "0",
                value: "0",
            },
            {
                label: "1",
                value: "1",
            },
            {
                label: "2",
                value: "2",
            },
            {
                label: "3",
                value: "3",
            },
            {
                label: "4",
                value: "4",
            },
            {
                label: "5",
                value: "5",
            },
        ],
    },
]

const RankedRangeListEdit = ({
    field,
    freeRanks = false,
    errors = {},
    withLabel = false,
}) => {
    const { value, onChange, error, ...other } = field

    const ranges = value.map((r) => ({
        low: r.split(";")[0],
        high: r.split(";")[1],
        rank: r.split(";")[2],
        label: withLabel ? r.split(";")[3] : "",
    }))

    const onRangeChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map(
                          (r) =>
                              r.low +
                              ";" +
                              r.high +
                              ";" +
                              r.rank +
                              (withLabel ? ";" + r.label : ""),
                      )
                    : [],
            },
        })
    }
    return (
        <div className="form-row">
            <TableField
                label=""
                width="col"
                editable
                moveRows
                schema={
                    withLabel
                        ? labeledSchema
                        : freeRanks
                        ? freeRankedRangeSchema
                        : rankedRangeSchema
                }
                errors={printableErrors(errors, "values")}
                {...other}
                value={ranges}
                onChange={onRangeChange}
            />
        </div>
    )
}

export default RankedRangeListEdit
