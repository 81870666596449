import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import SectionListHormonesValuesEdit from "./section-list-hormonesvalues-edit"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionLinesHormonesEdit = ({
    section,
    hormones = [],
    onSave,
    onCancel,
}) => {
    const model = {
        ...section,
    }

    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup
            .string()
            .test(
                "valid-description",
                "description is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        hormones: yup
            .array()
            .of(
                yup
                    .string()
                    .required()
                    .test(
                        "valid-hormon",
                        "hormones should only include valid registry entries",
                        (value) => hormonesSuggestions.indexOf(value) !== -1,
                    ),
            )
            .min(1)
            .required(),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                    })
                }
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <SectionListHormonesValuesEdit
                hormonesField={formist.getFieldProps("hormones")}
                errors={formist.errors}
                suggestions={hormonesSuggestions}
            />
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionLinesHormonesEdit
