export default {
    sectionConfigurationTypes: [
        "compare",
        "enterotype",
        "summary",
        "leaderboard",
        "list",
        "freetext",
        "dnachart",
        "listgenotype",
        "macrobiotachart",
        "histogram",
        "symbol",
        "balance",
        "ratio",
        "image",
        "listhormones",
        "gaugehormones",
        "summaryhormones",
        "hormoneschart",
        "multigaugehormones",
        "lineshormones",
        "ratiogaugehormones",
        "experimental",
        "leaderbarchart",
        "table",
    ],
    sectionTypes: [
        "category",
        "freetext",
        "barchart",
        "listgenotype",
        "histogram",
        "gauge",
        "ratio",
        "image",
        "table",
        "lines",
        "group",
        "stackedchart",
        "error",
    ],
    dataSourceTypes: [
        "Bacterium",
        "Virus",
        "Fungus",
        "Drug",
        "Parasite",
        "Hormon",
        "Gene",
    ],
}
