import * as yup from "yup"

export const hormonSpecifiers = {
    all: "All",
    man: "Man",
    woman: "Woman",
    womanpre: "Woman Pre",
    womanpost: "Woman Post",
}

export const allHormonSpecifiers = [
    "All",
    "Man",
    "Woman",
    "WomanPre",
    "WomanPost",
]

export const addEmpty = {
    name: "",
    description: "",
    variants: [],
    uom: "",
    attributes: {
        all: [],
        man: [],
        woman: [],
        womanpre: [],
        womanpost: [],
    },
}

const rangeSchema = yup.object().shape({
    low: yup.number().required().min(0),
    high: yup.number().required().min(0),
})

const attributeSchema = yup.object().shape({
    range: rangeSchema,
    rank: yup.number().required().min(0).max(10),
})

const variantSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
})

export const addSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    variants: yup.array().of(variantSchema).min(0),
    uom: yup.string().required(),
    attributes: yup.object().shape(
        Object.keys(hormonSpecifiers).reduce(
            (acc, s) => ({
                ...acc,
                [s]: yup.array().of(attributeSchema),
            }),
            {},
        ),
    ),
})

export const hormonesSchema = (hormones) =>
    yup.array().of(hormonSchema(hormones)).min(1).required()

export const hormonSchema = (hormones) =>
    yup
        .string()
        .required()
        .test(
            "valid-hormon",
            "hormones should only include valid registry entries",
            (value) => hormones.indexOf(value) !== -1,
        )

export const editSchema = addSchema
