import { match } from "../../shared/store"

export const initialState = {
    filter: {},
    isLoading: true,
    items: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-TEMPLATES", handleLoadAll)
        .case("LOAD-FILTERED-TEMPLATES", handleLoadFilteredTemplates)
        .case("ADD-TEMPLATE", handleAdd)
        .case("UPDATE-TEMPLATE", handleUpdate)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-TEMPLATES", data }
    },
    loadFiltered(data, filter) {
        return { type: "LOAD-FILTERED-TEMPLATES", data, filter }
    },
    add(data) {
        return { type: "ADD-TEMPLATE", data }
    },
    update(data) {
        return { type: "UPDATE-TEMPLATE", data }
    },
}

const handleLoadAll = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        items,
        filter: {},
        ...pagination,
    }
}

const handleLoadFilteredTemplates = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        items,
        filter: action.filter,
        ...pagination,
    }
}

const handleAdd = (state, action) => {
    return {
        templates: [...state.templates, action.data],
    }
}

const handleUpdate = (state, action) => {
    return {
        ...state,
        items: state.items.map((t) =>
            t.id === action.data.id ? action.data : t,
        ),
    }
}
