import React from "react"

const CheckField = ({
    value,
    label,
    error,
    onChange,
    className = "form-check-input",
    ...props
}) => {
    const onChangeInner = () => {
        const e = {
            target: { value: !value },
        }
        onChange(e)
    }
    if (label) {
        return (
            <div className="form-group" style={{ marginLeft: "2rem" }}>
                <input
                    className={className}
                    type="checkbox"
                    checked={value}
                    onChange={onChangeInner}
                    {...props}
                />
                <label>{label}</label>
                <small className="text-danger">{error}</small>
            </div>
        )
    }
    return (
        <input
            className={className}
            type="checkbox"
            checked={value}
            onChange={onChangeInner}
            {...props}
        />
    )
}

export default CheckField
