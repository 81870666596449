import React, { forwardRef } from "react"

// eslint-disable-next-line react/display-name
const TextField = forwardRef((props, ref) => {
    const {
        visible = true,
        label,
        placeholder,
        width,
        value,
        onChange,
        error,
        type = "text",
        ...otherProps
    } = props
    return (
        <div
            className={`form-group ${width}`}
            style={{ display: visible ? "block" : "none" }}>
            {label ? <label>{label}</label> : null}
            <input
                type={type}
                className="form-control"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={ref}
                {...otherProps}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
})

export default TextField
