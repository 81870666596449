import React from "react"
import { Dashboard } from "@uppy/react"
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import Uppy from "@uppy/core"
import Tus from "@uppy/tus"
import { getItem } from "../../../shared/security/storage"
import { routes } from "../../../shared/routing"
import { warning } from "../../../shared/notifications"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

const baseUrl = () => global.__serverUrl__ || ""

function buildUrl(url) {
    return `${baseUrl()}${url}`
}

function getAuthorization() {
    const user = getItem("user")
    if (user && user.token) {
        return {
            Authorization: "Bearer " + user.token,
        }
    }
    return {}
}

const FileUpload = ({ history }) => {
    const uppy = React.useMemo(() => {
        return new Uppy({
            meta: { type: "csv" },
            restrictions: { maxNumberOfFiles: 50 },
            autoProceed: false,
        }).use(Tus, {
            endpoint: buildUrl("/tus/files"),
            resume: true,
            removeFingerprintOnSuccess: true,
            retryDelays: [0, 1000, 3000, 5000],
            headers: getAuthorization(),
        })
    }, [])
    React.useEffect(() => {
        uppy.on("complete", () => {
            warning(
                "Genehome files processing can take some time, in the meantime the barcodes are marked as Uploading, check back in a few minutes to see if the processing was succesful, or click on Error on the imported files to understand what happened",
                {
                    dismiss: false,
                    onRemoval: () => {
                        history.push(routes.dashboard.index.url)
                    },
                },
            )
        })
        return () => uppy.close()
    }, [])
    return <Dashboard uppy={uppy} />
}

export default FileUpload
