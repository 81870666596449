import React from "react"
import { BarChart, Bar, XAxis, YAxis, Legend } from "recharts"
import { getLocalizedText } from "../../shared/locale"

function toObj(values, keyProp, valueProp) {
    const objs = values.map((obj) => ({ [obj[keyProp]]: obj[valueProp] }))
    const obj = objs.reduce((acc, cur) => Object.assign(acc, cur), {})
    return obj
}

const colors = [
    "#3365CC",
    "#DC3911",
    "#FF9900",
    "#0F9618",
    "#990099",
    "#0298C7",
    "#DD4477",
    "#65AA00",
    "#45B29D",
]

const sizes = {
    legend: [
        {
            width: 300,
            height: 250,
            bar: 20,
            xaxis: 40,
        },
        {
            width: 450,
            height: 320,
            bar: 50,
            xaxis: undefined,
        },
    ],
    nolegend: [
        {
            width: 200,
            height: 250,
            bar: 20,
            xaxis: 40,
        },
        {
            width: 300,
            height: 270,
            bar: 50,
            xaxis: undefined,
        },
    ],
}

export default function StackedChart({ data, showLegend, language }) {
    const transformedData = data.map((chart) => {
        const localizedData = chart.items.map((item) => ({
            name: getLocalizedText(item.name, language),
            value: item.value,
        }))
        return {
            subject: getLocalizedText(chart.name, language),
            ...toObj(localizedData, "name", "value"),
        }
    })

    const style = { fontSize: 14 }

    const size =
        sizes[showLegend ? "legend" : "nolegend"][data.length - 1] || {}

    return (
        <div style={style}>
            <BarChart
                width={size.width}
                height={size.height}
                barSize={size.bar}
                data={transformedData}
                stackOffset="expand">
                <XAxis dataKey="subject" />
                <YAxis
                    width={size.xaxis}
                    type="number"
                    tickFormatter={(t) => `${t * 100}%`}
                />

                {showLegend && (
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="top"
                        width={180}
                        wrapperStyle={{ right: 0 }}
                    />
                )}
                {(data[0] || { items: [] }).items.map((b, index) => (
                    <Bar
                        key={index}
                        dataKey={getLocalizedText(b.name, language)}
                        barSize={size.bar}
                        stackId="a"
                        fill={colors[index]}
                    />
                ))}
            </BarChart>
        </div>
    )
}
