import React from "react"
import TableField from "../shared/components/tablefield"

const schema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "points",
        label: "Points",
    },
]

const HormonPointsEdit = ({ title, field }) => {
    const { value, onChange, error, ...other } = field
    const ranges = value.map((r) => ({
        low: r.split(";")[0],
        high: r.split(";")[1],
        points: r.split(";")[2],
    }))
    const onRangeChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map(
                          (r) => r.low + ";" + r.high + ";" + r.points,
                      )
                    : [],
            },
        })
    }
    return (
        <>
            <h3>{title}</h3>
            <div className="form-row">
                <TableField
                    label=""
                    width="col"
                    editable
                    moveRows
                    schema={schema}
                    {...other}
                    value={ranges}
                    onChange={onRangeChange}
                />
            </div>
        </>
    )
}

export default HormonPointsEdit
