import React from "react"
import { AsyncSelect } from "@atlaskit/select"

const AutocompleteSelectField = ({
    label,
    width,
    value,
    onChange,
    error,
    options = [],
    name,
    disabled = false,
    load,
    ...props
}) => {
    const onChangeInner = (...args) => {
        const item = args[0]
        const e = {
            target: { value: item.value, label: item.label },
        }
        onChange(e)
    }
    const selectedOption = options.filter((o) => o.value === value)
    return (
        <div className={`form-group ${width}`}>
            {label ? <label>{label}</label> : null}
            <AsyncSelect
                className="single-select"
                classNamePrefix="react-select"
                defaultOptions
                isDisabled={disabled}
                onChange={onChangeInner}
                value={selectedOption}
                id={name}
                {...props}
                loadOptions={load}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
}

export default AutocompleteSelectField
