import React from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Button from "@atlaskit/button"
import Form from "../shared/components/form"

export default function MarkAsDoneBarcode({
    enabled = false,
    onConfirm,
    onCancel,
    warningMessage = "Are you sure you want to mark this barcode as done?",
}) {
    return enabled ? (
        <ModalDialog id="mark-as-done-barcode-dialog" heading="Mark As Done">
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={onConfirm}>
                        Confirm
                    </Button>,
                    "cancel",
                ]}
                primaryText="Remove"
                onCancel={onCancel}>
                <div className="form-row">{warningMessage}</div>
            </Form>
        </ModalDialog>
    ) : null
}
