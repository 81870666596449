import React, { createRef, useCallback } from "react"
import TextField from "./textfield"
import { useSuggest } from "../../../shared/form"

const suggestionFind = (suggestions) => (text, callback) => {
    const keyword = text.toLowerCase()
    const results = suggestions.reduce((previous, word) => {
        if (
            word.toLowerCase().indexOf(keyword) !== -1 &&
            word.toLowerCase() !== keyword
        ) {
            return [...previous, word]
        }
        return previous
    }, [])
    setTimeout(() => {
        callback(results)
    }, 300)
}

const SuggestTextField = ({
    suggestions,
    onSelected = () => {},
    regex = "(?:^|\\s+|,;)([a-zA-Z0-9; ]+)$",
    ...props
}) => {
    const inputRef = createRef(null)
    useSuggest({
        input: inputRef,
        suggestions: [useCallback(suggestionFind(suggestions), [suggestions])],
        onSelected,
        regex,
        trigger: /[\W;]/,
    })
    return <TextField ref={inputRef} {...props} />
}

export default SuggestTextField
