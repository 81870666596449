import { split, reverse, head, tail, reduce, mergeDeepLeft } from "ramda"

const flatten_separator = "_"

export function arrayToObj(xs, namePicker, valueBuilder) {
    return xs.reduce(
        (acc, cur) => ({
            ...acc,
            [camelize(namePicker(cur))]: valueBuilder(cur),
        }),
        {},
    )
}

export function objToArray(obj, name, valueName, valueBuilder) {
    return Object.entries(obj).reduce((acc, [key, val]) => {
        return [...acc, { [name]: key, [valueName]: valueBuilder(val) }]
    }, [])
}

export function flatten(obj, prefix = "", res = {}) {
    return Object.entries(obj).reduce((r, [key, val]) => {
        const k = `${prefix}${key}`
        if (typeof val === "object") {
            flatten(val, `${k}${flatten_separator}`, r)
        } else {
            res[k] = val
        }
        return r
    }, res)
}

export function unflatten(obj) {
    return Object.entries(obj).reduce((acc, [key, val]) => {
        const parts = reverse(split("_")(key))
        const deeper = { [head(parts)]: val }
        const obj = reduce(
            (prev, cur) => ({ [cur]: prev }),
            deeper,
            tail(parts),
        )
        return mergeDeepLeft(acc, obj)
    }, {})
}

export function camelize(str) {
    return str
        .replace(/\s(.)/g, function ($1) {
            return $1.toUpperCase()
        }) // upper first char after space
        .replace(/\s/g, "") // remove spaces
        .replace(/\//g, "") // remove /
        .replace(/'/g, "") // remove '
        .replace(/^(.)/, function ($1) {
            return $1.toLowerCase()
        }) // lower very first char
}

export function allEqual(arr) {
    return arr.every((e) => e === arr[0])
}

export function isFunction(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply)
}
