import React, { useEffect, useState } from "react"

export function hasPDF(item, layout) {
    return (
        item.status === "Analysed" &&
        item.pdf[layout] &&
        item.pdf[layout] !== "inprogress"
    )
}

export function useInProgress(analyses, pollStatus) {
    const [polling, setPolling] = useState(null)
    useEffect(() => {
        const newInProgress = analyses
            .filter(
                (a) =>
                    a.pdf &&
                    Object.values(a.pdf).some((s) => s === "inprogress"),
            )
            .reduce((acc, a) => {
                const inprogressLayouts = Object.keys(a.pdf)
                    .filter((l) => a.pdf[l] === "inprogress")
                    .map((l) => ({
                        layout: l,
                        barcode: a.barcode,
                    }))
                return [...acc, ...inprogressLayouts]
            }, [])
        if (polling) {
            clearTimeout(polling)
        }
        if (newInProgress.length > 0) {
            setPolling(setTimeout(pollStatus, 5000))
        } else {
            setPolling(null)
        }
    }, [analyses])
}

function getLayoutName(layoutId, layouts) {
    const matching = layouts.filter((l) => l.id === layoutId)
    if (matching && matching.length) return matching[0].name
    return layoutId
}

export default function PDFLink({
    item,
    layout,
    allLayouts = [],
    onCreate,
    onDownload,
    style = {},
    withLayout = false,
    reportMode,
}) {
    const layoutPostfix =
        (withLayout ? " " + getLayoutName(layout, allLayouts) : "") +
        ` (${reportMode})`
    if (item.status !== "Analysed") return <></>
    if (item.pdf && item.pdf[layout]) {
        if (item.pdf[layout] === "inprogress")
            return <span style={style}>Generating{layoutPostfix}...</span>
        return (
            <a
                style={style}
                href="#"
                onClick={(e) => {
                    e.preventDefault()
                    onDownload(item.barcode, layout, reportMode)
                }}>
                Download{layoutPostfix}
            </a>
        )
    }
    return (
        <a
            style={style}
            href="#"
            onClick={(e) => {
                e.preventDefault()
                onCreate(item.barcode, layout)
            }}>
            Create{layoutPostfix}
        </a>
    )
}
