import React from "react"
import { CommentAction } from "@atlaskit/comment"
import SuggestTextField from "../shared/components/suggesttextfield"
import TextField from "../shared/components/textfield"
import { SelectField } from "../shared/components/selectfield"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionListBacteriumSymbolEdit = ({ field, allBacteria }) => {
    const { value, onChange, error } = field
    const suggestions = allBacteria.map((b) => `${b.level};${b.group}`)

    const onFieldChange = (field, newValue, changed) => {
        onChange({
            target: {
                value: value.map((v, i) =>
                    i === changed
                        ? {
                              ...v,
                              [field]: newValue,
                          }
                        : v,
                ),
            },
        })
    }

    const removeBacterium = (removed) => {
        onChange({
            target: {
                value: value.filter((v, i) => i !== removed),
            },
        })
    }

    const addBacterium = () => {
        onChange({
            target: {
                value: [
                    ...value,
                    {
                        bacterium: "",
                        ranges: [],
                    },
                ],
            },
        })
    }

    const symbolOptions = [
        {
            value: "Star",
            label: "Star",
        },
        {
            value: "Danger",
            label: "Danger",
        },
    ]

    const conditionOptions = [
        {
            value: "Present",
            label: "Present",
        },
        {
            value: "Missing",
            label: "Missing",
        },
    ]

    const renderBacteria = (bacteria, suggestions) =>
        bacteria.map((b, idx) => (
            <div key={b.bacterium.level + b.bacterium.group}>
                <hr />

                <div className="form-row">
                    <div className="col">
                        <SuggestTextField
                            suggestions={suggestions}
                            label="Bacterium"
                            width="col"
                            value={b.bacterium}
                            onChange={(e) =>
                                onFieldChange("bacterium", e.target.value, idx)
                            }
                            onSelected={(e) =>
                                onFieldChange("bacterium", e, idx)
                            }
                        />
                        <LocalizedTextField
                            label="Name"
                            width="col"
                            value={b.name}
                            onChange={(e) =>
                                onFieldChange("name", e.target.value, idx)
                            }
                        />
                    </div>
                    <div className="col">
                        <SelectField
                            label="Symbol"
                            width="col"
                            value={b.symbol}
                            onChange={(e) =>
                                onFieldChange("symbol", e.target.value, idx)
                            }
                            options={symbolOptions}
                        />
                        <SelectField
                            label="Condition"
                            width="col"
                            value={b.condition}
                            onChange={(e) =>
                                onFieldChange("condition", e.target.value, idx)
                            }
                            options={conditionOptions}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <CommentAction key="1" onClick={() => removeBacterium(idx)}>
                        Remove Bacterium
                    </CommentAction>
                </div>
            </div>
        ))
    const globalError = error && typeof error === "string" ? error : ""
    return (
        <>
            <h3>Bacteria</h3>
            <div className="form-row">
                <CommentAction key="1" onClick={() => addBacterium()}>
                    New Bacterium
                </CommentAction>
            </div>
            <small className="text-danger">{globalError}</small>
            {renderBacteria(value, suggestions)}
            <hr />
        </>
    )
}

export default SectionListBacteriumSymbolEdit
