import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import RankedRangeListEdit from "./ranked-range-list-edit"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import SectionListHormonesValuesEdit from "./section-list-hormonesvalues-edit"
import { SelectField } from "../shared/components/selectfield"
import LocalizedTextField from "../shared/components/localized-textfield"
import { hormonesSchema } from "../hormones/schemas"
import {
    SectionHormonesDetailsEdit,
    getHormonesDetailsWithSpecifiers,
    saveHormones,
} from "./section-hormonesdetails-edit"

const gaugeTypeOptions = [
    {
        value: "Partial",
        label: "Partial",
    },
    {
        value: "Full",
        label: "Full",
    },
]

const numberFormatOptions = [
    {
        value: "Integer",
        label: "Integer",
    },
    {
        value: "Decimals",
        label: "Decimals",
    },
]

const SectionGaugeHormonesEdit = ({
    section,
    hormones = [],
    onSave,
    onCancel,
}) => {
    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const model = {
        ...section,
        hormones: Object.keys(section.hormones),
        hormonesDetails: getHormonesDetailsWithSpecifiers(section.hormones),
        barRanges: section.barRanges.map(b64DecodeUnicode),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        hormones: hormonesSchema(hormonesSuggestions),
        gaugeType: yup.string().required(),
        numberFormat: yup.string().required(),
        hormonesDetails: yup.object(),
        barRanges: yup.array().min(1).of(yup.string().required()),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        hormones: saveHormones(
                            formist.values.hormones,
                            formist.values.hormonesDetails,
                        ),
                        barRanges:
                            formist.values.barRanges.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const actualHormones = (
        formist.getFieldProps("hormones").value || []
    ).filter((h) => hormonesSuggestions.indexOf(h) !== -1)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <SelectField
                    label="Gauge Type"
                    width="col"
                    {...formist.getFieldProps("gaugeType")}
                    options={gaugeTypeOptions}
                />
                <SelectField
                    label="Number Format"
                    width="col"
                    {...formist.getFieldProps("numberFormat")}
                    options={numberFormatOptions}
                />
            </div>
            <h3>Hormones</h3>
            <SectionListHormonesValuesEdit
                hormonesField={formist.getFieldProps("hormones")}
                errors={formist.errors}
                suggestions={hormonesSuggestions}
            />
            <SectionHormonesDetailsEdit
                details={formist.getFieldProps("hormonesDetails")}
                hormones={actualHormones}
            />
            <h3>Final Points</h3>
            <div className="form-row">
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("barRanges"),
                    }}
                    errors={formist.getFieldProps("barRanges").error}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionGaugeHormonesEdit
