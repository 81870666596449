import React from "react"
import { withStateContext } from "../../../shared/state-context"
import { Switch, Route } from "react-router-dom"
import { values, flatten } from "ramda"
import { routes } from "../../../shared/routing"

export default (type, initialState, reducer) =>
    withStateContext(
        () => {
            const pageRoutes = flatten(values(routes[type].index.routes))
            return (
                <Switch>
                    {pageRoutes.map((route) => (
                        <Route
                            key={route.url}
                            exact
                            path={route.url}
                            component={route.component}
                        />
                    ))}
                </Switch>
            )
        },
        initialState,
        reducer,
    )
