import React from "react"
import { withRouter } from "react-router"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { addSchema as schema, addEmpty as empty } from "./schemas"
import { addMappers as M } from "./mappers"
import FormFields from "./form-fields"

const AddHormon = ({ history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)

    const add = (hormon) => {
        api.update(M.toApiModel(hormon), true).then(() => {
            history.push(routes.hormones.index.url)
        })
    }

    const initialValues = empty
    return (
        <FormFields
            initialValues={initialValues}
            schema={schema}
            onSubmit={add}
            onCancel={() => history.goBack()}
            formProps={{ title: "New Hormon", primaryText: "Add" }}
        />
    )
}

export default withRouter(AddHormon)
