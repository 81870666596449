import { identity, ifElse } from "ramda"
import { getItem } from "./security/storage"
import { error } from "./notifications"

const baseUrl = () => global.__serverUrl__ || ""

export function buildUrl(url) {
    return `${baseUrl()}${url}`
}

export function download(
    url,
    name,
    prefix = "html",
    errorMessage,
    errorValue,
    onError,
    token,
) {
    return fetch(url, makeGetOptions(token))
        .then(
            ifElse(
                (res) => res.ok,
                (res) => res.blob(),
                ajaxErrorHandler(errorMessage, errorValue, onError),
            ),
            ajaxErrorHandler(errorMessage, errorValue, onError),
        )
        .then((blob) => {
            if (blob.ajaxError) throw blob.ajaxError
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = name + "." + prefix
            document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click()
            a.remove() //afterwards we remove the element again
        })
}

export function getJson(url, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makeGetOptions()).then(
        ifElse(
            (res) => res.ok,
            (res) => res.json(),
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function getManyJson(urls, errorMessage, errorValue, onError) {
    const promises = urls.map((url) => fetch(buildUrl(url), makeGetOptions()))
    return Promise.all(promises).then(
        ifElse(
            (res) => res.ok,
            (res) => Promise.all(res.map((x) => x.json())),
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function postJson(url, value, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makePostOptions(value)).then(
        ifElse(
            (res) => res.ok,
            (res) => res.json(),
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function postEmpty(url, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makePostOptions({})).then(
        ifElse(
            (res) => res.ok,
            identity,
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function postFile(url, file, errorMessage, errorValue, onError) {
    const formData = new FormData()
    formData.append("file", file)
    return fetch(buildUrl(url), makePostFileOptions(formData)).then(
        ifElse(
            (r) => r.ok,
            (r) => (r.status === 200 && r.json()) || r,
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function putJson(url, value, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makePutOptions(value)).then(
        ifElse(
            (r) => r.ok,
            identity,
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

const ajaxErrorHandler =
    (msg, errorValue, onError = errorHandler) =>
    (res) => {
        onError(msg, res)
        return (
            errorValue ||
            (res.text && res.text().then((error) => ({ ajaxError: error }))) ||
            res
        )
    }

export function deleteEmpty(url, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makeDeleteOptions({})).then(
        ifElse(
            (res) => res.ok,
            identity,
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

export function deleteJson(url, errorMessage, errorValue, onError) {
    return fetch(buildUrl(url), makeDeleteOptions({})).then(
        ifElse(
            (res) => res.ok,
            (r) => (r.status === 200 && r.json()) || r,
            ajaxErrorHandler(errorMessage, errorValue, onError),
        ),
        ajaxErrorHandler(errorMessage, errorValue, onError),
    )
}

function errorHandler(msg, err) {
    if (msg) error(msg)
    console.error(err)
}

function getAuthorization(token) {
    if (token) {
        return {
            Authorization: "Bearer " + token,
        }
    }
    const user = getItem("user")
    if (user && user.token) {
        return {
            Authorization: "Bearer " + user.token,
        }
    }
    return {}
}

function makeGetOptions(token) {
    const headers = new Headers({
        ...getAuthorization(token),
    })
    return {
        method: "GET",
        headers: headers,
    }
}

function makePostOptions(value) {
    const content = JSON.stringify(value)
    const headers = new Headers({
        "Content-Type": "application/json",
        "Content-Length": content.length,
        ...getAuthorization(),
    })
    return {
        method: "POST",
        headers: headers,
        body: content,
    }
}

function makePostFileOptions(formData) {
    const headers = new Headers({
        ...getAuthorization(),
    })
    return {
        method: "POST",
        headers: headers,
        body: formData,
    }
}

function makePutOptions(value) {
    const content = JSON.stringify(value)
    const headers = new Headers({
        "Content-Type": "application/json",
        "Content-Length": content.length,
        ...getAuthorization(),
    })
    return {
        method: "PUT",
        headers: headers,
        body: content,
    }
}

function makeDeleteOptions() {
    const headers = new Headers({
        ...getAuthorization(),
    })
    return {
        method: "DELETE",
        headers: headers,
    }
}

export function serverError(error) {
    return {
        text: () => Promise.resolve(error),
    }
}
