export function setItem(key, data) {
    localStorage.setItem(addNamespaceTo(key), JSON.stringify(data))
}

export function getItem(key) {
    return JSON.parse(localStorage.getItem(addNamespaceTo(key)))
}

export function removeItems(...args) {
    args.map((x) => localStorage.removeItem(addNamespaceTo(x)))
}

function addNamespaceTo(key) {
    return `dl-nextgen-security-${key}`
}
