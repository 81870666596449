import React from "react"
import { SelectField } from "../shared/components/selectfield"
import { analysisTypes } from "../../analysisTypes"

const filterAnalysisTypes = [
    {
        label: "All Analysis Type",
        value: "",
    },
    ...analysisTypes,
]

const DashboardFilter = ({
    type,
    template,
    layout,
    onFilter,
    templates = [],
    layouts = [],
}) => {
    function setTypeFilter(evt) {
        onFilter("type", evt.target.value)
    }

    function setTemplateFilter(evt) {
        onFilter("template", evt.target.value)
    }

    function setLayoutFilter(evt) {
        onFilter("layout", evt.target.value)
    }

    const filterTemplates = [
        {
            label: "All Templates",
            value: "",
        },
        ...templates
            .filter((t) => !type || t.analysisType === type)
            .map((t) => ({
                label: t.name,
                value: t.id,
            })),
    ]

    const filterLayouts = [
        {
            label: "All Layouts",
            value: "",
        },
        ...layouts
            .filter((t) => !type || t.analysisType === type)
            .map((t) => ({
                label: t.name,
                value: t.id,
            })),
    ]

    return (
        <div style={{ display: "flex" }}>
            <SelectField
                width="col-10"
                options={filterAnalysisTypes}
                value={type || ""}
                onChange={setTypeFilter}
            />
            <SelectField
                width="col-10"
                options={filterTemplates}
                value={template || ""}
                onChange={setTemplateFilter}
            />
            <SelectField
                width="col-10"
                options={filterLayouts}
                value={layout || ""}
                onChange={setLayoutFilter}
            />
        </div>
    )
}

export default DashboardFilter
