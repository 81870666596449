import React, { Fragment } from "react"
import Textfield from "@atlaskit/textfield"
import dayjs from "dayjs"

function formatDate(value) {
    if (!value) return ""
    return dayjs(value).format("DD/MM/YYYY")
}

const Component = (props) => {
    const { name, label, date } = props

    return (
        <Fragment>
            <label htmlFor={name}>{label}</label>
            <Textfield
                name={name}
                width="xlarge"
                isReadOnly
                value={formatDate(date)}
            />
        </Fragment>
    )
}

export default Component
