import React, { useEffect, useState } from "react"
import RichTextEditor from "./rich-text-editor"
import { convertFromRaw, convertToRaw } from "draft-js"
import { CommentAction } from "@atlaskit/comment"

const RichTextEditorField = ({ value, error, onChange, onSave, onCancel }) => {
    const [content, setContent] = useState(() =>
        convertFromRaw(JSON.parse(value.replaceAll("\n", ""))),
    )
    useEffect(() => {
        setContent(convertFromRaw(JSON.parse(value.replaceAll("\n", ""))))
    }, [value])
    const onChangeInner = (changed) => {
        setContent(changed)
    }
    function save(e) {
        const value = JSON.stringify(convertToRaw(content))
        const ec = {
            target: { value },
        }
        onChange(ec)
        onSave(e, value)
    }
    return (
        <>
            <RichTextEditor content={content} onChange={onChangeInner} />
            <small className="text-danger">{error}</small>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={save}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </>
    )
}

export default RichTextEditorField
