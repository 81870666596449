import React, { useEffect } from "react"
import TextField from "../shared/components/textfield"
import {
    detailsToModel as diseasesDetailsToModel,
    modelToDetails as diseasesModelToDetails,
} from "../patients/patient-diseases"
import {
    detailsToModel as drugsDetailsToModel,
    modelToDetails as drugsModelToDetails,
} from "../patients/patient-drugs"

import { isDateValid, calcAge } from "../../shared/dates"
import { Button } from "@atlaskit/button/dist/cjs/components/Button"

export const model = {
    firstName: "",
    lastName: "",
    email: "",
    bornOn: "",
    birthPlace: "",
    socialNumber: "",
    vat: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    province: "",
    postalCode: "",
    anonymous: false,
}

export const detailsToModel = (patient) => ({
    ...patient,
    patientDiseases: diseasesDetailsToModel(patient.patientDiseases),
    patientDrugs: drugsDetailsToModel(patient.patientDrugs),
})

export const modelToDetails = (patient, barcodes = {}) => {
    const diseases = {
        barcode: barcodes.common || barcodes.diseases,
        groups: diseasesModelToDetails(patient.patientDiseases),
    }
    const {
        additionalDrugsInfo,
        additionalAllergiesInfo,
        additionalNotes,
        ...flatData
    } = patient.patientDrugs
    const drugs = {
        barcode: barcodes.common || barcodes.drugs,
        drugs: drugsModelToDetails(flatData),
        additionalDrugsInfo,
        additionalAllergiesInfo,
        additionalNotes,
    }
    const guardian = {
        ...patient.patientGuardian,
        barcode: barcodes.common || barcodes.guardian,
    }

    return {
        ...patient,
        patientDiseases: diseases,
        patientDrugs: drugs,
        patientGuardian: guardian,
    }
}

const PatientForm = ({
    formist,
    onAgeChange = () => {},
    onUnblock = () => {},
    anonymous = false,
}) => {
    const bornOn = formist.getFieldProps("bornOn").value
    useEffect(() => {
        if (isDateValid(bornOn)) {
            onAgeChange(calcAge(bornOn))
        }
    }, [bornOn])
    return (
        <>
            <div className="form-row">
                <TextField
                    label="First Name"
                    width="col-sm-4"
                    {...formist.getFieldProps("firstName")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                <TextField
                    label="Last Name"
                    width="col-sm-4"
                    {...formist.getFieldProps("lastName")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                {anonymous ? (
                    <div style={{ marginLeft: 20, marginTop: 30 }}>
                        <Button appearance="primary" onClick={onUnblock}>
                            Unblock
                        </Button>
                    </div>
                ) : null}
            </div>
            <div className="form-row">
                <TextField
                    label="Email"
                    width="col-sm-4"
                    {...formist.getFieldProps("email")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Born on"
                    width="col"
                    {...formist.getFieldProps("bornOn")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                <input type="hidden" {...formist.getFieldProps("birthPlace")} />
                <TextField
                    label="Social Number"
                    width="col-sm-4"
                    {...formist.getFieldProps("socialNumber")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                <TextField
                    label="VAT"
                    width="col-sm-4"
                    {...formist.getFieldProps("vat")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Street line"
                    width="col-sm-4"
                    {...formist.getFieldProps("streetLine1")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                <TextField
                    label="City"
                    width="col-sm-4"
                    {...formist.getFieldProps("city")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Province"
                    width="col-sm-4"
                    {...formist.getFieldProps("province")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
                <TextField
                    label="Postal Code"
                    width="col-sm-4"
                    {...formist.getFieldProps("postalCode")}
                    disabled={formist.getFieldProps("anonymous").value}
                />
            </div>
        </>
    )
}

export default PatientForm
