import React from "react"
import { getImages } from "../../shared/images"

const images = getImages(
    import.meta.glob("../../semaphore/*.png", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../semaphore/",
)

export const merge = (...objs) => Object.assign({}, ...objs)

export const semaphoreCss = { display: "block", borderRadius: "50%" }
const semaphoreWarnCss = { backgroundColor: "gold" }
const semaphoreDangerCss = { backgroundColor: "red" }
const semaphoreRedCss = { backgroundColor: "red" }
const semaphoreYellowCss = { backgroundColor: "yellow" }
const semaphoreOrangeCss = { backgroundColor: "orange" }
const semaphoreDangerEteroCss = {
    backgroundColor: "white",
    border: "2px solid red",
}
const semaphoreGoodCss = { backgroundColor: "green" }
const semaphorePowerCss = { backgroundColor: "blue" }
const semaphoreEnduranceCss = { backgroundColor: "purple" }

export const semaphoreTypeCss = {
    warn: semaphoreWarnCss,
    danger: semaphoreDangerCss,
    dangeretero: semaphoreDangerEteroCss,
    good: semaphoreGoodCss,
    power: semaphorePowerCss,
    endurance: semaphoreEnduranceCss,
    red: semaphoreRedCss,
    yellow: semaphoreYellowCss,
    orange: semaphoreOrangeCss,
    normal: {},
}

const Semaphore = ({ type, value, size }) => {
    if (type === "icon") {
        if (!["raise", "lower"].includes(value)) return null
        const image = images[value]
        if (!image) return null

        const style = merge({ width: size, height: size }, semaphoreCss)
        return (
            <span style={style}>
                <img src={image} />
            </span>
        )
    } else {
        if (
            ![
                "warn",
                "danger",
                "dangeretero",
                "good",
                "power",
                "endurance",
                "orange",
                "yellow",
                "red",
            ].includes(value)
        )
            return null
        const style = merge(
            { width: size, height: size },
            semaphoreCss,
            semaphoreTypeCss[value],
        )
        return <span style={style}>&nbsp;</span>
    }
}
export default Semaphore
