import React, { useEffect } from "react"
import { range } from "ramda"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import SectionHeader from "../shared/components/section-header"
import InlineSearch from "../shared/components/inline-search"
import PaginatedTable from "../shared/components/paginated-table"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import ButtonBar from "../shared/components/button-bar"

const Component = (props) => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { genes, isLoading, pageNumber, totalPages, pattern } = state
    const { history } = props
    const pages = range(1, totalPages + 1)

    useEffect(() => {
        if (!genes || genes.length === 0) {
            if (pattern.length >= 3) api.search(pattern, pageNumber)
            if (pattern.length == 0) api.loadAll(pageNumber)
        }
    }, [])

    const onChangePage = (e, pageNumber) => {
        if (pattern.length >= 3) api.search(pattern, pageNumber)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const onChangeSearch = (e) => {
        const pattern = e.target.value
        if (pattern.length >= 3) api.search(pattern)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const addClickHandler = (e) => {
        e.preventDefault()
        history.push(routes.genes.index.routes.add.url)
    }

    const head = {
        cells: [
            {
                key: "code",
                content: "Gene",
                width: 30,
            },
            {
                key: "genoType",
                content: "GenoTypes",
                width: 70,
            },
        ],
    }

    const mkEditUrl = (item) => {
        const { code } = item
        return routes.genes.index.routes.edit.buildUrl(code)
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.code}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: mkEditUrl(item),
                                    state: item,
                                }}>
                                {item.code}
                            </Link>
                        ),
                    },
                    {
                        content: item.variants
                            .map((i) => i.genoType)
                            .join(", "),
                    },
                ],
            }
        })
    }

    return (
        <>
            <SectionHeader title="Genes list" />
            <div className="row">
                <div className="col-4">
                    <InlineSearch onChange={onChangeSearch} pattern={pattern} />
                </div>
                {/*
                    NOTE: aggiunto form-group perché da margin bottom
                          Per allineare e centrare il button è una classe necessaria
                          dato che anche InlineSearch la usa.
                    TODO: Estrarre il <div className="form-group"> interno a InlineSearch
                          così da utilizzarlo qui nel div parent di InlineSearch e Button
                */}
                <div className="form-group col-4 btn-alongside-center">
                    <Button appearance="primary" onClick={addClickHandler}>
                        Add Gene
                    </Button>
                </div>
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(genes)}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
            />

            <ButtonBar>
                <Button appearance="primary" onClick={addClickHandler}>
                    Add Gene
                </Button>
            </ButtonBar>
        </>
    )
}

export default withRouter(Component)
