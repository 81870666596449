import { apis } from "../apis"
import { actions } from "./store"
import { setItem, removeItems } from "./storage"

export function mkApi(dispatch) {
    return {
        login(email, password) {
            return apis.security.login(email, password).then((user) => {
                if (user && user.token) {
                    setItem("user", user)
                    dispatch(actions.login(user))
                } else {
                    removeItems("user")
                    dispatch(
                        actions.loginError(user.error || "Wrong credentials"),
                    )
                }
                return user
            })
        },
        logout() {
            removeItems("user")
            dispatch(actions.logout())
        },
        validate(currentUser) {
            return apis.security.validate(currentUser).then((user) => {
                if (user && user.email) {
                    setItem("user", currentUser)
                    dispatch(actions.login(currentUser))
                } else {
                    removeItems("user")
                    dispatch(actions.tokenExpired())
                }
                return user
            })
        },
        changePassword(email, currentPassword, nextPassword) {
            return apis.security.changePassword(
                email,
                currentPassword,
                nextPassword,
            )
        },
    }
}
