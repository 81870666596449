import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import SuggestTextField from "../shared/components/suggesttextfield"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import LocalizedTextField from "../shared/components/localized-textfield"
import RankedRangeListEdit from "./ranked-range-list-edit"
import { hormonSchema } from "../hormones/schemas"

const SectionSummaryHormonesEdit = ({
    section,
    onSave,
    hormones = [],
    onCancel,
}) => {
    const model = {
        ...section,
        ranks: {
            male: section.ranks.male.map(b64DecodeUnicode),
            female: section.ranks.female.map(b64DecodeUnicode),
        },
    }

    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        numerator: yup.object().shape({
            male: hormonSchema(hormonesSuggestions),
            female: hormonSchema(hormonesSuggestions),
        }),
        denominator: yup.object().shape({
            male: hormonSchema(hormonesSuggestions),
            female: hormonSchema(hormonesSuggestions),
        }),
        ranks: yup.object().shape({
            male: yup.array().min(1).of(yup.string().required()),
            female: yup.array().min(1).of(yup.string().required()),
        }),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        ranks: {
                            male: formist.values.ranks.male.map(
                                b64EncodeUnicode,
                            ),
                            female: formist.values.ranks.female.map(
                                b64EncodeUnicode,
                            ),
                        },
                    })
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <h3>Man</h3>
            </div>
            <div className="form-row">
                <SuggestTextField
                    suggestions={hormonesSuggestions}
                    label="Numerator Hormon"
                    width="col"
                    {...formist.getFieldProps("numerator.male")}
                    onSelected={(b) =>
                        formist.getFieldProps("numerator.male").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <SuggestTextField
                    suggestions={hormonesSuggestions}
                    label="Denominator Hormon"
                    width="col"
                    {...formist.getFieldProps("denominator.male")}
                    onSelected={(b) =>
                        formist.getFieldProps("denominator.male").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks.male"),
                    }}
                    errors={formist.getFieldProps("ranks.male").error}
                />
            </div>
            <div className="form-row">
                <h3>Woman</h3>
            </div>
            <div className="form-row">
                <SuggestTextField
                    suggestions={hormonesSuggestions}
                    label="Numerator Hormon"
                    width="col"
                    {...formist.getFieldProps("numerator.female")}
                    onSelected={(b) =>
                        formist.getFieldProps("numerator.female").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <SuggestTextField
                    suggestions={hormonesSuggestions}
                    label="Denominator Hormon"
                    width="col"
                    {...formist.getFieldProps("denominator.female")}
                    onSelected={(b) =>
                        formist.getFieldProps("denominator.female").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks.female"),
                    }}
                    errors={formist.getFieldProps("ranks.female").error}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionSummaryHormonesEdit
