import { match } from "../../shared/store"

export const initialState = {
    analyses: [],
    isLoading: true,
    error: null,
    type: "",
    referral: "",
    patient: "",
    pattern: "",
}

export const reducer = (state, action) => {
    return match(action)
        .case("FETCH-SUCCESS", handleFetchSuccess)
        .case("FETCH-ERROR", handleFetchError)
        .apply(state)
}

export const actions = {
    load(data) {
        return { type: "FETCH-SUCCESS", data }
    },
    error(error) {
        return { type: "FETCH-ERROR", error }
    },
}

const handleFetchSuccess = (state, action) => {
    const { items, pagination, type, referral, patient, pattern } = action.data
    return {
        isLoading: false,
        analyses: items,
        type,
        referral,
        patient,
        pattern: pattern || "",
        ...pagination,
    }
}

const handleFetchError = (state, action) => {
    const { error } = action
    return {
        isLoading: false,
        analyses: [],
        error,
    }
}
