import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.bacteria
                .loadAll(pageNumber, pageSize)
                .then((bacteria) => dispatch(actions.loadAll(bacteria)))
        },
        load(id) {
            return apis.bacteria.load(id)
        },
        loadAnalysis(id, filter) {
            return apis.bacteria.loadAnalysis(id, filter)
        },
        update(value, isNew = false, reload = false) {
            const { level, group, ...body } = value
            const code = btoa(`${level};${group}`)
            return apis.bacteria
                .updateOne(code, body)
                .then(() =>
                    dispatch(actions[isNew ? "add" : "edit"](value, reload)),
                )
        },
        search(pattern, pageNumber, pageSize) {
            return apis.bacteria
                .search(pattern, pageNumber, pageSize)
                .then((bacteria) => {
                    dispatch(actions.search({ pattern, ...bacteria }))
                    return bacteria
                })
        },
        remove(id) {
            return apis.bacteria.remove(id)
        },
        clone(code, newCode) {
            return apis.bacteria.clone(code, newCode)
        },
    }
}
