import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import SectionListHormonesValuesEdit from "./section-list-hormonesvalues-edit"

import LocalizedTextField from "../shared/components/localized-textfield"
import RankedRangeListEdit from "./ranked-range-list-edit"
import { hormonesSchema } from "../hormones/schemas"
import {
    SectionHormonesDetailsWithSpecifierEdit,
    getHormonesDetails,
    decodeHormonWithSpecifiers,
    saveHormonesWithSpecifiers,
} from "./section-hormonesdetails-edit"

const SectionHormonesChartEdit = ({
    section,
    onSave,
    onCancel,
    hormones = [],
}) => {
    const decodedHormones = section.hormones
        .map(b64DecodeUnicode)
        .map(decodeHormonWithSpecifiers)
    const model = {
        ...section,
        ranks: section.ranks.map(b64DecodeUnicode),
        hormones: decodedHormones.map((h) => h.code),
        hormonesDetails: getHormonesDetails(decodedHormones),
    }

    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        ranks: yup.array().min(1).of(yup.string().required()),
        hormones: hormonesSchema(hormonesSuggestions),
        hormonesDetails: yup.object(),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                        hormones: saveHormonesWithSpecifiers(
                            formist.values.hormones,
                            formist.values.hormonesDetails,
                        ),
                    })
                }
            })
            .catch(() => {})
    }

    const actualHormones = (
        formist.getFieldProps("hormones").value || []
    ).filter((h) => hormonesSuggestions.indexOf(h) !== -1)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <h3>Hormones</h3>
            <SectionListHormonesValuesEdit
                hormonesField={formist.getFieldProps("hormones")}
                errors={formist.errors}
                suggestions={hormonesSuggestions}
            />
            <SectionHormonesDetailsWithSpecifierEdit
                details={formist.getFieldProps("hormonesDetails")}
                hormones={actualHormones}
            />
            <h4>Final Points</h4>
            <div className="form-row">
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks"),
                    }}
                    errors={formist.getFieldProps("ranks").error}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionHormonesChartEdit
