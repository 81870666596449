import React from "react"
import withFetch from "../shared/components/fetch"
import { mkApi } from "./api"
import useFormist from "react-formist"
import { routes } from "../../shared/routing"
import { success } from "../../shared/notifications"
import Form from "../shared/components/form"
import EmailsFields, { toForm, toModel } from "./emails-fields"
import { toIta } from "../../shared/dates"

const EditEmails = ({ match, history, data }) => {
    const api = mkApi()
    const barcode = match.params.id
    const geneticAnalysis = data[0]
    const initialData = toForm(geneticAnalysis)

    const formist = useFormist(initialData, {
        onSubmit: (formData) => {
            const data = toModel(formData)
            api.sendApprovedEmail(barcode, data).then(() => {
                success("Successfully sent emails")
                history.push(routes.geneticAnalyses.edit.buildUrl(barcode))
            })
        },
    })

    const onCancel = (e) => {
        e.preventDefault()
        history.push(routes.geneticAnalyses.edit.buildUrl(barcode))
    }

    return (
        <Form
            {...formist.getFormProps()}
            primaryText="Send"
            title={`Genetic Analysis (${barcode} - ${toIta(
                geneticAnalysis.registeredDate,
            )})`}
            onCancel={onCancel}>
            <EmailsFields formist={formist} />
        </Form>
    )
}

const loadData = ({ api, id }) => {
    return api.loadFull(id)
}

export default withFetch(EditEmails, mkApi(), loadData)
