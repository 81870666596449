import React, { useEffect, useState } from "react"
import { range } from "ramda"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import SectionHeader from "../shared/components/section-header"
import InlineSearch from "../shared/components/inline-search"
import PaginatedTable from "../shared/components/paginated-table"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import ModalDialog from "@atlaskit/modal-dialog"
import ButtonBar from "../shared/components/button-bar"
import Form from "../shared/components/form"
import { success, error } from "../../shared/notifications"

const head = {
    cells: [
        {
            key: "lastName",
            content: "Last name",
        },
        {
            key: "fisrtName",
            content: "First name",
        },
        {
            key: "email",
            content: "Email",
        },
        {
            key: "actions",
        },
    ],
}

const Patients = (props) => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { patients, isLoading, pageNumber, totalPages, pattern } = state
    const { history } = props
    const pages = range(1, totalPages + 1)

    const [removingPatient, setRemovingPatient] = useState(null)

    useEffect(() => {
        if (!patients || patients.length === 0) {
            if (pattern.length >= 3) api.search(pattern, pageNumber)
            if (pattern.length == 0) api.loadAll(pageNumber)
        }
    }, [])

    const onChangePage = (e, pageNumber) => {
        if (pattern.length >= 3) api.search(pattern, pageNumber)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const onChangeSearch = (e) => {
        const pattern = e.target.value
        if (pattern.length >= 3) api.search(pattern)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const addClickHandler = (e) => {
        e.preventDefault()
        history.push(routes.patients.index.routes.add.url)
    }

    const showError = (e) => {
        let ex = e
        if (typeof e === "string") {
            try {
                ex = JSON.parse(e)
            } catch (e) {
                ex = {
                    Message: "Error in removal",
                    StackTrace: "",
                }
            }
        }
        if (ex && ex.Message) {
            error(ex.Message, {
                dismiss: false,
            })
            console.error(ex.StackTrace)
        }
    }

    const confirmRemovePatient = () => {
        api.removePatient(removingPatient.id)
            .then((result) => {
                if (result && !result.error) {
                    success("Patient successfully removed!")
                    setRemovingPatient(null)
                    api.loadAll(pageNumber)
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    const mkEditUrl = (item) => {
        return routes.patients.index.routes.edit.buildUrl(item.id)
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            const lastName =
                !item.lastName || item.lastName.trim() === ""
                    ? "Anonymous"
                    : item.lastName
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: mkEditUrl(item),
                                    state: item,
                                }}>
                                {lastName}
                            </Link>
                        ),
                    },
                    { content: item.firstName },
                    { content: item.email },
                    {
                        content: (
                            <Button
                                appearance="primary"
                                onClick={() => setRemovingPatient(item)}>
                                Remove
                            </Button>
                        ),
                    },
                ],
            }
        })
    }

    return (
        <>
            <SectionHeader title="Patients list" />
            <div className="row">
                <div className="col-4">
                    <InlineSearch onChange={onChangeSearch} pattern={pattern} />
                </div>
                {/*
                    NOTE: aggiunto form-group perché da margin bottom
                          Per allineare e centrare il button è una classe necessaria
                          dato che anche InlineSearch la usa.
                    TODO: Estrarre il <div className="form-group"> interno a InlineSearch
                          così da utilizzarlo qui nel div parent di InlineSearch e Button
                */}
                <div
                    className="form-group col-4 btn-alongside-center"
                    style={{ display: "none" }}>
                    <Button appearance="primary" onClick={addClickHandler}>
                        Add Patient
                    </Button>
                </div>
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(patients)}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
            />
            <ButtonBar>
                <Button
                    appearance="primary"
                    onClick={addClickHandler}
                    style={{ display: "none" }}>
                    Add Patient
                </Button>
            </ButtonBar>
            {removingPatient && (
                <ModalDialog
                    id="remove-patient-dialog"
                    heading="Remove Patient">
                    <Form
                        title=""
                        size="full"
                        buttons={[
                            <Button
                                key="confirm"
                                appearance="primary"
                                onClick={confirmRemovePatient}>
                                Remove
                            </Button>,
                            "cancel",
                        ]}
                        primaryText="Remove"
                        onCancel={() => setRemovingPatient(null)}>
                        <div className="form-row">
                            Are you sure you want to remove patient{" "}
                            {removingPatient.firstName}{" "}
                            {removingPatient.lastName} ({removingPatient.email}
                            )? Note: If there are any analyses associated, the
                            patient will not be removed.
                        </div>
                        <div className="form-row">&nbsp;</div>
                    </Form>
                </ModalDialog>
            )}
        </>
    )
}

export default withRouter(Patients)
