import React, { useEffect, useRef, useState } from "react"
import Button from "@atlaskit/button"
import { isFunction } from "../../../shared/fun"
import ChevronUpIcon from "@atlaskit/icon/glyph/chevron-up"
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down"
import LocalizedTextField from "./localized-textfield"

const buttonsStyle = {
    width: "32px",
    marginLeft: "20px",
}

const newRowStyle = {
    backgroundColor: "#AAA",
    height: "55px",
}

const headerRow = (schema) => {
    return schema.map((f) => (
        <th key={"header-" + f.field}>
            <label>{f.label}</label>
        </th>
    ))
}

const toArray = (items) => {
    let arr
    if (items instanceof Array) arr = items
    else if (typeof items === "string" || items instanceof String) arr = [items]
    else arr = Object.values(items)
    return arr.filter((i) => i)
}

const TableField = ({
    label,
    width,
    value,
    schema,
    onChange,
    errors = [],
    mode = "objects",
    editable = false,
    moveRows = false,
}) => {
    const [rows, setRows] = useState(value)
    useEffect(() => {
        setRows(value)
    }, [value])
    const isList = mode === "list"

    const newRowFields = useRef({})
    const renderOptions = (options) =>
        options.map((o) => (
            <option key={o.value} value={o.value}>
                {o.label}
            </option>
        ))
    const renderNewField = (field) => {
        const type = field.type || "text"
        switch (type) {
            case "select": {
                return (
                    <select
                        style={{ width: (field.width || 100) + "px" }}
                        ref={(el) => (newRowFields.current[field.field] = el)}>
                        {renderOptions(field.options)}
                    </select>
                )
            }
            case "expression": {
                return (
                    <textarea
                        style={{ width: (field.width || 500) + "px" }}
                        ref={(el) => (newRowFields.current[field.field] = el)}
                    />
                )
            }
            case "label": {
                return <span />
            }
            default:
                return (
                    <input
                        style={{ width: (field.width || 100) + "px" }}
                        ref={(el) => (newRowFields.current[field.field] = el)}
                    />
                )
        }
    }
    const newRow = (schema) => {
        return schema.map((f) => (
            <td key={"newrow-" + f.field}>{renderNewField(f)}</td>
        ))
    }
    const updateDirtyField = (row, field, value) => {
        const newRows = rows.map((r, idx) =>
            idx !== row ? r : isList ? value : { ...r, [field]: value },
        )
        onChangeInner(newRows)
    }
    const renderField = (field, data, row) => {
        const type = field.type || "text"
        switch (type) {
            case "select": {
                return (
                    <select
                        style={{ width: (field.width || 100) + "px" }}
                        value={data}
                        onChange={(e) =>
                            updateDirtyField(row, field.field, e.target.value)
                        }>
                        {renderOptions(field.options)}
                    </select>
                )
            }
            case "expression": {
                return (
                    <textarea
                        style={{ width: (field.width || 500) + "px" }}
                        value={data}
                        onChange={(e) =>
                            updateDirtyField(row, field.field, e.target.value)
                        }
                        rows={3}
                    />
                )
            }
            default:
                return field.localized ? (
                    <LocalizedTextField
                        style={{ width: (field.width || 100) + "px" }}
                        value={data}
                        onChange={(e) =>
                            updateDirtyField(row, field.field, e.target.value)
                        }
                    />
                ) : (
                    <input
                        style={{ width: (field.width || 100) + "px" }}
                        value={data}
                        onChange={(e) =>
                            updateDirtyField(row, field.field, e.target.value)
                        }
                    />
                )
        }
    }
    const getFieldValue = (row, f) =>
        isList ? row : isFunction(f.field) ? f.field(row) : row[f.field]
    const dataRow = (schema, data, row) => {
        return schema.map((f) => (
            <td key={"row-" + f.field}>
                {editable && f.type !== "label" ? (
                    renderField(f, getFieldValue(data, f), row)
                ) : (
                    <span>{getFieldValue(data, f)}</span>
                )}
            </td>
        ))
    }
    const onChangeInner = (newValue) => {
        onChange({
            target: {
                value: newValue,
            },
        })
        setRows(newValue)
    }
    const getNewInputValue = (field) => newRowFields.current[field].value
    const getSimpleNewRow = () => getNewInputValue(schema[0].field)
    const addRow = () => {
        const newRow = isList
            ? getSimpleNewRow()
            : schema
                  .filter((f) => f.type !== "label")
                  .reduce(
                      (row, f) => ({
                          ...row,
                          [f.field]: getNewInputValue(f.field),
                      }),
                      {},
                  )
        Object.values(newRowFields.current).forEach((el) => {
            el.value = ""
        })
        const newRows = [...rows, newRow]
        onChangeInner(newRows)
    }

    const removeRow = (row) => {
        const newRows = rows.filter((r, idx) => idx !== row)
        onChangeInner(newRows)
    }
    const moveDown = (row) => {
        const newRows = rows.map((r, idx) => {
            if (idx === row) {
                return rows[row + 1]
            }
            if (idx === row + 1) {
                return rows[row]
            }
            return r
        })
        onChangeInner(newRows)
    }
    const moveUp = (row) => {
        const newRows = rows.map((r, idx) => {
            if (idx === row) {
                return rows[row - 1]
            }
            if (idx === row - 1) {
                return rows[row]
            }
            return r
        })
        onChangeInner(newRows)
    }
    const moveRowsStyle = (cond) => ({ color: cond ? "white" : "#888" })
    return (
        <div className={`form-group ${width}`}>
            <h3>{label}</h3>
            <table className="tablefield">
                <thead>
                    <tr>
                        {headerRow(schema)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={newRowStyle}>
                        {newRow(schema)}
                        <td>
                            <Button
                                style={buttonsStyle}
                                appearance="primary"
                                onClick={addRow}>
                                +
                            </Button>
                        </td>
                    </tr>
                    {rows.map((row, idx) => (
                        <tr key={"row-data-" + idx}>
                            {dataRow(schema, row, idx)}
                            <td>
                                <Button
                                    style={buttonsStyle}
                                    appearance="primary"
                                    onClick={() => removeRow(idx)}>
                                    x
                                </Button>
                                {moveRows && (
                                    <>
                                        <Button
                                            onClick={() => moveDown(idx)}
                                            data-testId="move-down"
                                            isDisabled={
                                                idx === rows.length - 1
                                            }>
                                            <span
                                                style={moveRowsStyle(
                                                    idx === rows.length - 1,
                                                )}>
                                                <ChevronDownIcon />
                                            </span>
                                        </Button>
                                        <Button
                                            onClick={() => moveUp(idx)}
                                            data-testId="move-up"
                                            isDisabled={idx === 0}>
                                            <span
                                                style={moveRowsStyle(
                                                    idx === 0,
                                                )}>
                                                <ChevronUpIcon />
                                            </span>
                                        </Button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <small className="text-danger">{toArray(errors).join(", ")}</small>
        </div>
    )
}

const rowError = (row) => {
    if (typeof row === "string") {
        return row
    }
    return Object.keys(row)
        .reduce((acc, current) => {
            return [...acc, row[current]]
        }, [])
        .join(", ")
}

export const printableErrors = (formErrors, name) => {
    if (formErrors[name]) {
        const errorsList = toArray(formErrors[name])
        return errorsList.reduce((errors, row, idx) => {
            if (row) {
                return [...errors, "Row " + (idx + 1) + ": " + rowError(row)]
            }
            return errors
        }, [])
    }
    return []
}

export default TableField
