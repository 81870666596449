import React from "react"
import { withRouter } from "react-router"
import Wizard from "../../views/shared/components/wizard"
import useFormist from "react-formist"
import { getItem } from "./storage"
import { apis } from "../../shared/apis"
import { routes } from "../../shared/routing"
import { mergeItem } from "./storage"
import { undo } from "./form"
import { diseasesSchema as schema } from "../patients/schemas"
import PatientDiseasesForm, {
    detailsToModel,
    modelToDetails,
} from "../patients/patient-diseases"

const RegisterPatientDiseases = ({ history, match }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)

    const initialData = detailsToModel(geneticAnalysis.patientDetails.diseases)
    const formist = useFormist(initialData, {
        schema,
        onSubmit: (diseases) => {
            const data = { groups: modelToDetails(diseases) }
            apis.patients.diseases
                .updateOne(geneticAnalysis.patientId, barcode, data)
                .then(() => {
                    mergeItem(barcode, {
                        patientDetails: {
                            ...geneticAnalysis.patientDetails,
                            diseases: data,
                        },
                    })
                    history.push(
                        routes.geneticAnalyses.registerPatientDrugs.buildUrl(
                            barcode,
                        ),
                    )
                })
        },
    })

    return (
        <>
            <Wizard
                title={`Diseases/Dysfunctions for: ${barcode}`}
                onUndo={undo(history, barcode)}
                onPrevious={() => history.goBack()}
                {...formist.getFormProps()}>
                <PatientDiseasesForm formist={formist} />
            </Wizard>
        </>
    )
}

export default withRouter(RegisterPatientDiseases)
