import React, { useState } from "react"
import { getLocalizedText } from "../../shared/locale"
import "./ratio.scss"
import Legend from "../shared/components/legend"
import Ratio, { SummaryRatio } from "../shared/components/ratio"

const formatNumber = (num, decimals) => Number(num).toFixed(decimals)

function getRatioType(type) {
    if (type.indexOf("dynamic:") !== -1) {
        const [, ratioType, ratioStyle = "simple"] = type.split(":")
        return [true, ratioType, ratioStyle]
    }
    return [false, type]
}

const ChartRatio = ({
    numerator,
    denominator,
    ratioType = "",
    language,
    subtitle,
    description,
    notes,
    ranks = [],
    points = [],
}) => {
    const [dynamic, type, ratioStyle] = getRatioType(ratioType)

    const ratio =
        denominator.value === 0 ? 0 : numerator.value / denominator.value

    const id =
        type +
        "_" +
        numerator.name.toLowerCase() +
        "_" +
        denominator.name.toLowerCase()

    return (
        <div>
            <div className="section-ratio-header">
                <h1>{getLocalizedText(name, language)}</h1>
                <h2>{getLocalizedText(subtitle, language)}</h2>
                <span>{formatNumber(ratio, 2)}</span>
            </div>
            <div className="section-ratio-chart">
                <span className="ratio-numerator">
                    {getLocalizedText(numerator.name, language)}
                </span>
                <span className="ratio-denominator">
                    {getLocalizedText(denominator.name, language)}
                </span>
                {dynamic ? (
                    <Ratio
                        id={id}
                        ratioStyle={ratioStyle}
                        language={language}
                        numerator={numerator}
                        denominator={denominator}
                        ranks={ranks}
                        points={points}
                        indexLegend={notes}
                    />
                ) : null}
                {ranks.length > 0 ? (
                    <Legend items={ranks} language={language} />
                ) : null}
            </div>
            <p>{getLocalizedText(description, language)}</p>
        </div>
    )
}

const SectionRatio = ({
    item,
    style,
    language,
    editable = false,
    onSave,
    onReset,
}) => {
    const [currentNumerator, setCurrentNumerator] = useState(
        item?.numerator?.value || 0,
    )
    const {
        numerator = { value: 0, name: "" },
        denominator = { value: 0, name: "" },
        rank = "neutral",
        ratioType = "",
    } = item

    const legendItems = [
        {
            rank,
            label: numerator.name,
        },
        {
            rank: "neutral",
            label: denominator.name,
        },
    ]
    const [, type] = getRatioType(ratioType)

    function saveValue(value) {
        const newValue = Number(value)
        if (Number.isInteger(newValue) && newValue !== currentNumerator) {
            setCurrentNumerator(newValue)
            onSave({
                ...item,
                numerator: { ...numerator, value },
            })
        }
    }

    function resetValue() {
        onReset(item)
    }

    return (
        <div
            className={`section-ratio section-ratio-${type}`}
            data-testid="section-ratio"
            style={style}>
            {ratioType === "Summary" ? (
                <>
                    <SummaryRatio
                        numerator={numerator}
                        denominator={denominator}
                        language={language}
                        rank={rank}
                        editable={editable}
                        onSave={saveValue}
                        onReset={resetValue}
                    />
                    <Legend items={legendItems} language={language} />
                </>
            ) : (
                <ChartRatio
                    {...item}
                    numerator={numerator}
                    denominator={denominator}
                    language={language}
                    editable={editable}
                />
            )}
        </div>
    )
}

export default SectionRatio
