import dayjs from "dayjs"
import { match } from "../../shared/store"

export const initialState = {
    analyses: [],
    isLoading: true,
    error: null,
    reporter: "",
    type: "",
    referral: "",
    patient: "",
    pattern: "",
    from: dayjs().startOf("year").format("DD/MM/YYYY"),
}

export const reducer = (state, action) => {
    return match(action)
        .case("FETCH-SUCCESS", handleFetchSuccess)
        .case("FETCH-ERROR", handleFetchError)
        .apply(state)
}

export const actions = {
    load(data) {
        return { type: "FETCH-SUCCESS", data }
    },
    error(error) {
        return { type: "FETCH-ERROR", error }
    },
}

const handleFetchSuccess = (state, action) => {
    const {
        items,
        pagination,
        reporter,
        type,
        referral,
        patient,
        from,
        pattern,
    } = action.data
    return {
        isLoading: false,
        analyses: items,
        reporter,
        type,
        referral,
        patient,
        from,
        pattern: pattern || "",
        ...pagination,
    }
}

const handleFetchError = (state, action) => {
    const { error } = action
    return {
        isLoading: false,
        analyses: [],
        error,
    }
}
