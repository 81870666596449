import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    pattern: "",
    genes: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-GENES", handleLoadGenes)
        .case("SEARCH-GENES", handleSearchGenes)
        .case("EDIT-GENE", handleEditGene)
        .case("ADD-GENE", handleAddGene)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-GENES", data }
    },
    search(data) {
        return { type: "SEARCH-GENES", data }
    },
    edit(data) {
        return { type: "EDIT-GENE", data }
    },
    add(data) {
        return { type: "ADD-GENE", data }
    },
}

const handleLoadGenes = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        genes: items,
        pattern: "",
        ...pagination,
    }
}

const handleSearchGenes = (state, action) => {
    const { items, pagination, pattern } = action.data
    return {
        isLoading: false,
        genes: items,
        pattern,
        ...pagination,
    }
}

const handleEditGene = (state, action) => {
    const { genes } = state
    const { code, ...other } = action.data
    const clearCode = atob(code)

    const newGenes = genes.map((g) =>
        g.code === clearCode ? { code: clearCode, ...other } : g,
    )
    return {
        genes: newGenes,
    }
}

const handleAddGene = (state, action) => {
    const { genes } = state
    const { code, ...other } = action.data
    const clearCode = atob(code)

    const newGenes = [{ code: clearCode, ...other }, ...genes]
    return {
        genes: newGenes,
    }
}
