import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    layouts: [],
    filter: {},
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-LAYOUTS", handleLoadLayouts)
        .case("LOAD-FILTERED-LAYOUTS", handleLoadFilteredLayouts)
        .apply(state)
}

export const actions = {
    load(data) {
        return { type: "LOAD-LAYOUTS", data }
    },
    loadFiltered(data, filter) {
        return { type: "LOAD-FILTERED-LAYOUTS", data, filter }
    },
}

const handleLoadLayouts = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        layouts: items,
        filter: {},
        ...pagination,
    }
}

const handleLoadFilteredLayouts = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        layouts: items,
        filter: action.filter,
        ...pagination,
    }
}
