import React from "react"
import RankBar from "./rankbar"
import Semaphore from "./semaphore"
import { getLocalizedText } from "../../shared/locale"
import { getImages } from "../../shared/images"

const images = getImages(
    import.meta.glob("../../symbols/*.png", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../symbols/",
)
const svgImages = getImages(
    import.meta.glob("../../symbols/*.svg", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../symbols/",
)

function createHeaders(columns, language) {
    return columns.map((c, idx) => (
        <th key={idx} className={`r-table-col ${getColumnStyle(c.type)}`}>
            {getLocalizedText(c.label, language)}
        </th>
    ))
}

function getChartRank(range) {
    const [low, high, rank] = range.split("-")
    return {
        low,
        high,
        rank,
    }
}

function getChartRanks(ranges) {
    return ranges.split(",").map((r, i) => getChartRank(r, i))
}

function renderColumn(column, fields, tableType, language) {
    switch (column.type) {
        case "text":
        case "numberastext":
            return getLocalizedText(fields[column.field], language)
        case "styledtext": {
            const [text, style] = fields[column.field].split("*")
            return (
                <span className={style}>
                    {getLocalizedText(text, language)}
                </span>
            )
        }
        case "number": {
            const value = Number(fields[column.field])
            return value.toFixed(2)
        }
        case "numberinrange": {
            const parts = fields[column.field].split("#")
            const min = Number(parts[0])
            const max = Number(parts[1])
            const value = Number(parts[2])
            if (value < min) return `<${min.toFixed(2)}`
            if (value > max) return `>${max.toFixed(2)}`
            return value.toFixed(2)
        }
        case "chart": {
            const [valueField, rangesField, rankField, specifierField] =
                column.field.split(",")
            const value = Number(fields[valueField])
            const ranks = getChartRanks(fields[rangesField])
            const rank = fields[rankField]
            const specifier = fields[specifierField] || ""
            return (
                <RankBar
                    value={value}
                    ranks={ranks}
                    rank={rank}
                    specifier={specifier}
                    type={tableType}
                    name={fields["Code"]}
                />
            )
        }
        case "icon": {
            const [semaphoreType, icon] = fields[column.field].split(".")
            return <Semaphore type={semaphoreType} value={icon} size={15} />
        }
        case "image": {
            const symbol = fields[column.field]
            return svgImages["symbol-" + symbol + "_" + language] ? (
                <object
                    data={svgImages["symbol-" + symbol + "_" + language]}
                    className="section-table-symbol"
                />
            ) : svgImages["symbol-" + symbol] ? (
                <object
                    data={svgImages["symbol-" + symbol]}
                    className="section-table-symbol"
                />
            ) : images["symbol-" + symbol + "_" + language] ? (
                <img src={images["symbol-" + symbol + "_" + language]} />
            ) : (
                <img src={images["symbol-" + symbol]} />
            )
        }
        default:
            return `Unknown column type: ${column.type}`
    }
}

function getColumnStyle(type) {
    switch (type) {
        case "number":
        case "numberastext":
            return "r-text-right"
        default:
            return "r-text-left"
    }
}

function createRow(row, columns, tableType, language) {
    return columns.map((c, index) => (
        <td key={index} className={`r-table-col ${getColumnStyle(c.type)}`}>
            {renderColumn(c, row.fields, tableType, language)}
        </td>
    ))
}

export default function SectionTable({ item, language, style = {} }) {
    const {
        name,
        description,
        tableType = "",
        columns = [],
        rows = [],
        note = "",
        flip = false,
    } = item

    const headers = createHeaders(columns, language)

    return (
        <div
            className={`section-table section-table-${tableType}`}
            style={style}>
            <div data-testid="section-table" style={{ display: "flex" }}>
                <div>
                    <h1 className="h2 mt-3">
                        {getLocalizedText(name, language)}
                    </h1>
                    <p>{getLocalizedText(description, language)}</p>
                </div>
            </div>
            {flip ? (
                <table className="r-table">
                    <tbody>
                        {columns.map((c, indexColumn) => (
                            <tr key={indexColumn}>
                                {headers[indexColumn]}
                                {rows.map((r, indexRow) => (
                                    <td
                                        key={`${indexColumn}-${indexRow}`}
                                        className={`r-table-col ${getColumnStyle(
                                            c.type,
                                        )}`}>
                                        {renderColumn(
                                            c,
                                            r.fields,
                                            tableType,
                                            language,
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <table className="r-table">
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        {rows.map((r, index) => (
                            <tr key={index}>
                                {createRow(r, columns, tableType, language)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {note ? (
                <p className="section-table-note">
                    {getLocalizedText(note, language)}
                </p>
            ) : null}
        </div>
    )
}
