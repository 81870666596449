import React from "react"
import StackedChart from "./stacked-chart"
import { getLocalizedText } from "../../shared/locale"

export default function SectionStackedChart({ item, style, language }) {
    const { name, withLegend, charts = [] } = item

    return (
        <div data-testid="section-stackedchart" style={style}>
            {name ? (
                <div className="section-stackedchart-title">
                    <h1 className="h2 mt-3 r-title">
                        {getLocalizedText(name, language)}
                    </h1>
                    <hr />
                </div>
            ) : null}

            <div className="r-row">
                <div className="r-col-60">
                    <StackedChart
                        data={charts}
                        showLegend={withLegend}
                        language={language}
                    />
                </div>
            </div>
        </div>
    )
}
