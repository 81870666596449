export const getEffectType = (value) => {
    if (value >= 0.5 && value <= 1.0) {
        return "negative"
    }
    if (value === 2.0) {
        return "positive"
    }
    if (value === 3.0 || value === 6.0) {
        return "power"
    }
    if (value === 4.0 || value === 8.0) {
        return "endurance"
    }
    return "noEffect"
}

export const addMappers = {
    toApiModel(source) {
        return {
            code: btoa(source.code),
            variants: source.variants.map((v) => ({
                genoType: v.genoType,
                genoTypeFileAlias: v.genoTypeFileAlias,
                name: v.name,
                firstAllele: {
                    allele: v.allele1,
                    effectType: getEffectType(parseFloat(v.alleleeffectvalue1)),
                    effectTypeValue: parseFloat(v.alleleeffectvalue1),
                },
                secondAllele: {
                    allele: v.allele2,
                    effectType: getEffectType(parseFloat(v.alleleeffectvalue2)),
                    effectTypeValue: parseFloat(v.alleleeffectvalue2),
                },
                thirdAllele: {
                    allele: v.allele3,
                    effectType: getEffectType(parseFloat(v.alleleeffectvalue3)),
                    effectTypeValue: parseFloat(v.alleleeffectvalue3),
                },
                fourthAllele: {
                    allele: v.allele4,
                    effectType: getEffectType(parseFloat(v.alleleeffectvalue4)),
                    effectTypeValue: parseFloat(v.alleleeffectvalue4),
                },
            })),
        }
    },
}

export const editMappers = {
    toFormModel(source) {
        return {
            ...source,
            variants: source.variants.map((v) => ({
                genoType: v.genoType,
                genoTypeFileAlias: v.genoTypeFileAlias,
                name: v.name,
                allele1: v.firstAllele.allele,
                alleleeffectvalue1: v.firstAllele.effectTypeValue,
                allele2: v.secondAllele.allele,
                alleleeffectvalue2: v.secondAllele.effectTypeValue,
                allele3: v.thirdAllele.allele,
                alleleeffectvalue3: v.thirdAllele.effectTypeValue,
                allele4: v.fourthAllele.allele,
                alleleeffectvalue4: v.fourthAllele.effectTypeValue,
            })),
        }
    },
    toApiModel(source) {
        return addMappers.toApiModel(source)
    },
}
