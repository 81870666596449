import React, { useState } from "react"
import Button, { ButtonGroup } from "@atlaskit/button"

const ButtonGroupSelection = ({ buttons = [], onClickHandler, style = {} }) => {
    const [selectedSort, setSelectedSort] = useState(0)

    const handleClick = (btn, key) => {
        setSelectedSort(key)
        onClickHandler(btn)
    }

    const buttonComponents = buttons.map((btn, i) => (
        <Button
            key={i}
            isSelected={selectedSort === i}
            onClick={() => handleClick(btn, i)}>
            {btn.label}
        </Button>
    ))

    return (
        <div className="form-group" style={style}>
            <ButtonGroup>{buttonComponents}</ButtonGroup>
        </div>
    )
}

export default ButtonGroupSelection
