import React, { forwardRef } from "react"

// eslint-disable-next-line react/display-name
const TextArea = forwardRef((props, ref) => {
    const {
        label,
        placeholder,
        width,
        value,
        onChange,
        error,
        rows,
        cols,
        ...otherProps
    } = props
    return (
        <div className={`form-group ${width}`}>
            <label>{label}</label>
            <textarea
                className="form-control"
                placeholder={placeholder}
                rows={rows}
                cols={cols}
                value={value}
                onChange={onChange}
                ref={ref}
                {...otherProps}
            />
            <small className="text-danger">{error}</small>
        </div>
    )
})

export default TextArea
