import React from "react"
import { withRouter } from "react-router"
import { getItem, removeItems } from "./storage"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import DefaultFormButtonBar from "../shared/components/default-form-button-bar"
import { undo } from "./form"
import Button from "@atlaskit/button"
import {
    EmailSummary,
    GeneticAnalysisSummary,
    PatientDiseasesSummary,
    PatientDrugsSummary,
    PatientGuardianSummary,
    PatientPhysicalDescriptionSummary,
    PatientSummary,
} from "./summary"

const RegisterSummary = ({ history, match }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)
    const templates = getItem("templates")
    const layouts = getItem("layouts")
    const referrals = getItem("referrals")
    const api = mkApi()

    const onSubmit = (e) => {
        e.preventDefault()
        removeItems(barcode, "templates", "layouts", "patients")
        api.register(geneticAnalysis).then(() =>
            history.push(routes.dashboard.index.url),
        )
    }

    const onCancel = (e) => {
        e.preventDefault()
        history.goBack()
    }

    const onEdit = (section) => (e) => {
        e.preventDefault()
        history.push(routes.geneticAnalyses[section].buildUrl(barcode))
    }

    const onUndo = undo(history, barcode)
    const {
        patientDetails = {},
        patientData,
        patientId,
        templateIds,
        layoutIds,
        analyzed,
        referral,
    } = geneticAnalysis
    const {
        physicalDescription,
        guardian,
        diseases,
        drugs: drugsObj,
    } = patientDetails
    const { drugs, ...additional } = drugsObj || {}
    return (
        <>
            <h1>Registration Summary</h1>
            <div className="col-md-6">
                <GeneticAnalysisSummary
                    barcode={barcode}
                    templateIds={templateIds}
                    layoutIds={layoutIds}
                    layouts={layouts}
                    templates={templates}
                    analyzed={analyzed}
                    referral={referral}
                    referrals={referrals}
                />
                {patientData && (
                    <PatientSummary
                        patientId={patientId}
                        patientData={patientData}
                    />
                )}
                {guardian && (
                    <PatientGuardianSummary
                        onEdit={onEdit("registerPatientGuardian")}
                        patientId={patientId}
                        patientData={guardian}
                    />
                )}
                {physicalDescription && (
                    <PatientPhysicalDescriptionSummary
                        onEdit={onEdit("registerPatientPhysicalDescription")}
                        patientId={patientId}
                        patientData={physicalDescription}
                    />
                )}
                {diseases && (
                    <PatientDiseasesSummary
                        onEdit={onEdit("registerPatientDiseases")}
                        patientId={patientId}
                        patientData={diseases.groups}
                    />
                )}
                {drugsObj && (
                    <PatientDrugsSummary
                        onEdit={onEdit("registerPatientDrugs")}
                        patientId={patientId}
                        patientData={drugs}
                        additionalData={additional}
                    />
                )}
                <EmailSummary geneticAnalysis={geneticAnalysis} />
                <DefaultFormButtonBar
                    primaryText="Save"
                    secondaryText="Previous"
                    buttons={[
                        <Button key="undo" onClick={onUndo}>
                            Undo
                        </Button>,
                        "cancel",
                        "submit",
                    ]}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </div>
        </>
    )
}

export default withRouter(RegisterSummary)
