import React from "react"

export default class StyleButton extends React.Component {
    constructor() {
        super()

        this.onToggle = (e) => {
            e.preventDefault()
            this.props.onToggle(this.props.style)
        }
    }

    render() {
        let style = this.props.buttonStyle || {
            color: "#999",
            cursor: "pointer",
            marginRight: "16px",
            padding: "2px 0",
            display: "inline-block",
        }
        let activeStyle = this.props.activeStyle || {
            color: "#5890ff",
        }
        if (this.props.active) {
            style = { ...style, ...activeStyle }
        }
        return (
            <span style={style} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        )
    }
}
