import React from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Form from "../shared/components/form"
import useFormist from "react-formist"
import EmailsFields, { toForm, toModel } from "./emails-fields"
import { toIta } from "../../shared/dates"

export default function ApproveEmailModal({
    geneticAnalysis,
    enabled = false,
    onConfirm,
    onCancel,
}) {
    const { barcode, registeredDate } = geneticAnalysis
    const initialData = toForm(geneticAnalysis)
    const formist = useFormist(initialData, {
        onSubmit: (formData) => {
            const data = toModel(formData)
            onConfirm(data)
        },
    })

    return enabled ? (
        <ModalDialog
            width="1000px"
            heading={`Send ${barcode} registration emails`}>
            <Form
                {...formist.getFormProps()}
                size="full"
                primaryText="Send"
                title={`Genetic Analysis (${barcode} - ${toIta(
                    registeredDate,
                )})`}
                onCancel={onCancel}>
                <EmailsFields formist={formist} />
            </Form>
        </ModalDialog>
    ) : null
}
