import React from "react"
import DynamicTableStateless from "@atlaskit/dynamic-table"
import Pagination from "@atlaskit/pagination"

const PaginatedTable = ({
    head,
    rows,
    isLoading,
    pages,
    onChangePage,
    pageNumber = 1,
    type = "dashboard",
}) => {
    return (
        <div className={`${type}-table`}>
            <DynamicTableStateless
                head={head}
                rows={rows}
                loadingSpinnerSize="large"
                isLoading={isLoading}
                isFixedSize
            />
            <div className="row justify-content-center">
                <div className="col-md-auto">
                    <Pagination
                        pages={pages}
                        onChange={onChangePage}
                        selectedIndex={pageNumber - 1}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaginatedTable
