import React, { useState } from "react"
import { withRouter } from "react-router"
import { routes } from "../../shared/routing"
import { mkApi } from "./api"
import { getItem, removeItems } from "./storage"
import SectionHeader from "../shared/components/section-header"
import DefaultFormButtonBar from "../shared/components/default-form-button-bar"
import { CommentAction } from "@atlaskit/comment"
import { curry } from "ramda"

const checkboxId = (barcode) => "barcode-" + barcode

const setSelected = ({ barcode, selected }, { id, checked }) =>
    checkboxId(barcode) === id ? checked : selected

const ImportLabResult = ({ history }) => {
    const { id, barcodes, analysisType } = getItem("barcodes")

    const [selectedBarcodes, setSelectedBarcodes] = useState(
        barcodes.map((b) => ({
            ...b,
            selected: true,
        })),
    )

    const api = mkApi()

    const onCancel = () => {
        removeItems("barcodes")
        history.push(routes.dashboard.index.url)
    }

    const getSelected = () =>
        selectedBarcodes.filter((b) => b.selected).map((b) => b.barcode)

    const onSubmit = () => {
        const selected = getSelected()
        api.import(id, selected, analysisType).then((response) => {
            if (!response.error) {
                history.push(routes.dashboard.index.url)
            }
        })
    }

    const onChange = (evt) => {
        setSelectedBarcodes(
            selectedBarcodes.map((b) => ({
                ...b,
                selected: setSelected(b, evt.target),
            })),
        )
    }

    const setAll = curry((value, list) => {
        setSelectedBarcodes(
            list.map((b) => ({
                ...b,
                selected: value,
            })),
        )
    })
    const selectAll = setAll(true)

    const deselectAll = setAll(false)
    const getBarcodeStatus = (barcode) => {
        return barcode.status == "Unknown"
            ? barcode.status
            : barcode.status + " - " + barcode.progressStatus
    }

    const getBarcodeStyle = (barcode, revert) => {
        if (
            barcode.status === "Known" &&
            (barcode.progressStatus == "Analysed" ||
                barcode.progressStatus == "NotYetRegistered")
        ) {
            return {
                color: revert ? "green" : "red",
            }
        }
        if (barcode.status === "Unknown") {
            return {
                color: revert ? "red" : "green",
            }
        }
        return {}
    }
    const title = `Upload summary ${
        analysisType === "macrobiota"
            ? "Symbyo GUT"
            : analysisType === "vaginal"
            ? "Symbyo Vaginal"
            : analysisType === "gutextra"
            ? "Symbyo GUT Extra File"
            : analysisType === "oral"
            ? "Symbyo Oral"
            : "Epigene"
    }`
    const onlyKnowns = analysisType === "gutextra"
    return (
        <>
            <SectionHeader title={title} />
            <div style={{ marginBottom: "15px" }}>
                <CommentAction onClick={() => selectAll(selectedBarcodes)}>
                    Select All
                </CommentAction>
                <span> </span>
                <CommentAction onClick={() => deselectAll(selectedBarcodes)}>
                    Deselect All
                </CommentAction>
            </div>
            {selectedBarcodes.map((b) => (
                <div key={b.barcode}>
                    <input
                        type="checkbox"
                        id={checkboxId(b.barcode)}
                        checked={b.selected}
                        onChange={onChange}
                    />
                    <label style={getBarcodeStyle(b, onlyKnowns)}>
                        {b.barcode} ({getBarcodeStatus(b, onlyKnowns)})
                    </label>
                </div>
            ))}

            <DefaultFormButtonBar
                primaryText="Import"
                onSubmit={onSubmit}
                onCancel={onCancel}
                isSubmitEnabled={getSelected().length > 0}
            />
        </>
    )
}

export default withRouter(ImportLabResult)
