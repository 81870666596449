import React, { useState, useEffect } from "react"
import TextField from "../shared/components/textfield"
import RadioCheckList from "../shared/components/radio-check-list"

export const model = {
    age: "",
    genderType: "unspecified",
    menopauseType: "notapplicable",
    weight: "",
    height: "",
    ethnicityType: "unspecified",
    exerciseFrequencyType: "unspecified",
    smokerType: "unspecified",
    smokerNoMoreFrom: "",
}

const calcBMI = ({ weight, height }) => {
    if (weight && height) {
        const heightM = height / 100.0
        return weight / heightM / heightM
    }
    return NaN
}

const PatientPhysicalDescriptionForm = ({ formist, anonymous = false }) => {
    const height = formist.getFieldProps("height").value
    const weight = formist.getFieldProps("weight").value
    const gender = formist.getFieldProps("genderType").value
    const [bmi, setBMI] = useState(
        calcBMI({
            weight,
            height,
        }),
    )

    useEffect(() => {
        setBMI(
            calcBMI({
                weight,
                height,
            }),
        )
    }, [weight, height])
    useEffect(() => {
        if (gender === "male") formist.change("menopauseType", "notapplicable")
    }, [gender])
    return (
        <>
            <div className="form-row">
                <TextField
                    label="Age"
                    width="col-sm-4"
                    disabled={!anonymous}
                    readOnly={!anonymous}
                    {...formist.getFieldProps("age")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Weight"
                    width="col-sm-4"
                    {...formist.getFieldProps("weight")}
                />
                <TextField
                    label="Height (cm)"
                    width="col-sm-4"
                    {...formist.getFieldProps("height")}
                />
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Gender"
                    width="col-sm-4"
                    items={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                    ]}
                    {...formist.getFieldProps("genderType")}
                />
                <RadioCheckList
                    label="Menopause"
                    width="col-sm-4"
                    style={{
                        display: gender === "female" ? "block" : "none",
                    }}
                    items={[
                        { label: "Pre", value: "pre" },
                        { label: "Post", value: "post" },
                    ]}
                    {...formist.getFieldProps("menopauseType")}
                />
            </div>
            <div className="form-row">
                <div className="form-group col-sm-4">
                    <label style={{ fontWeight: "bold" }}>BMI:</label>
                    &nbsp;
                    <label>{(isNaN(bmi) && "--") || bmi.toFixed(2)}</label>
                </div>
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Ethnicity"
                    items={[
                        { label: "Caucasian", value: "caucasian" },
                        { label: "African", value: "african" },
                        { label: "Amerindian", value: "amerindian" },
                        { label: "Asian", value: "asian" },
                    ]}
                    {...formist.getFieldProps("ethnicityType")}
                />
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Exercise Frequency"
                    items={[
                        { label: "Low (1/w)", value: "low" },
                        { label: "Average (3/w)", value: "average" },
                        { label: "High (6/w)", value: "high" },
                    ]}
                    {...formist.getFieldProps("exerciseFrequencyType")}
                />
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Smoker"
                    width="col-sm-4"
                    items={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                        { label: "No more", value: "noMore" },
                    ]}
                    {...formist.getFieldProps("smokerType")}
                />
                <TextField
                    label=" "
                    width="col-sm-4"
                    placeholder="from..."
                    {...formist.getFieldProps("smokerNoMoreFrom")}
                />
            </div>
        </>
    )
}

export default PatientPhysicalDescriptionForm
