import React from "react"
import { getLocalizedText } from "../../shared/locale"

const indexShapes = {
    summary: ({ className, type, position }) => (
        <svg
            viewBox="0 0 491.52 491.52"
            preserveAspectRatio="none"
            style={{ left: `${position}%` }}>
            <path
                d="M245.761,0C156.033,0,83.293,72.74,83.293,162.468S245.761,491.52,245.761,491.52s162.467-239.323,162.467-329.052
                C408.228,72.74,335.49,0,245.761,0z M245.761,264.518c-56.36,0-102.049-45.689-102.049-102.049
                c0-56.359,45.688-102.048,102.049-102.048c56.359,0,102.048,45.689,102.048,102.048S302.12,264.518,245.761,264.518z"
                className={`${className}-position ${type}-position`}
            />
        </svg>
    ),
    man: ({ className, type, position }) => (
        <svg
            viewBox="-421 153 117 256"
            preserveAspectRatio="none"
            style={{ left: `${position}%` }}>
            <g className={`${className}-position ${type}-position`}>
                <path
                    d="M-362.9,157.9c11.3,0,20.5,9.2,20.5,20.5s-9.2,20.5-20.5,20.5s-20.5-9.2-20.5-20.5S-374.2,157.9-362.9,157.9z M-337.1,204.2
	h-51.2c-14.2,0-25.6,11.4-25.6,25.6v62.6c0,4.9,3.9,9,9,9s9-3.9,9-9v-57.5c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6v155.2
	c0,7.7,5.7,14,12.8,14s12.8-6.3,12.8-14v-88.5c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6v88.5c0,7.7,5.7,14,12.8,14s12.8-6.3,12.8-14
	V234.9c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6v57.6c0,4.9,3.9,9,9,9s9-3.9,9-9v-62.7C-311.5,215.6-323.2,204.2-337.1,204.2z"
                />
            </g>
        </svg>
    ),
    runningman: ({ className, type, position }) => (
        <svg
            viewBox="0 0 50.000000 50.000000"
            preserveAspectRatio="none"
            style={{ left: `${position}%` }}>
            <g
                className={`${className}-position ${type}-position`}
                transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)">
                <path d="M300 451 c-19 -36 14 -70 53 -55 39 14 24 74 -18 74 -14 0 -29 -8-35 -19z" />
                <path
                    d="M204 382 c-20 -14 -44 -57 -44 -79 0 -33 27 -29 45 7 9 17 22 30 31
                    30 11 0 4 -21 -30 -82 l-45 -83 -53 -5 c-29 -3 -52 -8 -50 -12 1 -5 2 -12 2
                    -18 0 -6 28 -10 63 -10 63 0 64 1 83 35 10 19 21 35 24 35 4 0 19 -13 34 -29
                    26 -27 28 -33 21 -80 -6 -43 -4 -52 10 -57 24 -10 35 13 35 77 0 48 -4 59 -30
                    84 l-29 28 26 46 c15 25 28 48 29 50 2 2 16 -6 32 -18 41 -29 49 -27 72 18 16
                    33 17 41 5 51 -11 9 -17 6 -30 -14 l-16 -25 -47 30 c-45 29 -112 39 -138 21z"
                />
            </g>
        </svg>
    ),
    default: ({ className, type, position }) => (
        <svg
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{ left: `${position}%` }}>
            <path
                d="M50,5C30.701,5,15,20.701,15,40C15,61.036,36.618,82.654,48.232,94.268C48.72,94.756,49.36,95,50,95S51.28,94.756,51.768,94.268C63.382,82.654,85,61.036,85,40C85,20.701,69.299,5,50,5Z"
                className={`${className}-position ${type}-position`}></path>
            <path
                d="M50,15C36.215,15,25,26.215,25,40S36.215,65,50,65S75,53.785,75,40S63.785,15,50,15Z"
                className={`${className}-position-hole ${type}-position-hole`}></path>
        </svg>
    ),
}

const Index = ({ className, type, style, indexShape, position }) => {
    const Shape = indexShapes[indexShape] || indexShapes["default"]
    return (
        <div
            className={`${className}-position-container ${type}-position-container ${style}-position-container`}>
            <Shape
                className={className}
                type={type}
                position={position}
                style={style}
            />
        </div>
    )
}

const BarChart = ({
    className = "barchart",
    value,
    type,
    barStyle,
    ticks = [],
    subTicks = [],
    ranks = [],
    withIndex = false,
    labelOffset = 10,
    gap = 0.5,
    tickStart = 3,
    tickSize = 2,
    subTickStart = 3,
    subTickSize = 1,
    indexShape = "default",
    barLabels = false,
    language = "it",
}) => {
    return (
        <div className={`${className}-container ${type}-container`}>
            {withIndex ? (
                <Index
                    className={className}
                    position={value}
                    type={type}
                    style={barStyle}
                    indexShape={indexShape}
                />
            ) : null}
            <div
                className={`${className} ${type} ${barStyle}`}
                style={{ "--bar-value": value }}>
                {ranks.length > 0 ? (
                    <svg viewBox="0 0 100 20" preserveAspectRatio="none">
                        <linearGradient id="yellow-gradient">
                            <stop className="red-stop" offset="0%" />
                            <stop className="orange-stop" offset="10%" />
                            <stop className="yellow-stop" offset="20%" />
                        </linearGradient>
                        <linearGradient id="orange-gradient">
                            <stop className="orange-stop" offset="0%" />
                            <stop className="yellow-stop" offset="20%" />
                        </linearGradient>
                        <linearGradient id="orange-inverted-gradient">
                            <stop className="yellow-stop" offset="70%" />
                            <stop className="orange-stop" offset="100%" />
                        </linearGradient>
                        <linearGradient id="green-gradient">
                            <stop className="yellow-stop" offset="0%" />
                            <stop className="green-stop" offset="10%" />
                        </linearGradient>
                        {ranks.map((r, idx) => (
                            <g key={idx} className={`rank-${r.rank}`}>
                                <rect
                                    x={r.range.low}
                                    y={0}
                                    height={20}
                                    width={
                                        r.range.high -
                                        r.range.low -
                                        (idx === ranks.length - 1 ? 0 : gap)
                                    }
                                />
                                {barLabels ? (
                                    <text
                                        className={`${className}-bar-label ${type}-bar-label`}
                                        style={{
                                            "--end": r.range.high + "%",
                                            "--start": r.range.low + "%",
                                        }}>
                                        <tspan
                                            dangerouslySetInnerHTML={{
                                                __html: getLocalizedText(
                                                    r.label,
                                                    language,
                                                ),
                                            }}
                                        />
                                    </text>
                                ) : null}
                            </g>
                        ))}
                    </svg>
                ) : null}
            </div>
            {ticks ? (
                <svg
                    viewBox="0 0 100 10"
                    preserveAspectRatio="none"
                    style={{ overflow: "visible" }}
                    className={`${className}-ticks ${type}-ticks`}>
                    <g>
                        <line
                            className={`${className}-ticks-line ${type}-ticks-line ${type}-ticks-line-horizontal`}
                            x1="0"
                            y1={tickStart + tickSize}
                            x2="100"
                            y2={tickStart + tickSize}
                        />
                        {ticks.map((t) => (
                            <>
                                <line
                                    key={t.value}
                                    className={`${className}-ticks-line ${type}-ticks-line`}
                                    x1={t.value}
                                    y1={tickStart}
                                    x2={t.value}
                                    y2={tickStart + tickSize}
                                />
                                <text
                                    className={`${className}-ticks-label ${type}-ticks-label`}
                                    transform={`translate(${t.value} ${labelOffset})`}>
                                    <tspan x="0" y="0">
                                        {t.label}
                                    </tspan>
                                </text>
                            </>
                        ))}
                        {subTicks.map((t) => (
                            <>
                                <line
                                    key={t}
                                    className={`${className}-subticks-line ${type}-subticks-line`}
                                    x1={t}
                                    y1={subTickStart}
                                    x2={t}
                                    y2={subTickStart + subTickSize}
                                />
                            </>
                        ))}
                    </g>
                </svg>
            ) : null}
        </div>
    )
}

export default BarChart
