import React, { createRef, useCallback } from "react"
import TextArea from "../shared/components/textarea"
import { useSuggest } from "../../shared/form"
import castArray from "lodash/castArray"

const suggestionFind = (suggestions) => (text, callback) => {
    const keyword = text.toLowerCase()
    const results = suggestions.reduce((previous, word) => {
        if (
            word.toLowerCase().indexOf(keyword) !== -1 &&
            word.toLowerCase() !== keyword
        ) {
            return [...previous, word]
        }
        return previous
    }, [])
    setTimeout(() => {
        callback(results)
    }, 300)
}

const SectionListHormonesValuesEdit = ({
    hormonesField,
    errors = {},
    suggestions = [],
    separator = ";",
}) => {
    const { value, onChange, error, ...other } = hormonesField
    const textarea = createRef(null)
    useSuggest({
        input: textarea,
        suggestions: [useCallback(suggestionFind(suggestions), [suggestions])],
        onSelected: (value) => {
            onChange({
                target: {
                    value: value.split("\n"),
                },
            })
        },
        regex: "(?:^|\\s+|" + separator + ")([a-zA-Z0-9 ]+)$",
    })

    const hormonesValue = value.join("\n")

    const onHormonesChange = (e) => {
        e.preventDefault()
        onChange({
            target: {
                value: e.target.value.split("\n"),
            },
        })
    }
    const hormonesError = castArray(
        errors[hormonesField && hormonesField.name] || [],
    )
        .map((e, idx) => "Row " + (idx + 1) + ": " + e)
        .filter((b) => b)
        .join(", ")

    return (
        <>
            <div className="form-row">
                <TextArea
                    label="Hormones"
                    width="col"
                    rows={10}
                    ref={textarea}
                    error={hormonesError}
                    {...other}
                    value={hormonesValue}
                    onChange={onHormonesChange}
                />
            </div>
        </>
    )
}

export default SectionListHormonesValuesEdit
