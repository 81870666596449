import React from "react"
import { withRouter } from "react-router"
import Wizard from "../../views/shared/components/wizard"
import useFormist from "react-formist"
import { routes } from "../../shared/routing"
import { getItem, mergeItem } from "./storage"
import { undo } from "./form"
import EmailsFields, { toForm, toModel } from "./emails-fields"

const RegisterEmails = ({ match, history }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)
    const initialData = toForm(geneticAnalysis)
    const formist = useFormist(initialData, {
        onSubmit: (formData) => {
            const data = toModel(formData)
            mergeItem(barcode, data)
            history.push(
                routes.geneticAnalyses.registerSummary.buildUrl(barcode),
            )
        },
    })

    return (
        <Wizard
            title={`Registration email for: ${barcode}`}
            onUndo={undo(history, barcode)}
            onPrevious={() => history.goBack()}
            {...formist.getFormProps()}>
            <EmailsFields formist={formist} />
        </Wizard>
    )
}

export default withRouter(RegisterEmails)
