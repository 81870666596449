import React from "react"
import { withRouter } from "react-router"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import { addSchema as schema, addEmpty as empty } from "./schemas"
import { addMappers as M } from "./mappers"
import FormFields from "./form-fields"

export const formatBacteriumGroup = (bacterium) => {
    const groupCleaned = bacterium.group
        .replace("spp.", "")
        .replace("spp", "")
        .trim()
    const groupFormatted =
        groupCleaned.charAt(0).toUpperCase() + groupCleaned.slice(1)
    return {
        ...bacterium,
        group: groupFormatted,
    }
}

const AddBacterium = ({ history, match }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { level, group } = match.params
    const preloaded = level && group && true

    const add = (bacterium) => {
        api.update(
            M.toApiModel(formatBacteriumGroup(bacterium)),
            true,
            preloaded,
        ).then(() => {
            history.push(routes.bacteria.index.url)
        })
    }

    const initialValues = preloaded
        ? {
              ...empty,
              level,
              group,
          }
        : empty
    return (
        <FormFields
            initialValues={initialValues}
            schema={schema}
            onSubmit={add}
            onCancel={() => history.goBack()}
            formProps={{ title: "New Bacterium", primaryText: "Add" }}
        />
    )
}

export default withRouter(AddBacterium)
