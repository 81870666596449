export function setItem(key, data) {
    localStorage.setItem(addNamespaceTo(key), JSON.stringify(data))
}

export function getItem(key) {
    return JSON.parse(localStorage.getItem(addNamespaceTo(key)))
}

export function mergeItem(barcode, data) {
    const stored = getItem(barcode)
    const merged = { ...stored, ...data }
    setItem(barcode, merged)
}

export function removeItems(...args) {
    args.map((x) => localStorage.removeItem(x))
}

function addNamespaceTo(key) {
    return `dl-nextgen-imported-result-${key}`
}
