import React from "react"
import "./legend.scss"
import { getLocalizedText } from "../../../shared/locale"

export const LegendItem = ({ rank, label }) => {
    return (
        <>
            <div className="sample-container">
                <div className={`sample sample-${rank}`}></div>
            </div>
            <div className="label">
                <span>{label}</span>
            </div>
        </>
    )
}

export const Legend = ({
    items = [],
    id = "legend",
    className = "",
    style = {},
    language = "it",
}) => {
    return (
        <div id={id} className={`legend ${className}`} style={style}>
            {items.map((i, idx) => (
                <LegendItem
                    key={idx}
                    rank={i.rank}
                    label={getLocalizedText(i.label, language)}
                />
            ))}
        </div>
    )
}

export default Legend
