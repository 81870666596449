import React, { useState } from "react"
import Tabs from "@atlaskit/tabs"
import { hormonSpecifiers } from "../hormones/schemas"
import TableField from "../shared/components/tablefield"

const schema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "rank",
        label: "Rank",
    },
]

function getRows(attributes) {
    return attributes.map((attribute) => ({
        low: attribute.range.low,
        high: attribute.range.high,
        rank: attribute.rank,
    }))
}

const HormonSpecifiersRangesEdit = ({ hormon, field, visible }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const { value, onChange } = field

    function onAttributeChange(specifier) {
        return function (e) {
            onChange({
                target: {
                    value: {
                        ...value,
                        attributes: {
                            ...value.attributes,
                            [specifier]: e.target.value.map((v) => ({
                                range: {
                                    low: v.low,
                                    high: v.high,
                                },
                                rank: v.rank,
                            })),
                        },
                    },
                },
            })
        }
    }
    const tabs = Object.keys(hormonSpecifiers).map(function (s) {
        return {
            label: hormonSpecifiers[s],
            content: (
                <>
                    <div className="form-row">
                        <TableField
                            key={s}
                            label=""
                            width="col"
                            editable
                            moveRows
                            schema={schema}
                            // errors={printableErrors(errors, "values")}
                            value={getRows(value.attributes[s])}
                            onChange={onAttributeChange(s)}
                        />
                    </div>
                </>
            ),
        }
    })

    return (
        <div style={{ display: visible ? "block" : "none" }}>
            <h3>{hormon}</h3>
            <Tabs
                tabs={tabs}
                onSelect={(tab, idx) => setSelectedTab(idx)}
                selected={selectedTab}
            />
        </div>
    )
}

export default HormonSpecifiersRangesEdit
