export default function formist({ formist, namespace, children: renderFn }) {
    const { onChange, value = {}, error } = formist.getFieldProps(namespace)
    const getFieldProps = (field) => ({
        disabled: formist.isSubmitting,
        value: value[field],
        error: error[field],
        name: field,
        id: field,
        onChange: (e) =>
            onChange({
                target: { value: { ...value, [field]: e.target.value } },
            }),
    })
    const change = function (field, value) {
        formist.change(namespace + "." + field, value)
    }

    return renderFn({
        getFieldProps,
        change,
    })
}
