import React from "react"

export default function SectionHeader({ title, size = "h2", style }) {
    return (
        <div style={style}>
            <h1 className={`${size} mt-3`}>{title}</h1>
            <hr />
        </div>
    )
}
