import React, { useCallback, useEffect, useState } from "react"
import Form from "../shared/components/form"
import { SelectField, MultiSelectField } from "../shared/components/selectfield"
import { withRouter } from "react-router"
import { mkApi } from "./api"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import useFormist from "react-formist"
import * as yup from "yup"
import { success } from "../../shared/notifications"
import Button from "@atlaskit/button"
import { routes } from "../../shared/routing"
import { analysisTypes } from "../../analysisTypes"
import { toIta, toIso } from "../../shared/dates"
import dayjs from "dayjs"
import {
    templatesFilterBy,
    layoutsFilterBy,
    toSelectModel,
} from "./options-utils.js"
import DateField from "../shared/components/datefield.jsx"

const patientsToSelectModel = (items) =>
    items.map((x) => {
        return {
            value: x.id,
            label: x.anonymous ? "Anonymous" : `${x.lastName} ${x.firstName}`,
        }
    })

const schema = yup.object().shape({
    barcode: yup.string().required(),
    analysisType: yup.string().required(),
    analysisSubType: yup.string().nullable(),
    templateIds: yup.array().of(yup.string().required()).required(),
    layoutIds: yup.array().of(yup.string().required()).required(),
    patientId: yup.string().required(),
    referral: yup.string(),
})

function addCurrentLayouts(layouts, currentLayoutIds, allLayouts) {
    const additional = currentLayoutIds
        .filter((id) => !layouts.find((l) => l.value === id))
        .map((id) => {
            const layout = allLayouts.find((l) => l.id === id)
            if (layout) {
                return {
                    value: layout.id,
                    label: layout.name,
                }
            }
        })
        .filter((x) => x)
    return [...layouts, ...additional]
}

const EditGeneticAnalysis = ({
    history,
    api,
    templates,
    layouts,
    patients,
    geneticAnalysis,
    referrals,
}) => {
    const [filterReferral, setFilterReferral] = useState(true)
    const [dueDate, setDueDate] = useState(toIta(geneticAnalysis.dueDate))

    const toggleReferralFilter = useCallback(() => {
        setFilterReferral(!filterReferral)
    }, [filterReferral])

    const { barcode } = geneticAnalysis
    const formist = useFormist(geneticAnalysis, {
        schema,
        onSubmit: (geneticAnalysis) => {
            api.updateReportConfiguration(geneticAnalysis).then(() => {
                success("Genetic Analysis saved!")
            })
        },
    })

    const patientOptions = patientsToSelectModel(patients)
    const templateOptions = toSelectModel(
        templatesFilterBy(
            templates.items,
            formist.values.analysisType,
            formist.values.analysisSubType || "Unknown",
        ),
    )
    const layoutOptions = addCurrentLayouts(
        toSelectModel(
            layoutsFilterBy(
                layouts.items,
                formist.values.analysisType,
                formist.values.analysisSubType || "Unknown",
                filterReferral ? formist.values.referral : "",
            ),
        ),
        formist.values.layoutIds,
        layouts.items,
    )
    const referralOptions = toSelectModel(referrals.items, (x) => x.code)

    const onCancel = () => history.goBack()

    const viewSummary = () => {
        history.push(routes.geneticAnalyses.summary.buildUrl(barcode))
    }

    const viewRegistrationEmail = () => {
        history.push(routes.geneticAnalyses.registrationEmail.buildUrl(barcode))
    }

    const editPatient = () => {
        history.push(routes.patients.edit.buildUrl(barcode))
    }

    const patientDefined =
        geneticAnalysis.patientId &&
        geneticAnalysis.patientId !== "00000000-0000-0000-0000-000000000000"

    const currentAnalysisType = analysisTypes.find(
        (a) => a.value === formist.getFieldProps("analysisType").value,
    )

    useEffect(() => {
        if (
            (
                analysisTypes.find(
                    (a) =>
                        a.value === formist.getFieldProps("analysisType").value,
                )?.subTypes || []
            )
                .map((s) => s.value)
                .indexOf(formist.values.analysisSubType) === -1
        )
            formist.change("analysisSubType", "Unknown")
    }, [formist.values.analysisType])

    const analysisSubTypes = [
        {
            label: " - None - ",
            value: "Unknown",
        },
        ...(currentAnalysisType?.subTypes || []),
    ]

    function isDateValid(date) {
        return date && dayjs(date, "DD/MM/YYYY", true).isValid()
    }

    const updateDueDate = (value) => {
        const newDate = dayjs(value, "YYYY-MM-DD")
        setDueDate(newDate)
        if (isDateValid(newDate)) {
            formist.change("dueDate", toIso(newDate))
        }
    }

    return (
        <Form
            {...formist.getFormProps()}
            primaryText={"Save"}
            title={`Genetic Analysis (${barcode} - ${toIta(
                geneticAnalysis.registeredDate,
            )})`}
            onCancel={onCancel}>
            <Button
                appearance="primary"
                isDisabled={!patientDefined}
                onClick={viewSummary}>
                View Summary
            </Button>
            <Button
                className="ml-2"
                isDisabled={!patientDefined}
                onClick={viewRegistrationEmail}>
                Registration email
            </Button>
            <div className="form-container-separator">
                <div className="form-row">
                    <SelectField
                        label="Analysis Type"
                        width="col"
                        options={analysisTypes}
                        {...formist.getFieldProps("analysisType")}
                    />

                    <SelectField
                        disabled={analysisSubTypes.length <= 1}
                        label="Analysis Sub Type"
                        width="col"
                        options={analysisSubTypes}
                        {...formist.getFieldProps("analysisSubType")}
                    />
                </div>
                <div className="form-row">
                    <SelectField
                        label="Referral"
                        width="col"
                        {...formist.getFieldProps("referral")}
                        options={referralOptions}
                    />
                </div>
                <div className="form-row">
                    <MultiSelectField
                        label="Templates"
                        width="col"
                        {...formist.getFieldProps("templateIds")}
                        options={templateOptions}
                    />
                </div>
                <div className="form-row">
                    <MultiSelectField
                        label="Layout"
                        width="col"
                        {...formist.getFieldProps("layoutIds")}
                        options={layoutOptions}
                    />
                    <div
                        className="fa fa-filter filter-toggle"
                        data-toggle-selected={filterReferral}
                        onClick={toggleReferralFilter}></div>
                </div>
                <div className="form-row">
                    <SelectField
                        label="Patient"
                        width="col"
                        disabled={true}
                        {...formist.getFieldProps("patientId")}
                        options={patientOptions}
                    />
                </div>
                <div className="row">
                    <DateField
                        label="Expected analysis completion"
                        width="col"
                        value={dueDate}
                        disabled={geneticAnalysis.status === "Analysed"}
                        onChange={updateDueDate}
                        error={isDateValid(dueDate) ? null : "Invalid date"}
                    />
                </div>
                <Button
                    appearance="primary"
                    isDisabled={!patientDefined}
                    onClick={editPatient}>
                    Edit Patient Data
                </Button>
            </div>
        </Form>
    )
}

const loadData = ({ api, barcode }) => {
    return Promise.all([
        api.loadTemplates(),
        api.loadLayouts(),
        api.loadFull(barcode),
        api.loadReferrals(),
    ])
}

export const EditGeneticAnalysisFetch = ({ history, match }) => {
    const api = mkApi()
    const barcode = match.params.id
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        api,
        barcode,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    if (data) {
        const [templates, layouts, geneticAnalysis, referrals] = data
        const patients = geneticAnalysis?.patientDetails?.patient
            ? [geneticAnalysis?.patientDetails?.patient]
            : []
        return (
            <EditGeneticAnalysis
                history={history}
                api={api}
                templates={templates}
                layouts={layouts}
                patients={patients}
                geneticAnalysis={geneticAnalysis}
                referrals={referrals}
            />
        )
    }
    return null
}

export default withRouter(EditGeneticAnalysisFetch)
