import React from "react"
import BarChart from "./barchart"
import { getLocalizedText } from "../../shared/locale"
import "./barchart.scss"
import Legend from "../shared/components/legend"

const formatNumber = (num, decimals) => Number(num).toFixed(decimals)

const renderValue = (format, value) => {
    switch (format) {
        case "number":
            return formatNumber(value)
        case "numberwithdecimals":
            return formatNumber(value, 2)
        case "fraction":
            return formatNumber(value) + "/100"
        case "percentagewithcolon":
            return ": " + formatNumber(value, 2) + "%"
        case "percentage":
            return formatNumber(value, 2) + "%"
        default:
            return ""
    }
}

function getBarType(type) {
    if (type.indexOf("dynamic:Custom") === 0) {
        return [true, "custom", "gradient"]
    }
    if (type.indexOf("dynamic:") !== -1) {
        const [, barType, style = "simple"] = type.split(":")
        return [true, barType, style]
    }
    return [false, type, "simple"]
}

const tickStarts = {
    bar: 1,
    simple: 1,
    decimal: 1,
    summary: -7,
    dnabase: -6.5,
    talent: -6.5,
}

const tickSizes = {
    summary: 7,
    dnabase: 1.2,
    talent: 1.2,
}

const subTickStarts = {
    simple: 1,
    decimal: 1,
    dnabase: -6.2,
    talent: -6.2,
}

const withIndex = {
    gradient: false,
    bar: true,
    simple: true,
    decimal: true,
    summary: true,
    dnabase: true,
    talent: true,
}

const withLegend = {
    gradient: true,
    simple: true,
    bar: false,
    decimal: true,
    summary: false,
    dnabase: false,
    talent: false,
}

const labelOffsets = {
    gradient: 10,
    bar: 4,
    simple: 8,
    decimal: 8,
    summary: 5,
    dnabase: 3,
    talent: 3,
}

const gaps = {
    gradient: 0.5,
    simple: 0.5,
    gaps: 0.5,
    decimal: 0.5,
    summary: 0,
    dnabase: 0.3,
    talent: 0.3,
}

const indexShapes = {
    bar: "summary",
    summary: "summary",
    dnabase: "man",
    talent: "runningman",
}

function getSubTicks(type, max) {
    switch (type) {
        case "decimal": {
            const num = max < 0.5 ? 25 : 10
            const step = 100.0 / num
            return [...Array(num + 1).keys()].map((idx) => idx * step)
        }
        case "dnabase":
        case "talent": {
            const step = 5.0
            return [...Array(20).keys()].map((idx) => idx * step)
        }
        default:
            return []
    }
}

const barLabels = {
    dnabase: true,
    talent: true,
}

function getTicks(type, max, num = 5) {
    switch (type) {
        case "gradient": {
            return [
                { value: 0, label: "0" },
                { value: 10, label: "" },
                { value: 20, label: "20" },
                { value: 30, label: "" },
                { value: 40, label: "40" },
                { value: 50, label: "" },
                { value: 60, label: "60" },
                { value: 70, label: "" },
                { value: 80, label: "80" },
                { value: 90, label: "" },
                { value: 100, label: "100" },
            ]
        }
        case "dnabase":
        case "talent": {
            return [
                { value: 0, label: "0" },
                { value: 10, label: "" },
                { value: 20, label: "20" },
                { value: 30, label: "" },
                { value: 40, label: "40" },
                { value: 50, label: "" },
                { value: 60, label: "60" },
                { value: 70, label: "" },
                { value: 80, label: "80" },
                { value: 90, label: "" },
                { value: 100, label: "100" },
            ]
        }
        case "decimal": {
            const step = 100.0 / num
            const labelStep = max / num
            return [...Array(num + 1).keys()].map((idx) => ({
                value: idx * step,
                label: formatNumber(idx * labelStep, max < 0.5 ? 2 : 1),
            }))
        }
        case "summary": {
            const step = 100.0 / num
            const labelStep = 0.1
            return [...Array(num + 1).keys()].map((idx) => ({
                value: idx * step,
                label: formatNumber((idx + 1) * labelStep, 1),
            }))
        }
        case "bar": {
            return [
                { value: 0, label: "0" },
                { value: 15, label: "15" },
                { value: 30, label: "30" },
                { value: 60, label: "60" },
                { value: 100, label: "100" },
            ]
        }
        default: {
            return [
                { value: 0, label: "0" },
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 30, label: "30" },
                { value: 40, label: "40" },
                { value: 50, label: "50" },
                { value: 60, label: "60" },
                { value: 70, label: "70" },
                { value: 80, label: "80" },
                { value: 90, label: "90" },
                { value: 100, label: "100" },
            ]
        }
    }
}

const SectionBarChart = ({ item, language = "it", style }) => {
    const {
        name,
        description,
        barIndex,
        value,
        chartType = "bars",
        rank = 0,
        format = "none",
        ranks = [],
        max = 100,
    } = item

    const [dynamic, type, barStyle] = getBarType(chartType)

    const min = ranks.length > 0 ? Number(ranks[0].range.low) : 0
    const factor = 100 / (max - min)
    const chartRanks = ranks.map((r) => ({
        ...r,
        range: {
            low: (r.range.low - min) * factor,
            high: (r.range.high - min) * factor,
        },
    }))
    return (
        <div data-testid="section-barchart" style={style}>
            <div
                className={`progressbar-container progressbar-container-${type} progressbar-container-rank-${rank}`}>
                <div>
                    <h1 className="h2 mt-3 r-title">
                        {getLocalizedText(name, language)}
                    </h1>
                    <p>{renderValue(format, value)}</p>
                </div>
                <p>{getLocalizedText(description, language)}</p>
                {dynamic ? (
                    <div>
                        <BarChart
                            value={barIndex}
                            type={type}
                            barStyle={barStyle}
                            ranks={chartRanks}
                            ticks={getTicks(barStyle, max)}
                            subTicks={getSubTicks(barStyle, max)}
                            withIndex={withIndex[barStyle]}
                            labelOffset={labelOffsets[barStyle]}
                            gap={gaps[barStyle]}
                            tickStart={tickStarts[barStyle]}
                            subTickStart={subTickStarts[barStyle]}
                            tickSize={tickSizes[barStyle]}
                            indexShape={indexShapes[barStyle]}
                            barLabels={barLabels[barStyle] || false}
                            language={language}
                        />
                    </div>
                ) : null}
            </div>
            {dynamic && withLegend[barStyle] ? (
                <Legend items={ranks} language={language} />
            ) : null}
        </div>
    )
}

export default SectionBarChart
