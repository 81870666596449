import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    pattern: "",
    bacteria: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-BACTERIA", handleLoadBacteria)
        .case("CLEAR-BACTERIA", handleClearBacteria)
        .case("SEARCH-BACTERIA", handleSearchBacteria)
        .case("EDIT-BACTERIUM", handleEditBacterium)
        .case("ADD-BACTERIUM", handleAddBacterium)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-BACTERIA", data }
    },
    clear() {
        return { type: "CLEAR-BACTERIA" }
    },
    search(data) {
        return { type: "SEARCH-BACTERIA", data }
    },
    edit(data) {
        return { type: "EDIT-BACTERIUM", data }
    },
    add(data, reload) {
        return { type: "ADD-BACTERIUM", data, reload }
    },
}

const handleLoadBacteria = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        bacteria: items,
        pattern: "",
        ...pagination,
    }
}

const handleClearBacteria = () => {
    return {
        isLoading: false,
        bacteria: [],
        pattern: "",
        pageNumber: undefined,
        pageSize: undefined,
        totalPages: undefined,
    }
}

const handleSearchBacteria = (state, action) => {
    const { items, pagination, pattern } = action.data
    return {
        isLoading: false,
        bacteria: items,
        pattern,
        ...pagination,
    }
}

const handleEditBacterium = (state, action) => {
    const { bacteria } = state
    const { level, group } = action.data

    const newBacteria = bacteria.map((b) =>
        b.level === level && b.group === group ? action.data : b,
    )
    return {
        bacteria: newBacteria,
    }
}

const handleAddBacterium = (state, action) => {
    const { bacteria } = state

    const newBacteria = [action.data, ...bacteria]
    return action.reload
        ? { bacteria: [] }
        : {
              bacteria: newBacteria,
          }
}
