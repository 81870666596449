import { match } from "../../shared/store"

export const initialState = {
    analyses: [],
    isLoading: true,
    error: null,
    pattern: "",
    sortField: "",
    type: "",
    template: "",
    layout: "",
}

export const reducer = (state, action) => {
    return match(action).case("FETCH-SUCCESS", handleFetchSuccess).apply(state)
}

export const actions = {
    load(data) {
        return { type: "FETCH-SUCCESS", data }
    },
}

const handleFetchSuccess = (state, action) => {
    const { items, pagination, pattern, sortField, type, template, layout } =
        action.data
    return {
        isLoading: false,
        analyses: items,
        pattern: pattern || "",
        type,
        template,
        layout,
        sortField: sortField,
        ...pagination,
    }
}
