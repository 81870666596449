import React, { useEffect, useState } from "react"
import { range } from "ramda"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import SectionHeader from "../shared/components/section-header"
import InlineSearch from "../shared/components/inline-search"
import PaginatedTable from "../shared/components/paginated-table"
import { useStateContext } from "../../shared/state-context"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import Button from "@atlaskit/button"
import ButtonBar from "../shared/components/button-bar"
import DropdownMenu, {
    DropdownItemGroup,
    DropdownItem,
} from "@atlaskit/dropdown-menu"
import { error } from "../../shared/notifications"
import ModalDialog from "@atlaskit/modal-dialog"
import Form from "../shared/components/form"
import TextField from "../shared/components/textfield"
import { SelectField } from "../shared/components/selectfield"
import { bacteriaTypes } from "./form-fields"

function CloneBacterium({ enabled = false, onConfirm, onCancel, code }) {
    const [newCode, setNewCode] = useState(code)
    useEffect(() => setNewCode(code), [code])
    return enabled ? (
        <ModalDialog
            id="clone-bacterium-dialog"
            heading="Clone Bacterium"
            height={450}>
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={() => onConfirm(newCode)}>
                        Clone
                    </Button>,
                    "cancel",
                ]}
                primaryText="Clone"
                onCancel={onCancel}>
                <div className="form-row">
                    <TextField
                        label="Old Bacterium"
                        isDisabled
                        width="col"
                        value={`${code.level} - ${code.group}`}
                    />
                </div>
                <div className="form-row">
                    <SelectField
                        label="Level"
                        width="col"
                        options={bacteriaTypes}
                        value={newCode?.level}
                        onChange={(e) =>
                            setNewCode({ ...newCode, level: e.target.value })
                        }
                    />
                    <TextField
                        label="Group"
                        width="col"
                        value={newCode?.group}
                        onChange={(e) =>
                            setNewCode({ ...newCode, group: e.target.value })
                        }
                    />
                </div>
            </Form>
        </ModalDialog>
    ) : null
}

function formatExpected(s) {
    const number = Number(s)
    if (isNaN(number)) return ""
    return number.toFixed(4)
}

function getRange(analysisObj) {
    if (analysisObj.range)
        return `${analysisObj.range.low.toFixed(
            4,
        )} - ${analysisObj.range.high.toFixed(4)}`
    return ""
}

function getExpected(analysisObj) {
    if (analysisObj.expected) return formatExpected(analysisObj.expected.value)
    return ""
}

function getImplications(analysisObj) {
    if (analysisObj.implications) return analysisObj.implications.text || ""
    return ""
}

function getValues(attributesObj, getValue) {
    return Object.keys(attributesObj)
        .map((t) => getValue(attributesObj[t] || {}))
        .filter((t) => t)
        .join(", ")
}

const BacteriaList = (props) => {
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const { bacteria, isLoading, pageNumber, totalPages, pattern } = state
    const { history } = props
    const pages = range(1, totalPages + 1)

    const [cloning, setCloning] = useState(null)

    useEffect(() => {
        if (!bacteria || bacteria.length === 0) {
            if (pattern.length >= 3) api.search(pattern, pageNumber)
            if (pattern.length == 0) api.loadAll(pageNumber)
        }
    }, [])

    const onChangePage = (e, pageNumber) => {
        if (pattern.length >= 3) api.search(pattern, pageNumber)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const onChangeSearch = (e) => {
        const pattern = e.target.value
        if (pattern.length >= 3) api.search(pattern)
        if (pattern.length == 0) api.loadAll(pageNumber)
    }

    const addClickHandler = (e) => {
        e.preventDefault()
        history.push(routes.bacteria.index.routes.add.url)
    }

    const head = {
        cells: [
            {
                key: "level",
                content: "Level",
                width: 5,
            },
            {
                key: "group",
                content: "Group",
                width: 25,
            },
            {
                key: "range",
                content: "Range",
            },
            {
                key: "expected",
                content: "Expected",
            },
            {
                key: "implications",
                content: "Implications",
            },
            {
                key: "actions",
            },
        ],
    }

    const mkEditUrl = (item) => {
        const { level, group } = item
        const code = btoa(`${level};${group}`)
        return routes.bacteria.index.routes.edit.buildUrl(code)
    }

    const removeMenuItem = (item, handler) => {
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Remove
                </Link>
            </DropdownItem>
        )
    }

    const cloneMenuItem = (item, handler) => {
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Clone
                </Link>
            </DropdownItem>
        )
    }

    const removeHandler = (item) => (e) => {
        e.preventDefault()
        api.remove(btoa(`${item.level};${item.group}`))
            .then(() => {
                if (pattern.length >= 3) api.search(pattern, pageNumber)
                if (pattern.length == 0) api.loadAll(pageNumber)
            })
            .catch((e) => {
                error(JSON.parse(e).Message)
            })
    }

    const cloneHandler = (item) => (e) => {
        e.preventDefault()
        setCloning(item)
    }

    const cancelClone = () => {
        setCloning(null)
    }

    const confirmClone = (newCode) => {
        const item = cloning
        api.clone(
            btoa(`${item.level};${item.group}`),
            btoa(`${newCode.level};${newCode.group}`),
        ).then(() => {
            setCloning(null)
            if (pattern.length >= 3) api.search(pattern, pageNumber)
            if (pattern.length == 0) api.loadAll(pageNumber)
        })
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    { content: item.level },
                    {
                        content: (
                            <Link
                                to={{
                                    pathname: mkEditUrl(item),
                                    state: item,
                                }}>
                                {item.group}
                            </Link>
                        ),
                    },
                    {
                        content: getValues(item.attributes, getRange),
                    },
                    {
                        content: getValues(item.attributes, getExpected),
                    },
                    {
                        content: getValues(item.attributes, getImplications),
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {removeMenuItem(item, removeHandler)}
                                    {cloneMenuItem(item, cloneHandler)}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    return (
        <>
            <SectionHeader title="Bacteria list" />
            <div className="row">
                <div className="col-4">
                    <InlineSearch onChange={onChangeSearch} pattern={pattern} />
                </div>
                {/*
                    NOTE: aggiunto form-group perché da margin bottom
                          Per allineare e centrare il button è una classe necessaria
                          dato che anche InlineSearch la usa.
                    TODO: Estrarre il <div className="form-group"> interno a InlineSearch
                          così da utilizzarlo qui nel div parent di InlineSearch e Button
                */}
                <div className="form-group col-4 btn-alongside-center">
                    <Button appearance="primary" onClick={addClickHandler}>
                        Add Bacterium
                    </Button>
                </div>
            </div>
            <PaginatedTable
                head={head}
                rows={mkRows(bacteria)}
                isLoading={isLoading}
                pages={pages}
                pageNumber={pageNumber}
                onChangePage={onChangePage}
            />

            <ButtonBar>
                <Button appearance="primary" onClick={addClickHandler}>
                    Add Bacterium
                </Button>
            </ButtonBar>
            <CloneBacterium
                enabled={!!cloning}
                onConfirm={confirmClone}
                onCancel={cancelClone}
                code={cloning}
            />
        </>
    )
}

export default withRouter(BacteriaList)
