import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { SelectField } from "../shared/components/selectfield"
import { CommentAction } from "@atlaskit/comment"
import TextField from "../shared/components/textfield"
import { b64DecodeUnicode, b64EncodeUnicode } from "../../shared/base64"
import RankedRangeListEdit from "./ranked-range-list-edit"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    summaryType: yup.string().required(),
    min: yup.number().required(),
    max: yup.number().required(),
    ranks: yup.array().min(1).of(yup.string().required()),
})

const SectionSummaryEdit = ({ section, onSave, onCancel }) => {
    const model = {
        ...section,
        ranks: section.ranks.map(b64DecodeUnicode),
    }
    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const summaryTypeOptions = [
        {
            value: "MicrobialBiodiversity",
            label: "Biodiversità batterica",
        },
    ]

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <SelectField
                    label="Summary Type"
                    width="col"
                    {...formist.getFieldProps("summaryType")}
                    options={summaryTypeOptions}
                />
                <TextField
                    label="Min"
                    width="col"
                    type="number"
                    {...formist.getFieldProps("min")}
                />
                <TextField
                    label="Max"
                    width="col"
                    type="number"
                    {...formist.getFieldProps("max")}
                />
            </div>
            <div className="form-row">
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks"),
                    }}
                    errors={formist.getFieldProps("ranks").error}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionSummaryEdit
