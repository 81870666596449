import "../../report.css"
import React, { useEffect, useState } from "react"
import { apis } from "../../shared/apis"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"
import Button from "@atlaskit/button"
import { Link } from "react-router-dom"
import { download } from "../../shared/ajax"
import { error, success } from "../../shared/notifications"
import SectionHeader from "../shared/components/section-header"
import TextField from "../shared/components/textfield"

const showError = (e) => {
    let ex = e
    if (typeof e === "string") {
        try {
            ex = JSON.parse(e)
        } catch (e) {
            ex = {
                Message: "Error posting file",
                StackTrace: "",
            }
        }
    }
    if (ex && ex.Message) {
        error(ex.Message, {
            dismiss: false,
        })
        console.error(ex.StackTrace)
    }
}

export const SendEmail = ({ barcode, layout, data }) => {
    const {
        data: patient,
        error,
        isLoading,
    } = useAsync({
        promiseFn: loadPatientData,
        patient: data.patientId,
    })

    const [pdf, setPdf] = useState(data.pdf[layout])
    const [email, setEmail] = useState("")
    const [emailId, setEmailId] = useState(data.reportEmails[layout])
    const [generating, setGenerating] = useState(pdf === "inprogress")
    useEffect(() => {
        if (patient) setEmail(patient.email)
    }, [patient])
    const downloadPDF = () => {
        const url = `${apis.reports.pdf.byId(barcode, layout)}`
        download(url, `${barcode}-${layout}`, ".pdf")
    }
    const refresh = () => {
        apis.geneticAnalyses.load(barcode).then((refreshedData) => {
            if (refreshedData.pdf[layout] === "inprogress") {
                setTimeout(refresh, 5000)
            } else {
                success(
                    "PDF generated! You can now preview it or send it by email.",
                )
                setPdf(refreshedData.pdf[layout])
                setGenerating(false)
            }
        })
    }
    const createPDF = () => {
        apis.geneticAnalyses
            .createPDF(barcode, layout)
            .then(() => {
                success(
                    "PDF regeneration started! It can take some time. Get back later to see the results.",
                )
                setGenerating(true)
                setTimeout(refresh, 5000)
            })
            .catch(showError)
    }
    const send = () => {
        apis.reports
            .sendEmail(barcode, layout, email)
            .then((resp) => {
                success("Report successfully sent by email!")
                setEmailId(resp.emailId)
            })
            .catch(showError)
    }
    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return <div className="error">{error.message}</div>
    if (patient) {
        return (
            <div>
                <SectionHeader
                    title={`Send by Email: ${barcode} - ${layout}`}
                />
                <div>
                    {pdf ? (
                        <>
                            {generating ? (
                                <span>Generating PDF...</span>
                            ) : (
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault()
                                        downloadPDF()
                                    }}
                                    href="#"
                                    target="_blank">
                                    Preview PDF
                                </Link>
                            )}
                            <Button
                                className="ml-4"
                                appearance="primary"
                                onClick={createPDF}>
                                Regenerate PDF
                            </Button>
                        </>
                    ) : (
                        <>
                            <span>No PDF Available</span>
                            <Button
                                className="ml-4"
                                appearance="primary"
                                onClick={createPDF}>
                                Create PDF
                            </Button>
                        </>
                    )}
                </div>
                {pdf && !generating ? (
                    <div>
                        <TextField
                            label="Send To:"
                            value={email}
                            onChange={(evt) => setEmail(evt.target.value)}
                        />
                        <Button appearance="primary" onClick={send}>
                            {emailId ? "Send Again" : "Send"}
                        </Button>
                    </div>
                ) : null}
            </div>
        )
    }
    return null
}

const loadData = ({ barcode }) => {
    return apis.geneticAnalyses.load(barcode)
}

const loadPatientData = ({ patient }) => {
    return apis.patients.load(patient)
}

const SendEmailFetch = ({ match }) => {
    const barcode = match.params.id
    const layout = match.params.layout
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        barcode,
        layout,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return <div className="error">{error.message}</div>
    if (data) return <SendEmail barcode={barcode} layout={layout} data={data} />
    return null
}

export default SendEmailFetch
