import React, { useState } from "react"
import Wizard from "../shared/components/wizard"
import { withRouter } from "react-router"
import * as yup from "yup"
import useFormist from "react-formist"
import { mergeItem, getItem } from "./storage"
import { routes } from "../../shared/routing"
import { mkApi } from "./api"
import { undo } from "./form"
import Button from "@atlaskit/button"
import CheckField from "../shared/components/checkfield"
import AutocompleteCreatableSelectField from "../shared/components/autocomplete-creatable-selectedfield"

const empty = {
    patientId: "",
    patientName: "",
    anonymous: false,
}

const schema = yup.object().shape({
    anonymous: yup.boolean(),
    patientId: yup.string().when("anonymous", {
        is: false,
        then: (schema) => schema.required(),
    }),
    patientName: yup.string().when("anonymous", {
        is: false,
        then: (schema) => schema.required(),
    }),
})

export const toSelectModel = (items) =>
    items.map((x) => {
        return {
            value: x.id,
            label: `${x.lastName} ${x.firstName} (${x.email})`,
        }
    })

const RegisterPatientSelect = ({
    history,
    match,
    onCreate,
    inputModel = empty,
}) => {
    const barcode = match.params.id
    const api = mkApi()
    const geneticAnalyses = getItem(barcode)

    const model = {
        ...inputModel,
        ...geneticAnalyses,
        anonymous:
            (geneticAnalyses.patientData &&
                geneticAnalyses.patientData.anonymous) ||
            false,
    }

    const [patient, setPatient] = useState(
        model.patientId
            ? {
                  value: model.patientId,
                  label: model.patientName,
              }
            : null,
    )
    const formist = useFormist(model, {
        schema,
        onSubmit: (data) => {
            if (formist.values.anonymous) {
                const patient = {
                    anonymous: true,
                    lastName: "",
                    firstName: "",
                    email: "",
                }
                api.addOnePatient(patient).then((patientId) => {
                    mergeItem(barcode, {
                        newPatient: true,
                        patientId: patientId.id,
                        patientData: patient,
                    })
                    history.push(
                        routes.geneticAnalyses.registerPatientPhysicalDescription.buildUrl(
                            barcode,
                        ),
                    )
                })
            } else {
                api.loadPatientDetails(data.patientId).then((all) => {
                    const { patientData, ...details } = all
                    mergeItem(barcode, {
                        newPatient: false,
                        patientId: data.patientId,
                        patientName: data.patientName,
                        patientData,
                        patientDetails: details,
                    })
                    history.push(
                        routes.geneticAnalyses.registerPatientPhysicalDescription.buildUrl(
                            barcode,
                        ),
                    )
                })
            }
        },
    })

    const onPrevious = () => {
        history.push(routes.geneticAnalyses.addback.buildUrl(barcode))
    }

    const onSkip = () => {
        history.push(routes.geneticAnalyses.registerSummary.buildUrl(barcode))
    }

    function autocompletePatients(pattern) {
        if (!pattern) {
            return Promise.resolve([])
        }
        return api.searchPatients(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
            }))
        })
    }

    function updatePatient(patient) {
        setPatient(patient)
        formist.change("patientId", patient.value)
        formist.change("patientName", patient.label)
    }

    const patientsOptions = patient ? [patient] : []

    return (
        <>
            <Wizard
                title={`Patient for: ${barcode}`}
                {...formist.getFormProps()}
                onPrevious={onPrevious}
                onUndo={undo(history, barcode)}
                buttons={[
                    "undo",
                    "previous",
                    "next",
                    <Button key="skip" onClick={onSkip}>
                        Skip
                    </Button>,
                ]}>
                <div className="form-row">
                    <CheckField
                        label="Anonymous"
                        {...formist.getFieldProps("anonymous")}
                    />
                </div>
                {formist.values.anonymous ? null : (
                    <div className="form-row">
                        <AutocompleteCreatableSelectField
                            placeholder="Select..."
                            label="Patient"
                            width="col"
                            options={patientsOptions}
                            value={patient?.value || ""}
                            onChange={(e) => updatePatient(e.target)}
                            load={autocompletePatients}
                            onCreate={onCreate}
                        />
                    </div>
                )}
            </Wizard>
        </>
    )
}

export default withRouter(RegisterPatientSelect)
