import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import TextField from "../shared/components/textfield"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import SuggestTextField from "../shared/components/suggesttextfield"
import LocalizedTextField from "../shared/components/localized-textfield"
import RankedRangeListEdit from "./ranked-range-list-edit"
import LabeledPointListEdit from "./labeledpoint-list-edit"

const SectionRatioEdit = ({ section, onSave, bacteria = [], onCancel }) => {
    const model = {
        ...section,
        numerator: b64DecodeUnicode(section.numerator),
        denominator: b64DecodeUnicode(section.denominator),
        ranks: section.ranks.map(b64DecodeUnicode),
        points: section.points.map(b64DecodeUnicode),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        ratioType: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        notes: yup.string(),
        numerator: yup
            .string()
            .test(
                "valid-bacteria",
                "Unknown or duplicated bacterium",
                (value) =>
                    bacteria.filter((b) => b.level + ";" + b.group === value)
                        .length === 1,
            ),
        denominator: yup
            .string()
            .test(
                "valid-bacteria",
                "Unknown or duplicated bacterium",
                (value) =>
                    bacteria.filter((b) => b.level + ";" + b.group === value)
                        .length === 1,
            ),
        ranks: yup.array().of(yup.string().required()),
        points: yup.array().of(yup.string().required()),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        numerator: b64EncodeUnicode(formist.values.numerator),
                        denominator: b64EncodeUnicode(
                            formist.values.denominator,
                        ),
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                        points: formist.values.points.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const suggestions = bacteria.map((b) => `${b.level};${b.group}`)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <LocalizedTextField
                    label="Subtitle"
                    width="col"
                    {...formist.getFieldProps("subtitle")}
                />
                <LocalizedTextField
                    label="Notes"
                    width="col"
                    {...formist.getFieldProps("notes")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Ratio Type"
                    width="col"
                    {...formist.getFieldProps("ratioType")}
                />
                <SuggestTextField
                    suggestions={suggestions}
                    label="Numerator Bacterium"
                    width="col"
                    {...formist.getFieldProps("numerator")}
                    onSelected={(b) =>
                        formist.getFieldProps("numerator").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
                <SuggestTextField
                    suggestions={suggestions}
                    label="Denominator Bacterium"
                    width="col"
                    {...formist.getFieldProps("denominator")}
                    onSelected={(b) =>
                        formist.getFieldProps("denominator").onChange({
                            target: {
                                value: b,
                            },
                        })
                    }
                />
            </div>
            <div className="form-row">
                <div>
                    <h5>Ranks</h5>
                    <RankedRangeListEdit
                        freeRanks
                        withLabel
                        field={{
                            ...formist.getFieldProps("ranks"),
                        }}
                        errors={formist.getFieldProps("ranks").error}
                    />
                </div>
                <div style={{ marginLeft: 50 }}>
                    <h5>Points</h5>
                    <LabeledPointListEdit
                        field={{
                            ...formist.getFieldProps("points"),
                        }}
                        errors={formist.getFieldProps("points").error}
                    />
                </div>
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionRatioEdit
