import React from "react"
import Wizard from "../../views/shared/components/wizard"
import useFormist from "react-formist"
import { drugsSchema as schema } from "../patients/schemas"
import { unflatten } from "../../shared/fun"
import { getItem } from "./storage"
import { apis } from "../../shared/apis"
import { routes } from "../../shared/routing"
import { mergeItem } from "./storage"
import { withRouter } from "react-router"
import { undo } from "./form"

import PatientDrugsForm, { detailsToModel } from "../patients/patient-drugs"

import { objToChecks } from "../patients/fun"

const RegisterPatientDrugs = ({ match, history }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)
    const initialData = detailsToModel(geneticAnalysis.patientDetails.drugs)
    const formist = useFormist(initialData, {
        schema,
        onSubmit: (formData) => {
            const {
                additionalDrugsInfo,
                additionalAllergiesInfo,
                additionalNotes,
                ...flatData
            } = formData
            const data = {
                drugs: objToChecks(unflatten(flatData)),
                additionalDrugsInfo,
                additionalAllergiesInfo,
                additionalNotes,
            }
            apis.patients.drugs
                .updateOne(geneticAnalysis.patientId, barcode, data)
                .then(() => {
                    mergeItem(barcode, {
                        patientDetails: {
                            ...geneticAnalysis.patientDetails,
                            drugs: data,
                        },
                    })
                    history.push(
                        routes.geneticAnalyses.registerEmails.buildUrl(barcode),
                    )
                })
        },
    })
    return (
        <Wizard
            title={`Drugs for: ${barcode}`}
            onUndo={undo(history, barcode)}
            onPrevious={() => history.goBack()}
            {...formist.getFormProps()}>
            <PatientDrugsForm formist={formist} />
        </Wizard>
    )
}

export default withRouter(RegisterPatientDrugs)
