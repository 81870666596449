import React from "react"
import { getLocalizedText } from "../../shared/locale"
import "./histogram.scss"

const SectionHistogram = ({ item, style, language = "it" }) => {
    const {
        name,
        description,
        histogramType = "PositiveBar-3",
        value = "",
    } = item

    const [type, barsCount] = histogramType.split("-")
    const bars = [...Array(Number(barsCount)).keys()]
    return (
        <div
            data-testid="section-histogram"
            style={style}
            className={"histogram-container histogram-container-" + type}>
            <div>
                <h1 className="h2 mt-3 r-title">
                    {getLocalizedText(name, language)}
                </h1>
            </div>
            <p>{getLocalizedText(description, language)}</p>
            <div
                className={`histogram-bars histogram-bars-${type} histogram-bars-${value.toLowerCase()}`}>
                {bars.map((bar) => (
                    <div
                        key={bar}
                        className={`histogram-bar histogram-bar-${bar}`}></div>
                ))}
            </div>
        </div>
    )
}

export default SectionHistogram
