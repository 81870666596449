import React from "react"
import SectionHeader from "../shared/components/section-header"
import Comment, { CommentAuthor, CommentAction } from "@atlaskit/comment"

const SectionRead = ({
    section,
    onEdit,
    onUp,
    onDown,
    onRemove,
    onClone,
    mode = "read",
}) => {
    const { name, type, index, htmlElementId } = section

    return (
        <div>
            <Comment
                author={
                    <CommentAuthor>{`${
                        index + 1
                    }. ${name} (${htmlElementId})`}</CommentAuthor>
                }
                type={type}
                actions={
                    mode === "read"
                        ? [
                              <CommentAction key="1" onClick={onEdit}>
                                  Edit
                              </CommentAction>,
                              <CommentAction key="2" onClick={onUp}>
                                  Up
                              </CommentAction>,
                              <CommentAction key="3" onClick={onDown}>
                                  Down
                              </CommentAction>,
                              <CommentAction key="4" onClick={onRemove}>
                                  Remove
                              </CommentAction>,
                              <CommentAction key="5" onClick={onClone}>
                                  Clone
                              </CommentAction>,
                          ]
                        : []
                }
            />
            <SectionHeader title="" />
        </div>
    )
}

export default SectionRead
