import { hormonSpecifiers } from "./schemas"

export const addMappers = {
    toApiModel(source) {
        return {
            name: source.name,
            description: source.description,
            variants: source.variants,
            uom: source.uom,
            attributes: source.attributes,
        }
    },
}

function attributesToFormModel(attributes) {
    return Object.keys(hormonSpecifiers).reduce(
        (acc, s) => ({
            ...acc,
            [s]: attributes[s] || [],
        }),
        {},
    )
}

export const editMappers = {
    toFormModel(source) {
        return {
            name: source.name,
            description: source.description,
            uom: source.uom,
            variants: source.variants,
            attributes: attributesToFormModel(source.attributes),
        }
    },
    toApiModel(source) {
        const addModel = addMappers.toApiModel(source)
        return {
            ...addModel,
        }
    },
}
