import React from "react"
import SectionHeader from "../shared/components/section-header"
import Textfield from "@atlaskit/textfield"
import { withRouter } from "react-router"
import { mkApi } from "./api"
import DateField from "./date"
import { useAsync } from "react-async"
import Spinner from "@atlaskit/spinner"

const ReadOnlyTextField = ({ name, label, value }) => {
    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <Textfield name={name} width="xlarge" isReadOnly value={value} />
        </div>
    )
}

const ShowGeneticAnalysis = ({ templates, geneticAnalysis }) => {
    const { barcode, registeredDate, analysedDate, templateIds, layoutIds } =
        geneticAnalysis

    const templateNames = templates.items
        .filter((x) => templateIds.includes(x.id))
        .map((x) => x.name)
        .join(", ")

    return (
        <>
            <SectionHeader title={`Genetic Analysis (${barcode})`} />
            <DateField
                name="registered-date"
                label="Registered on:"
                date={registeredDate}
            />
            <DateField
                name="analysed-date"
                label="Analysed on:"
                date={analysedDate}
            />
            <ReadOnlyTextField
                name="templates"
                label="Templates"
                value={templateNames}
            />
            <ReadOnlyTextField name="layout" label="Layout" value={layoutIds} />
        </>
    )
}

const loadData = ({ api, barcode }) => {
    return Promise.all([api.loadTemplates(), api.load(barcode)])
}

export const ShowGeneticAnalysisFetch = ({ match }) => {
    const api = mkApi()
    const barcode = match.params.id
    const { data, error, isLoading } = useAsync({
        promiseFn: loadData,
        api,
        barcode,
    })

    if (isLoading) return <Spinner size="xlarge" isCompleting={false} />
    if (error) return `Something went wrong: ${error.message}`
    if (data) {
        const [templates, geneticAnalysis] = data
        return (
            <ShowGeneticAnalysis
                templates={templates}
                geneticAnalysis={geneticAnalysis}
            />
        )
    }
    return null
}

export default withRouter(ShowGeneticAnalysisFetch)
