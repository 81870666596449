import { match } from "../../shared/store"

export const initialState = {
    isLoading: true,
    pattern: "",
    hormones: [],
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOAD-HORMONES", handleLoad)
        .case("CLEAR-HORMONES", handleClear)
        .case("SEARCH-HORMONES", handleSearch)
        .case("EDIT-HORMON", handleEdit)
        .case("ADD-HORMON", handleAdd)
        .apply(state)
}

export const actions = {
    loadAll(data) {
        return { type: "LOAD-HORMONES", data }
    },
    clear() {
        return { type: "CLEAR-HORMONES" }
    },
    search(data) {
        return { type: "SEARCH-HORMONES", data }
    },
    edit(data) {
        return { type: "EDIT-HORMON", data }
    },
    add(data, reload) {
        return { type: "ADD-HORMON", data, reload }
    },
}

const handleLoad = (state, action) => {
    const { items, pagination } = action.data
    return {
        isLoading: false,
        hormones: items,
        pattern: "",
        ...pagination,
    }
}

const handleClear = () => {
    return {
        isLoading: false,
        hormones: [],
        pattern: "",
        pageNumber: undefined,
        pageSize: undefined,
        totalPages: undefined,
    }
}

const handleSearch = (state, action) => {
    const { items, pagination, pattern } = action.data
    return {
        isLoading: false,
        hormones: items,
        pattern,
        ...pagination,
    }
}

const handleEdit = (state, action) => {
    const { hormones } = state
    const { name } = action.data

    const newHormones = hormones.map((b) => (b.name === name ? action.data : b))
    return {
        hormones: newHormones,
    }
}

const handleAdd = (state, action) => {
    const { hormones } = state

    const newHormones = [action.data, ...hormones]
    return action.reload
        ? { hormones: [] }
        : {
              hormones: newHormones,
          }
}
