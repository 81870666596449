import React, { Fragment, useEffect, useState } from "react"
import { withRouter } from "react-router"
import { useStateContext, withStateContext } from "../../shared/state-context"
import { initialState, reducer } from "./store"
import { mkApi } from "./api"
import { routes } from "../../shared/routing"
import useFormist from "react-formist"
import * as yup from "yup"
import TextField from "../shared/components/textfield"
import Form from "../shared/components/form"
import { SelectField } from "../shared/components/selectfield"
import { analysisTypes } from "../../analysisTypes"

const empty = {
    name: "",
    title: "",
    analysisType: "MacroBiota",
    analysisSubType: "Unknown",
    defaultTemplate: false,
    baseTemplate: null,
}

const schema = yup.object().shape({
    name: yup.string().required(),
    title: yup.string().required(),
    analysisType: yup.string().required(),
    analysisSubType: yup.string(),
    defaultTemplate: yup.boolean().required(),
    baseTemplate: yup.string().nullable(),
})

const AddTemplate = (props) => {
    const { dispatch } = useStateContext()
    const [allTemplates, setAllTemplates] = useState([])

    const api = mkApi(dispatch)
    useEffect(() => {
        api.loadTemplates().then((templates) =>
            setAllTemplates(templates.items),
        )
    }, [])
    const { history } = props
    const formist = useFormist(empty, {
        schema,
        onSubmit: (template) => {
            add(template)
        },
    })

    const add = (template) => {
        api.add(template).then(() => history.push(routes.templates.index.url))
    }

    const analysisType = formist.getFieldProps("analysisType").value
    const analysisSubType =
        formist.getFieldProps("analysisSubType").value || "Unknown"

    const baseTemplates = [
        {
            value: null,
            label: " - None - ",
        },
        ...allTemplates
            .filter(
                (t) =>
                    (!analysisType || t.analysisType === analysisType) &&
                    (analysisSubType === "Unknown" ||
                        t.analysisSubType === analysisSubType),
            )
            .map((t) => ({
                value: t.id,
                label: t.name,
            })),
    ]

    const currentAnalysisType = analysisTypes.find(
        (a) => a.value === formist.getFieldProps("analysisType").value,
    )

    const analysisSubTypes = [
        {
            label: " - None - ",
            value: "Unknown",
        },
        ...(currentAnalysisType?.subTypes || []),
    ]

    return (
        <Fragment>
            <Form
                size="full"
                title="New Template"
                {...formist.getFormProps()}
                primaryText="Add"
                onCancel={() => history.goBack()}>
                <div className="form-group">
                    <div className="form-row">
                        <TextField
                            label="Name"
                            width="col"
                            {...formist.getFieldProps("name")}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <TextField
                        label="Title"
                        width="col"
                        {...formist.getFieldProps("title")}
                    />
                </div>
                <div className="form-row">
                    <SelectField
                        label="Analysis Type"
                        width="col"
                        options={analysisTypes}
                        {...formist.getFieldProps("analysisType")}
                    />
                    {analysisSubTypes.length > 1 ? (
                        <SelectField
                            label="Analysis Sub Type"
                            width="col"
                            options={analysisSubTypes}
                            {...formist.getFieldProps("analysisSubType")}
                        />
                    ) : null}
                </div>
                <div className="form-row">
                    <SelectField
                        label="Base Template"
                        width="col"
                        options={baseTemplates}
                        {...formist.getFieldProps("baseTemplate")}
                    />
                </div>
            </Form>
        </Fragment>
    )
}

export default withStateContext(withRouter(AddTemplate), initialState, reducer)
