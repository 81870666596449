import React from "react"
import { create } from "zustand"

export const useDirtyStore = create((set) => ({
    dirty: {},
    setDirty: (item, dirty) =>
        set((state) => ({ dirty: { ...state.dirty, [item]: dirty } })),
}))

const DirtyNotifier = () => {
    const isDirty = useDirtyStore((state) =>
        Object.entries(state.dirty).reduce((acc, cur) => acc || cur[1], false),
    )
    return <div id="dirty-notify" className={`${isDirty ? "dirty" : ""}`}></div>
}

export default DirtyNotifier
