import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import RichTextEditorField from "../shared/components/rich-text-editorfield"
import LocalizedTextField from "../shared/components/localized-textfield"
import CheckField from "../shared/components/checkfield"

const schema = yup.object().shape({
    id: yup.string().required(),
    name: yup
        .string()
        .test(
            "valid-name",
            "name is a required field",
            (value) => value.replace("#", "").trim() !== "",
        ),
    htmlElementId: yup.string().required(),
    type: yup.string().required(),
    index: yup.number().min(0).required(),
    content: yup.string().required(),
    description: yup.string(),
    inSummary: yup.boolean().default(false),
})

const SectionFreetextEdit = ({ section, onSave, onCancel }) => {
    const formist = useFormist(section, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e, content) => {
        if (e && e.preventDefault) {
            e.preventDefault()
        }
        formist
            .validate()
            .then((errors) => {
                if (!errors) onSave({ ...formist.values, content })
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <h4>Include section</h4>
            {
                <div className="form-row">
                    <CheckField
                        label="in Sinthesys"
                        width="col"
                        {...formist.getFieldProps("inSummary")}
                    />
                </div>
            }
            <RichTextEditorField
                {...formist.getFieldProps("content")}
                onSave={onLocalSave}
                onCancel={onCancel}
            />
        </div>
    )
}

export default SectionFreetextEdit
