import * as yup from "yup"

export const addEmpty = {
    code: "",
    variants: [],
}

const codeSchema = yup.string().required()
const genoTypeSchema = yup.string().required()
const variantNameSchema = yup.string().required()
const alleleSchema = yup.string().required()
const alleleEffectTypeValueSchema = yup.number().required()

const variantsSchema = yup
    .array()
    .of(
        yup.object().shape({
            genoType: genoTypeSchema,
            genoTypeFileAlias: genoTypeSchema,
            name: variantNameSchema,
            allele1: alleleSchema,
            alleleeffectvalue1: alleleEffectTypeValueSchema,
            allele2: alleleSchema,
            alleleeffectvalue2: alleleEffectTypeValueSchema,
            allele3: alleleSchema,
            alleleeffectvalue3: alleleEffectTypeValueSchema,
            allele4: alleleSchema,
            alleleeffectvalue4: alleleEffectTypeValueSchema,
        }),
    )
    .min(1)

export const addSchema = yup.object().shape({
    code: codeSchema,
    variants: variantsSchema,
})

export const editSchema = yup.object().shape({
    code: codeSchema,
    variants: variantsSchema,
})
