import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import SectionDataSourceListEdit from "./section-datasource-list-edit"
import { CommentAction } from "@atlaskit/comment"
import { pipe, identity, converge } from "ramda"
import CheckField from "../shared/components/checkfield"
import LocalizedTextField from "../shared/components/localized-textfield"

const appendLevel = (level, bacterium) =>
    bacterium.indexOf(";") === -1 ? level + ";" + bacterium : bacterium

const findByGroup = (bacteria) => (bacterium) =>
    bacteria.filter(
        (g) => g.group === bacterium || g.level + ";" + g.group === bacterium,
    )[0].level

const SectionListEdit = ({ section, bacteria = [], onSave, onCancel }) => {
    const model = {
        ...section,
        bacteria: section.bacteria.map((b) => atob(b)),
    }

    const bacteriaSuggestions = bacteria.map((b) => b.group)
    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        missingValueLabel: yup.string(),
        note: yup.string(),
        semaphore: yup.boolean().required(),
        bacteria: yup
            .array()
            .of(
                yup
                    .string()
                    .test(
                        "valid-bacteria",
                        "Unknown or duplicated bacterium",
                        (value) =>
                            value.trim() === "" ||
                            bacteria.filter(
                                (b) =>
                                    b.group === value ||
                                    b.level + ";" + b.group === value,
                            ).length === 1,
                    ),
            )
            .min(1)
            .required(),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors) {
                    onSave({
                        ...formist.values,
                        bacteria: formist.values.bacteria
                            .filter((b) => b.trim() !== "")
                            .map(
                                pipe(
                                    converge(appendLevel, [
                                        findByGroup(bacteria),
                                        identity,
                                    ]),
                                    btoa,
                                ),
                            ),
                    })
                }
            })
            .catch(() => {})
    }

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
            </div>
            <div className="form-row">
                <div className="r-col-20">
                    <div>
                        <CheckField
                            className="ml-1"
                            width="col"
                            {...formist.getFieldProps("semaphore")}
                        />
                        <label style={{ marginLeft: 5 }}>Semaphore</label>
                    </div>
                </div>
                <div style={{ marginLeft: 50 }}>
                    <SectionDataSourceListEdit
                        dataSourceField={formist.getFieldProps("bacteria")}
                        errors={formist.errors}
                        hideLevel
                        suggestions={bacteriaSuggestions}
                    />
                </div>
                <LocalizedTextField
                    label="Missing Value Label"
                    width="col"
                    {...formist.getFieldProps("missingValueLabel")}
                />
                <LocalizedTextField
                    label="Notes"
                    width="col"
                    {...formist.getFieldProps("note")}
                />
            </div>

            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionListEdit
