import React from "react"
import RadioCheckList from "../shared/components/radio-check-list"
import TextField from "../shared/components/textfield"
import { completionInDays } from "../../analysisTypes"
import { addDays, toIso, toIta, toDays } from "../../shared/dates"

function completionDate(inputDays) {
    const days = Math.max(1, inputDays)
    return days ? addDays(days) : addDays(1)
}

function toYesNo(value) {
    return value ? "yes" : "no"
}

function toBool(value) {
    return value === "yes"
}

export function toModel({
    shouldSendToPatient,
    shouldSendToReferral,
    expectedCompletionDate,
}) {
    return {
        shouldSendToPatient: toBool(shouldSendToPatient),
        shouldSendToReferral: toBool(shouldSendToReferral),
        expectedCompletionDate: toIso(expectedCompletionDate),
    }
}

export function toForm({
    analysisType,
    sendRules = { patient: false, referral: false },
    dueDate,
    registeredDate,
}) {
    const days = completionInDays(analysisType)
    return {
        shouldSendToPatient: toYesNo(sendRules.patient),
        shouldSendToReferral: toYesNo(sendRules.referral),
        expectedCompletionDate: dueDate ? toIta(dueDate) : completionDate(days),
        completionInDays: dueDate
            ? toDays(toIta(registeredDate), toIta(dueDate))
            : days,
    }
}

const yesNoOptions = [
    { label: "No", value: "no" },
    { label: "Yes", value: "yes" },
]

const EmailsFields = ({ formist }) => {
    const updateCompletionDate = (e) => {
        formist.change("completionInDays", e.target.value)
        formist.change("expectedCompletionDate", completionDate(e.target.value))
    }

    return (
        <>
            <div className="row">
                <RadioCheckList
                    label="Patient"
                    style={{ marginLeft: "1rem" }}
                    items={yesNoOptions}
                    {...formist.getFieldProps("shouldSendToPatient")}
                />
            </div>
            <div className="row">
                <RadioCheckList
                    label="Referral"
                    style={{ marginLeft: "1rem" }}
                    items={yesNoOptions}
                    {...formist.getFieldProps("shouldSendToReferral")}
                />
            </div>
            <div className="row">
                <TextField
                    type="number"
                    label="Completion in days"
                    width="col"
                    {...formist.getFieldProps("completionInDays")}
                    onChange={updateCompletionDate}
                />
            </div>
            <div className="row">
                <TextField
                    disabled={true}
                    label="Expected analysis completion"
                    width="col"
                    {...formist.getFieldProps("expectedCompletionDate")}
                />
            </div>
        </>
    )
}

export default EmailsFields
