export function match(typeOf) {
    const t = typeOf.toLowerCase()
    let data = {}
    return {
        case(options) {
            data = { ...data, ...options }
            return this
        },
        apply(state) {
            if (!data[t])
                throw Error("Missing section case expression in match: " + t)
            return data[t](state)
        },
    }
}

export function byIndex(a, b) {
    return a.index > b.index ? 1 : b.index > a.index ? -1 : 0
}
