import React from "react"
import { withRouter } from "react-router"
import { Form } from "./form"
import Button from "@atlaskit/button"

export const Wizard = ({
    onUndo = () => {},
    onNext,
    onPrevious,
    onSubmit,
    onCancel,
    nextText = "Next",
    previousText = "Previous",
    undoText = "Undo",
    buttons = ["undo", "previous", "next"],
    ...props
}) => {
    const wizardButtons = {
        undo: (
            <Button key="undo" onClick={onUndo}>
                {undoText}
            </Button>
        ),
        previous: "cancel",
        next: "submit",
    }
    return (
        <Form
            {...props}
            primaryText={nextText}
            secondaryText={previousText}
            onSubmit={onNext || onSubmit}
            onCancel={onPrevious || onCancel}
            buttons={buttons.map((b) => wizardButtons[b] || b)}
        />
    )
}

export default withRouter(Wizard)
