import { postFile } from "../../shared/ajax"

export function mkApi() {
    return {
        uploadLabResult(file, type) {
            return postFile("/api/files/" + type, file).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
    }
}
