function isDivisible(num, dividend) {
    return num % dividend === 0
}

const defaultSpans = [0.5, 1, 2, 3, 4, 5, 8, 10, 20, 23, 30, 40, 50]

export function getTicksSpan(min, max, factor = 0.2, spans = defaultSpans) {
    const size = max - min
    const candidate = factor * size
    const validSpans = spans.filter((span) => isDivisible(size, span))
    return validSpans.reduce(
        (acc, cur) => {
            if (Math.abs(cur - candidate) < acc.distance)
                return { span: cur, distance: Math.abs(cur - candidate) }
            return acc
        },
        { span: 1, distance: Number.MAX_VALUE },
    ).span
}
