import React from "react"
import { match } from "../../shared/sections"
import SectionRead from "./section-read"
import SectionCompareEdit from "./section-compare-edit"
import SectionEnterotypeEdit from "./section-enterotype-edit"
import SectionLeaderboardEdit from "./section-leaderboard-edit"
import SectionListEdit from "./section-list-edit"
import SectionTableEdit from "./section-table-edit"
import SectionListGenoTypeEdit from "./section-listgenotype-edit"
import SectionListHormonesEdit from "./section-listhormones-edit"
import SectionHistogramEdit from "./section-histogram-edit"
import SectionSymbolEdit from "./section-symbol-edit"
import SectionDNAChartEdit from "./section-dnachart-edit"
import SectionHormonesChartEdit from "./section-hormoneschart-edit"
import SectionMacrobiotaChartEdit from "./section-macrobiotachart-edit"
import SectionFreetextEdit from "./section-freetext-edit"
import SectionSummaryEdit from "./section-summary-edit"
import SectionRatioEdit from "./section-ratio-edit"
import SectionSummaryHormonesEdit from "./section-summaryhormones-edit"
import SectionImageEdit from "./section-image-edit"
import SectionGaugeHormonesEdit from "./section-gaugehormones-edit"
import SectionRatioGaugeHormonesEdit from "./section-ratiogaugehormones-edit"
import SectionMultiGaugeHormonesEdit from "./section-multigaugehormones-edit"
import SectionLinesHormonesEdit from "./section-lineshormones-edit"
import SectionExperimentalEdit from "./section-experimental-edit"
import SectionBalanceEdit from "./section-balance-edit"

const SectionEdit = (props) => {
    const { section } = props
    return match(section.type)
        .case({
            leaderboard() {
                return <SectionLeaderboardEdit {...props} />
            },
            leaderbarchart() {
                return <SectionLeaderboardEdit {...props} />
            },
            compare() {
                return <SectionCompareEdit {...props} />
            },
            list() {
                return <SectionListEdit {...props} />
            },
            table() {
                return <SectionTableEdit {...props} />
            },
            listgenotype() {
                return <SectionListGenoTypeEdit {...props} />
            },
            enterotype() {
                return <SectionEnterotypeEdit {...props} />
            },
            dnachart() {
                return <SectionDNAChartEdit {...props} />
            },
            freetext() {
                return <SectionFreetextEdit {...props} />
            },
            macrobiotachart() {
                return <SectionMacrobiotaChartEdit {...props} />
            },
            histogram() {
                return <SectionHistogramEdit {...props} />
            },
            symbol() {
                return <SectionSymbolEdit {...props} />
            },
            summary() {
                return <SectionSummaryEdit {...props} />
            },
            ratio() {
                return <SectionRatioEdit {...props} />
            },
            image() {
                return <SectionImageEdit {...props} />
            },
            listhormones() {
                return <SectionListHormonesEdit {...props} />
            },
            gaugehormones() {
                return <SectionGaugeHormonesEdit {...props} />
            },
            summaryhormones() {
                return <SectionSummaryHormonesEdit {...props} />
            },
            hormoneschart() {
                return <SectionHormonesChartEdit {...props} />
            },
            multigaugehormones() {
                return <SectionMultiGaugeHormonesEdit {...props} />
            },
            lineshormones() {
                return <SectionLinesHormonesEdit {...props} />
            },
            ratiogaugehormones() {
                return <SectionRatioGaugeHormonesEdit {...props} />
            },
            experimental() {
                return <SectionExperimentalEdit {...props} />
            },
            balance() {
                return <SectionBalanceEdit {...props} />
            },
        })
        .apply(section)
}

const SectionItem = (props) => {
    const { section } = props
    if (section.isEditMode) {
        return (
            <>
                <div className="section-edit-modal-container">
                    <SectionRead {...props} mode="edit" />
                    <div className="section-edit-modal">
                        <SectionEdit {...props} />
                    </div>
                </div>
            </>
        )
    }
    return <SectionRead {...props} />
}

export default SectionItem
