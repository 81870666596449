export function getImages(all, folder) {
    return Object.keys(all).reduce(
        (acc, path) => ({
            ...acc,
            [path.substring(folder.length).split(".").slice(0, -1).join(".")]:
                all[path],
        }),
        {},
    )
}
