import React, { useState } from "react"
import TextField from "./textfield"
import {
    getTranslations,
    locales,
    updateTranslation,
} from "../../../shared/locale"
import Select from "@atlaskit/select"

const LocalizedTextField = (props) => {
    const { label, value, onChange, width, ...otherProps } = props
    const values = getTranslations(value)
    function changeLocalized(value, language) {
        onChange({
            target: {
                value: updateTranslation(value, language, values),
            },
        })
    }

    const localeOptions = locales.map((locale) => ({
        label: locale.language.substring(0, 2).toUpperCase(),
        value: locale.language,
    }))

    const [currentLocale, setCurrentLocale] = useState(localeOptions[0])

    return (
        <div className={`form-group ${width}`}>
            <label>{label}</label>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                {locales.map((locale) => (
                    <TextField
                        visible={currentLocale.value === locale.language}
                        key={locale}
                        value={values[locale.language]}
                        type="text"
                        width="col"
                        onChange={(evt) =>
                            changeLocalized(evt.target.value, locale.language)
                        }
                        {...otherProps}
                    />
                ))}
                <Select
                    className="locale-select"
                    classNamePrefix="react-select"
                    onChange={setCurrentLocale}
                    value={currentLocale}
                    options={localeOptions}
                />
            </div>
        </div>
    )
}

export default LocalizedTextField
