export const toSelectModel = (items, getId = (x) => x.id) =>
    items.map((x) => {
        return {
            value: getId(x),
            label: x.name,
        }
    })

const byAnalysisType = (value) => {
    return (x) => x.analysisType === value
}

const byAnalysisSubType = (value) => {
    return (x) =>
        value === "Unknown" ||
        x.analysisSubType === value ||
        x.analysisSubType === "Unknown"
}

const byReferral = (value) => {
    return (x) => x.defaultLayout || (value ? x.referralCode === value : true)
}

export const templatesFilterBy = (items, analysisType, analysisSubType) => {
    return items
        .filter(byAnalysisType(analysisType))
        .filter(byAnalysisSubType(analysisSubType))
}

export const layoutsFilterBy = (
    items,
    analysisType,
    analysisSubType,
    referral,
) => {
    return items
        .filter(byAnalysisType(analysisType))
        .filter(byAnalysisSubType(analysisSubType))
        .filter(byReferral(referral))
}
