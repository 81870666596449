import React from "react"
import TableField, { printableErrors } from "../shared/components/tablefield"

const genoTypesSchema = [
    {
        field: "genoType",
        label: "GenoType",
    },
    {
        field: "effect",
        label: "Effect or Role",
        width: 300,
    },
]

const SectionGenoTypeListEdit = ({ genoTypesField, errors = {} }) => {
    const { value, onChange, error, ...other } = genoTypesField
    const genoTypes = value.map((g) => ({
        genoType: g.substring(0, g.indexOf(";")),
        effect: g.substring(g.indexOf(";") + 1),
    }))
    const onGenoTypesChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map((g) => g.genoType + ";" + g.effect)
                    : [],
            },
        })
    }
    return (
        <div className="form-row">
            <TableField
                label=""
                width="col"
                editable
                moveRows
                schema={genoTypesSchema}
                errors={printableErrors(errors, "values")}
                {...other}
                value={genoTypes}
                onChange={onGenoTypesChange}
            />
        </div>
    )
}

export default SectionGenoTypeListEdit
