import React from "react"
import RadioCheckList from "../shared/components/radio-check-list"
import LocalizedTextField from "../shared/components/localized-textfield"

const ImplicationsEdit = ({ formist, field }) => {
    function onTextChange(evt) {
        if (!evt.target.value || evt.target.value === "#") {
            formist.change(`${field}.type`, "")
        }
        formist.getFieldProps(`${field}.text`).onChange(evt)
    }
    return (
        <div className="form-row">
            <LocalizedTextField
                label="Implications"
                width="col-md-9"
                {...formist.getFieldProps(`${field}.text`)}
                onChange={(evt) => onTextChange(evt)}
            />

            <RadioCheckList
                style={{ marginTop: "2.2rem", marginLeft: "1rem" }}
                items={[
                    { label: "Good", value: "good" },
                    { label: "Bad", value: "bad" },
                ]}
                {...formist.getFieldProps(`${field}.type`)}
            />
        </div>
    )
}

export default ImplicationsEdit
