import React, { useState } from "react"
import { SelectField } from "../shared/components/selectfield"
import DefaultFormButtonBar from "../shared/components/default-form-button-bar"
import { getImages } from "../../shared/images"
import { getLocalizedText } from "../../shared/locale"

const images = getImages(
    import.meta.glob("../../images/*.png", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../images/",
)

const svgImages = getImages(
    import.meta.glob("../../images/*.svg", {
        eager: true,
        query: "?url",
        import: "default",
    }),
    "../../images/",
)

const SectionImage = ({
    item,
    language = "it",
    style,
    readOnly = false,
    onSave,
    onCancel,
    autoSave = false,
}) => {
    const { name = "", values = [], value, imageType = "" } = item
    const [selectedImage, setSelectedImage] = useState(value)

    const id = (imageType || name).split("#")[0] + "_" + selectedImage
    const image = images[id + "_" + language] || images[id]
    const svgImage = svgImages[id + "_" + language] || svgImages[id]

    const imagesOptions = values.map((i) => ({
        value: i,
        label: i,
    }))

    const onSaveHandler = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault()
        }
        onSave({
            ...item,
            value: selectedImage,
        })
    }

    const buttonsBar = () => {
        if (!readOnly && !autoSave) {
            return (
                <DefaultFormButtonBar
                    primaryText="Save"
                    onSubmit={onSaveHandler}
                    onCancel={onCancel}
                />
            )
        }
        // else render nothing
    }

    const onSelect = (e) => {
        setSelectedImage(e.target.value)
        if (autoSave) {
            onSave({
                ...item,
                value: e.target.value,
            })
        }
    }

    return readOnly && !image && !svgImage ? null : (
        <div data-testid="section-image" style={style}>
            <div>
                <div className="section-image-header">
                    <h1>{getLocalizedText(name, language)}</h1>
                </div>
                <div className="section-image-picture">
                    {!readOnly && imagesOptions.length > 0 ? (
                        <SelectField
                            label="Selected"
                            width="col"
                            value={selectedImage}
                            options={imagesOptions}
                            onChange={onSelect}
                        />
                    ) : null}
                    {image ? (
                        <img src={image} className={"section-image-" + id} />
                    ) : svgImage ? (
                        <object
                            data={svgImage}
                            className={"section-image-" + id}
                        />
                    ) : (
                        !readOnly && <span>No image found for {id}</span>
                    )}
                </div>
                {buttonsBar()}
            </div>
        </div>
    )
}

export default SectionImage
