import React from "react"
import styled from "styled-components"

const Container = styled.div`
    button {
        margin-right: 1rem;
    }
`

const ButtonBar = ({ children, buttonsClassName, separator = true }) => {
    return (
        <Container className={buttonsClassName}>
            {separator ? <hr /> : null}
            <div>{children}</div>
        </Container>
    )
}
export default ButtonBar
