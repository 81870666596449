import React from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Button from "@atlaskit/button"
import Form from "../shared/components/form"

export default function RemoveBarcode({
    enabled = false,
    onConfirm,
    onCancel,
    warningMessage,
}) {
    return enabled ? (
        <ModalDialog id="remove-barcode-dialog" heading="Remove Barcode">
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={onConfirm}>
                        Remove
                    </Button>,
                    "cancel",
                ]}
                primaryText="Remove"
                onCancel={onCancel}>
                <div className="form-row">{warningMessage}</div>
            </Form>
        </ModalDialog>
    ) : null
}
