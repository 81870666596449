import React from "react"
import TableField, { printableErrors } from "../shared/components/tablefield"

const schema = [
    {
        field: "x",
        label: "X",
    },
    {
        field: "y",
        label: "Y",
    },
    {
        field: "label",
        label: "Label",
    },
]

const LabeledPointListEdit = ({ field, errors = {} }) => {
    const { value, onChange, error, ...other } = field

    const points = value.map((r) => ({
        x: r.split(";")[0],
        y: r.split(";")[1],
        label: r.split(";")[2],
    }))

    const onPointChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map((r) => r.x + ";" + r.y + ";" + r.label)
                    : [],
            },
        })
    }
    return (
        <div className="form-row">
            <TableField
                label=""
                width="col"
                editable
                moveRows
                schema={schema}
                errors={printableErrors(errors, "values")}
                {...other}
                value={points}
                onChange={onPointChange}
            />
        </div>
    )
}

export default LabeledPointListEdit
