import { match } from "../store"

export const initialState = {
    user: {
        expired: false,
        token: null,
        email: "",
        error: "error",
    },
}

export const reducer = (state, action) => {
    return match(action)
        .case("LOGIN", handleLogin)
        .case("LOGIN_ERROR", handleLoginError)
        .case("EXPIRED_TOKEN", handleExpiredToken)
        .case("LOGOUT", handleLogout)
        .apply(state)
}

export const actions = {
    login(data) {
        return { type: "LOGIN", data }
    },
    loginError(error) {
        return { type: "LOGIN_ERROR", error }
    },
    tokenExpired() {
        return { type: "EXPIRED_TOKEN" }
    },
    logout() {
        return { type: "LOGOUT" }
    },
}

const handleLogin = (state, action) => {
    const user = action.data
    return {
        user: {
            ...state.user,
            ...user,
            expired: false,
            error: null,
        },
    }
}

const handleLoginError = (state, action) => {
    const error = action.error
    return {
        user: {
            ...initialState.user,
            error,
        },
    }
}

const handleExpiredToken = () => {
    return {
        user: {
            ...initialState.user,
            expired: true,
            error: null,
        },
    }
}

const handleLogout = () => {
    return {
        user: {
            ...initialState.user,
            error: null,
        },
    }
}
