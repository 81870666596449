import React from "react"
import {
    ContentState,
    Editor,
    EditorState,
    RichUtils,
    Modifier,
} from "draft-js"

import AlignmentStyleControls, {
    getAlignmentStyle,
} from "./rich-text/alignment"

import ColorStyleControls, { colorStyleMap } from "./rich-text/color"

import ListTypeControls, {
    setListType,
    getListType,
    getListStyle,
} from "./rich-text/list"

import ModalDialog from "@atlaskit/modal-dialog"

import StyleButton from "./rich-text/style-button"

export const PAGE_BREAK = ">---------------------------------------------<"

class RichtTextEditor extends React.Component {
    constructor(props) {
        super(props)

        let editorState
        if (props.markup) {
            const state = ContentState.createFromBlockArray(
                props.markup.contentBlocks,
                props.markup.entityMap,
            )
            editorState = EditorState.createWithContent(state)
        } else if (props.content) {
            editorState = EditorState.createWithContent(props.content)
        } else {
            editorState = EditorState.createEmpty()
        }

        this.state = {
            editorState: editorState,
            fullScreen: false,
            revision: props.revision || 1,
        }

        this.onChange = (editorState) => {
            if (this.listType) {
                editorState = setListType(editorState, this.listType)
                this.listType = null
            }
            this.setState({ editorState })
            if (this.props.onChange) {
                // Send the changes up to the parent component.
                const content = editorState
                    ? editorState.getCurrentContent()
                    : this.state.editorState.getCurrentContent()
                this.props.onChange(content)
            }
        }

        this.toggleBlockType = this._toggleBlockType.bind(this)
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this)
        this.toggleColorStyle = this._toggleColorStyle.bind(this)
        this.toggleAlignmentStyle = this._toggleAlignmentStyle.bind(this)
        this.toggleListType = this._toggleListType.bind(this)
        this.getBlockStyle = this._getBlockStyle.bind(this)
        this.handleReturn = this._handleReturn.bind(this)
        this.handleTab = this._handleTab.bind(this)
        this.toggleFullScreen = this._toggleFullScreen.bind(this)
        this.insertPageBreak = this._insertPageBreak.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.revision !== state.revision) {
            const newState = EditorState.createWithContent(props.content)
            if (props.onChange) {
                // Send the changes up to the parent component.
                props.onChange(newState.getCurrentContent())
            }
            return {
                editorState: newState,
                revision: props.revision,
            }
        }
        return { ...state }
    }

    _insertPageBreak() {
        const { editorState } = this.state
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection()

        const newContent = Modifier.insertText(
            currentContent,
            currentSelection,
            PAGE_BREAK,
        )

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            "insert-characters",
        )
        this.onChange(
            EditorState.forceSelection(
                newEditorState,
                newContent.getSelectionAfter(),
            ),
        )
    }

    _toggleFullScreen() {
        this.setState({
            fullScreen: !this.state.fullScreen,
        })
    }

    _handleReturn(_, editorState) {
        const block = editorState
            .getCurrentContent()
            .getBlockForKey(editorState.getSelection().getStartKey())
        this.listType = getListType(block)
    }

    _handleTab(e) {
        e.preventDefault()

        // assign a constant for the new editorState
        const newState = RichUtils.onTab(e, this.state.editorState, 4)

        // if a new editor state exists, set editor state to new state
        // and return 'handled', otherwise return 'not-handled
        if (newState) {
            this.setState({ editorState: newState })
            return "handled"
        } else {
            return "not-handled"
        }
    }

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            this.onChange(newState)
            return true
        }
        return false
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(this.state.editorState, blockType),
        )
    }
    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
        )
    }

    _toggleColorStyle(colorStyle) {
        const { editorState } = this.state
        const selection = editorState.getSelection()

        // Let's just allow one color at a time. Turn off all active colors.
        const nextContentState = Object.keys(colorStyleMap).reduce(
            (contentState, color) => {
                return Modifier.removeInlineStyle(
                    contentState,
                    selection,
                    color,
                )
            },
            editorState.getCurrentContent(),
        )

        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            "change-inline-style",
        )

        const currentStyle = editorState.getCurrentInlineStyle()

        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state, color)
            }, nextEditorState)
        }

        // If the color is being toggled on, apply it.
        if (!currentStyle.has(colorStyle)) {
            nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                colorStyle,
            )
        }

        this.onChange(nextEditorState)
    }

    _toggleAlignmentStyle(alignmentStyle) {
        this.onChange(
            RichUtils.toggleAlignment(this.state.editorState, alignmentStyle),
        )
    }

    _toggleListType(blockType, listType) {
        if (blockType === "unordered-list-item") {
            this._toggleUnorderedListType(listType)
        } else if (blockType === "ordered-list-item") {
            this._toggleOrderedListType(listType)
        }
    }

    _toggleUnorderedListType(listType) {
        this.onChange(
            RichUtils.toggleUnorderedListType(this.state.editorState, listType),
        )
    }

    _toggleOrderedListType(listType) {
        this.onChange(
            RichUtils.toggleOrderedListType(this.state.editorState, listType),
        )
    }

    _getBlockStyle(block) {
        return [getListStyle(block), getAlignmentStyle(block)]
            .filter((s) => s)
            .join(" ")
    }

    renderEditor() {
        const baseStyle = this.state.fullScreen
            ? styles.editorRootFullScreen
            : styles.editorRoot

        const style = this.props.disabled
            ? { ...baseStyle, pointerEvents: "none", opacity: 0.5 }
            : baseStyle
        return (
            <div
                style={style}
                className={
                    this.state.fullScreen ? "rich-text-editor-full" : ""
                }>
                <div style={styles.controls}>
                    <BlockStyleControls
                        editorState={this.state.editorState}
                        onToggle={this.toggleBlockType}
                    />
                    <ListTypeControls
                        editorState={this.state.editorState}
                        onToggle={this.toggleBlockType}
                        onToggleStyle={this.toggleListType}
                    />
                    <span className="mr-2">|</span>
                    <StyleButton
                        label="Page Break"
                        style="full-screen"
                        onToggle={this.insertPageBreak}
                    />
                    <span className="mr-2">|</span>
                    <StyleButton
                        label="Full Screen"
                        style="full-screen"
                        onToggle={this.toggleFullScreen}
                        active={this.state.fullScreen}
                    />
                </div>
                <div style={styles.controls}>
                    <InlineStyleControls
                        editorState={this.state.editorState}
                        onToggle={this.toggleInlineStyle}
                    />
                    <span className="mr-2">|</span>
                    <AlignmentStyleControls
                        editorState={this.state.editorState}
                        onToggle={this.toggleAlignmentStyle}
                    />
                    <span className="mr-2">|</span>
                    <ColorStyleControls
                        editorState={this.state.editorState}
                        onToggle={this.toggleColorStyle}
                    />
                </div>
                <div
                    style={styles.editor}
                    onClick={this.focus}
                    className="editor-container">
                    <Editor
                        editorState={this.state.editorState}
                        blockStyleFn={this.getBlockStyle}
                        onChange={this.onChange}
                        handleReturn={this.handleReturn}
                        onTab={this.handleTab}
                        placeholder={this.props.placeholder}
                        customStyleMap={colorStyleMap}
                    />
                </div>
            </div>
        )
    }

    render() {
        if (this.props.readOnly) {
            return (
                <Editor
                    editorState={this.state.editorState}
                    blockStyleFn={this.getBlockStyle}
                    customStyleMap={colorStyleMap}
                    readOnly="true"
                />
            )
        }
        const close = () => {
            this.setState({ fullScreen: false })
        }
        const save = () => {
            this.props.onChange(this.state.editorState.getCurrentContent())
            this.props.onSave()
            close()
        }
        if (this.state.fullScreen) {
            const actions = this.props.onSave
                ? [
                      { text: "Save", onClick: save },
                      { text: "Close", onClick: close },
                  ]
                : [{ text: "Close", onClick: close }]
            return (
                <ModalDialog
                    actions={actions}
                    onClose={close}
                    id="rich-text-editor-full-dialog"
                    width="100%"
                    heading="Editor"
                    shouldCloseOnOverlayClick={false}>
                    {this.renderEditor()}
                </ModalDialog>
            )
        }
        return this.renderEditor()
    }
}

const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
]

const BlockStyleControls = (props) => {
    const { editorState } = props
    const selection = editorState.getSelection()
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()

    return (
        <>
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </>
    )
}

const INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Monospace", style: "CODE" },
]

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle()

    return (
        <>
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </>
    )
}

const styles = {
    editorRoot: {
        background: "#fff",
        border: "1px solid #ddd",
        fontSize: "14px",
        padding: "15px",
        minHeight: "50em",
    },
    editorRootFullScreen: {
        background: "#fff",
        border: "1px solid #ddd",
        fontSize: "14px",
        padding: "15px",
    },
    editor: {
        borderTop: "1px solid #ddd",
        cursor: "text",
        fontSize: "16px",
        marginTop: "10px",
    },
    controls: {
        fontFamily: "Helvetica, sans-serif",
        fontSize: "14px",
        marginBottom: "5px",
        userSelect: "none",
    },
}

export default RichtTextEditor
