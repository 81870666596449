import React from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import TextField from "../shared/components/textfield"
import { CommentAction } from "@atlaskit/comment"
import SectionDataSourceListEdit from "./section-datasource-list-edit"
import { b64DecodeUnicode, b64EncodeUnicode } from "../../shared/base64"
import TextArea from "../shared/components/textarea"
import LocalizedTextField from "../shared/components/localized-textfield"

const SectionEnterotypeEdit = ({
    section,
    bacteria = [],
    onSave,
    onCancel,
}) => {
    const model = {
        ...section,
        bacteria: section.bacteria.map(b64DecodeUnicode),
    }

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        bacteria: yup
            .array()
            .of(
                yup
                    .string()
                    .test(
                        "valid-bacteria",
                        "Unknown or duplicated bacterium",
                        (value) =>
                            value.trim() === "" ||
                            bacteria.filter(
                                (b) => b.level + ";" + b.group === value,
                            ).length === 1,
                    ),
            )
            .required(),
        formula: yup.string(),
        descriptionOne: yup
            .string()
            .test(
                "valid-description-1",
                "descriptionOne is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        descriptionTwo: yup
            .string()
            .test(
                "valid-description-2",
                "descriptionTwo is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        descriptionThree: yup
            .string()
            .test(
                "valid-description-3",
                "descriptionThree is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        descriptionFour: yup.string(),
        descriptionFive: yup.string(),
        descriptionSix: yup.string(),
        valueOne: yup.string().required(),
        valueTwo: yup.string().required(),
        valueThree: yup.string().required(),
        valueFour: yup.string(),
        valueFive: yup.string(),
        valueSix: yup.string(),
    })

    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        bacteria: formist.values.bacteria
                            .filter((b) => b.trim() !== "")
                            .map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const bacteriaSuggestions = bacteria.map((b) => b.level + ";" + b.group)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <SectionDataSourceListEdit
                    dataSourceField={formist.getFieldProps("bacteria")}
                    errors={formist.errors}
                    suggestions={bacteriaSuggestions}
                    hideLevel={false}
                />
                <TextArea
                    label="Formula"
                    width="col"
                    {...formist.getFieldProps("formula")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 1"
                    width="r-col-20"
                    {...formist.getFieldProps("valueOne")}
                />
                <LocalizedTextField
                    label="Description 1"
                    width="col"
                    {...formist.getFieldProps("descriptionOne")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 2"
                    width="r-col-20"
                    {...formist.getFieldProps("valueTwo")}
                />
                <LocalizedTextField
                    label="Description 2"
                    width="col"
                    {...formist.getFieldProps("descriptionTwo")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 3"
                    width="r-col-20"
                    {...formist.getFieldProps("valueThree")}
                />
                <LocalizedTextField
                    label="Description 3"
                    width="col"
                    {...formist.getFieldProps("descriptionThree")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 4"
                    width="r-col-20"
                    {...formist.getFieldProps("valueFour")}
                />
                <LocalizedTextField
                    label="Description 4"
                    width="col"
                    {...formist.getFieldProps("descriptionFour")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 5"
                    width="r-col-20"
                    {...formist.getFieldProps("valueFive")}
                />
                <LocalizedTextField
                    label="Description 5"
                    width="col"
                    {...formist.getFieldProps("descriptionFive")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Value 6"
                    width="r-col-20"
                    {...formist.getFieldProps("valueSix")}
                />
                <LocalizedTextField
                    label="Description 6"
                    width="col"
                    {...formist.getFieldProps("descriptionSix")}
                />
            </div>
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
        </div>
    )
}

export default SectionEnterotypeEdit
