import React from "react"
import Email, { getEmailStatus } from "./report-emails"

const EmailMenu = ({
    layouts = [],
    barcode = "",
    emails = [],
    onSend,
    rules,
    attachmentRules,
}) => {
    if (layouts.length > 0) {
        const globalStatus = layouts.reduce((acc, layout) => {
            const status = getEmailStatus(emails, layout)
            return acc || status.IsSentToPatient || status.IsSentToReferral
        }, false)
        return (
            <div className="dashboard-menu">
                <Email
                    items={layouts.map((layout) => {
                        const status = getEmailStatus(emails, layout)
                        return {
                            barcode,
                            layout,
                            rules,
                            attachmentRules,
                            status,
                        }
                    })}
                    onSend={onSend}
                />
                <div className={globalStatus ? "icon-sent" : "no-icon"} />
            </div>
        )
    }

    return null
}

export default EmailMenu
