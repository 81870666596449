import React from "react"
import DropdownMenu, {
    DropdownItem,
    DropdownItemGroup,
} from "@atlaskit/dropdown-menu"

import { routes } from "../../shared/routing"
import StatusLink from "../shared/components/status-link"

function getLayoutName(layoutId, layouts) {
    const matching = layouts.filter((l) => l.id === layoutId)
    if (matching && matching.length) return matching[0].name
    return layoutId
}

const PrintMenu = ({
    layouts = [],
    barcode = "",
    token = "",
    status,
    allLayouts = [],
}) => {
    if (status !== "Analysed") return null
    if (layouts.length === 0) return <span>No Layout Set</span>
    if (layouts.length === 1)
        return (
            <>
                <div className="dashboard-menu">
                    <DropdownMenu trigger="Print" triggerType="button">
                        <DropdownItemGroup>
                            <DropdownItem key={layouts[0]}>
                                <div>
                                    <StatusLink
                                        item={{
                                            status,
                                        }}
                                        expectedStatus="analysed"
                                        linkText={`Complete`}
                                        to={{
                                            pathname: routes.reports.toPrintUrl(
                                                {
                                                    layout: layouts[0],
                                                    barcode,
                                                    reportMode: "complete",
                                                    token,
                                                },
                                            ),
                                        }}
                                        target="_blank"
                                    />
                                </div>
                                <div>
                                    <StatusLink
                                        item={{
                                            status,
                                        }}
                                        expectedStatus="analysed"
                                        linkText={`Summary`}
                                        to={{
                                            pathname: routes.reports.toPrintUrl(
                                                {
                                                    layout: layouts[0],
                                                    barcode,
                                                    reportMode: "summary",
                                                    token,
                                                },
                                            ),
                                        }}
                                        target="_blank"
                                    />
                                </div>
                            </DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                </div>
            </>
        )
    return (
        <div className="dashboard-menu">
            <DropdownMenu trigger="Print" triggerType="button">
                <DropdownItemGroup>
                    {layouts.map((layout) => (
                        <DropdownItem key={layout}>
                            <div>
                                <StatusLink
                                    item={{
                                        status,
                                    }}
                                    expectedStatus="analysed"
                                    linkText={`${getLayoutName(
                                        layout,
                                        allLayouts,
                                    )} (complete)`}
                                    to={{
                                        pathname: routes.reports.toPrintUrl({
                                            layout,
                                            barcode,
                                            reportMode: "complete",
                                            token,
                                        }),
                                    }}
                                    target="_blank"
                                />
                            </div>
                            <div>
                                <StatusLink
                                    item={{
                                        status,
                                    }}
                                    expectedStatus="analysed"
                                    linkText={`${getLayoutName(
                                        layout,
                                        allLayouts,
                                    )} (summary)`}
                                    to={{
                                        pathname: routes.reports.toPrintUrl({
                                            layout,
                                            barcode,
                                            reportMode: "summary",
                                            token,
                                        }),
                                    }}
                                    target="_blank"
                                />
                            </div>
                        </DropdownItem>
                    ))}
                </DropdownItemGroup>
            </DropdownMenu>
        </div>
    )
}

export default PrintMenu
