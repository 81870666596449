import React from "react"
import RadioCheckList from "../shared/components/radio-check-list"
import TextField from "../shared/components/textfield"

export const model = {
    guardian: "no",
    firstName: "",
    lastName: "",
    socialNumber: "",
    address: "",
    telephone: "",
    email: "",
}

const PatientGuardianForm = ({ formist }) => {
    const dataVisibility = {
        visibility:
            formist.getFieldProps("guardian").value === "yes"
                ? "visible"
                : "hidden",
    }
    return (
        <>
            <div className="form-row">
                <RadioCheckList
                    label="Legal guardian of a minor?"
                    style={{ marginLeft: "1rem" }}
                    items={[
                        { label: "No", value: "no" },
                        { label: "Yes", value: "yes" },
                    ]}
                    {...formist.getFieldProps("guardian")}
                />
            </div>
            <div className="form-row" style={dataVisibility}>
                <TextField
                    label="First Name"
                    width="col"
                    {...formist.getFieldProps("firstName")}
                />
                <TextField
                    label="Last Name"
                    width="col"
                    {...formist.getFieldProps("lastName")}
                />
            </div>

            <div style={dataVisibility} className="form-row">
                <TextField
                    label="Email"
                    width="col"
                    {...formist.getFieldProps("email")}
                />
            </div>
            <div style={dataVisibility} className="form-row">
                <TextField
                    label="Social Number"
                    width="col"
                    {...formist.getFieldProps("socialNumber")}
                />
            </div>
            <div style={dataVisibility} className="form-row">
                <TextField
                    label="Address"
                    width="col"
                    {...formist.getFieldProps("address")}
                />
            </div>
            <div style={dataVisibility} className="form-row">
                <TextField
                    label="Telephone"
                    width="col"
                    {...formist.getFieldProps("telephone")}
                />
            </div>
        </>
    )
}

export default PatientGuardianForm
