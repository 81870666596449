import React, { useState } from "react"
import ApproveEmailModal from "./approve-emails-modal"
import Button from "@atlaskit/button"

function Email({ api, geneticAnalysis, onSent, onError }) {
    const [showEmailsModal, setEmailsModal] = useState(false)

    const openModal = (e) => {
        e.preventDefault()
        setEmailsModal(true)
    }

    const closeModal = (e) => {
        e.preventDefault()
        setEmailsModal(false)
    }

    const confirmModal = (data) => {
        setEmailsModal(false)
        send(data)
    }

    const send = (options) => {
        api.sendApprovedEmail(geneticAnalysis.barcode, options)
            .then(() => {
                onSent()
            })
            .catch(onError)
    }

    return (
        <div className="ml-4">
            <Button appearance="primary" onClick={openModal}>
                Send
            </Button>
            <ApproveEmailModal
                geneticAnalysis={geneticAnalysis}
                enabled={showEmailsModal}
                onCancel={closeModal}
                onConfirm={confirmModal}
            />
        </div>
    )
}

export default Email
