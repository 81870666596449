export function match(action) {
    const data = {}
    return {
        case(actionType, reducer) {
            if (data[actionType])
                throw Error(
                    "Duplicated case expression in match: " + actionType,
                )
            data[actionType] = reducer
            return this
        },
        apply(state) {
            if (!data[action.type])
                throw Error("Missing case expression in match: " + action.type)
            const newState = data[action.type](state, action)
            return { ...state, ...newState }
        },
    }
}
