import React, { useState } from "react"
import { mkApi } from "./api"
import Form from "../shared/components/form"
import useFormist from "react-formist"
import { success } from "../../shared/notifications"
import { useStateContext, withStateContext } from "../../shared/state-context"
import Tabs from "@atlaskit/tabs"
import withFetch from "../shared/components/fetch"
import { fullSchema } from "./schemas"
import PatientForm, {
    detailsToModel,
    modelToDetails,
} from "../patients/patient"
import PatientPhysicalDescriptionForm from "../patients/patient-physical"
import PatientGuardianForm from "../patients/patient-guardian"
import PatientDiseasesForm from "../patients/patient-diseases"
import PatientDrugsForm from "../patients/patient-drugs"
import SubForm from "../shared/components/subform"
import { initialState, reducer } from "./store"

export const Edit = ({ data, history }) => {
    const { dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const [patientData] = data[0]
    const barcode = patientData.patientPhysicalDescription.barcode

    const update = (p) => {
        const patient = modelToDetails(p, {
            common: barcode,
        })

        api.updateFull(patient).then(() => {
            success("Patient saved!")
        })
    }

    const formist = useFormist(detailsToModel(patientData), {
        schema: fullSchema,
        onSubmit: update,
    })

    const tabs = [
        {
            label: "Personal Data",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patient">
                        {({ getFieldProps }) => (
                            <PatientForm
                                formist={{ getFieldProps }}
                                anonymous={
                                    formist.getFieldProps("patient.anonymous")
                                        .value
                                }
                                onUnblock={() =>
                                    formist.change("patient.anonymous", false)
                                }
                                onAgeChange={(age) =>
                                    formist.change(
                                        "patientPhysicalDescription.age",
                                        age,
                                    )
                                }
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Physical Description",
            content: (
                <div className="form-container-separator">
                    <SubForm
                        formist={formist}
                        namespace="patientPhysicalDescription">
                        {({ getFieldProps, change }) => (
                            <PatientPhysicalDescriptionForm
                                formist={{ getFieldProps, change }}
                                anonymous={patientData.patient.anonymous}
                            />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Guardian",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientGuardian">
                        {({ getFieldProps }) => (
                            <PatientGuardianForm formist={{ getFieldProps }} />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Diseases",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientDiseases">
                        {({ getFieldProps }) => (
                            <PatientDiseasesForm formist={{ getFieldProps }} />
                        )}
                    </SubForm>
                </div>
            ),
        },
        {
            label: "Drugs",
            content: (
                <div className="form-container-separator">
                    <SubForm formist={formist} namespace="patientDrugs">
                        {({ getFieldProps }) => (
                            <PatientDrugsForm formist={{ getFieldProps }} />
                        )}
                    </SubForm>
                </div>
            ),
        },
    ]
    const [selectedTab, selectTab] = useState(0)
    const patientName = patientData.patient.anonymous
        ? "Anonymous"
        : `${patientData.patient.firstName} ${patientData.patient.lastName}`
    return (
        <>
            <Form
                title={`Edit Patient - ${patientName} - ${barcode}`}
                size="full"
                primaryText="Save"
                {...formist.getFormProps()}
                onCancel={() => history.goBack()}>
                <Tabs
                    tabs={tabs}
                    onSelect={(tab, idx) => selectTab(idx)}
                    selected={selectedTab}
                />
            </Form>
        </>
    )
}

const loadData = ({ api, id }) => {
    return api.loadBarcode(id)
}

const loadReferrals = ({ api }) => {
    return api.loadReferrals()
}

export default withStateContext(
    withFetch(Edit, mkApi(), loadData, useStateContext, () => {}, [
        loadReferrals,
    ]),
    initialState,
    reducer,
)
