function rangeTransformer(value) {
    return !value.low && !value.high
        ? null
        : {
              low: !value.low ? 0 : Number(value.low),
              high: !value.high ? 100 : Number(value.high),
          }
}

function implicationsTransformer(value) {
    if (value && !value.type) {
        return null
    }
    return value
}

function expectedTransformer(value) {
    return { value: Number(value) }
}

export const addMappers = {
    toApiModel(source) {
        const attributes = {
            MacroBiota: {
                range: getAttributeForApi(
                    source,
                    "MacroBiota",
                    "range",
                    rangeTransformer,
                    null,
                ),
                expected: getAttributeForApi(
                    source,
                    "MacroBiota",
                    "expected",
                    expectedTransformer,
                    null,
                ),
                implications: getAttributeForApi(
                    source,
                    "MacroBiota",
                    "implications",
                    implicationsTransformer,
                    null,
                ),
            },
            Vaginal: {
                range: getAttributeForApi(
                    source,
                    "Vaginal",
                    "range",
                    rangeTransformer,
                    null,
                ),
                expected: getAttributeForApi(
                    source,
                    "Vaginal",
                    "expected",
                    expectedTransformer,
                    null,
                ),
                implications: getAttributeForApi(
                    source,
                    "Vaginal",
                    "implications",
                    implicationsTransformer,
                    null,
                ),
            },
            Oral: {
                range: getAttributeForApi(
                    source,
                    "Oral",
                    "range",
                    rangeTransformer,
                    null,
                ),
                expected: getAttributeForApi(
                    source,
                    "Oral",
                    "expected",
                    expectedTransformer,
                    null,
                ),
                implications: getAttributeForApi(
                    source,
                    "Oral",
                    "implications",
                    implicationsTransformer,
                    null,
                ),
            },
        }

        return {
            level: source.level,
            group: source.group,
            attributes,
        }
    },
}

function asStrings(obj) {
    if (typeof obj === "number") {
        return String(obj)
    }
    if (typeof obj === "object") {
        return Object.keys(obj).reduce((acc, current) => {
            return { ...acc, [current]: asStrings(obj[current]) }
        }, {})
    }
    return obj
}

function getAttributeForApi(
    attributes,
    analysisType,
    attribute,
    transform,
    defaultValue,
) {
    if (
        attributes &&
        attributes[attribute] &&
        attributes[attribute][analysisType]
    ) {
        const value = attributes[attribute][analysisType]
        if (value) return transform(value)
    }
    return defaultValue
}

function getAttributeForForm(
    attributes,
    analysisType,
    attribute,
    defaultValue,
) {
    if (
        attributes &&
        attributes[analysisType] &&
        attributes[analysisType][attribute]
    ) {
        const value = attributes[analysisType][attribute]
        if (value.value || value.value === 0) return asStrings(value.value)
        if (value || value === 0) return asStrings(value)
    }
    return defaultValue
}

export const editMappers = {
    toFormModel(source) {
        const range = {
            MacroBiota: getAttributeForForm(
                source.attributes,
                "MacroBiota",
                "range",
                {
                    low: "",
                    high: "",
                },
            ),
            Vaginal: getAttributeForForm(
                source.attributes,
                "Vaginal",
                "range",
                {
                    low: "",
                    high: "",
                },
            ),
            Oral: getAttributeForForm(source.attributes, "Oral", "range", {
                low: "",
                high: "",
            }),
        }
        const expected = {
            MacroBiota: getAttributeForForm(
                source.attributes,
                "MacroBiota",
                "expected",
                "",
            ),
            Vaginal: getAttributeForForm(
                source.attributes,
                "Vaginal",
                "expected",
                "",
            ),
            Oral: getAttributeForForm(
                source.attributes,
                "Oral",
                "expected",
                "",
            ),
        }
        const implications = {
            MacroBiota: getAttributeForForm(
                source.attributes,
                "MacroBiota",
                "implications",
                {
                    text: "",
                    type: "",
                },
            ),
            Vaginal: getAttributeForForm(
                source.attributes,
                "Vaginal",
                "implications",
                {
                    text: "",
                    type: "",
                },
            ),
            Oral: getAttributeForForm(
                source.attributes,
                "Oral",
                "implications",
                {
                    text: "",
                    type: "",
                },
            ),
        }
        return {
            level: source.level,
            group: source.group,
            range,
            expected,
            implications,
        }
    },
    toApiModel(source) {
        const addModel = addMappers.toApiModel(source)
        return {
            ...addModel,
        }
    },
}
