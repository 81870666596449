import React from "react"
import Button from "@atlaskit/button"
import ButtonBar from "./button-bar"

const DefaultButtonBar = ({
    primaryText = "Add",
    secondaryText = "Cancel",
    onSubmit,
    onCancel,
    isSubmitEnabled = true,
    isCancelEnabled = true,
    buttons = ["submit", "cancel"],
    buttonsClassName,
    separator = true,
}) => {
    const standardButtons = {
        submit: (
            <Button
                key="submit"
                appearance="primary"
                onClick={onSubmit}
                type="submit"
                isDisabled={!isSubmitEnabled}>
                {primaryText}
            </Button>
        ),
        cancel: (
            <Button
                key="cancel"
                appearance="secondary"
                onClick={onCancel}
                isDisabled={!isCancelEnabled}>
                {secondaryText}
            </Button>
        ),
    }
    return (
        <ButtonBar buttonsClassName={buttonsClassName} separator={separator}>
            {buttons.map((b) => standardButtons[b] || b)}
        </ButtonBar>
    )
}

export default DefaultButtonBar
