import React from "react"
import TableField, { printableErrors } from "../shared/components/tablefield"

const colorRangeSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "color",
        label: "Color",
        type: "select",
        options: [
            {
                label: "Green",
                value: "Green",
            },
            {
                label: "Yellow",
                value: "Yellow",
            },
            {
                label: "Orange",
                value: "Orange",
            },
            {
                label: "Red",
                value: "Red",
            },
        ],
    },
]

const SectionBacteriumColorRangeEdit = ({ field, errors = {} }) => {
    const { value, onChange, error, ...other } = field

    const colors = value.map((r) => ({
        low: r.split(";")[0],
        high: r.split(";")[1],
        color: r.split(";")[2],
    }))

    const onRangeChange = (e) => {
        onChange({
            target: {
                value: e.target.value
                    ? e.target.value.map(
                          (r) => r.low + ";" + r.high + ";" + r.color,
                      )
                    : [],
            },
        })
    }
    return (
        <div className="form-row">
            <TableField
                label=""
                width="col"
                editable
                moveRows
                schema={colorRangeSchema}
                errors={printableErrors(errors, "values")}
                {...other}
                value={colors}
                onChange={onRangeChange}
            />
        </div>
    )
}

export default SectionBacteriumColorRangeEdit
