import React from "react"
import CheckField from "../../views/shared/components/checkfield"
import { arrayToObj, objToArray, camelize } from "../../shared/fun"
import { checksToObj, objToChecks, detailsToObj } from "./fun"
import { flatten, unflatten } from "../../shared/fun"

export const diseaseName = (group, disease, type) =>
    type
        ? `${camelize(group)}_${camelize(disease)}_${type}`
        : `${camelize(group)}_${camelize(disease)}`

const DeseaseField = ({ formist, group, label }) => {
    const symtomsName = diseaseName(group, label, "symtoms")
    const familiarityName = diseaseName(group, label, "familiarity")
    return (
        <div className="row">
            <div className="col">
                <label>{label}</label>
            </div>
            <div className="col">
                <CheckField
                    className="form-check-input-patient"
                    id={symtomsName}
                    {...formist.getFieldProps(symtomsName)}
                />
            </div>
            <div className="col">
                <CheckField
                    className="form-check-input-patient"
                    id={familiarityName}
                    {...formist.getFieldProps(familiarityName)}
                />
            </div>
        </div>
    )
}

const DeseaseGroup = ({ formist, label, checks }) => {
    return (
        <>
            <div className="row col-sm-4">
                <span>
                    <b>{label}</b>
                </span>
            </div>
            {checks.map((x, index) => {
                return (
                    <DeseaseField
                        key={index}
                        formist={formist}
                        group={label}
                        {...x}
                    />
                )
            })}
        </>
    )
}

export const formInputs = [
    {
        label: "Respiratory",
        checks: [
            { label: "Allergy" },
            { label: "Asthma" },
            { label: "Chronic Bronchitis" },
        ],
    },
    {
        label: "Hematologic",
        checks: [{ label: "Anemia" }, { label: "Macrocytosis" }],
    },
    {
        label: "Bones and Joints",
        checks: [
            { label: "Rheumatoid Arthritis" },
            { label: "Arthrosis" },
            { label: "Joint Stiffness" },
            { label: "Scoliosis" },
            { label: "Osteoporosis" },
            { label: "Discopatia" },
            { label: "Fibromyalgia" },
        ],
    },
    {
        label: "Cardiovascular",
        checks: [
            { label: "Atherosclerosis" },
            { label: "Tachycardia" },
            { label: "Bradycardia" },
            { label: "Thrombosis" },
            { label: "Arterial Hypotension" },
            { label: "Hypertension" },
            { label: "Heart Failure" },
            { label: "High Cholesterol" },
            { label: "High Homocysteine" },
        ],
    },
    {
        label: "Food",
        checks: [
            { label: "Colitis" },
            { label: "Slow Digestion" },
            { label: "Liver Dysfunction" },
            { label: "Ulcer" },
            { label: "Constipation" },
            { label: "Liver Calculations" },
            { label: "Lactose Intolerance" },
            { label: "Celiac Disease" },
            { label: "Diverticulosis" },
            { label: "Gastritis" },
            { label: "Crohn's Disease" },
            { label: "Diarrhea" },
        ],
    },
    {
        label: "Kidney / Urogenital",
        checks: [
            { label: "Kidney Stones" },
            { label: "Gout" },
            { label: "Kidney Failure" },
            { label: "Chronic Cystitis" },
            { label: "Recurrent Cystitis" },
            { label: "Poor Diuresis" },
            { label: "Candidiasis" },
            { label: "Urethritis Prostatitis" },
            { label: "Bacterial Vaginosis" },
        ],
    },
    {
        label: "Endocrine",
        checks: [
            { label: "type I diabetes" },
            { label: "type II diabetes" },
            { label: "hypercholesterolemia" },
            { label: "hypocholesterolemia" },
            { label: "hyperthyroidism" },
            { label: "hypothyroidism" },
            { label: "decreased libido" },
            { label: "hyperparathyroidism" },
            { label: "hypoglycemia" },
            { label: "obesity" },
            { label: "thinness" },
            { label: "ipersurreanalismo" },
            { label: "hypoadrenalism" },
        ],
    },
    {
        label: "Dermatological",
        checks: [
            { label: "Eczema" },
            { label: "Acne" },
            { label: "Dermatitis" },
            { label: "Urticaria" },
            { label: "Psoriasis" },
            { label: "Scleroderma" },
            { label: "Disidrosi" },
        ],
    },
    {
        label: "Metabolism",
        checks: [
            { label: "Hyperglycemia" },
            { label: "Insulin resistance" },
            { label: "Metabolic Syndrome" },
        ],
    },
    {
        label: "Neurologic",
        checks: [
            { label: "Asthenia" },
            { label: "Anxiety" },
            { label: "Depression" },
            { label: "Stress" },
            { label: "Panic Attacks" },
            { label: "Migraine" },
            { label: "Sleeping Disorders" },
        ],
    },
    {
        label: "Female",
        checks: [
            { label: "Mammary Cysts" },
            { label: "Menstrual Irregularity" },
            { label: "Oviarian Cysts" },
            { label: "Premenopause" },
            { label: "Menopause" },
            { label: "Polycystic Ovary Syndrome" },
        ],
    },
    {
        label: "Appetite",
        checks: [
            { label: "Lack of appetite" },
            { label: "Insatiable" },
            { label: "Regular" },
        ],
    },
]

const allDiseasesForGroup = (group, type) =>
    group.checks.reduce(
        (acc, disease) => [
            ...acc,
            diseaseName(group.label, disease.label, type),
        ],
        [],
    )

export const diseasesForGroup = (group) =>
    formInputs
        .filter((i) => camelize(i.label) === group)[0]
        .checks.map((d) => camelize(d.label))

export const allDiseasesGroups = () =>
    formInputs.reduce((acc, group) => [...acc, camelize(group.label)], [])

export const allDiseases = (type) =>
    formInputs.reduce(
        (acc, group) => [...acc, ...allDiseasesForGroup(group, type)],
        [],
    )

const groupsToObj = (groups, defaults) =>
    arrayToObj(
        groups,
        (x) => x.label,
        (x) => checksToObj(x.checks, defaults),
    )

export const model = groupsToObj(formInputs, {
    symtoms: false,
    familiarity: false,
})

const objToGroups = (obj) => objToArray(obj, "name", "items", objToChecks)

const internalDetailsToModel = (details, empty) => {
    if (details) {
        return {
            ...empty,
            ...arrayToObj(
                details.groups,
                (x) => x.name,
                (x) => detailsToObj(x.items),
            ),
        }
    }
    return empty
}

export const detailsToModel = (data) =>
    flatten(internalDetailsToModel(data, model))

export const modelToDetails = (data) => objToGroups(unflatten(data))

const PatientDiseasesForm = ({ formist }) => {
    return (
        <>
            <div className="row">
                <div className="col"> </div>
                <div className="col"> Symptoms </div>
                <div className="col">Familiarity</div>
            </div>
            {formInputs.map((x, index) => {
                return <DeseaseGroup key={index} formist={formist} {...x} />
            })}
        </>
    )
}

export default PatientDiseasesForm
