import { apis } from "../../shared/apis"
import { actions } from "./store"

export function mkApi(dispatch) {
    return {
        loadAll(pageNumber, pageSize) {
            return apis.genes
                .loadAll(pageNumber, pageSize)
                .then((genes) => dispatch(actions.loadAll(genes)))
        },
        load(id) {
            return apis.genes.load(btoa(id))
        },
        update(value, isNew = false) {
            const { code, ...body } = value
            return apis.genes
                .updateOne(code, body)
                .then(() => dispatch(actions[isNew ? "add" : "edit"](value)))
        },
        search(pattern, pageNumber, pageSize) {
            return apis.genes
                .search(pattern, pageNumber, pageSize)
                .then((genes) =>
                    dispatch(actions.search({ pattern, ...genes })),
                )
        },
    }
}
